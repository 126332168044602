export const GET_JOBS_START = 'DASHBOARD::GET_JOBS_START';
export const GET_JOBS_SUCCESS = 'DASHBOARD::GET_JOBS_SUCCESS';
export const GET_JOBS_FAIL = 'DASHBOARD::GET_JOBS_FAIL';

export const DELETE_JOB_START = 'DASHBOARD::DELETE_JOB_START';
export const DELETE_JOB_SUCCESS = 'DASHBOARD::DELETE_JOB_SUCCESS';
export const DELETE_JOB_FAIL = 'DASHBOARD::DELETE_JOB_FAIL';

export const ARCHIVE_JOB_START = 'DASHBOARD::ARCHIVE_JOB_START';
export const ARCHIVE_JOB_SUCCESS = 'DASHBOARD::ARCHIVE_JOB_SUCCESS';
export const ARCHIVE_JOB_FAIL = 'DASHBOARD::ARCHIVE_JOB_FAIL';

export const PUBLISH_JOB_START = 'DASHBOARD::PUBLISH_JOB_START';
export const PUBLISH_JOB_SUCCESS = 'DASHBOARD::PUBLISH_JOB_SUCCESS';
export const PUBLISH_JOB_FAIL = 'DASHBOARD::PUBLISH_JOB_FAIL';

export const OPEN_JOB_ACTION_MODAL = 'DASHBOARD::OPEN_JOB_ACTION_MODAL';
export const CLOSE_JOB_ACTION_MODAL = 'DASHBOARD::CLOSE_JOB_ACTION_MODAL';

export const ADD_JOB_COLLABORATOR_START =
  'DASHBOARD::ADD_JOB_COLLABORATOR_START';
export const ADD_JOB_COLLABORATOR_SUCCESS =
  'DASHBOARD::ADD_JOB_COLLABORATOR_SUCCESS';
export const ADD_JOB_COLLABORATOR_FAIL = 'DASHBOARD::ADD_JOB_COLLABORATOR_FAIL';

export const CREATE_AND_ADD_JOB_COLLABORATOR_START =
  'DASHBOARD::CREATE_AND_ADD_JOB_COLLABORATOR_START';
export const CREATE_AND_ADD_JOB_COLLABORATOR_SUCCESS =
  'DASHBOARD::CREATE_AND_ADD_JOB_COLLABORATOR_SUCCESS';
export const CREATE_AND_ADD_JOB_COLLABORATOR_FAIL =
  'DASHBOARD::CREATE_AND_ADD_JOB_COLLABORATOR_FAIL';

export const REMOVE_JOB_COLLABORATOR_START =
  'DASHBOARD::REMOVE_JOB_COLLABORATOR_START';
export const REMOVE_JOB_COLLABORATOR_SUCCESS =
  'DASHBOARD::REMOVE_JOB_COLLABORATOR_SUCCESS';
export const REMOVE_JOB_COLLABORATOR_FAIL =
  'DASHBOARD::REMOVE_JOB_COLLABORATOR_FAIL';

export const RESET_DASHBOARD_STORE = 'DASHBOARD::RESET_DASHBOARD_STORE';
