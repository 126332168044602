import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Pagination,
  Spinner,
  Grid,
  Heading,
  Icon,
  LinkButton,
} from '@lionstep/ui';
import { Helmet } from 'react-helmet';

// Components
import JobCard from './components/JobCard';
import ArchiveJobModal from './components/ArchiveJobModal/ArchiveJobModal';
import DeleteJobModal from './components/DeleteJobModal/DeleteJobModal';
import JobMailingPermissionsModal from './components/JobMailingPermissionsModal';
import JobShareModal from './components/JobShareModal';
import ValidatedCandidates from './components/ValidatedCandidates';
import PublishJobModal from './components/PublishJobModal/PublishJobModal';
import { JobPlaceholder } from '../../../../components/refactoring/placeholders';
import Filters from './components/Filters';

// selectors
import {
  dashboardIsLoadingSelector,
  dashboardJobsSelector,
  dashboardTotalJobsSelector,
  jobActionModalSelector,
  selectedJobSelector,
} from '../../redux/selectors/dashboard.selectors';

// actions
import {
  getJobs,
  deleteJob,
  archiveJob,
  publishJob,
  closeJobActionModal,
  resetDashboardStore,
} from '../../redux/actions';
import { getCollaborators } from '../../../company/redux/actions';

// hooks
import useSearchParams from '../../../../hooks/useSearchParams';
import useIsLionstepAdmin from '../../../../hooks/useIsLionstepAdmin';

// constants
import { PAGE_SIZE, JOB_DROPDOWN_ACTIONS } from '../../dashboard.constants';

// Queries
import { useUserQuery } from '../../../../queries/userQueries';

// Styles
import styles from './dashboard.module.scss';

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(dashboardIsLoadingSelector);
  const jobs = useSelector(dashboardJobsSelector);
  const totalJobs = useSelector(dashboardTotalJobsSelector);
  const selectedJob = useSelector(selectedJobSelector);
  const jobActionModal = useSelector(jobActionModalSelector);
  const isLionstepAdmin = useIsLionstepAdmin();
  const { data: user } = useUserQuery();

  const { params, setQueryParams } = useSearchParams();
  const { page: initialPage = 1, archived = false } = params;

  useEffect(() => {
    const offset = PAGE_SIZE * (initialPage - 1);

    dispatch(getJobs({ offset, archived, user: params.collaborator }));
    dispatch(getCollaborators());

    return () => dispatch(resetDashboardStore());
  }, [dispatch, archived]);

  const onPaginationChange = async (page) => {
    try {
      const offset = PAGE_SIZE * (page - 1);

      setQueryParams({ ...params, page });
      await dispatch(getJobs({ offset, archived }));
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch {
      // DO NOTHING
    }
  };

  const refetchJobsOnArchiveDeletePublish = () => {
    const lastPage = Math.ceil((totalJobs - 1) / PAGE_SIZE);
    const currentPage = initialPage <= lastPage ? initialPage : lastPage;
    const offset = PAGE_SIZE * (currentPage - 1);

    setQueryParams({ ...params, page: currentPage });
    dispatch(getJobs({ offset, archived, user: params.collaborator }));
  };

  const onJobArchive = async (id, title) => {
    try {
      await dispatch(archiveJob({ id, title, user }));
      refetchJobsOnArchiveDeletePublish();
    } catch {
      // DO NOTHING
    }
  };

  const onJobDelete = async (id) => {
    try {
      await dispatch(deleteJob({ id }));
      refetchJobsOnArchiveDeletePublish();
    } catch {
      // DO NOTHING
    }
  };

  const onJobPublish = async (id, title) => {
    try {
      await dispatch(publishJob({ id, title, user }));
      refetchJobsOnArchiveDeletePublish();
    } catch {
      // DO NOTHING
    }
  };

  const onActionModalClose = () => dispatch(closeJobActionModal());

  return (
    <Grid.Container className={styles.dashboard}>
      <Helmet>
        <title>{t('dashboard')} | Lionstep</title>
      </Helmet>

      {isLoading && <Spinner position="fixed" size={100} />}
      {/* Title */}
      <Grid.Row gutter={30}>
        <Grid.Col
          className={styles.header}
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          <Heading className={styles.title}>
            {archived ? (
              <>
                {t('dashboard_page.title.archived')}
                <span className={styles.totalCount}>{totalJobs} Archived</span>
              </>
            ) : (
              <>
                {t('dashboard_page.title.active')}
                <span className={styles.totalCount}>{totalJobs} Active</span>
              </>
            )}
          </Heading>
          {isLionstepAdmin && (
            <LinkButton
              to="/create-job"
              component={Link}
              data-testid="add-new-job-btn"
              className={styles.addNewJobBtn}
              type="secondary"
            >
              <Icon name="Plus" /> {t('dashboard_page.add_new_job_btn')}
            </LinkButton>
          )}
        </Grid.Col>
      </Grid.Row>
      {!archived && (
        <Grid.Row gutter={30}>
          <Grid.Col
            xs={24}
            xl={{ span: 22, offset: 1 }}
            xxl={{ span: 18, offset: 3 }}
          >
            <ValidatedCandidates />
          </Grid.Col>
        </Grid.Row>
      )}

      {((!archived && isLionstepAdmin && !!jobs.length) ||
        (!archived &&
          isLionstepAdmin &&
          !jobs.length &&
          params.collaborator)) && (
        <Grid.Row>
          <Grid.Col
            xs={24}
            xl={{ span: 22, offset: 1 }}
            xxl={{ span: 18, offset: 3 }}
          >
            <Filters />
          </Grid.Col>
        </Grid.Row>
      )}
      <Grid.Row gutter={[30, 30]}>
        {/* Render Jobs */}
        {jobs.length &&
          jobs.map((job, index) => (
            <Grid.Col
              xs={24}
              lg={12}
              xl={{ span: 11, offset: index % 2 === 0 ? 1 : 0 }}
              xxl={{ span: 9, offset: index % 2 === 0 ? 3 : 0 }}
              key={job.id}
            >
              <JobCard job={job} archived={archived} />
            </Grid.Col>
          ))}
        {/* Render Empty message */}
        {!isLoading && !jobs.length && (
          <Grid.Col xs={24}>
            <JobPlaceholder position="right">
              <span className={styles.placeholderText}>
                {archived
                  ? t('dashboard_page.no_archived_jobs')
                  : t('dashboard_page.no_active_jobs')}
              </span>
            </JobPlaceholder>
          </Grid.Col>
        )}
      </Grid.Row>
      {/* Render Pagination */}
      <Grid.Row className={styles.paginationWrapper} gutter={30}>
        <Grid.Col
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          {!!jobs.length && (
            <Pagination
              className={styles.pagination}
              currentPage={Number(initialPage)}
              pageSize={PAGE_SIZE}
              totalPages={totalJobs}
              onChange={onPaginationChange}
            />
          )}
        </Grid.Col>
      </Grid.Row>

      {/* Action Modals */}
      <DeleteJobModal
        show={jobActionModal === JOB_DROPDOWN_ACTIONS.deleteJob}
        onConfirm={() => onJobDelete(selectedJob.id)}
        onClose={onActionModalClose}
      />
      <ArchiveJobModal
        show={jobActionModal === JOB_DROPDOWN_ACTIONS.archiveJob}
        onConfirm={() =>
          onJobArchive(selectedJob.id, selectedJob.opening_title)
        }
        onClose={onActionModalClose}
      />
      <PublishJobModal
        show={jobActionModal === JOB_DROPDOWN_ACTIONS.publishJob}
        onConfirm={() =>
          onJobPublish(selectedJob.id, selectedJob.opening_title)
        }
        onClose={onActionModalClose}
      />
      <JobMailingPermissionsModal
        show={jobActionModal === JOB_DROPDOWN_ACTIONS.jobMailingPermissions}
        job={selectedJob}
        onClose={onActionModalClose}
      />

      <JobShareModal
        show={jobActionModal === JOB_DROPDOWN_ACTIONS.shareJob}
        job={selectedJob}
        onClose={onActionModalClose}
      />
    </Grid.Container>
  );
};

export default Dashboard;
