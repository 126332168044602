import React from 'react';
import PropTypes from 'prop-types';

const AdditionalCustomBonus = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 74 68"
    className={className}
  >
    <g id="Coloured_1_">
      <path
        fill={companyColour}
        d="M55,47.2v-9.1c2.3,0.4,3.9,2.3,3.9,4.5c0,0.6,0.4,1,1,1s1-0.4,1-1c0-3.3-2.6-6-5.9-6.5V35c0-0.6-0.4-1-1-1
          s-1,0.4-1,1v1.1c-3.4,0.4-6,3.2-6,6.5c0,3.3,2.6,6,6,6.5v9.1c-2.3-0.4-4-2.3-4-4.5c0-0.6-0.4-1-1-1s-1,0.4-1,1c0,3.3,2.6,6,6,6.5
          V61c0,0.6,0.4,1,1,1s1-0.4,1-1v-0.8c3.4-0.5,5.9-3.2,5.9-6.5C60.9,50.4,58.4,47.7,55,47.2z M49,42.6c0-2.2,1.7-4.1,4-4.5v9
          C50.7,46.6,49,44.8,49,42.6z M55,58.2v-8.9c2.3,0.4,3.9,2.3,3.9,4.5S57.3,57.7,55,58.2z"
      />
      <path
        fill={companyColour}
        d="M22,28c3.3,0,6-2.7,6-6s-2.7-6-6-6s-6,2.7-6,6S18.7,28,22,28z M22,18c2.2,0,4,1.8,4,4s-1.8,4-4,4s-4-1.8-4-4
          S19.8,18,22,18z"
      />
      <path
        fill={companyColour}
        d="M37,31c-3.3,0-6,2.7-6,6c0,2.8,2,5.3,4.8,5.9c0.1,0,0.1,0,0.2,0c0.5,0,0.9-0.3,1-0.8c0.1-0.5-0.2-1.1-0.8-1.2
          c-1.9-0.4-3.2-2-3.2-3.9c0-2.2,1.8-4,4-4c1.3,0,2.5,0.6,3.3,1.7c0.3,0.4,0.9,0.6,1.4,0.2c0.4-0.3,0.6-0.9,0.2-1.4
          C40.8,31.9,38.9,31,37,31z"
      />
      <path
        fill={companyColour}
        d="M41.3,16.3l-25,25c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l25-25c0.4-0.4,0.4-1,0-1.4
          S41.7,15.9,41.3,16.3z"
      />
    </g>
    <g id="Grey_1_">
      <path
        fill="#899198"
        d="M48.2,3.8C50.2,2.6,52.6,2,55,2c7.7,0,14,6.3,14,14c0,4.2-1.9,8.2-5.2,10.9c-0.4,0.3-0.5,1-0.1,1.4
          c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2C68.8,25.4,71,20.9,71,16c0-8.8-7.2-16-16-16c-2.7,0-5.4,0.7-7.8,2
          c-0.5,0.3-0.7,0.9-0.4,1.4C47.1,3.9,47.7,4,48.2,3.8z"
      />
      <path
        fill="#899198"
        d="M33,56.8c-1.2,0.1-2.3,0.2-3.5,0.2C14.3,57,2,44.7,2,29.5c0-6.7,2.4-13.1,6.8-18.1c0.4-0.4,0.3-1-0.1-1.4
          c-0.4-0.4-1-0.3-1.4,0.1C2.6,15.4,0,22.3,0,29.5C0,45.8,13.2,59,29.5,59c1.3,0,2.5-0.1,3.8-0.2c0.5-0.1,0.9-0.6,0.9-1.1
          C34.1,57.1,33.6,56.7,33,56.8z"
      />
      <path
        fill="#899198"
        d="M11.4,8.8C16.4,4.4,22.8,2,29.5,2c13.6,0,25.3,10.1,27.2,23.5c0.1,0.5,0.5,0.9,1,0.9c0,0,0.1,0,0.1,0
          c0.5-0.1,0.9-0.6,0.8-1.1C56.6,10.8,44,0,29.5,0C22.3,0,15.4,2.6,10,7.3c-0.4,0.4-0.5,1-0.1,1.4C10.3,9.1,11,9.2,11.4,8.8z"
      />
      <path
        fill="#899198"
        d="M54,28c-11,0-20,9-20,20s9,20,20,20s20-9,20-20S65,28,54,28z M54,66c-9.9,0-18-8.1-18-18s8.1-18,18-18
          s18,8.1,18,18S63.9,66,54,66z"
      />
    </g>
  </svg>
);

AdditionalCustomBonus.defaultProps = {
  companyColour: '#ab1923',
};

AdditionalCustomBonus.propTypes = {
  companyColour: PropTypes.string,
};

export default AdditionalCustomBonus;
