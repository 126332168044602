import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Spinner } from '@lionstep/ui';
import 'chart.js/auto';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import classnames from 'classnames/bind';

// Actions
import { getInsightsByJobId } from './insights.actions';

// Selectors
import {
  isLoadingInsightsSelector,
  insightsSelector,
} from './insights.selectors';
import {
  extraInfoSelector,
  jobSelector,
} from '../../redux/selectors/job.selectors';

// Components
import ContentCard from '../../../../components/refactoring/contentCard';
import PageHeading from '../../../../components/refactoring/pageHeading/PageHeading';
import { TimeToFirstValidation, NetPromoterScore } from './components/infoBox';
import SalaryRangeChart from './components/salaryRangeChart';
import Disclaimer from './components/disclaimer';

import CandidateFunnelChart from '../../components/Charts/CandidateFunnelChart';
import NewSourcedCandidateChart from '../../components/Charts/NewSourcedCandidateChart';
import RejectedReasonsChart from '../../components/Charts/RejectedReasonsChart';

// Helpers
import {
  candidateFunnelFormatter,
  newSourcedCandidateFormatter,
  rejectionReasonFormatter,
} from './insights.helpers';

// Queries
import { useUserQuery } from '../../../../queries/userQueries';

// Constants
import { USER_ROLES } from '../../../../constants/user.const';

// Styles
import styles from './insights.module.scss';

const cx = classnames.bind(styles);

const Insights = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const isLoading = useSelector(isLoadingInsightsSelector);
  const insights = useSelector(insightsSelector);
  const job = useSelector(jobSelector);
  const extraInfo = useSelector(extraInfoSelector);
  const { data: user } = useUserQuery();

  useEffect(() => {
    dispatch(getInsightsByJobId({ id }));
  }, []);

  if (isLoading) {
    return <Spinner position="fixed" size={100} />;
  }

  if (!insights) {
    return null;
  }

  return (
    <Grid.Container className={styles.insights}>
      <Helmet>
        <title>{t('insights_page.title')} | Lionstep</title>
      </Helmet>

      <Grid.Row>
        <Grid.Col xs={24}>
          <PageHeading
            className={styles.pageHeading}
            additionalInfo={job?.opening_title}
          >
            {t('insights_page.title')}
          </PageHeading>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={24}>
          <Disclaimer />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row gutter={[30, 30]}>
        <Grid.Col xs={24} sm={12} xl={8}>
          <ContentCard className={styles.contentCard}>
            <TimeToFirstValidation insights={insights} />
          </ContentCard>
        </Grid.Col>

        <Grid.Col xs={24} sm={12} xl={8}>
          <ContentCard className={styles.contentCard}>
            <NetPromoterScore insights={insights} />
          </ContentCard>
        </Grid.Col>

        <Grid.Col xs={24}>
          <ContentCard className={styles.contentCard}>
            <CandidateFunnelChart
              insights={insights}
              formatterFunc={candidateFunnelFormatter}
            />
          </ContentCard>
        </Grid.Col>

        <Grid.Col xs={24} xl={12}>
          <ContentCard
            className={cx(styles.contentCard, styles.cardFullHeight)}
          >
            <NewSourcedCandidateChart
              insights={insights}
              formatterFunc={newSourcedCandidateFormatter}
            />
          </ContentCard>
        </Grid.Col>

        <Grid.Col xs={24} xl={12}>
          <ContentCard
            className={cx(styles.contentCard, styles.cardFullHeight)}
          >
            <RejectedReasonsChart
              formatterFunc={rejectionReasonFormatter}
              insights={insights}
            />
          </ContentCard>
        </Grid.Col>

        {user.role !== USER_ROLES.viewer && (
          <Grid.Col xs={24} xl={12}>
            <ContentCard
              className={cx(styles.contentCard, styles.cardFullHeight)}
            >
              <SalaryRangeChart insights={insights} extraInfo={extraInfo} />
            </ContentCard>
          </Grid.Col>
        )}
      </Grid.Row>
    </Grid.Container>
  );
};

export default Insights;
