import React from 'react';
import PropTypes from 'prop-types';

const CarsEbikes = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 69"
    className={className}
  >
    <g id="Coloured_1_">
      <path
        d="M50,14c0-7.7-6.3-14-14-14S22,6.3,22,14s6.3,14,14,14S50,21.7,50,14z M36,26c-6.6,0-12-5.4-12-12S29.4,2,36,2
    s12,5.4,12,12S42.6,26,36,26z"
        fill={companyColour}
      />
      <path
        d="M43,13h-6V7c0-0.6-0.4-1-1-1s-1,0.4-1,1v7c0,0.6,0.4,1,1,1h7c0.6,0,1-0.4,1-1S43.6,13,43,13z"
        fill={companyColour}
      />
      <path
        d="M24.5,51c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5S26.4,51,24.5,51z M24.5,56
          c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S25.3,56,24.5,56z"
        fill={companyColour}
      />
      <path
        d="M47.5,51c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5S49.4,51,47.5,51z M47.5,56
          c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S48.3,56,47.5,56z"
        fill={companyColour}
      />
      <path
        d="M8.5,46C6.6,46,5,47.6,5,49.5S6.6,53,8.5,53s3.5-1.6,3.5-3.5S10.4,46,8.5,46z M8.5,51C7.7,51,7,50.3,7,49.5
          S7.7,48,8.5,48s1.5,0.7,1.5,1.5S9.3,51,8.5,51z"
        fill={companyColour}
      />
      <path
        d="M63.5,46c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5S65.4,46,63.5,46z M63.5,51
          c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S64.3,51,63.5,51z"
        fill={companyColour}
      />
    </g>
    <g id="Grey_1_">
      <path
        d="M41,56H31c-0.6,0-1,0.4-1,1s0.4,1,1,1h10c0.6,0,1-0.4,1-1S41.6,56,41,56z"
        fill="#899198"
      />
      <path
        d="M41,51H31c-0.6,0-1,0.4-1,1s0.4,1,1,1h10c0.6,0,1-0.4,1-1S41.6,51,41,51z"
        fill="#899198"
      />
      <path
        d="M66.8,41.1l-2.2-7.2c-0.5-1.7-2.1-2.9-3.8-2.9H43.2c-1.8,0-3.3,1.2-3.8,2.9L38.8,36h-5.5l-0.6-2.1
          c-0.5-1.7-2.1-2.9-3.8-2.9H11.2c-1.8,0-3.3,1.1-3.8,2.9l-2.2,7.2C2.3,41.4,0,44,0,47v10c0,0.6,0.4,1,1,1h1v4c0,1.1,0.9,2,2,2h5
          c1.1,0,2-0.9,2-2v-4h5v4c0,0.6,0.4,1,1,1h1v4c0,1.1,0.9,2,2,2h5c1.1,0,2-0.9,2-2v-4h18v4c0,1.1,0.9,2,2,2h5c1.1,0,2-0.9,2-2v-4h1
          c0.6,0,1-0.4,1-1v-4h5v4c0,1.1,0.9,2,2,2h5c1.1,0,2-0.9,2-2v-4h1c0.6,0,1-0.4,1-1V47C72,44,69.7,41.4,66.8,41.1z M41.3,34.4
          c0.3-0.8,1-1.4,1.9-1.4h17.5c0.9,0,1.7,0.6,1.9,1.4l2,6.6H49.2l-0.6-2.1c-0.5-1.7-2.1-2.9-3.8-2.9h-3.9L41.3,34.4z M25.3,39.4
          c0.3-0.9,1-1.4,1.9-1.4h17.5c0.9,0,1.7,0.6,1.9,1.4l2,6.6H23.3L25.3,39.4z M9.3,34.4c0.3-0.9,1-1.4,1.9-1.4h17.5
          c0.9,0,1.7,0.6,1.9,1.4l0.5,1.6h-3.9c-1.8,0-3.3,1.1-3.8,2.9L22.8,41H7.3L9.3,34.4z M9,63v-1h0L9,63z M9,62l-5,0v-4h5L9,62z M10,56
          H3H2v-9c0-2.2,1.8-4,4-4h16.2l-0.9,3c-0.1,0-0.2,0-0.2,0h-6c-0.6,0-1,0.4-1,1s0.4,1,1,1h2.5c-0.7,0.8-1.3,1.9-1.5,3H15
          c-0.6,0-1,0.4-1,1s0.4,1,1,1h1v3H10z M25,68v-1h0L25,68z M25,67l-5,0v-4h5L25,67z M52,68v-1h0L52,68z M52,67l-5,0v-4h5L52,67z
          M54,61h-1h-7H26h-7h-1v-9c0-2.2,1.8-4,4-4h28c2.2,0,4,1.8,4,4V61z M68,62h-5v-4h5L68,62z M70,56h-1h-7h-6v-3h1c0.6,0,1-0.4,1-1
          s-0.4-1-1-1h-1.1c-0.2-1.1-0.7-2.2-1.5-3H57c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6c-0.1,0-0.2,0-0.2,0l-0.9-3H66c2.2,0,4,1.8,4,4V56z"
        fill="#899198"
      />
    </g>
  </svg>
);

CarsEbikes.defaultProps = {
  companyColour: '#ab1923',
};

CarsEbikes.propTypes = {
  companyColour: PropTypes.string,
};

export default CarsEbikes;
