import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Card,
  Heading,
  FormInput,
  FormSelectV2,
  Button,
  Grid,
  Spinner,
} from '@lionstep/ui';

// Actions
import { addCredits } from '../../../../redux/actions';

// Selectors
import {
  creditsPackagesSelector,
  isLoadingCreditsPackagesSelector,
  isLoadingAddCreditsSelector,
} from '../../../../redux/selectors/credits.selectors';

// Styles
import styles from './addCreditsCard.module.scss';

// Schema
const addCreditsSchema = Yup.object().shape({
  comment: Yup.string().max(250).required('Required'),
  package: Yup.object()
    .shape({
      value: Yup.string().required('Required'),
      label: Yup.string().required('Required'),
    })
    .nullable()
    .required('Required'),
});

const AddCreditsCard = () => {
  const dispatch = useDispatch();
  const packages = useSelector(creditsPackagesSelector);
  const isLoadingPackages = useSelector(isLoadingCreditsPackagesSelector);
  const isLoadingAddCredits = useSelector(isLoadingAddCreditsSelector);

  const formMethods = useForm({
    defaultValues: {
      comment: '',
      package: null,
    },
    resolver: yupResolver(addCreditsSchema),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  const handleOnAddCredits = (values) => {
    dispatch(
      addCredits({
        comment: values.comment,
        id: values.package.value,
        onSuccess: () => {
          reset({ comment: '', package: null });
        },
      }),
    );
  };

  return (
    <Card className={styles.addCreditsCard} data-testid="add-credits-card">
      {(isLoadingPackages || isLoadingAddCredits) && (
        <Spinner position="fixed" size={100} />
      )}

      <Heading className={styles.addCreditsCardTitle} level={4}>
        Add credits
      </Heading>

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleOnAddCredits)}>
          <Grid.Row gutter={30}>
            <Grid.Col xs={24} md={12}>
              <FormInput
                name="comment"
                label={'Comment'}
                placeholder="Add short description"
                error={errors.comment?.message}
              />
            </Grid.Col>

            <Grid.Col xs={24} md={12}>
              <FormSelectV2
                name="package"
                label="CHF Packages"
                placeholder="Choose package"
                error={errors.package?.message}
                options={packages
                  ?.filter((pack) => pack.currency === 'CHF')
                  .map((pack) => ({
                    value:
                      process.env.REACT_APP_NODE_ENV === 'production'
                        ? pack.id
                        : pack.public_uid,
                    label: `${pack.credits} credits - ${pack.total_price} ${pack.currency}`,
                  }))}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row gutter={30}>
            <Grid.Col xs={24}>
              <Button typeHtml="submit">Add Credits</Button>
            </Grid.Col>
          </Grid.Row>
        </form>
      </FormProvider>
    </Card>
  );
};

export default AddCreditsCard;
