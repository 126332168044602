import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@lionstep/ui';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

// Components
import NoDataLabel from './NoDataLabel';

// Styles
import styles from './charts.module.scss';

const NewSourcedCandidateChart = ({ insights, formatterFunc, hasLegend }) => {
  const { t } = useTranslation();

  const config = formatterFunc({
    candidatesInternal: insights.check_interest_extension,
    candidatesExternal: insights.check_interest_non_extension,
    styles,
    t,
  });

  const noData =
    !insights.check_interest_extension.length &&
    !insights.check_interest_non_extension.length;

  return (
    <>
      <Heading level={4} className={styles.barTitle}>
        {t('insights_page.new_sourced_candidates.title')}
        {noData && <NoDataLabel className={styles.noDataLabel} />}
      </Heading>
      {hasLegend && (
        <div
          id="newSourcedCandidateLegend"
          className={styles.barContainerLegend}
        />
      )}
      <div className={styles.barContainer}>
        <div className={styles.barContainerChart}>
          <Bar {...config} />
        </div>
      </div>
    </>
  );
};

NewSourcedCandidateChart.defaultProps = {
  hasLegend: false,
};

NewSourcedCandidateChart.propTypes = {
  hasLegend: PropTypes.bool,
  formatterFunc: PropTypes.func.isRequired,
  insights: PropTypes.shape().isRequired,
};

export default NewSourcedCandidateChart;
