import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Modal, Text, Spinner, FormSelectV2, FormTextarea } from '@lionstep/ui';
import classnames from 'classnames/bind';

// Selectors
import { isLoadingUpdateSelector } from '../../candidate.selectors';

// Styles
import styles from './rejectCandidateModal.module.scss';

const cx = classnames.bind(styles);
const rejectionSchema = Yup.object().shape({
  rejectionFeedback: Yup.array(
    Yup.object().shape({
      value: Yup.string().required('Required'),
      label: Yup.string().required('Required'),
    }),
  ).min(1, 'Required'),
  rejectionReason: Yup.string(),
});

const RejectCandidateModal = ({ show, onClose, onConfirm }) => {
  const { t } = useTranslation();

  const REJECTED_FEEDBACK_OPTIONS = useMemo(
    () => [
      {
        label: t('rejection_feedback.personality'),
        value: 'personality',
        key: 'rejection_feedback.personality',
      },
      {
        label: t('rejection_feedback.years_of_experience'),
        value: 'years_of_experience',
        key: 'rejection_feedback.years_of_experience',
      },
      {
        label: t('rejection_feedback.salary'),
        value: 'salary',
        key: 'rejection_feedback.salary',
      },
      {
        label: t('rejection_feedback.home_office_option'),
        value: 'home_office_option',
        key: 'rejection_feedback.home_office_option',
      },
      {
        label: t('rejection_feedback.missing_skills'),
        value: 'missing_skills',
        key: 'rejection_feedback.missing_skills',
      },
      {
        label: t('rejection_feedback.hired_someone_else'),
        value: 'hired_someone_else',
        key: 'rejection_feedback.hired_someone_else',
      },
      {
        label: t('rejection_feedback.notice_period'),
        value: 'notice_period',
        key: 'rejection_feedback.notice_period',
      },
      {
        label: t('rejection_feedback.language_knowledge'),
        value: 'language_knowledge',
        key: 'rejection_feedback.language_knowledge',
      },
      {
        label: t('rejection_feedback.motivation'),
        value: 'motivation',
        key: 'rejection_feedback.motivation',
      },
      {
        label: t('rejection_feedback.teamfit'),
        value: 'teamfit',
        key: 'rejection_feedback.teamfit',
      },
      {
        label: t('rejection_feedback.other'),
        value: 'other',
        key: 'rejection_feedback.other',
      },
    ],
    [],
  );

  const formMethods = useForm({
    defaultValues: {
      rejectionFeedback: [],
      rejectionReason: '',
    },
    resolver: yupResolver(rejectionSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const handleOnConfirm = ({ rejectionFeedback, rejectionReason }) => {
    onConfirm({
      rejectionFeedback: rejectionFeedback.map((feedback) => feedback.value),
      rejectionReason,
    });
  };

  const isLoadingUpdate = useSelector(isLoadingUpdateSelector);

  return (
    <Modal
      show={show}
      onClose={onClose}
      className={styles.rejectCandidateModal}
    >
      {isLoadingUpdate && <Spinner position="absolute" size={100} />}

      <Modal.Header>{t('reject_candidate_modal.title')}</Modal.Header>

      <Modal.Body className={styles.rejectCandidateModalBody}>
        <Text className={cx(styles.info, styles.infoParagraphSection)}>
          {t('reject_candidate_modal.intro')}
        </Text>

        <Text className={styles.info}>
          {t('reject_candidate_modal.first_point')}
        </Text>
        <Text className={cx(styles.info, styles.infoParagraphSection)}>
          {t('reject_candidate_modal.second_point')}
        </Text>

        <Text className={cx(styles.info, styles.infoWarning)}>
          {t('reject_candidate_modal.important')}{' '}
          {t('reject_candidate_modal.important_msg')}
        </Text>

        <FormProvider {...formMethods}>
          <form>
            <div className={styles.rejectionReasonWrapper}>
              <div className={styles.rejectionReasonItem}>
                <FormSelectV2
                  label={t('reject_candidate_modal.rejection_reasons')}
                  required
                  name="rejectionFeedback"
                  multiple
                  placeholder={t('select')}
                  options={REJECTED_FEEDBACK_OPTIONS}
                  error={errors.rejectionFeedback?.message}
                  onRenderLabel={(options) =>
                    t(
                      options.length > 1
                        ? 'reject_candidate_modal.rejection_reasons_selected.plural'
                        : 'reject_candidate_modal.rejection_reasons_selected.singular',
                      {
                        selectedOptions: options.length,
                      },
                    )
                  }
                />
              </div>
              <div className={styles.rejectionReasonItem}>
                <FormTextarea
                  label={t('details')}
                  name="rejectionReason"
                  placeholder={t('your_rejection_reason')}
                  resize
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </Modal.Body>

      <Modal.Footer
        confirmButtonType="danger"
        confirmText={t('inform_this_candidate')}
        closeText={t('cancel')}
        onConfirm={handleSubmit(handleOnConfirm)}
        onClose={onClose}
      />
    </Modal>
  );
};

export default RejectCandidateModal;
