import * as types from '../types/job.types';

const initalState = {
  isLoading: false,
  job: null,
  extraInfo: null,
  isLoadingExtraInfo: false,
  titles: [],
  isLoadingTitles: false,
  skills: [],
  isLoadingMandatorySkills: false,
  isLoadingOptionalSkills: false,
  locations: [],
  isLoadingMainLocations: false,
  isLoadingAdditionalLocations: false,
  languages: [],
  isLoadingLanguages: false,
  jobFile: null,
  isLoadingJobFile: false,
};

export default (state = initalState, action) => {
  switch (action.type) {
    /**
     * JOB
     */
    case types.GET_JOB_START:
      return {
        ...state,
        isLoading: true,
        job: null,
      };

    case types.UPDATE_JOB_ACCOUNT_MANAGER_START:
    case types.UPDATE_JOB_PERSONALITY_CHECK_START:
    case types.UPDATE_JOB_START:
    case types.CREATE_JOB_START:
      return {
        ...state,
        isLoading: true,
      };

    case types.GET_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        job: action.payload,
      };

    case types.CREATE_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        job: action.payload,
      };

    case types.UPDATE_JOB_ACCOUNT_MANAGER_START_SUCCESS:
      return {
        ...state,
        job: {
          ...state.job,
          account_manager: action.payload,
        },
        isLoading: false,
      };

    case types.UPDATE_JOB_PERSONALITY_CHECK_SUCCESS:
      return {
        ...state,
        job: {
          ...state.job,
          personality_check: action.payload,
        },
        isLoading: false,
      };

    case types.UPDATE_JOB_SUCCESS:
      return {
        ...state,
        job: {
          ...state.job,
          ...action.payload,
        },
        isLoading: false,
      };

    case types.GET_JOB_FAIL:
    case types.UPDATE_JOB_ACCOUNT_MANAGER_START_FAIL:
    case types.UPDATE_JOB_PERSONALITY_CHECK_FAIL:
    case types.UPDATE_JOB_FAIL:
    case types.CREATE_JOB_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case types.RESET_JOB:
      return { ...initalState };

    /**
     * EXTRA INFO
     */
    case types.GET_EXTRA_INFO_START:
      return {
        ...state,
        isLoadingExtraInfo: true,
        extraInfo: null,
      };

    case types.UPDATE_EXTRA_INFO_START:
      return {
        ...state,
        isLoadingExtraInfo: true,
      };

    case types.GET_EXTRA_INFO_FAIL:
    case types.UPDATE_EXTRA_INFO_FAIL:
      return {
        ...state,
        isLoadingExtraInfo: false,
      };

    case types.GET_EXTRA_INFO_SUCCESS:
    case types.UPDATE_EXTRA_INFO_SUCCESS:
      return {
        ...state,
        isLoadingExtraInfo: false,
        extraInfo: action.payload,
      };

    /**
     * TITLES
     */
    case types.GET_TITLES_START:
      return {
        ...state,
        isLoadingTitles: true,
        titles: [],
      };

    case types.GET_TITLES_SUCCESS:
      return {
        ...state,
        isLoadingTitles: false,
        titles: action.payload,
      };

    case types.GET_TITLES_FAIL:
      return {
        ...state,
        isLoadingTitles: false,
      };

    case types.CLEAR_TITLES:
      return {
        ...state,
        titles: [],
      };

    /**
     * SKILLS
     */
    case types.GET_SKILLS_START:
      return {
        ...state,
        isLoadingMandatorySkills: action.payload.isLoadingMandatorySkills,
        isLoadingOptionalSkills: action.payload.isLoadingOptionalSkills,
        skills: [],
      };

    case types.GET_SKILLS_SUCCESS:
      return {
        ...state,
        isLoadingMandatorySkills: false,
        isLoadingOptionalSkills: false,
        skills: action.payload,
      };

    case types.GET_SKILLS_FAIL:
      return {
        ...state,
        isLoadingMandatorySkills: false,
        isLoadingOptionalSkills: false,
      };

    case types.CLEAR_SKILLS:
      return {
        ...state,
        skills: [],
      };

    /**
     * LOCATIONS
     */
    case types.GET_LOCATIONS_START:
      return {
        ...state,
        isLoadingMainLocations: action.payload.isLoadingMainLocations,
        isLoadingAdditionalLocations:
          action.payload.isLoadingAdditionalLocations,
        locations: [],
      };

    case types.GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        isLoadingMainLocations: false,
        isLoadingAdditionalLocations: false,
        locations: action.payload,
      };

    case types.GET_LOCATIONS_FAIL:
      return {
        ...state,
        isLoadingMainLocations: false,
        isLoadingAdditionalLocations: false,
      };

    case types.CLEAR_LOCATIONS:
      return {
        ...state,
        locations: [],
      };

    /**
     * LANGUAGES
     */
    case types.GET_LANGUAGES_START:
      return {
        ...state,
        isLoadingLanguages: true,
        languages: [],
      };

    case types.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        isLoadingLanguages: false,
        languages: action.payload,
      };

    case types.GET_LANGUAGES_FAIL:
      return {
        ...state,
        isLoadingLanguages: false,
      };

    case types.CLEAR_LANGUAGES:
      return {
        ...state,
        languages: [],
      };

    /**
     * JOB FILE
     */
    case types.UPLOAD_JOB_FILE_START:
    case types.DELETE_JOB_FILE_START:
      return {
        ...state,
        isLoadingJobFile: true,
      };

    case types.UPLOAD_JOB_FILE_SUCCESS:
      return {
        ...state,
        jobFile: action.payload,
        isLoadingJobFile: false,
      };

    case types.DELETE_JOB_FILE_SUCCESS:
      return {
        ...state,
        jobFile: null,
        isLoadingJobFile: false,
      };

    case types.UPLOAD_JOB_FILE_FAIL:
    case types.DELETE_JOB_FILE_FAIL:
      return {
        ...state,
        isLoadingJobFile: false,
      };

    default:
      return state;
  }
};
