import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, FormInput, FormSelectV2, Checkbox } from '@lionstep/ui';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

// Constants
import { JOB_LANGUAGE_OPTIONS } from '../../../../../../job/job.constants';
import { USER_ROLES } from '../../../../../../../constants/user.const';

// Helpers
import {
  constantsArrayWithTranslatedLabel,
  langShorthand,
} from '../../../../../../../support';

// Actions
import { createCollaboratorAndShareJob } from '../../../../../redux/actions';

// Styles
import styles from './addNewCollaborator.module.scss';

const ADD_NEW_COLLABORATOR_DEFAULT_FORM_VALUES = {
  name: '',
  email: '',
  language: { value: 'en', label: 'English' },
};

const AddNewCollaborator = ({ job }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [receiveNotifications, setReceiveNotifications] = useState(true);

  const schema = Yup.object().shape({
    email: Yup.string().email().required(t('Required')),
    name: Yup.string()
      // https://stackoverflow.com/a/57754227
      .matches(
        // TODO: move RegExp to some constants file
        /[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+\s+[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+/,
        t('Please provide your full name'),
      )
      .max(140)
      .required(t('Required')),
    language: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .required(t('Required')),
  });

  // options that needs translating
  const JOB_LANGUAGE_OPTIONS_TRANSLATED = constantsArrayWithTranslatedLabel(
    t,
    JOB_LANGUAGE_OPTIONS,
  );

  const formMethods = useForm({
    defaultValues: ADD_NEW_COLLABORATOR_DEFAULT_FORM_VALUES,
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  const handleOnSubmit = async (data) => {
    try {
      const [firstName, lastName] = data.name.split(' ');

      await dispatch(
        createCollaboratorAndShareJob({
          job,
          email: data.email,
          firstName,
          lastName,
          language: langShorthand(data.language.value),
          role: USER_ROLES.viewer,
          shouldAddMailingPermission: receiveNotifications,
        }),
      );
      reset(ADD_NEW_COLLABORATOR_DEFAULT_FORM_VALUES);
    } catch {
      // already handled in action
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid.Row gutter={10} className={styles.addNewViewerForm}>
          <Grid.Col xs={24} md={12} lg={8}>
            <FormInput
              formItemClassName={styles.formItems}
              label={t('add_new_viewer')}
              name="name"
              placeholder="John Doe"
              error={errors.name?.message}
            />
          </Grid.Col>
          <Grid.Col xs={24} md={12} lg={8}>
            <FormInput
              formItemClassName={styles.formItems}
              label={t('work_email')}
              name="email"
              placeholder="email@example.com"
              error={errors.email?.message}
            />
          </Grid.Col>
          <Grid.Col xs={24} md={12} lg={4}>
            <FormSelectV2
              formItemClassName={styles.formItems}
              label={t('email_lang')}
              name="language"
              error={errors.language?.message}
              options={JOB_LANGUAGE_OPTIONS_TRANSLATED}
            />
          </Grid.Col>
          <Grid.Col xs={24} md={12} lg={4}>
            <Button typeHtml="submit" block className={styles.submitButton}>
              {t('invite')}
            </Button>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className={styles.sendCollaboratorStatus}>
          <Grid.Col xs={24}>
            <Checkbox
              inputAlign
              name="userJobNotifications"
              checked={receiveNotifications}
              label={t('send_email_updates')}
              onChange={(e) => setReceiveNotifications(e.target.checked)}
            />
          </Grid.Col>
        </Grid.Row>
      </form>
    </FormProvider>
  );
};

AddNewCollaborator.defaultProps = {
  job: {},
};

AddNewCollaborator.propTypes = {
  job: PropTypes.shape(),
};

export default AddNewCollaborator;
