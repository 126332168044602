import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lionstep/ui';

// Styles
import styles from './disclaimer.module.scss';

const Disclaimer = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.disclaimerContainer}>
      <Icon name="WarningCircle" /> {t('insights_page.disclaimer_message')}
    </div>
  );
};

export default Disclaimer;
