import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Heading,
  Grid,
  FormSelectV2,
  FormDatepicker,
  FormInput,
  Button,
  toast,
} from '@lionstep/ui';

// Styles
import styles from './planCard.module.scss';

// Queries
import {
  useCompanyPlanQuery,
  useCompanyPlanMutation,
} from '../../../../../../queries/companyQueries';

// Constants
import {
  PLAN_OPTIONS,
  SUBSCRIPTION_PLAN_VALUES,
} from '../../../../company.constants';
import { DATE_FORMAT } from '../../../../../../constants/date.const';

const PlanCard = () => {
  const { t } = useTranslation();

  // FORM
  const schema = Yup.object().shape({
    slots: Yup.string()
      .nullable()
      .when('name', {
        is: (value) =>
          value?.value && SUBSCRIPTION_PLAN_VALUES.includes(value?.value),
        then: (yupSchema) => yupSchema.required(t('Required')),
        otherwise: (yupSchema) => yupSchema.notRequired(),
      }),
    validUntil: Yup.string()
      .nullable()
      .when('name', {
        is: (value) =>
          value?.value && SUBSCRIPTION_PLAN_VALUES.includes(value?.value),
        then: (yupSchema) => yupSchema.required(t('Required')),
        otherwise: (yupSchema) => yupSchema.notRequired(),
      }),
    name: Yup.object()
      .shape({
        value: Yup.string().nullable(),
        label: Yup.string(),
      })
      .nullable()
      .required(t('Required')),
  });

  const formMethods = useForm({
    defaultValues: {
      slots: null,
      validUntil: null,
      name: { ...PLAN_OPTIONS[0], label: t(PLAN_OPTIONS[0].label) },
    },
    resolver: yupResolver(schema),
  });

  const {
    formState: { errors, isDirty },
    handleSubmit,
    watch,
    setValue,
    reset,
  } = formMethods;

  const planWatch = watch('name');
  const validUntilWatch = watch('validUntil');

  // Queries
  const companyPlanMutation = useCompanyPlanMutation();
  const { data: companyPlan } = useCompanyPlanQuery();

  useEffect(() => {
    if (companyPlan) {
      const name = companyPlan.name
        ? PLAN_OPTIONS.find((option) => option.value === companyPlan.name)
        : PLAN_OPTIONS[0];
      const validUntil = companyPlan.valid_until
        ? moment(companyPlan.valid_until).format(DATE_FORMAT)
        : null;

      reset({
        name: { ...name, label: t(name.label) },
        validUntil,
        slots: `${companyPlan.slots}` || null,
      });
    }
  }, [companyPlan]);

  // Handlers
  const handleOnPlanSelect = (value) => {
    if (!SUBSCRIPTION_PLAN_VALUES.includes(value.value)) {
      setValue('validUntil', null);
      setValue('slots', null);
    }
  };

  const handleOnPlanSubmit = (values) => {
    const { mutate } = companyPlanMutation;
    const data = {
      name: values.name.value,
    };

    if (SUBSCRIPTION_PLAN_VALUES.includes(values.name.value)) {
      data.valid_until = moment(values.validUntil, DATE_FORMAT).format(
        'YYYY-MM-DD',
      );
      data.slots = values.slots;
    }

    mutate(data, {
      onSuccess: () =>
        toast.success(t('purchases_page.plan.form.submit_success_message'), {
          id: 'COMPANY_PLAN_UPDATE_SUCCESS',
        }),
      onError: () =>
        toast.error(t('purchases_page.plan.form.submit_error_message'), {
          id: 'COMPANY_PLAN_UPDATE_ERROR',
        }),
    });
  };

  const initialVisibleMonth = () =>
    validUntilWatch
      ? moment(validUntilWatch, DATE_FORMAT)
      : moment().add(1, 'year');

  return (
    <Card className={styles.planCard} data-testid="purchases-plan-card">
      <Heading className={styles.planCardTitle} level={4}>
        {t('purchases_page.plan.title')}
      </Heading>

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleOnPlanSubmit)}>
          <Grid.Row gutter={30}>
            <Grid.Col xs={24} md={12}>
              <FormSelectV2
                name="name"
                label={t('purchases_page.plan.form.name.label')}
                options={PLAN_OPTIONS.map((option) => ({
                  ...option,
                  label: t(option.label),
                }))}
                error={errors.name?.message}
                onChange={handleOnPlanSelect}
              />
            </Grid.Col>
            {SUBSCRIPTION_PLAN_VALUES.includes(planWatch?.value) && (
              <Grid.Col xs={24} md={6}>
                <FormDatepicker
                  initialVisibleMonth={initialVisibleMonth}
                  name="validUntil"
                  displayFormat={DATE_FORMAT}
                  label={t('purchases_page.plan.form.valid_until.label')}
                  placeholder={t('select')}
                  error={errors.validUntil?.message}
                />
              </Grid.Col>
            )}
            {SUBSCRIPTION_PLAN_VALUES.includes(planWatch?.value) && (
              <Grid.Col xs={24} md={6}>
                <FormInput
                  name="slots"
                  type="number"
                  label={t('purchases_page.plan.form.slots.label')}
                  placeholder="10/20/30"
                  error={errors.slots?.message}
                />
              </Grid.Col>
            )}
          </Grid.Row>

          <Grid.Row gutter={30}>
            <Grid.Col xs={24}>
              <Button
                typeHtml="submit"
                disabled={companyPlanMutation.isLoading || !isDirty}
              >
                {t('purchases_page.plan.form.submit_btn')}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </form>
      </FormProvider>
    </Card>
  );
};

export default PlanCard;
