import * as types from '../types/company.types';

const initalState = {
  activeJobsTitles: [],
  collaborators: [],
  company: null,
  pictures: [],
  isFetchingActiveJobsTitles: false,
  isLoading: false,
  isUpdating: false,
  isLoadingCollaborators: false,
  isLoadingLocations: false,
  isLoadingPictures: false,
  isUploadingPicture: false,
  isDeletingPicture: false,
  locations: [],
};

export default (state = initalState, action) => {
  switch (action.type) {
    /**
     * COMPANY
     */
    case types.GET_COMPANY_START:
      return {
        ...state,
        isLoading: true,
      };

    case types.GET_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload,
        isLoading: false,
      };

    case types.GET_COMPANY_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case types.UPDATE_COMPANY_START:
      return {
        ...state,
        isUpdating: true,
      };

    case types.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        company: action.payload,
      };

    case types.UPDATE_COMPANY_FAIL:
      return {
        ...state,
        isUpdating: false,
      };

    /**
     * GET PICTURES
     */
    case types.GET_COMPANY_PICTURES_START:
      return {
        ...state,
        isLoadingPictures: true,
      };

    case types.GET_COMPANY_PICTURES_SUCCESS:
      return {
        ...state,
        pictures: action.payload,
        isLoadingPictures: false,
      };

    case types.GET_COMPANY_PICTURES_FAIL:
      return {
        ...state,
        isLoadingPictures: false,
      };

    /**
     * UPLOAD PICTURES
     */
    case types.UPLOAD_COMPANY_PICTURES_START:
      return {
        ...state,
        isUploadingPicture: true,
      };

    case types.UPLOAD_COMPANY_PICTURES_SUCCESS:
      return {
        ...state,
        pictures: action.payload,
        isUploadingPicture: false,
      };

    case types.UPLOAD_COMPANY_PICTURES_FAIL:
      return {
        ...state,
        isUploadingPicture: false,
      };

    /**
     * DELETE PICTURES
     */
    case types.DELETE_COMPANY_PICTURES_START:
      return {
        ...state,
        isDeletingPicture: true,
      };

    case types.DELETE_COMPANY_PICTURES_SUCCESS:
      return {
        ...state,
        pictures: state.pictures.filter(
          (picture) => picture.id !== action.payload.pictureId,
        ),
        isDeletingPicture: false,
      };

    case types.DELETE_COMPANY_PICTURES_FAIL:
      return {
        ...state,
        isDeletingPicture: false,
      };

    /**
     * LOCATIONS
     */
    case types.GET_COMPANY_LOCATIONS_START:
      return {
        ...state,
        locations: [],
        isLoadingLocations: true,
      };

    case types.GET_COMPANY_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.payload,
        isLoadingLocations: false,
      };

    case types.GET_COMPANY_LOCATIONS_FAIL:
      return {
        ...state,
        isLoadingLocations: false,
      };

    /**
     * COLLABORATORS
     */
    case types.GET_COLLABORATORS_START:
      return {
        ...state,
        isLoadingCollaborators: true,
      };

    case types.GET_COLLABORATORS_SUCCESS:
      return {
        ...state,
        isLoadingCollaborators: false,
        collaborators: action.payload,
      };

    case types.GET_COLLABORATORS_FAIL:
      return {
        ...state,
        isLoadingCollaborators: false,
      };

    /**
     * ACTIVE JOBS TITLES
     */
    case types.GET_ACTIVE_JOBS_TITLES_START:
      return {
        ...state,
        activeJobsTitles: [],
        isFetchingActiveJobsTitles: true,
      };

    case types.GET_ACTIVE_JOBS_TITLES_FAIL:
      return {
        ...state,
        isFetchingActiveJobsTitles: false,
      };

    case types.GET_ACTIVE_JOBS_TITLES_SUCCESS:
      return {
        ...state,
        activeJobsTitles: action.payload,
        isFetchingActiveJobsTitles: false,
      };

    case types.CLEAR_COMPANY:
      return initalState;

    default:
      return state;
  }
};
