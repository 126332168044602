import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@lionstep/ui';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useTranslation } from 'react-i18next';

// Components
import NoDataLabel from './NoDataLabel';

// Styles
import styles from './charts.module.scss';

// Register the plugin
Chart.register(annotationPlugin);

const CandidateFunnelChart = ({ insights, hasLegend, formatterFunc }) => {
  const { t } = useTranslation();

  const config = formatterFunc({
    candidateFunnel: insights.candidate_funnel,
    styles,
    t,
  });

  const noData = Object.values(insights.candidate_funnel).every(
    (value) => !value,
  );

  return (
    <>
      <Heading level={4} className={styles.barTitle}>
        {t('insights_page.candidate_funnel.title')}
        {noData && <NoDataLabel />}
      </Heading>
      {hasLegend && (
        <div id="candidateFunnelLegend" className={styles.barContainerLegend} />
      )}
      <div className={styles.barContainer}>
        <div className={styles.barContainerChart}>
          <Bar {...config} />
        </div>
      </div>
    </>
  );
};

CandidateFunnelChart.defaultProps = {
  hasLegend: false,
};

CandidateFunnelChart.propTypes = {
  hasLegend: PropTypes.bool,
  insights: PropTypes.shape().isRequired,
  formatterFunc: PropTypes.func.isRequired,
};

export default CandidateFunnelChart;
