export const optionalQuestionsInputMapper = (questions) => ({
  questions: questions?.length
    ? questions.map((question) => ({
        text: question.title,
        question_id: question.question_id,
      }))
    : [],
});

export const optionalQuestionsOutputMapper = (questions) => ({
  questions: questions.map((question, index) => ({
    choices: [],
    hide: false,
    question_type: 'text',
    is_active: true,
    sort_order: index,
    title: question.text,
    ...(question.question_id && { question_id: question.question_id }),
  })),
});
