import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@lionstep/ui';
import classnames from 'classnames/bind';

// Components
import HeaderDropdown from './HeaderDropdown';

// Selectors
import { appLanguageSelector } from '../../../../modules/app/redux/selectors/language.selectors';

// Styles
import styles from './headerDropdown.module.scss';
import useLanguageDropdown from './useLanguageDropdown';

const cx = classnames.bind(styles);

const HeaderLanguageDropdown = ({ menuSecondary }) => {
  const appLanguage = useSelector(appLanguageSelector);

  const { items, handleOnLanguageMenuItemClick, FLAGS } = useLanguageDropdown();

  const renderDropdownMenuItem = (item, onClick) => (
    <div
      className={cx(styles.menuItem, {
        [styles.menuItemSecondary]: menuSecondary,
      })}
      key={item.languageCode}
      onClick={() => {
        handleOnLanguageMenuItemClick(item.languageCode);
        onClick();
      }}
    >
      {/* Flag */}
      <span className={styles.menuItemFlag}>{item.flag}</span>

      {/* Label */}
      {item.label}

      {/*  Checked icon */}
      {appLanguage === item.languageCode && (
        <Icon name="Check" className={styles.menuItemChecked} />
      )}
    </div>
  );

  const renderDropdownTrigger = () => (
    <span className={styles.dropdownTriggerAvatar}>{FLAGS[appLanguage]}</span>
  );

  return (
    <HeaderDropdown
      menuItems={items}
      menuSecondary={menuSecondary}
      renderDropdownTrigger={renderDropdownTrigger}
      renderDropdownMenuItem={renderDropdownMenuItem}
    />
  );
};

export default HeaderLanguageDropdown;
