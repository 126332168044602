// TODO: Keep as a proof that 0 was true and 1 was false
export const BONUS_OPTIONS = [
  { label: 'Yes', value: 0 },
  { label: 'No', value: 1 },
];

export const QUESTIONS_OPTIONS = [
  { value: 'boolean', label: 'Yes/No' },
  { value: 'multiple_choices', label: 'Multiple Choice' },
  { value: 'skills', label: 'Skills' },
  { value: 'text', label: 'Open Answer' },
];

export const QUESTIONS_TYPE = {
  yesNo: 'boolean',
  multipleChoice: 'multiple_choices',
  skills: 'skills',
  openAnswer: 'text',
};

export const MULTIPLE_CHOICE_ANSWERS_TYPE = {
  acceptable: 'acceptable',
  notValid: 'not_valid',
  valid: 'valid',
};
