import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from '@lionstep/ui';

// Styles
import styles from './publishJobModal.module.scss';

const PublishJobModal = ({ show, onConfirm, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onClose={onClose}>
      <Modal.Header>
        {t('dashboard_page.modals.publish_job_title')}
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        {t('dashboard_page.modals.publish_job_text')}
      </Modal.Body>
      <Modal.Footer
        confirmText={t('publish_job')}
        closeText={t('cancel')}
        onConfirm={() => {
          onConfirm();
          onClose();
        }}
        onClose={onClose}
      />
    </Modal>
  );
};

PublishJobModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PublishJobModal;
