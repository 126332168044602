import React from 'react';
import PropTypes from 'prop-types';

const Cafeteria = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 66"
    className={className}
  >
    <title>08</title>
    <g id="Grey">
      <path
        id="Object2"
        d="M65.72,28.3A1,1,0,0,0,65,28H1a1,1,0,0,0-1,1C.35,42.9,7.22,52.92,21,59.63V65a1,1,0,0,0,1,1H44a1,1,0,0,0,1-1V59.63C58.78,52.92,65.65,42.9,66,29A1,1,0,0,0,65.72,28.3ZM43.57,58.1A1,1,0,0,0,43,59v5H23V59a1,1,0,0,0-.57-.9A47.66,47.66,0,0,1,15.35,54h35.3A47.66,47.66,0,0,1,43.57,58.1ZM53.19,52A1,1,0,0,0,53,52H13a1,1,0,0,0-.19,0A29.42,29.42,0,0,1,2,30H64A29.42,29.42,0,0,1,53.19,52Z"
        transform="translate(0 0)"
        fill="#899198"
      />
    </g>
    <g id="Coloured">
      <g id="Object1">
        <path
          d="M42.22,25H37a1,1,0,0,0,0,2h5.22c1,0,1.78.57,1.78,1.09V29a1,1,0,1,0,2,0v-.91C46,26.36,44.34,25,42.22,25Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M25,25H19a3,3,0,0,0-3,3v1a1,1,0,1,0,2,0V28a1,1,0,0,1,1-1h6a1,1,0,0,0,0-2Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M65.28,4A1,1,0,0,0,64,4.72l-7,24a1,1,0,0,0,1.92.56l7-24A1,1,0,0,0,65.28,4Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M60.18,0A1,1,0,0,0,59,.82L54.62,25.07A12,12,0,0,0,43,16H36a1,1,0,1,0,0,2h7A10,10,0,0,1,53,28a1,1,0,0,0,1,1l.09,0a1,1,0,0,0,.73.6l.18,0a1,1,0,0,0,1-.82l5-27.6A1,1,0,0,0,60.18,0Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M25,22H19a6,6,0,0,0-6,6,1,1,0,1,0,2,0,4,4,0,0,1,4-4h6a1,1,0,0,0,0-2Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M19,21h5a1,1,0,0,0,0-2H19a9,9,0,0,0-9,9,1,1,0,1,0,2,0A7,7,0,0,1,19,21Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M19,18h2a1,1,0,1,0,0-2H19A12,12,0,0,0,7,28a1,1,0,1,0,2,0A10,10,0,0,1,19,18Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M43,22H37a1,1,0,0,0,0,2h6a4,4,0,0,1,4,4,1,1,0,1,0,2,0A6,6,0,0,0,43,22Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M43,19H37a1,1,0,0,0,0,2h6a7,7,0,0,1,7,7,1,1,0,1,0,2,0A9,9,0,0,0,43,19Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M28,30a1,1,0,0,0,1-1V24A11,11,0,0,0,18,13H17a3,3,0,0,0-2.88,1.54,1,1,0,0,0,1.77.92C16,15.32,16.13,15,17,15h1a9,9,0,0,1,9,9v5A1,1,0,0,0,28,30Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M31,30a1,1,0,0,0,1-1V24A14.05,14.05,0,0,0,18,10h-1A6,6,0,0,0,11,15.94a1,1,0,0,0,2,0A4,4,0,0,1,16.94,12h1A12,12,0,0,1,30,24v5A1,1,0,0,0,31,30Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M9,19a1,1,0,0,0,1-1V16a7,7,0,0,1,7-7h1A15,15,0,0,1,33,24v5a1,1,0,1,0,2,0V24A17,17,0,0,0,18,7H17a9,9,0,0,0-9,9v2A1,1,0,0,0,9,19Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M25,7a1,1,0,0,0,1-1,4,4,0,0,1,4-4h2.23C38.72,2,44,6.33,44,11.65V14a1,1,0,1,0,2,0V11.65C46,5.22,39.82,0,32.23,0H30a6,6,0,0,0-6,6A1,1,0,0,0,25,7Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M42,15a1,1,0,0,0,1-1V12.36C43,7.2,38.54,3,33.06,3H31a4,4,0,0,0-4,4v1a1,1,0,1,0,2,0V7a2,2,0,0,1,2-2h2.09C37.44,5,41,8.3,41,12.36V14A1,1,0,0,0,42,15Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M39,15a1,1,0,0,0,1-1v-.92C40,9.18,36.4,6,32,6H31a1,1,0,0,0,0,2h1c3.32,0,6,2.28,6,5.08V14A1,1,0,0,0,39,15Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M36,15a1,1,0,0,0,1-1v-.2a5,5,0,0,0-5.1-4.9h-.11l-.9.1a1,1,0,0,0,.22,2L32,10.9a2.93,2.93,0,0,1,3,2.9V14A1,1,0,0,0,36,15Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M33.71,35.29a1,1,0,0,0-1.41,0l-5,5a1,1,0,0,0,0,1.41l5,5a1,1,0,0,0,1.41,0l5-5a1,1,0,0,0,0-1.41ZM33,44.59,29.41,41,33,37.41,36.59,41Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
      </g>
    </g>
  </svg>
);

Cafeteria.defaultProps = {
  companyColour: '#ab1923',
};

Cafeteria.propTypes = {
  companyColour: PropTypes.string,
};

export default Cafeteria;
