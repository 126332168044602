import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

// Styles
import styles from './profileViewersCard.module.scss';

const cx = classnames.bind(styles);

const ProfileViewersCard = ({
  profileViewersNumber,
  hasNotInterestedCandidates,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.profileViewersCard)}>
      <div className={cx(styles.content)}>
        {hasNotInterestedCandidates
          ? t(
              profileViewersNumber > 1
                ? 'candidates_page.job_visitors_with_data.plural'
                : 'candidates_page.job_visitors_with_data.singular',
              { number: profileViewersNumber },
            )
          : t(
              profileViewersNumber > 1
                ? 'candidates_page.job_visitors_no_data.plural'
                : 'candidates_page.job_visitors_no_data.singular',
              { number: profileViewersNumber },
            )}
      </div>
    </div>
  );
};

ProfileViewersCard.propTypes = {
  hasNotInterestedCandidates: PropTypes.bool.isRequired,
  profileViewersNumber: PropTypes.number.isRequired,
};

export default ProfileViewersCard;
