import React from 'react';

import { useDropzone } from 'react-dropzone';

const LsDropzone = ({
  onDrop,
  accept,
  multiple = false,
  rootStyles,
  children,
}) => {
  const { getRootProps, getInputProps, isFileDialogActive } = useDropzone({
    onDrop,
    accept,
    multiple,
  });

  return (
    <div {...getRootProps()} style={{ ...rootStyles }}>
      <input {...getInputProps()} data-isopen={isFileDialogActive} />
      {children}
    </div>
  );
};

export default LsDropzone;
