import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

// Styles
import styles from './charts.module.scss';

const cx = classNames.bind(styles);

const NoDataLabel = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.noDataLabel, className)}>
      {t('insights_page.no_data')}
    </div>
  );
};

NoDataLabel.defaultProps = {
  className: '',
};

NoDataLabel.propTypes = {
  className: PropTypes.string,
};

export default NoDataLabel;
