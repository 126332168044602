import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Label, Icon, Spinner } from '@lionstep/ui';
import Compressor from 'compressorjs';

// Selectors
import {
  companyPicturesSelector,
  isUploadingCompanyPicturesSelector,
  isDeletingCompanyPicturesSelector,
} from '../../../../redux/selectors/company.selectors';

// Helpers
import { toBase64 } from '../../../../../../support';

// Actions
import {
  uploadCompanyPictures,
  deleteCompanyPicture,
} from '../../../../redux/actions/company.actions';

// Components
import { ReactComponent as CloudUploadIcon } from '../../../../../../assets/icons/cloudUpload.svg';
import LsDropzone from '../../../../../../components/shared/LsDropzone';

// Styles
import styles from './companyPhotos.module.scss';

const UploadPicture = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const companyPictures = useSelector(companyPicturesSelector);
  const isUploadingCompanyPictures = useSelector(
    isUploadingCompanyPicturesSelector,
  );
  const isDeletingCompanyPictures = useSelector(
    isDeletingCompanyPicturesSelector,
  );
  const [error, setError] = useState('');

  const handleFileReaderOnLoad = async (event, file) => {
    const uploadData = {
      picture: {
        data: event.target.result,
        filename: `${file.name.slice(
          0,
          file.name.lastIndexOf('.'),
        )}-${Math.random().toString(36).substring(6)}${file.name.slice(
          file.name.lastIndexOf('.'),
        )}`,
      },
    };

    return new Compressor(file, {
      quality: 0.8,
      maxHeight: 1200,
      maxWidth: 1200,
      success: async (result) => {
        uploadData.picture.data = await toBase64(result);

        dispatch(uploadCompanyPictures(uploadData));
      },
      error: () => {
        dispatch(uploadCompanyPictures(uploadData));
      },
    });
  };

  const handleOnImageDelete = ({ e, id }) => {
    e.stopPropagation();
    dispatch(deleteCompanyPicture({ id }));
  };

  const handleOnDropImage = (files) => {
    if (companyPictures.length + files.length > 8) {
      setError('The maximum number of pictures is 8');
      return;
    }
    setError('');

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = (event) => handleFileReaderOnLoad(event, file);
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      {(isDeletingCompanyPictures || isUploadingCompanyPictures) && (
        <Spinner position="fixed" size={100} />
      )}
      <Label
        htmlFor="company_image"
        bold
        hint={t('company_page.displayed_section.company_photos.helptext')}
      >
        {t('company_page.displayed_section.company_photos.label')}
      </Label>
      <div className={styles.container}>
        <LsDropzone accept="image/*" multiple onDrop={handleOnDropImage}>
          <div className={styles.picturesContainer}>
            {companyPictures.map((picture) => (
              <div className={styles.pictureWrapper} key={picture.id}>
                <img className={styles.picture} src={picture.url} alt="" />
                <Icon
                  className={styles.pictureRemove}
                  name="MinusCircle"
                  onClick={(e) => handleOnImageDelete({ e, id: picture.id })}
                />
              </div>
            ))}
          </div>
          <div className={styles.placeholderContainer}>
            <CloudUploadIcon className={styles.placeholderIcon} />
            <p className={styles.placeholderText}>
              {t('company_page.displayed_section.company_photos.placeholder')}
            </p>
          </div>
        </LsDropzone>
        {error && <span className={styles.error}>{error}</span>}
      </div>
    </>
  );
};

UploadPicture.defaultProps = {};

UploadPicture.propTypes = {};

export default UploadPicture;
