import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from '@lionstep/ui';

// Api
import { layer1Axios } from '../api/http';

const USER_QUERY_KEYS = {
  user: 'user',
  userLogin: 'userLogin',
  userLogout: 'userLogout',
  userRegister: 'userRegister',
  userCollaborators: 'userCollaborators',
  usersSignupCollaborator: 'usersSignupCollaborator',
};

// LOGIN
const postUserLogin = ({ data: loginData }) =>
  layer1Axios({
    method: 'POST',
    url: '/users/login',
    data: loginData,
  }).then(({ data }) => data);

export const useUserLoginMutation = ({ options = {} } = {}) =>
  useMutation([USER_QUERY_KEYS.userLogin], postUserLogin, {
    ...options,
    onSuccess: (data) => {
      // TEMPORARILY
      const loginTime = Math.floor(new Date().getTime() / 1000);

      localStorage.setItem('start', loginTime);
      localStorage.setItem('sessionId', data.id_token);
      localStorage.setItem('refreshToken', data.refresh_token);
      localStorage.setItem('logged', true);

      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  });

export const useUserLogoutQuery = ({ history, clearReduxStore } = {}) => {
  const queryClient = useQueryClient();

  return useQuery(
    [USER_QUERY_KEYS.userLogout],
    async () => {
      history.push('/');

      localStorage.setItem('sessionId', null);
      localStorage.setItem('refreshToken', null);
      localStorage.setItem('logged', false);

      clearReduxStore(); // TODO: remove this when redux is removed

      await queryClient.removeQueries();

      return null;
    },
    {
      enabled: false,
    },
  );
};
// REGISTER
const postUserRegister = ({ data: loginData }) =>
  layer1Axios({
    method: 'POST',
    url: '/users/register',
    data: loginData,
  }).then(({ data }) => data);

export const useUserRegisterMutation = ({ options = {} } = {}) =>
  useMutation([USER_QUERY_KEYS.userRegister], postUserRegister, {
    ...options,
    onSuccess: (data) => {
      // TEMPORARILY
      const loginTime = Math.floor(new Date().getTime() / 1000);

      localStorage.setItem('start', loginTime);
      localStorage.setItem('sessionId', data.id_token);
      localStorage.setItem('refreshToken', data.refresh_token);
      localStorage.setItem('logged', true);

      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  });

// PROFILE
const getUser = () =>
  layer1Axios({
    method: 'GET',
    url: '/users/profile',
  }).then(({ data }) => data);

export const useUserQuery = ({ options = {} } = {}) => {
  const queryClient = useQueryClient();

  return useQuery([USER_QUERY_KEYS.user], getUser, {
    ...options,
    staleTime: !queryClient.getQueryData([USER_QUERY_KEYS.user]) ? 0 : Infinity,
  });
};

const putUser = ({ data: userData }) =>
  layer1Axios({
    method: 'PUT',
    url: '/users',
    data: userData,
  }).then(({ data }) => data);

export const useUserMutation = ({ options = {} } = {}) => {
  const queryClient = useQueryClient();

  return useMutation([USER_QUERY_KEYS.user], putUser, {
    ...options,
    onSuccess: ({ user }) => {
      queryClient.setQueryData([USER_QUERY_KEYS.user], user);

      toast.success('Profile information  saved!', {
        id: 'UPDATE_USER_SUCCESS',
      });

      if (options.onSuccess) {
        options.onSuccess();
      }
    },
    onError: (error) => {
      toast.error('Profile information not saved!', {
        id: 'UPDATE_USER_FAIL',
        statusCode: error?.response?.status,
      });

      if (options.onError) {
        options.onError();
      }
    },
  });
};

// Collaborators
const getUserCollaborators = () =>
  layer1Axios({
    method: 'get',
    url: '/users/collaborators',
  }).then(({ data }) => data);

export const useUserCollaborators = ({ options = {} } = {}) =>
  useQuery([USER_QUERY_KEYS.userCollaborators], getUserCollaborators, {
    ...options,
  });

// Signup Collaborator
const postSignupCollaborator = ({ data: collaboratorData }) =>
  layer1Axios({
    method: 'post',
    url: '/users/signup-collaborator',
    data: collaboratorData,
  }).then(({ data }) => data);

export const useSignupCollaboratorMutation = ({ options = {} } = {}) =>
  useMutation(
    [USER_QUERY_KEYS.usersSignupCollaborator],
    postSignupCollaborator,
    {
      ...options,
      onSuccess: () => {
        toast.success('Collaborator signin success', {
          id: 'SIGNUP_COLLABORATOR_SUCCESS',
        });

        if (options.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (error) => {
        toast.error('Collaborator signin failed', {
          id: 'SIGNUP_COLLABORATOR_FAIL',
          statusCode: error?.response?.status,
        });

        if (options.onError) {
          options.onError();
        }
      },
    },
  );
