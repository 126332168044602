import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Text } from '@lionstep/ui';

// Styles
import styles from './adminActionsInfoModal.module.scss';

const AdminActionsInfoModal = ({ show, actionType, onConfirm, onClose }) => {
  const { t } = useTranslation();

  const renderTitle = () => {
    let title = '';

    if (
      actionType.includes('jobPublished') &&
      actionType.includes('personalityEnabled')
    ) {
      title = `${t('admin_actions_page.info_modal.publish_job_title')} ${t(
        'and',
      )} ${t('admin_actions_page.info_modal.enable_personality_title')}`;
    } else if (
      actionType.includes('jobClosed') &&
      actionType.includes('personalityEnabled')
    ) {
      title = `${t('admin_actions_page.info_modal.close_job_title')} ${t(
        'and',
      )} ${t('admin_actions_page.info_modal.enable_personality_title')}`;
    } else if (actionType.includes('jobPublished')) {
      title = t('admin_actions_page.info_modal.publish_job_title');
    } else if (actionType.includes('personalityEnabled')) {
      title = t('admin_actions_page.info_modal.enable_personality_title');
    } else if (actionType.includes('jobClosed')) {
      title = t('admin_actions_page.info_modal.close_job_title');
    }

    return title;
  };

  const renderText = () => {
    let text = '';

    if (
      actionType.includes('jobPublished') &&
      actionType.includes('personalityEnabled')
    ) {
      text = `${t('admin_actions_page.info_modal.publish_job_text')} ${t(
        '\n\n',
      )} ${t('admin_actions_page.info_modal.enable_personality_text')}`;
    } else if (
      actionType.includes('jobClosed') &&
      actionType.includes('personalityEnabled')
    ) {
      text = `${t('admin_actions_page.info_modal.close_job_text')} ${t(
        '\n\n',
      )} ${t('admin_actions_page.info_modal.enable_personality_text')}`;
    } else if (actionType.includes('jobPublished')) {
      text = t('admin_actions_page.info_modal.publish_job_text');
    } else if (actionType.includes('personalityEnabled')) {
      text = t('admin_actions_page.info_modal.enable_personality_text');
    } else if (actionType.includes('jobClosed')) {
      text = t('admin_actions_page.info_modal.close_job_text');
    }

    return text;
  };

  return (
    <Modal show={show} onClose={onClose}>
      <Modal.Header>{renderTitle()}</Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Text className={styles.modalBodyWarning}>{t('warning')}</Text>
        <Text className={styles.modalBodyInfo}>{renderText()}</Text>
      </Modal.Body>
      <Modal.Footer
        confirmText={t('confirm')}
        closeText={t('close')}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </Modal>
  );
};

AdminActionsInfoModal.propTypes = {
  actionType: PropTypes.arrayOf(PropTypes.string).isRequired,
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AdminActionsInfoModal;
