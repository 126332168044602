// TODO: group all constants
export const SHA256_KEY = 'Z7cqdScy6Wux8LhkSuR3TldtBwZBxjU-OapMXeU9';

export const JOB_STATUSES_KEY_VALUE = {
  no_status: 'No status',
  job_description_done: 'Job description done',
  search_done: 'Search done',
  checking_interest: 'Checking interest',
  additional_search_needed: 'Additional search needed',
  job_on_hold: 'Job on hold',
  job_closed: 'Job closed',
};

export const CANDIDATE_FEEDBACK_OPTIONS = [
  {
    label: 'candidate_feedback.interesting_profile',
    value: 'interesting_profile',
  },
  {
    label: 'candidate_feedback.interview_scheduled_1',
    value: 'interview_scheduled_1',
  },
  {
    label: 'candidate_feedback.interview_scheduled_2',
    value: 'interview_scheduled_2',
  },
  {
    label: 'candidate_feedback.interview_scheduled_3',
    value: 'interview_scheduled_3',
  },
  {
    label: 'candidate_feedback.candidate_hired',
    value: 'candidate_hired',
  },
  {
    label: 'candidate_feedback.hired_someone_else',
    value: 'hired_someone_else',
  },
  {
    label: 'candidate_feedback.dropped_out_after_interview',
    value: 'dropped_out_after_interview',
  },
  {
    label: 'candidate_feedback.refused_offer',
    value: 'refused_offer',
  },
  {
    label: 'candidate_feedback.accepted_another_offer_before_interview',
    value: 'accepted_another_offer_before_interview',
  },
  {
    label: 'candidate_feedback.accepted_another_offer_after_interview',
    value: 'accepted_another_offer_after_interview',
  },
  {
    label: 'candidate_feedback.could_not_be_reached_several_times',
    value: 'could_not_be_reached_several_times',
  },
  { label: 'candidate_feedback.rejected', value: 'rejected' },
];
