import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip, Icon } from '@lionstep/ui';
import classnames from 'classnames/bind';

// Styles
import styles from './noElasticSearchBadge.module.scss';

const cx = classnames.bind(styles);

const NoElasticSearchBadge = ({ className, id }) => {
  const { t } = useTranslation();

  return (
    <span className={cx(styles.noElasticSearchBadge, className)}>
      <Tooltip
        id={id}
        place="top"
        content={
          <span className={styles.tooltipText}>
            {t('candidate_no_elastic_search')}
          </span>
        }
      >
        <Icon
          name="NoElasticSearch"
          className={styles.elasticSearchIcon}
          size={20}
        />
      </Tooltip>
    </span>
  );
};

NoElasticSearchBadge.defaultProps = {
  className: '',
};

NoElasticSearchBadge.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default NoElasticSearchBadge;
