import React from 'react';
import PropTypes from 'prop-types';

const TopFurniture = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 68 63"
    className={className}
  >
    <g id="Grey">
      <g id="Object2">
        <path
          d="M67,20H66V8a1,1,0,0,0-1-1H60a1,1,0,0,0-1,1V9H55a1,1,0,0,0-1,1v1H50a1,1,0,0,0-1,1v8H36V17a1,1,0,0,0-2,0v3H31V17a1,1,0,0,0-2,0v3H11V18h2a1,1,0,1,0,0-2H3a1,1,0,1,0,0,2H5v2H1a1,1,0,0,0-1,1v5a1,1,0,0,0,1,1H2V44s0,0,0,0,0,0,0,0l1.17,18a1,1,0,0,0,1,.94H8.83a1,1,0,0,0,1-.94L10.94,45H21a1,1,0,0,0,1-1V36H46v8a1,1,0,0,0,1,1H57.06l1.11,17.06a1,1,0,0,0,1,.94h4.67a1,1,0,0,0,1-.94l1.17-18s0,0,0,0,0,0,0,0V27h1a1,1,0,0,0,1-1V21A1,1,0,0,0,67,20ZM61,9h3V20H61V9Zm-5,2h3v9H56V11Zm-5,2h3v7H51ZM7,18H9v2H7Zm.9,43H5.1l-1-16H8.93ZM20,43H4V27H20Zm2-9V27H46v7ZM62.9,61H60.1l-1-16h4.87ZM64,43H48V27H64Zm2-18H2V22H66Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M8,14a1,1,0,0,0,0-2H8A2,2,0,1,0,8,8a1,1,0,0,0,0,2H8a2,2,0,1,0,0,4Z"
          transform="translate(0 0)"
          fill="#899198"
        />
      </g>
    </g>
    <g id="Coloured">
      <g id="Object1">
        <path
          d="M44,0H21a1,1,0,0,0-1,1V17a1,1,0,0,0,1,1H44a1,1,0,0,0,1-1V1A1,1,0,0,0,44,0ZM43,16H22V2H43Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M38,29H30a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M16,33H8a1,1,0,0,0,0,2h3v1a1,1,0,0,0,2,0V35h3a1,1,0,0,0,0-2Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M60,33H52a1,1,0,0,0,0,2h3v1a1,1,0,0,0,2,0V35h3a1,1,0,0,0,0-2Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
      </g>
    </g>
  </svg>
);

TopFurniture.defaultProps = {
  companyColour: '#ab1923',
};

TopFurniture.propTypes = {
  companyColour: PropTypes.string,
};

export default TopFurniture;
