import {
  accountManagerMapper,
  citiesMapper,
} from '../../pages/AdminActions/adminActions.helper';

export const accountManagersSelector = (state) =>
  accountManagerMapper(state.newJob.adminActions.accountManagers);
export const isLoadingAccountManagerSelector = (state) =>
  state.newJob.adminActions.isLoadingAccountManagers;

export const citiesSelector = (state) =>
  citiesMapper(state.newJob.adminActions.cities);
export const isLoadingCitiesSelector = (state) =>
  state.newJob.adminActions.isLoadingCities;

// KILLER QUESTIONS
export const killerQuestionsSelector = (state) =>
  state.newJob.adminActions.killerQuestions;

export const isLoadingKillerQuestionsSelector = (state) =>
  state.newJob.adminActions.isLoadingKillerQuestions;

export const skillsSelector = (state) => state.newJob.adminActions.skills;
export const isLoadingSkillsSelector = (state) =>
  state.newJob.adminActions.isLoadingSkills;
