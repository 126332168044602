import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

// Components
import { Spinner } from '@lionstep/ui';
import JobForm from '../../components/JobForm/JobForm';

// actions
import { getAccountManagers } from '../../redux/actions';

// selectors
import {
  isLoadingCompanySelector,
  companySelector,
} from '../../../company/redux/selectors/company.selectors';
import {
  jobSelector,
  isLoadingJobSelector,
  extraInfoSelector,
  isLoadingExtraInfoSelector,
} from '../../redux/selectors/job.selectors';
import {
  accountManagersSelector,
  isLoadingAccountManagerSelector,
} from '../../redux/selectors/adminActions.selectors';

// helpers
import {
  jobMapperInput,
  jobMapperOutput,
} from '../../components/JobForm/job.helper';
import {
  adminActionsFormMapperInput,
  adminActionsFormMapperOutput,
} from '../AdminActions/adminActions.helper';

// schema
import { draftJobSchema } from '../../components/JobForm/job.schema';

// Queries
import {
  useJobCreateMutation,
  useJobExtraInfoMutation,
  useJobCreateVersionsMutation,
  useQuestionsQuery,
  useQuestionsMutation,
  useUserToJobMutation,
  useJobMailingPermissionsMutation,
  useJobUpdatePatchMutation,
} from '../../../../queries/jobQueries';
import { useUserQuery } from '../../../../queries/userQueries';

const DuplicateJob = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  // Queries
  const jobCreateMutate = useJobCreateMutation();
  const jobUpdatePatchMutate = useJobUpdatePatchMutation();
  const jobExtraInfoMutate = useJobExtraInfoMutation();
  const jobCreateVersionMutate = useJobCreateVersionsMutation();
  const killerQuestions = useQuestionsQuery({
    id,
    questionType: 'killer_questions',
  });
  const killerQuestionsMutate = useQuestionsMutation({
    id,
    questionType: 'killer_questions',
  });
  const optionalQuestions = useQuestionsQuery({
    id,
    questionType: 'optional_questions',
  });
  const optionalQuestionsMutate = useQuestionsMutation({
    id,
    questionType: 'optional_questions',
  });
  const userToJobMutate = useUserToJobMutation();
  const jobMailingPermissionsMutate = useJobMailingPermissionsMutation();

  const currentJob = useSelector(jobSelector);
  const currentExtraInfo = useSelector(extraInfoSelector);
  const isLoadingJob = useSelector(isLoadingJobSelector);
  const isLoadingExtraInfo = useSelector(isLoadingExtraInfoSelector);
  const company = useSelector(companySelector);
  const isLoadingCompany = useSelector(isLoadingCompanySelector);
  const { data: user } = useUserQuery();
  const ACCOUNT_MANAGERS_OPTIONS = useSelector(accountManagersSelector);
  const isLoadingAccountManagers = useSelector(isLoadingAccountManagerSelector);

  const [isDuplicatingJob, setIsDuplicatingJob] = useState(false);

  useEffect(() => {
    dispatch(getAccountManagers());
  }, [company, dispatch]);

  const editJob = {
    ...currentJob,
    ...currentExtraInfo,
    benefits_checked: [
      ...(currentJob?.benefits_pool_selected ?? []),
      ...(currentJob?.benefits_custom_selected ?? []),
    ],
  };

  const onSubmit = ({ data }) => {
    setIsDuplicatingJob(true);

    const { extraInfo, job } = jobMapperOutput(data);
    const { jobData } = adminActionsFormMapperOutput(
      adminActionsFormMapperInput(
        currentJob,
        extraInfo,
        ACCOUNT_MANAGERS_OPTIONS,
      ),
    );

    jobCreateMutate.mutate(
      { data: { ...job, ...jobData, status: null } },
      {
        onSuccess: ({ job: newJobData }) => {
          Promise.allSettled([
            // Update Job
            jobUpdatePatchMutate.mutateAsync({
              id: newJobData.id,
              data: {
                account_manager: jobData.account_manager,
                personality_check: jobData.personality_check,
              },
            }),
            // Update Extra Info
            jobExtraInfoMutate.mutateAsync({
              id: newJobData.id,
              data: { ...extraInfo },
            }),
            // Create job version
            jobCreateVersionMutate.mutateAsync({
              id: newJobData.id,
            }),
            // Update Questions
            killerQuestionsMutate.mutateAsync({
              id: newJobData.id,
              data: {
                questions: killerQuestions.data.map(
                  ({ question_id, ...restQuestions }) => ({
                    ...restQuestions,
                  }),
                ),
              },
            }),
            optionalQuestionsMutate.mutateAsync({
              id: newJobData.id,
              data: {
                questions: optionalQuestions.data.map(
                  ({ question_id, ...restQuestions }) => ({
                    ...restQuestions,
                  }),
                ),
              },
            }),
            userToJobMutate.mutateAsync({
              id: newJobData.id,
              userId: user.local_id,
            }),
            jobMailingPermissionsMutate.mutateAsync({
              data: { job_id: newJobData.id, user_id: user.local_id },
            }),
          ]).then(() => {
            history.push(`/edit-job/${newJobData.id}/admin`);
            setIsDuplicatingJob(false);
          });
        },
        onError: () => setIsDuplicatingJob(false),
      },
    );
  };

  const isLoading =
    isDuplicatingJob ||
    isLoadingCompany ||
    isLoadingJob ||
    isLoadingExtraInfo ||
    isLoadingAccountManagers ||
    killerQuestions.isLoading;
  const shouldRenderForm = company && currentJob && currentExtraInfo;

  return (
    <>
      <Helmet>
        <title>{t('edit_job')} | Lionstep</title>
      </Helmet>

      {isLoading && <Spinner position="fixed" size={100} />}

      {shouldRenderForm && (
        <JobForm
          job={jobMapperInput(editJob, t)}
          company={company}
          draftJobSchema={draftJobSchema}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default DuplicateJob;
