import actions from './actions';

import { getRecaptcha } from '../../../support';
import { ENV } from '../../../config';

let test = 0;

const { logoutUser } = actions;

const checkIsLogged = () => (dispatch) => {
  const logged = localStorage.getItem('logged');
  if (logged !== 'true') {
    dispatch(actions.logoutUser());
    test = 0;
  } else {
    setTimeout(() => {
      test = 1;
      dispatch(checkIsLogged());
    }, 1000);
  }
};

const checkUserToken = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const start = localStorage.getItem('start');
    const refresh = localStorage.getItem('refreshToken');
    const now = Math.floor(new Date().getTime() / 1000.0);
    if (
      now - start > 3590 ||
      typeof refresh === 'undefined' ||
      refresh === null ||
      refresh === 'null'
    ) {
      dispatch(actions.logoutUser());
    } else if (
      ENV === 'development' ||
      window.location.origin === 'https://dev-app.lionstep.com' ||
      window.location.origin === 'http://localhost:3000'
    ) {
      dispatch(
        actions.refreshUserToken({
          refresh_token: refresh,
          recaptcha_token: 'xsqFdjTK5i9w1w2',
        }),
      ).then(
        (res) => {
          setTimeout(() => {
            if (test === 0) {
              dispatch(checkIsLogged());
            }
          }, 1000); // 900000
          setTimeout(() => {
            dispatch(checkUserToken());
          }, 900000); // 900000
          resolve(res);
        },
        (err) => {
          reject(err);
        },
      );
    } else {
      getRecaptcha()
        .then((recaptcha) => {
          dispatch(
            actions.refreshUserToken({
              refresh_token: refresh,
              recaptcha_token: recaptcha,
            }),
          ).then(
            (res) => {
              setTimeout(() => {
                if (test === 0) {
                  dispatch(checkIsLogged());
                }
              }, 1000); // 900000
              setTimeout(() => {
                dispatch(checkUserToken());
              }, 900000); // 900000
              resolve(res);
            },
            (err) => {
              reject(err);
            },
          );
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

const loginUser = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.loginUser(data)).then(
      (res) => {
        setTimeout(() => {
          dispatch(checkIsLogged());
        }, 1000);
        setTimeout(() => {
          dispatch(checkUserToken());
        }, 900000); // 900000
        resolve(res);
      },
      (err) => {
        reject(err);
      },
    );
  });

const signupUser = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.signupUser(data)).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      },
    );
  });

const refreshUserToken = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.refreshUserToken(data)).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      },
    );
  });

const resetPassword = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.resetPassword(data)).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      },
    );
  });

const verifySignupCollaborator = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.verifySignupCollaborator(data)).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      },
    );
  });

const signupCollaborator = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.signupCollaborator(data)).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      },
    );
  });

const verifyOobCode = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.verifyOobCode(data)).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      },
    );
  });

const changePassword = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.changePassword(data)).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      },
    );
  });

export default {
  loginUser,
  signupUser,
  refreshUserToken,
  checkUserToken,
  resetPassword,
  verifyOobCode,
  changePassword,
  verifySignupCollaborator,
  signupCollaborator,
  logoutUser,
};
