import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import EN from './support/translations/en-en.json';
import DE from './support/translations/de-de.json';
import ES from './support/translations/es-es.json';
import FR from './support/translations/fr-fr.json';

i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    resources: {
      en: {
        translation: EN,
      },
      de: {
        translation: DE,
      },
      es: {
        translation: ES,
      },
      fr: {
        translation: FR,
      },
    },
    react: {
      wait: true,
    },
  });

export default i18n;
