import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

// Api
import { layer1Axios, pythonAxios } from '../api/http';

// Keys
export const COMPANY_QUERY_KEYS = {
  plan: 'company-plan',
  komboIntegration: 'company-kombo-integration',
  komboJobs: 'company-kombo-jobs',
};

// Company plan
const getCompanyPlan = () =>
  pythonAxios({
    method: 'GET',
    url: '/company/plan',
  }).then(({ data }) => data);

export const useCompanyPlanQuery = (options = {}) =>
  useQuery([COMPANY_QUERY_KEYS.plan], getCompanyPlan, { ...options });

const postCompanyPlan = (planData) =>
  pythonAxios({
    method: 'POST',
    url: '/company/plan',
    data: planData,
  }).then(({ data }) => data);

export const useCompanyPlanMutation = (options = {}) => {
  const queryClient = useQueryClient();

  return useMutation([COMPANY_QUERY_KEYS.plan], postCompanyPlan, {
    ...options,
    onSuccess: (data) => {
      queryClient.setQueryData([COMPANY_QUERY_KEYS.plan], data);

      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  });
};

// Company kombo integration
const getCompanyKomboIntegration = ({ id }) =>
  layer1Axios({
    method: 'GET',
    url: `/companies/${id}/ats-integrations/kombo`,
  }).then(({ data }) => data);

export const useCompanyKomboIntegrationQuery = ({ id }, options = {}) =>
  useQuery(
    [COMPANY_QUERY_KEYS.komboIntegration],
    () => getCompanyKomboIntegration({ id }),
    {
      ...options,
    },
  );

// Company kombo jobs
const getCompanyKomboJobs = ({ id }) =>
  layer1Axios({
    method: 'GET',
    url: `/companies/${id}/ats-integrations/kombo/jobs/filtered-for-job-form`,
  }).then(({ data }) => data);

export const useCompanyKomboJobsQuery = ({ id }, options = {}) =>
  useQuery([COMPANY_QUERY_KEYS.komboJobs], () => getCompanyKomboJobs({ id }), {
    enabled: false,
    ...options,
  });
