import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from '@lionstep/ui';
import classnames from 'classnames/bind';

// Components
import ValidatedCandidatesCard from './ValidatedCandidatesCard';

// Constants
import { JOB_STATUSES_KEY_VALUE } from '../../../../../../constants';

// Styles
import styles from './validatedCandidatesJobsList.module.scss';

const cx = classnames.bind(styles);

const statusIcon = {
  checking_interest: 'PlayCircle',
  job_description_done: 'CheckCircle',
  additional_search_needed: 'CheckCircle', // DEFAULT
  search_done: 'Search',
  job_on_hold: 'PauseCircle',
  job_closed: 'CloseCircle',
};

const ValidatedCandidatesJobsList = ({
  newValidatedCandidates,
  totalCandidates,
}) => {
  const getRootElement = () => {
    let root;

    if (!root) {
      root = document.getElementById('root');
    }

    return root;
  };

  const getScrollerContainerElement = () => {
    let scrollerContainer;

    if (!scrollerContainer) {
      scrollerContainer = document.getElementById('scrollerContainer');
    }

    return scrollerContainer;
  };

  const getScrollerCardsElement = () => {
    let scrollerCards;

    if (!scrollerCards) {
      scrollerCards = document.querySelectorAll('.scrollerCards');
    }

    return scrollerCards;
  };

  const getCardsOffsets = () => {
    const root = getRootElement();
    const scroller = getScrollerContainerElement();
    const cards = getScrollerCardsElement();

    const cardsOffsets = Array.from(cards).map((item) => ({
      offset: Math.floor(
        item.offsetLeft - (root.offsetWidth - scroller.offsetWidth) / 2,
      ),
      width: item.offsetWidth,
    }));

    return cardsOffsets;
  };

  const handleOnScrollLeft = () => {
    const scroller = getScrollerContainerElement();
    const cardsOffsets = getCardsOffsets();

    if (scroller.scrollWidth - scroller.offsetWidth >= scroller.scrollLeft) {
      const toScroll = cardsOffsets.reduce((acc, curr) => {
        if (curr.offset < scroller.scrollLeft) {
          return curr.offset + curr.width + 30 - scroller.offsetWidth;
        }

        return acc;
      }, 0);

      scroller.scroll({
        left: toScroll,
        behavior: 'smooth',
      });
    }
  };
  const handleOnScrollRight = () => {
    const scroller = getScrollerContainerElement();
    const cardsOffsets = getCardsOffsets();
    let toScroll = scroller.scrollWidth - scroller.offsetWidth;

    if (
      scroller.scrollWidth - scroller.offsetWidth >
      scroller.scrollLeft + scroller.offsetWidth
    ) {
      toScroll = cardsOffsets.reduce((acc, curr) => {
        if (
          curr.offset + curr.width >=
            scroller.scrollLeft + scroller.offsetWidth &&
          acc === 0
        ) {
          return curr.offset - 30;
        }

        return acc;
      }, 0);
    }

    scroller.scroll({
      left: toScroll,
      behavior: 'smooth',
    });
  };

  const renderJobStatusIcon = (job) => {
    const dynamicClasses = {
      [styles.jobStatusIconCheckingInterest]:
        job.status === 'checking_interest',
      [styles.jobStatusIconJobDescriptionDone]:
        job.status === 'job_description_done' ||
        job.status === 'additional_search_needed',
      [styles.jobStatusIconJobOnHold]: job.status === 'job_on_hold',
      [styles.jobStatusIconJobClosed]: job.status === 'job_closed',
      [styles.jobStatusIconSearchDone]: job.status === 'search_done',
    };

    return (
      <Tooltip id={job.id} content={JOB_STATUSES_KEY_VALUE[job.status]}>
        <span className={cx(styles.jobStatusIcon, dynamicClasses)}>
          <Icon
            name={statusIcon[job.status] || statusIcon.additional_search_needed}
          />
        </span>
      </Tooltip>
    );
  };

  return (
    <>
      <div id="scrollerContainer" className={styles.jobs}>
        {/* Job */}
        {newValidatedCandidates.map((job) => (
          <div className={styles.job} key={job.id}>
            <div className={styles.candidates}>
              {/* Candidate */}
              {job.validated_candidates.map((candidate) => (
                <ValidatedCandidatesCard job={job} candidate={candidate} />
              ))}
            </div>
            <div className={styles.jobName}>
              {job.opening_title} {renderJobStatusIcon(job)}
            </div>
          </div>
        ))}
      </div>

      {/* Arrows */}
      <span
        className={cx(styles.arrows, {
          [styles.arrowsActive]: totalCandidates,
        })}
      >
        <span
          className={styles.arrowItem}
          onClick={() => totalCandidates && handleOnScrollLeft()}
        >
          <Icon name="ArrowLeft" />
        </span>
        <span
          className={styles.arrowItem}
          onClick={() => totalCandidates && handleOnScrollRight()}
        >
          <Icon name="ArrowRight" />
        </span>
      </span>
    </>
  );
};

ValidatedCandidatesJobsList.propTypes = {
  newValidatedCandidates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalCandidates: PropTypes.number.isRequired,
};

export default ValidatedCandidatesJobsList;
