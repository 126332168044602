import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Spinner } from '@lionstep/ui';

// selectors
import { dashboardIsLoadingSelector } from '../../../../redux/selectors/dashboard.selectors';

// Components
import { CandidatePlaceholder } from '../../../../../../components/refactoring/placeholders';

// Hooks
import useMediaQuery from '../../../../../../hooks/useMediaQuery';

// Styles
import styles from './validatedCandidatesPlaceholder.module.scss';

const ValidatedCandidatesPlaceholder = ({ isFetching }) => {
  const { t } = useTranslation();
  const { activeBreakpoints } = useMediaQuery();
  const isLoadingJobs = useSelector(dashboardIsLoadingSelector);

  return (
    <CandidatePlaceholder
      className={styles.placeholder}
      position={activeBreakpoints.includes('sm') ? 'right' : 'bottom'}
    >
      {!isLoadingJobs && isFetching && (
        <Spinner position="absolute" size={50} />
      )}
      <span className={styles.placeholderText}>
        {t('dashboard_page.new_validated_candidates.no_new_validated')}
      </span>
    </CandidatePlaceholder>
  );
};

ValidatedCandidatesPlaceholder.propTypes = {
  isFetching: PropTypes.bool.isRequired,
};

export default ValidatedCandidatesPlaceholder;
