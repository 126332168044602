import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { withTranslation } from 'react-i18next';

import { Spinner } from '@lionstep/ui';
import PostLoginLayout from './refactoring/layouts/PostLoginLayout';
import ErrorBoundary from './ErrorBoundary';

// Queries
import { useUserQuery } from '../queries/userQueries';

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const { isLoading, data: userData } = useUserQuery();

  if (isLoading) return <Spinner size={100} position="absolute" />;

  return (
    <Route
      {...rest}
      render={(props) => {
        const passedProps = { ...props, userData };
        const PageLayout = Layout || React.Fragment;

        return userData ? (
          <ErrorBoundary>
            <PostLoginLayout>
              <PageLayout>
                <Component {...passedProps} />
              </PageLayout>
            </PostLoginLayout>
          </ErrorBoundary>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default withTranslation()(PrivateRoute);
