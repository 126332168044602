import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal, Text, Spinner } from '@lionstep/ui';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import DayPickerSingleDateController from 'react-dates/lib/components/DayPickerSingleDateController';
import moment from 'moment';

// Selectors
import { isLoadingUpdateSelector } from '../../candidate.selectors';

// Styles
import styles from './hireCandidateModal.module.scss';

const HireCandidateModal = ({ show, onClose, onConfirm }) => {
  const { t } = useTranslation();
  const isLoadingUpdate = useSelector(isLoadingUpdateSelector);

  const [startDate, setStartDate] = useState(null);
  const handleOnStartDateSelect = (date) => {
    setStartDate(date);
  };

  return (
    <Modal show={show} onClose={onClose}>
      {isLoadingUpdate && <Spinner position="absolute" size={100} />}

      <Modal.Header>Hire Candidate</Modal.Header>

      <Modal.Body>
        <Text className={styles.hireCandidateDesc}>
          {t(
            "We are looking forward to receiving the flowers on the talent's starting date",
          )}
        </Text>
        <div className={styles.calendarWrapper}>
          <DayPickerSingleDateController
            date={startDate}
            onDateChange={handleOnStartDateSelect}
            noBorder
            isOutsideRange={(date) => date.isBefore(moment())}
            numberOfMonths={2}
            orientation={
              window.matchMedia('(max-width: 744px)').matches
                ? 'vertical'
                : 'horizontal'
            }
          />
        </div>
      </Modal.Body>

      <Modal.Footer
        confirmText={t('candidate_page.header.hire_btn')}
        closeText={t('cancel')}
        onClose={onClose}
        onConfirm={() => onConfirm(startDate)}
      />
    </Modal>
  );
};

export default HireCandidateModal;
