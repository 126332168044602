export const totalCreditsSelector = (state) => state.newCompany.credits.total;

export const creditsHistorySelector = (state) =>
  state.newCompany.credits.history;

export const creditsPackagesSelector = (state) =>
  state.newCompany.credits.packages;
export const isLoadingCreditsPackagesSelector = (state) =>
  state.newCompany.credits.isLoadingPackages;

export const isLoadingRefundCreditSelector = (state) =>
  state.newCompany.credits.isLoadingRefund;
export const isLoadingAddCreditsSelector = (state) =>
  state.newCompany.credits.isLoadingAddCredits;

export const purchasesSelector = (state) => state.newCompany.credits.purchases;
export const isLoadingPurchasesSelector = (state) =>
  state.newCompany.credits.isLoadingPurchases;
