import { pythonAxios, rubyAxios, layer1Axios } from './http';

export const getUserProfileApi = () =>
  layer1Axios({
    method: 'get',
    url: '/users/profile',
  });

export const putUserApi = ({ data }) =>
  layer1Axios({
    method: 'put',
    url: `/users`,
    data,
  });

export const getCollaboratorsApi = () =>
  layer1Axios({
    method: 'get',
    url: '/users/collaborators',
  });

export const addCollaboratorApi = (data) =>
  layer1Axios({
    method: 'post',
    url: '/users/collaborators',
    data,
  });

export const deleteCollaboratorApi = ({ id }) =>
  pythonAxios({
    method: 'delete',
    url: `/user/${id}/delete`,
  });

export const updateCollaboratorRoleApi = ({ data = {} } = {}) =>
  rubyAxios({
    method: 'put',
    url: '/users/update_role',
    data,
  });
