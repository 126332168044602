import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Heading, Table } from '@lionstep/ui';
import moment from 'moment';
import classnames from 'classnames';

// Selectors
import { creditsHistorySelector } from '../../../../redux/selectors/credits.selectors';

// Helpers
import { constantsArrayWithTranslatedLabel } from '../../../../../../support';

// Constants
import { SPENT_ON_OPTIONS } from '../../credits.constants';
import { DATE_FORMAT } from '../../../../../../constants/date.const';

// Styles
import styles from './creditsHistoryCard.module.scss';

const cx = classnames.bind(styles);

const CreditsHistoryCard = () => {
  const { t } = useTranslation();
  const creditsHistory = useSelector(creditsHistorySelector);
  const spentOnOptionsTranslated = constantsArrayWithTranslatedLabel(
    t,
    SPENT_ON_OPTIONS,
  );

  const totalUsed = creditsHistory.reduce(
    (acc, curr) => acc + curr.charged_amount,
    0,
  );

  return (
    <Card
      className={styles.creditsHistoryCard}
      data-testid="credits-history-card"
    >
      <Heading
        className={styles.creditHistoryTitle}
        level={4}
        data-testid="credits-history-heading"
      >
        {t('credits_page.credits_history.title')}{' '}
        <span className={styles.totalCreditsInfo}>
          {t('credits_page.credits_history.total_credits_used')}:{' '}
          <span data-testid="total-credits-used">{totalUsed}</span>
        </span>
      </Heading>

      <Table data-testid="credits-history-table">
        <Table.Header>
          <Table.HeaderRow>
            <Table.HeaderCell>
              {t('credits_page.credits_history.table.job_title')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('credits_page.credits_history.table.credits')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('credits_page.credits_history.table.job_owner')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('credits_page.credits_history.table.description')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('credits_page.credits_history.table.candidate')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('credits_page.credits_history.table.comment')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('credits_page.credits_history.table.reverted_on')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('credits_page.credits_history.table.date')}
            </Table.HeaderCell>
          </Table.HeaderRow>
        </Table.Header>

        <Table.Body>
          {creditsHistory.map((record, index) => (
            <Table.Row
              key={record.id}
              className={cx({
                [styles.revertedCreditRow]: record.removed,
              })}
              data-testid={`table-credits-row-${index}`}
            >
              <Table.Cell>
                <div className={cx(styles.fixedCell, styles.jobTitle)}>
                  {record.job_title}
                </div>
              </Table.Cell>
              <Table.Cell>{record.charged_amount}</Table.Cell>
              <Table.Cell>{record.owner_name}</Table.Cell>
              <Table.Cell>
                <div className={styles.fixedCell}>
                  {t(
                    spentOnOptionsTranslated.find(
                      (option) => option.value === record.spent_on,
                    ).label,
                  )}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className={styles.fixedCell}>
                  {record.candidate_name ?? '⎯'}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className={styles.fixedCell}>{record.comment ?? '⎯'}</div>
              </Table.Cell>
              <Table.Cell>
                {record.removed_at
                  ? moment(record.removed_at).format(DATE_FORMAT)
                  : '⎯'}
              </Table.Cell>
              <Table.Cell>
                {moment(record.spent_at).format(DATE_FORMAT)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Card>
  );
};

export default CreditsHistoryCard;
