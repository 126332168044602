import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Card,
  Heading,
  FormInput,
  FormSelectV2,
  Button,
  Grid,
} from '@lionstep/ui';

// Actions
import { getActiveJobsTitles, chargeCredits } from '../../../../redux/actions';

// Selectors
import { activeJobsTitlesSelector } from '../../../../redux/selectors/company.selectors';

// Helpers
import { constantsArrayWithTranslatedLabel } from '../../../../../../support';

// Constants
import { SPENT_ON_OPTIONS } from '../../credits.constants';

// Components
import InfoPlusIconWithTooltip from '../../../../../../components/refactoring/infoPlusIconWithTooltip';

// Styles
import styles from './chargeCreditsCard.module.scss';

const chargeCreditsSchema = Yup.object().shape({
  job: Yup.object()
    .shape({
      value: Yup.string().required('Required'),
      label: Yup.string().required('Required'),
    })
    .nullable()
    .required('Required'),
  comment: Yup.string().required('Required'),
  charged_amount: Yup.number()
    .typeError('Required')
    .positive('Must be 1 or greater')
    .required('Required'),
  spent_on: Yup.object()
    .shape({
      value: Yup.string().required('Required'),
      label: Yup.string().required('Required'),
    })
    .nullable()
    .required('Required'),
});

const ChargeCreditsCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeJobsTitles = useSelector(activeJobsTitlesSelector);
  const spentOnOptionsTranslated = constantsArrayWithTranslatedLabel(
    t,
    SPENT_ON_OPTIONS,
  );

  const formMethods = useForm({
    defaultValues: {
      job: null,
      comment: '',
      charged_amount: '',
      spent_on: spentOnOptionsTranslated[1],
    },
    resolver: yupResolver(chargeCreditsSchema),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    dispatch(getActiveJobsTitles());
  }, []);

  const handleOnChargeCredits = (data) => {
    dispatch(
      chargeCredits({
        job_title: data.job.label,
        job_posting_id: data.job.value,
        spent_on: data.spent_on.value,
        charged_amount: data.charged_amount,
        comment: data.comment,
        onSuccess: reset,
      }),
    );
  };

  return (
    <Card
      className={styles.chargeCreditsCard}
      data-testid="charge-credits-card"
    >
      <Heading
        className={styles.chargeCreditsCardTitle}
        level={4}
        data-testid="charge-credits-title"
      >
        {t('credits_page.charge_credits.title')}
        <InfoPlusIconWithTooltip />
      </Heading>

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleOnChargeCredits)}>
          <Grid.Row gutter={30}>
            <Grid.Col xs={24} md={12}>
              <FormSelectV2
                name="job"
                label={t('credits_page.charge_credits.job_title.label')}
                placeholder={t(
                  'credits_page.charge_credits.job_title.placeholder',
                )}
                options={activeJobsTitles.map((job) => ({
                  value: job.job_id,
                  label: job.opening_title,
                }))}
                error={errors.job?.message}
              />
            </Grid.Col>

            <Grid.Col xs={24} md={6}>
              <FormInput
                name="charged_amount"
                type="number"
                label={t('credits_page.charge_credits.number_of_credits.label')}
                placeholder="1"
                error={errors.charged_amount?.message}
              />
            </Grid.Col>

            <Grid.Col xs={24} md={6}>
              <FormSelectV2
                name="spent_on"
                label={t('credits_page.charge_credits.spent_on.label')}
                options={spentOnOptionsTranslated}
                error={errors.spent_on?.message}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row gutter={30}>
            <Grid.Col xs={24}>
              <FormInput
                name="comment"
                label={t('credits_page.charge_credits.comment.label')}
                placeholder={t(
                  'credits_page.charge_credits.comment.placeholder',
                )}
                error={errors.comment?.message}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row gutter={30}>
            <Grid.Col xs={24}>
              <Button typeHtml="submit">
                {t('credits_page.charge_credits.charge_credits_btn')}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </form>
      </FormProvider>
    </Card>
  );
};

export default ChargeCreditsCard;
