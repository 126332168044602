import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';

// Components
import { Modal, Text, Spinner } from '@lionstep/ui';

// Constants
import { DATE_FORMAT } from '../../../../constants/date.const';

// Styles
import styles from './allVersionsModal.module.scss';

const AllVersionsModal = ({ show, isLoading, versions, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onClose={onClose} className={styles.allVersionsModal}>
      <Modal.Header>
        {t('job.versions.all_versions.previous_versions')}
      </Modal.Header>

      <Modal.Body className={styles.allVersionsModalBody}>
        {isLoading && <Spinner position="fixed" size={100} />}

        {orderBy(versions, (item) => item.version, 'desc').map((version) => (
          <div key={version.version} className={styles.versionBlock}>
            <Text className={styles.versionTitle}>
              {t('job.versions.current.version_number', {
                number: version.version,
              })}
            </Text>

            <Text className={styles.versionDate}>
              {t('job.versions.current.date_created', {
                date: moment(version.created).format(DATE_FORMAT),
                interpolation: { escapeValue: false },
              })}
            </Text>

            {version.description && (
              <Text className={styles.versionText}>{version.description}</Text>
            )}
          </div>
        ))}
      </Modal.Body>

      <Modal.Footer
        confirmButtonGhost
        confirmText={t('close')}
        onConfirm={onClose}
      />
    </Modal>
  );
};

AllVersionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      version: PropTypes.number,
      description: PropTypes.string,
      created: PropTypes.string,
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AllVersionsModal;
