/* eslint-disable import/prefer-default-export */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { layer1Axios } from '../api/http';

import { COMPANY_QUERY_KEYS } from './companyQueries';

const postKomboCreateLink = (data) =>
  layer1Axios({
    method: 'POST',
    url: '/ats-integration/kombo/integrations/init',
    data,
  }).then((response) => response.data?.data?.link);

export const useKomboCreateLinkMutation = ({ options = {} } = {}) =>
  useMutation(['kombo-create-link'], postKomboCreateLink, { ...options });

const postKomboActivateIntegration = (data) =>
  layer1Axios({
    method: 'POST',
    url: '/ats-integration/kombo/integrations/activate',
    data,
  }).then((response) => response.data);

export const useKomboActivateIntegrationMutation = ({ options = {} } = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['kombo-activate-integration'],
    postKomboActivateIntegration,
    {
      ...options,
      onSuccess: (data) => {
        queryClient.setQueryData([COMPANY_QUERY_KEYS.komboIntegration], data);

        if (options.onSuccess) {
          options.onSuccess();
        }
      },
    },
  );
};

const postKomboRelinkIntegration = ({ id, language }) =>
  layer1Axios({
    method: 'POST',
    url: `/ats-integration/kombo/integrations/${id}/relink`,
    data: {
      language,
    },
  }).then((response) => response.data?.data?.link);

export const useKomboRelinkIntegrationMutation = ({ options = {} } = {}) => {
  const queryClient = useQueryClient();

  return useMutation(['kombo-relink-integration'], postKomboRelinkIntegration, {
    ...options,
    onSuccess: (data) => {
      queryClient.setQueryData([COMPANY_QUERY_KEYS.komboIntegration], data);

      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  });
};
