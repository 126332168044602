import { toast } from '@lionstep/ui';

import { getFeedbackAnalyticsApi } from '../../../../api/job.api';

// types
import * as types from './analytics.types';

export const getFeedbackAnalytics =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.GET_FEEDBACK_ANALYTICS_START });

      const { data } = await getFeedbackAnalyticsApi({ id });

      dispatch({
        type: types.GET_FEEDBACK_ANALYTICS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({ type: types.GET_FEEDBACK_ANALYTICS_FAIL });
      toast.error('Error fetching account managers', {
        id: types.GET_FEEDBACK_ANALYTICS_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const clearFeedbackAnalytics = () => (dispatch) =>
  dispatch({ type: types.CLEAR_FEEDBACK_ANALYTICS_DATA });
