import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Label, Icon } from '@lionstep/ui';
import classnames from 'classnames/bind';

// Icons
import AdditionalCustomBonus from './icons/additional_custom_bonus';
import Cafeteria from './icons/cafeteria';
import CarsEbikes from './icons/cars_ebikes';
import ChildCareStipend from './icons/child_care_stipend';
import CoffeeSnacks from './icons/coffee_snacks';
import CommutingStipend from './icons/commuting_stipend';
import CustomDiscountBonus from './icons/custom_discount_bonus';
import CustomFinancialBonus from './icons/custom_financial_bonus';
import CustomHealthBonus from './icons/custom_health_bonus';
import CustomTimeoffBonus from './icons/custom_timeoff_bonus';
import CustomWorkEnvBonus from './icons/custom_work_env_bonus';
import DentalCoverage from './icons/dental_coverage';
import EquityIncentives from './icons/equity_incentives';
import ExternalServices from './icons/external_services';
import ExtraMaternityPaternityLeave from './icons/extra_maternity_paternity_leave';
import Gym from './icons/gym';
import HealthInsurance from './icons/health_insurance';
import LearningOpportunities from './icons/learning_opportunities';
import LifeInsurance from './icons/life_insurance';
import PensionFund from './icons/pension_fund';
import PetFriendlyOffice from './icons/pet_friendly_office';
import RemoteWork from './icons/remote_work';
import Sabbatical from './icons/sabbatical';
import StockPlan from './icons/stock_plan';
import TimeoffMovingExams from './icons/timeoff_moving_exams';
import TopEquipement from './icons/top_equipement';
import TopFurniture from './icons/top_furniture';
import Wellness from './icons/wellness';
import Wfh from './icons/wfh';

// Components
import ShowHideTranslation from '../showHideTranslation';

// Styles
import styles from './customBenefits.module.scss';

const cx = classnames.bind(styles);

const iconsMap = {
  additional_custom_bonus: AdditionalCustomBonus,
  cafeteria: Cafeteria,
  cars_ebikes: CarsEbikes,
  child_care_stipend: ChildCareStipend,
  coffee_snacks: CoffeeSnacks,
  commuting_stipend: CommutingStipend,
  custom_discount_bonus: CustomDiscountBonus,
  custom_financial_bonus: CustomFinancialBonus,
  custom_health_bonus: CustomHealthBonus,
  custom_timeoff_bonus: CustomTimeoffBonus,
  custom_work_env_bonus: CustomWorkEnvBonus,
  dental_coverage: DentalCoverage,
  equity_incentives: EquityIncentives,
  external_services: ExternalServices,
  extra_maternity_paternity_leave: ExtraMaternityPaternityLeave,
  gym: Gym,
  health_insurance: HealthInsurance,
  learning_opportunities: LearningOpportunities,
  life_insurance: LifeInsurance,
  pension_fund: PensionFund,
  pet_friendly_office: PetFriendlyOffice,
  remote_work: RemoteWork,
  sabbatical: Sabbatical,
  stock_plan: StockPlan,
  timeoff_moving_exams: TimeoffMovingExams,
  top_equipement: TopEquipement,
  top_furniture: TopFurniture,
  wellness: Wellness,
  wfh: Wfh,
};

const CustomBenefit = ({
  field,
  index,
  labelIndex,
  languages,
  onUpdate,
  ...restProps
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();

  const handleOnDropdownToggle = () => {
    setIsDropdownOpen((curr) => !curr);
  };

  const handleOnIconSelect = ({ icon }) => {
    onUpdate(index, { ...field, icon });
    setIsDropdownOpen(false);
  };

  const handleOnRemoveClick = () => {
    onUpdate(index, { ...field, deleted: true });
  };

  const renderDropDown = () => {
    const SelectedIcon = iconsMap[field.icon];

    return (
      <div className={styles.iconsContainer} {...restProps}>
        <Label htmlFor="custom_benefit_icon">
          {t('company_page.displayed_section.benefits.custom_benefits.icon')}
        </Label>
        <div
          className={cx(styles.iconSelected, {
            isOpen: isDropdownOpen,
          })}
          onClick={handleOnDropdownToggle}
        >
          {SelectedIcon && <SelectedIcon />}
          <Icon
            name="ArrowDown"
            className={cx(styles.iconSelectedArrow, {
              isOpen: isDropdownOpen,
            })}
          />
        </div>
        <div
          className={cx(styles.iconOptionsWrapper, {
            isOpen: isDropdownOpen,
          })}
        >
          <div className={styles.iconOptions}>
            {Object.entries(iconsMap).map(([key, Component]) => (
              <div
                key={key}
                className={styles.iconOption}
                onClick={() => handleOnIconSelect({ icon: key })}
              >
                <Component className={styles.icon} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.customBenefit} key={field.id}>
      <div className={styles.customBenefitHeader}>
        <span>Custom Benefit {labelIndex}</span>{' '}
        <Icon
          className={styles.removeBenefitIcon}
          name="MinusCircle"
          onClick={handleOnRemoveClick}
        />
      </div>
      <div className={styles.customBenefitBody}>
        <div className={styles.customBenefitSelect}>
          {/* Dropdown */}
          {renderDropDown()}
        </div>
        <ShowHideTranslation className={styles.customBenefitTextareas}>
          <ShowHideTranslation.Label htmlFor="custom_benefit_description">
            {t(
              'company_page.displayed_section.benefits.custom_benefits.description',
            )}
          </ShowHideTranslation.Label>
          {field.description.map(
            (desc, descIndex) =>
              languages.includes(desc.lang) && (
                <ShowHideTranslation.Item
                  key={`${field.id}-${desc.lang}`}
                  index={descIndex}
                  language={desc.lang}
                  name={`benefits_custom.${index}.description.${descIndex}.content`}
                  placeholder={t(
                    'company_page.displayed_section.benefits.custom_benefits.placeholder',
                  )}
                  translationPlaceholder={desc.content}
                />
              ),
          )}
        </ShowHideTranslation>
      </div>
    </div>
  );
};

CustomBenefit.propTypes = {
  field: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  labelIndex: PropTypes.number.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default CustomBenefit;
