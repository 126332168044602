import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Checkbox } from '@lionstep/ui';

// Styles
import styles from './candidatePDFReportModal.module.scss';

const CandidatePDFReportModal = ({ isOpen, onClose, onConfirm }) => {
  const [includeSalary, setIncludeSalary] = useState(false);

  const handleIncludeSalaryChange = (e) => {
    setIncludeSalary(e.target.checked);
  };

  return (
    <Modal show={isOpen} onClose={onClose}>
      <Modal.Header>Candidate PDF Report</Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Checkbox
          name="includeSalary"
          label="Include salary information in candidate PDF report"
          onChange={handleIncludeSalaryChange}
        />
      </Modal.Body>
      <Modal.Footer
        onClose={onClose}
        onConfirm={() => onConfirm({ hideSalary: !includeSalary })}
      />
    </Modal>
  );
};

CandidatePDFReportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default CandidatePDFReportModal;
