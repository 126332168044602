import React, {
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { Spinner, Grid, Accordion, Text, Icon, Button } from '@lionstep/ui';
import classnames from 'classnames/bind';

// Components
import Question from '../question';

// Helpers
import {
  initialQuestionsMapperInput,
  initialQuestionsMapperOutput,
  questionValidation,
} from './killerQuestions.helper';

// Actions
import {
  getKillerQuestions,
  updateKillerQuestions,
} from '../../../../redux/actions';

// Selectors
import {
  isLoadingKillerQuestionsSelector,
  killerQuestionsSelector,
  skillsSelector,
  isLoadingSkillsSelector,
} from '../../../../redux/selectors/adminActions.selectors';
import { jobSelector } from '../../../../redux/selectors/job.selectors';

// Styles
import styles from './killerQuestions.module.scss';

// Constants
import {
  QUESTIONS_TYPE,
  MULTIPLE_CHOICE_ANSWERS_TYPE,
} from '../../adminActions.constants';

const SUPPORTED_QUESTION_TYPES = [
  QUESTIONS_TYPE.yesNo,
  QUESTIONS_TYPE.multipleChoice,
  QUESTIONS_TYPE.skills,
];

const QUESTIONS = {
  [QUESTIONS_TYPE.yesNo]: {
    hide: true,
    title: '',
    type: QUESTIONS_TYPE.yesNo,
    choices: [
      { choice: 'yes', expected_str: MULTIPLE_CHOICE_ANSWERS_TYPE.valid },
      { choice: 'no', expected_str: MULTIPLE_CHOICE_ANSWERS_TYPE.notValid },
    ],
  },
  [QUESTIONS_TYPE.multipleChoice]: {
    hide: false,
    title: '',
    type: QUESTIONS_TYPE.multipleChoice,
    choices: [
      { choice: '', expected_str: MULTIPLE_CHOICE_ANSWERS_TYPE.notValid },
    ],
  },
  [QUESTIONS_TYPE.skills]: {
    hide: false,
    title: '',
    type: QUESTIONS_TYPE.skills,
    choices: [],
  },
  [QUESTIONS_TYPE.openAnswer]: {
    hide: false,
    title: '',
    type: QUESTIONS_TYPE.openAnswer,
    choices: [],
  },
};

const cx = classnames.bind(styles);

// Sortable
const SortableQuestionDragHandler = SortableHandle(() => (
  <Icon className={cx(styles.headerDragHandler)} name="DragHandler" size={16} />
));

const SortableQuestionItem = SortableElement(({ children }) => (
  <div>{children}</div>
));

const SortableQuestionList = SortableContainer(({ children }) => (
  <Accordion withSpace>{children}</Accordion>
));

const KillerQuestions = React.forwardRef((_, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingKillerQuestionsSelector);
  const killerQuestions = useSelector(killerQuestionsSelector);
  const job = useSelector(jobSelector);

  const skills = useSelector(skillsSelector);
  const isLoadingSkills = useSelector(isLoadingSkillsSelector);
  const [questions, setQuestions] = useState([]);
  const [questionsError, setQuestionsError] = useState([]);

  useEffect(() => {
    dispatch(getKillerQuestions(job.id));
  }, []);

  useEffect(() => {
    if (killerQuestions.length) {
      setQuestions(initialQuestionsMapperInput(killerQuestions));
    }
  }, [killerQuestions]);

  /**
   * Add question - push default killer question to array
   */
  const handleOnQuestionAdd = () => {
    setQuestions((currState) => [
      ...currState,
      {
        ...QUESTIONS[QUESTIONS_TYPE.yesNo],
        id: uuid(),
      },
    ]);
  };

  /**
   * Delete question - remove question from array base on question index
   *
   * @param {number} questionIndex
   */
  const handleOnQuestionDelete = (e, questionIndex) => {
    e.preventDefault();
    e.stopPropagation();
    setQuestions((currState) =>
      currState.filter((item, index) => index !== questionIndex),
    );
    setQuestionsError((currState) =>
      currState.filter((item) => item !== `question-${questionIndex}`),
    );
  };

  /**
   * Change question type - when type is changed, we are spread default type options
   * and we keep title from current question. By spreading default options we are
   * solving issue of setting hide to exact value flag by changing question type
   *
   * @param {number} questionIndex
   * @param {string} questionType
   */
  const handleOnQuestionTypeChange = (questionIndex, questionType) => {
    setQuestions((currState) =>
      currState.map((qItem, qIndex) => {
        if (qIndex === questionIndex) {
          return {
            ...QUESTIONS[questionType],
            id: uuid(),
            title: qItem.title,
          };
        }

        return qItem;
      }),
    );
  };

  /**
   * Question Value change - with this method, we handle change of:
   *  - question title
   *  - show/hide question flag (if question type === yes/no)
   *
   * @param {number} questionIndex
   * @param {string} key
   * @param {any} value
   */
  const handleOnQuestionValueChange = (questionIndex, key, value) => {
    setQuestions((currState) =>
      currState.map((qItem, qIndex) => {
        if (qIndex === questionIndex) {
          return {
            ...qItem,
            [key]: value,
          };
        }

        return qItem;
      }),
    );
  };

  /**
   * Add choice to question base on question ID.
   *
   * @param {number} questionIndex
   * @param {string} choiceChoice
   * @param {boolean} choiceExpected
   */
  const handleOnChoiceAdd = (
    questionIndex,
    choiceChoice = '',
    choiceExpected = MULTIPLE_CHOICE_ANSWERS_TYPE.notValid,
  ) => {
    setQuestions((currState) =>
      currState.map((qItem, qIndex) => {
        if (qIndex === questionIndex) {
          return {
            ...qItem,
            choices: [
              ...qItem.choices,
              { choice: choiceChoice, expected_str: choiceExpected },
            ],
          };
        }

        return qItem;
      }),
    );
  };

  /**
   * Delete choice - delete choice for specific question, by question and choice ID
   *
   * @param {number} questionIndex
   * @param {number} choiceIndex
   */
  const handleOnChoiceDelete = (questionIndex, choiceIndex) => {
    setQuestions((currState) =>
      currState.map((qItem, qIndex) => {
        if (qIndex === questionIndex) {
          return {
            ...qItem,
            choices: qItem.choices.filter(
              (cItem, cIndex) => cIndex !== choiceIndex,
            ),
          };
        }

        return qItem;
      }),
    );
  };

  /**
   * Change question choice value based on question and choice ID
   *
   * @param {number} questionIndex
   * @param {number} choiceIndex
   * @param {string} choiceChoice
   * @param {boolean} choiceExpected
   */
  const handleOnChoiceChange = (
    questionIndex,
    choiceIndex,
    choiceChoice,
    choiceExpected,
  ) => {
    setQuestions((currState) =>
      currState.map((qItem, qIndex) => {
        if (qIndex === questionIndex) {
          return {
            ...qItem,
            choices: qItem.choices.map((cItem, cIndex) => {
              if (cIndex === choiceIndex) {
                return {
                  choice: choiceChoice,
                  expected_str: choiceExpected,
                };
              }

              return cItem;
            }),
          };
        }

        return qItem;
      }),
    );
  };

  /**
   * Saving questions
   */
  const handleOnQuestionsSubmit = async () => {
    setQuestionsError([]);
    dispatch(
      updateKillerQuestions({
        id: job.id,
        data: initialQuestionsMapperOutput(questions),
      }),
    );
  };

  /**
   * Sorting item on drag and drop
   */
  const handleOnSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const sortedQuestions = [...questions];
      sortedQuestions.splice(oldIndex, 1);
      sortedQuestions.splice(newIndex, 0, questions[oldIndex]);

      setQuestions(sortedQuestions);

      if (questionsError.length) {
        const sortedErrors = questionsError.map((error) => {
          let newError = error;

          if (error.split('-')[1] === `${oldIndex}`) {
            newError = `question-${newIndex}`;
          } else if (error.split('-')[1] === `${newIndex}`) {
            newError = `question-${oldIndex}`;
          }

          return newError;
        });

        setQuestionsError(sortedErrors);
      }
    }
  };

  /**
   * Render question (accordion) header
   */
  const renderQuestionsHeader = useCallback(
    ({ questionIndex }) => (
      <div className={cx(styles.questionsHeader)}>
        <SortableQuestionDragHandler />
        <span className={cx(styles.headerTitleWrapper)}>
          <Text className={cx(styles.headerTitle)}>{`${t('question')} ${
            questionIndex + 1
          }`}</Text>
          {questionsError.includes(`question-${questionIndex}`) && (
            <p className={cx(styles.headerTitleError)}>
              {t('Please make sure you filed all the information')}
            </p>
          )}
        </span>
        <Icon
          className={cx(styles.headerDeleteQuestion)}
          name="Close"
          onClick={(e) => handleOnQuestionDelete(e, questionIndex)}
        />
      </div>
    ),
    [t, questionsError],
  );

  useImperativeHandle(ref, () => ({
    onKillerQuestionsSubmit: handleOnQuestionsSubmit,
    onKillerQuestionsValidation: async (shouldScrollToError = false) => {
      const errors = await questionValidation(questions);

      if (errors.length) {
        const elementId = errors[0];
        const element = document.getElementById(elementId);

        setQuestionsError(errors);

        if (element && shouldScrollToError) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }

      return errors;
    },
  }));

  if (!job || isLoading) return <Spinner position="fixed" size={100} />;

  return (
    <Grid.Row ref={ref}>
      <Grid.Col xs={24}>
        {!!questions.length && (
          <SortableQuestionList useDragHandle onSortEnd={handleOnSortEnd}>
            {questions.map((question, index) => (
              <SortableQuestionItem
                key={`sort-question-${question.id}`}
                index={index}
              >
                <Accordion.Item
                  className={cx({
                    [styles.accordionItemError]: questionsError.includes(
                      `question-${index}`,
                    ),
                  })}
                  id={`question-${index}`}
                  itemKey={`question-${question.id}`}
                  preview={<Text>{question.title}</Text>}
                >
                  <Accordion.ItemHeader>
                    {renderQuestionsHeader({ questionIndex: index })}
                  </Accordion.ItemHeader>
                  <Accordion.ItemBody>
                    <Question
                      isLoadingSkills={isLoadingSkills}
                      question={question}
                      questionIndex={index}
                      skills={skills}
                      supportedQuestionTypes={SUPPORTED_QUESTION_TYPES}
                      onQuestionChoiceAdd={handleOnChoiceAdd}
                      onQuestionChoiceChange={handleOnChoiceChange}
                      onQuestionChoiceDelete={handleOnChoiceDelete}
                      onQuestionTypeChange={handleOnQuestionTypeChange}
                      onQuestionValueChange={handleOnQuestionValueChange}
                    />
                  </Accordion.ItemBody>
                </Accordion.Item>
              </SortableQuestionItem>
            ))}
          </SortableQuestionList>
        )}
      </Grid.Col>
      <Grid.Col xs={24}>
        <Button
          block
          ghost
          onClick={handleOnQuestionAdd}
          data-testid="button-add-question"
        >
          {t('add_question')}
        </Button>
      </Grid.Col>
    </Grid.Row>
  );
});

export default KillerQuestions;
