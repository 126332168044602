import { useState, useEffect } from 'react';

const BREAK_POINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

const useMediaQuery = () => {
  const [breakpoints, setBreakpoints] = useState({
    activeBreakpoints: [],
    currentBreakpoint: 'xs',
  });
  const currentWidth = window.innerWidth;

  const handleActiveBreakpoints = () => {
    const actBreakpoints = Object.entries(BREAK_POINTS).reduce(
      (acc, [key, value]) => {
        if (currentWidth >= value) {
          acc.push(key);
        }
        return acc;
      },
      [],
    );

    return actBreakpoints;
  };

  const handleBreakpointCalc = () => {
    const brcPoints = handleActiveBreakpoints();

    setBreakpoints({
      activeBreakpoints: brcPoints,
      currentBreakpoint: brcPoints[brcPoints.length - 1],
    });
  };

  useEffect(() => {
    handleBreakpointCalc();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleBreakpointCalc);

    return () => window.removeEventListener('resize', handleBreakpointCalc);
  });

  return breakpoints;
};

export default useMediaQuery;
