import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Label, RadioButton } from '@lionstep/ui';

// Constants
import { QUESTIONS_TYPE } from '../../adminActions.constants';

const HIDE_QUESTION_OPTIONS = [
  { label: 'extra_questions.hide_show_question.yes', value: false },
  { label: 'extra_questions.hide_show_question.no', value: true },
];

const QuestionYesNoHide = ({
  question,
  questionIndex,
  onQuestionValueChange,
}) => {
  const { t } = useTranslation();
  return (
    <Grid.Row gutter={30} style={{ marginBottom: '2.25rem' }}>
      <Grid.Col xs={24}>
        <Label htmlFor={`hide-${questionIndex}`}>
          {t('extra_questions.hide_show_question.label')}
        </Label>
      </Grid.Col>
      {HIDE_QUESTION_OPTIONS.map((item, index) => (
        <Grid.Col key={`${QUESTIONS_TYPE.yesNo}-${index}`} xs={24} md="auto">
          <RadioButton
            checked={item.value === question.hide}
            label={t(item.label)}
            inputAlign
            name={`hide-${questionIndex}-${index}`}
            value={item.value}
            onChange={(e) =>
              onQuestionValueChange(
                questionIndex,
                'hide',
                e.target.value === 'true',
              )
            }
          />
        </Grid.Col>
      ))}
    </Grid.Row>
  );
};

QuestionYesNoHide.propTypes = {
  question: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    hide: PropTypes.bool.isRequired,
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        choice: PropTypes.string.isRequired,
        expected_str: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  questionIndex: PropTypes.number.isRequired,
  onQuestionValueChange: PropTypes.func.isRequired,
};

export default QuestionYesNoHide;
