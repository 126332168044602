export const jobsTitlesSelector = (state) =>
  state.newCompany.settings.jobsTitles;
export const isLoadingJobsTitlesSelector = (state) =>
  state.newCompany.settings.isLoadingJobsTitles;

export const collaboratorsSelector = (state) =>
  state.newCompany.settings.collaborators;
export const isLoadingCollaboratorsSelector = (state) =>
  state.newCompany.settings.isLoadingCollaborators;

export const isUpdatingStatusSelector = (state) =>
  state.newCompany.settings.isUpdatingStatus;

export const isUpdatingOwnerSelector = (state) =>
  state.newCompany.settings.isUpdatingOwner;
