// Constants
import {
  COMPLEXITY_LEVEL_OPTIONS,
  JOB_INDUSTRIES_ACUITY_OPTIONS,
  JOB_PRODUCT_OPTIONS,
  JOB_PRODUCT_OPTIONS_OLD,
  JOB_STATUSES_OPTIONS,
  PERSONALITY_TEST_TRIGGER_OPTIONS,
  RECOMMENDATION_REGION_OPTIONS,
  CURRENCIES_OPTIONS,
} from '../../job.constants';

export const adminActionsFormMapperInput = (
  job,
  extraInfo,
  ACCOUNT_MANAGERS_OPTIONS,
) => ({
  account_manager: ACCOUNT_MANAGERS_OPTIONS.find(
    (option) => option.value === job?.account_manager,
  ),
  caller_info: job?.caller_info || '',
  competitors_list: job?.competitors_list || '',
  preferred_companies: job?.preferred_companies || '',
  complexity: COMPLEXITY_LEVEL_OPTIONS.find(
    (option) => option.value === job?.complexity,
  ),
  ads_only: job?.ads_only || false,
  job_industry: JOB_INDUSTRIES_ACUITY_OPTIONS.find(
    (option) => option.value === job?.job_industry,
  ),
  personality_check: job?.personality_check || false,
  personality_check_trigger: job?.personality_check_trigger
    ? PERSONALITY_TEST_TRIGGER_OPTIONS.find(
        (option) => option.value === job.personality_check_trigger,
      )
    : PERSONALITY_TEST_TRIGGER_OPTIONS[1],
  publish_to_job_board: job?.publish_to_job_board || false,
  urgent: job?.urgent || false,
  is_one_landing_page: job?.is_one_landing_page || false,
  search_exclude_cities: job?.search_exclude_cities?.map((city) => ({
    label: city.name,
    value: city,
  })),
  search_locations: RECOMMENDATION_REGION_OPTIONS.find(
    (option) => option.value === job?.search_locations?.type?.toLowerCase(),
  ),
  searcher_info: job?.searcher_info || '',
  status:
    JOB_STATUSES_OPTIONS.find((option) => option.value === job?.status) || null,
  job_product:
    [...JOB_PRODUCT_OPTIONS, ...JOB_PRODUCT_OPTIONS_OLD].find(
      (option) => option.value === job?.job_product,
    ) || null,
  number_of_positions: job?.number_of_positions || 1,
  free_question: extraInfo?.free_question ?? '',
  questionnaire: extraInfo?.questionnaire ?? '',
  // NAS info
  multichannel:
    job.multichannel?.map((nasInfoRecord) => ({
      ...nasInfoRecord,
      location: {
        label: nasInfoRecord.location.name,
        value: nasInfoRecord.location,
      },
    })) ?? [],
  optional_questions_enabled: job?.optional_questions_enabled || false,
  // Salary
  base_salary_max: extraInfo?.base_salary_max
    ? extraInfo?.base_salary_max.replace("'", '')
    : '',
  base_salary_min: extraInfo?.base_salary_min
    ? extraInfo?.base_salary_min.replace("'", '')
    : '',
  currency: extraInfo?.currency
    ? CURRENCIES_OPTIONS.find((option) => option.value === extraInfo?.currency)
    : CURRENCIES_OPTIONS[0],
  bonus: extraInfo?.bonus ?? '',
});

export const adminActionsFormMapperOutput = (formData) => ({
  jobData: {
    account_manager: formData?.account_manager?.value || null,
    caller_info: formData?.caller_info || '',
    competitors_list: formData?.competitors_list || '',
    preferred_companies: formData?.preferred_companies || '',
    complexity: formData?.complexity?.value || null,
    ads_only: formData?.ads_only,
    job_industry: formData?.job_industry?.value || null,
    personality_check: formData?.personality_check,
    personality_check_trigger:
      formData?.personality_check_trigger?.value ||
      PERSONALITY_TEST_TRIGGER_OPTIONS[1].value,
    publish_to_job_board: formData?.publish_to_job_board,
    urgent: formData?.urgent,
    is_one_landing_page: formData?.is_one_landing_page,
    search_exclude_cities:
      formData?.search_exclude_cities?.map((option) => option.value) || [],
    search_locations: formData?.search_locations?.value
      ? { type: formData.search_locations.value }
      : null,
    searcher_info: formData?.searcher_info || '',
    status: formData?.status?.value,
    job_product: formData?.job_product?.value,
    number_of_positions: formData?.number_of_positions,
    // NAS info
    multichannel:
      formData?.multichannel?.map((nasInfoRecord) => ({
        ...nasInfoRecord,
        location: nasInfoRecord.location.value,
      })) ?? [],
    optional_questions_enabled: formData?.optional_questions_enabled,
  },
  extraInfoData: {
    free_question: formData?.free_question || '',
    questionnaire: formData?.questionnaire || '',
    base_salary_min: formData?.base_salary_min || '',
    base_salary_max: formData?.base_salary_max || '',
    bonus: formData?.bonus || '',
    currency: formData?.currency.value || '',
  },
});

export const accountManagerMapper = (accountManagers) =>
  accountManagers.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

export const citiesMapper = (cities) =>
  cities.map((city) => ({
    label: city.description,
    value: { name: city.description, placeId: city.place_id },
  }));
