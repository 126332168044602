import i18n from '../../../../i18n';

import { JOBS_PORTAL_APP } from '../../../../config';

export const calculateDefaultKillerQuestions = ({ approach, languageCode }) => [
  {
    choices: [
      {
        choice: i18n.t(`default_killer_question_2_answer_1`, {
          lng: languageCode,
        }),
        expected_str: 'not_valid',
      },
      {
        choice: i18n.t(`default_killer_question_2_answer_2`, {
          lng: languageCode,
        }),
        expected_str: 'valid',
      },
      {
        choice: i18n.t(`default_killer_question_2_answer_3`, {
          lng: languageCode,
        }),
        expected_str: 'valid',
      },
      {
        choice: i18n.t(`default_killer_question_2_answer_4`, {
          lng: languageCode,
        }),
        expected_str: 'valid',
      },
      {
        choice: i18n.t(`default_killer_question_2_answer_5`, {
          lng: languageCode,
        }),
        expected_str: 'valid',
      },
      {
        choice: i18n.t(`default_killer_question_2_answer_6`, {
          lng: languageCode,
        }),
        expected_str: 'valid',
      },
    ],
    hide: false,
    question_type: 'multiple_choices',
    sort_order: 0,
    title: i18n.t(`default_killer_question_2_${approach.toLowerCase()}`, {
      lng: languageCode,
    }),
  },
  {
    choices: [
      {
        choice: i18n.t(
          `default_killer_question_1_answer_1_${approach.toLowerCase()}`,
          {
            lng: languageCode,
          },
        ),
        expected_str: 'valid',
      },
      {
        choice: i18n.t(
          `default_killer_question_1_answer_2_${approach.toLowerCase()}`,
          {
            lng: languageCode,
          },
        ),
        expected_str: 'valid',
      },
      {
        choice: i18n.t(
          `default_killer_question_1_answer_3_${approach.toLowerCase()}`,
          {
            lng: languageCode,
          },
        ),
        expected_str: 'valid',
      },
      {
        choice: i18n.t(
          `default_killer_question_1_answer_4_${approach.toLowerCase()}`,
          {
            lng: languageCode,
          },
        ),
        expected_str: 'valid',
      },
      {
        choice: i18n.t(
          `default_killer_question_1_answer_5_${approach.toLowerCase()}`,
          {
            lng: languageCode,
          },
        ),
        expected_str: 'not_valid',
      },
    ],
    hide: false,
    question_type: 'multiple_choices',
    sort_order: 1,
    title: i18n.t(`default_killer_question_1_${approach.toLowerCase()}`, {
      lng: languageCode,
    }),
  },
  {
    choices: [
      { choice: 'yes', expected_str: 'valid' },
      { choice: 'no', expected_str: 'not_valid' },
    ],
    hide: false,
    question_type: 'boolean',
    sort_order: 2,
    title: i18n.t(`default_killer_question_3_${approach.toLowerCase()}`, {
      lng: languageCode,
    }),
  },
  {
    choices: [
      { choice: 'yes', expected_str: 'valid' },
      { choice: 'no', expected_str: 'not_valid' },
    ],
    hide: true,
    question_type: 'boolean',
    sort_order: 3,
    title: i18n.t(`default_killer_question_4_${approach.toLowerCase()}`, {
      lng: languageCode,
    }),
  },
];

export const calculateDefaultOptionalQuestions = ({
  approach,
  languageCode,
}) => [
  {
    choices: [],
    hide: false,
    question_type: 'text',
    is_active: true,
    sort_order: 0,
    title: i18n.t(`default_optional_question_1_${approach.toLowerCase()}`, {
      lng: languageCode,
    }),
  },
  {
    choices: [],
    hide: false,
    question_type: 'text',
    is_active: true,
    sort_order: 1,
    title: i18n.t(`default_optional_question_2_${approach.toLowerCase()}`, {
      lng: languageCode,
    }),
  },
  {
    choices: [],
    hide: false,
    question_type: 'text',
    is_active: true,
    sort_order: 2,
    title: i18n.t(`default_optional_question_3_${approach.toLowerCase()}`, {
      lng: languageCode,
    }),
  },
  {
    choices: [],
    hide: false,
    question_type: 'text',
    is_active: true,
    sort_order: 3,
    title: i18n.t(`default_optional_question_4_${approach.toLowerCase()}`, {
      lng: languageCode,
    }),
  },
  {
    choices: [],
    hide: false,
    question_type: 'text',
    is_active: true,
    sort_order: 4,
    title: i18n.t(`default_optional_question_5_${approach.toLowerCase()}`, {
      lng: languageCode,
    }),
  },
];

export const refreshJobDescriptionAfterUpdate = (slug) =>
  setTimeout(() => {
    const i = document.createElement('iframe');
    i.style.display = 'none';
    i.onload = function () {
      i.parentNode.removeChild(i);
    };
    i.src = `${JOBS_PORTAL_APP}/job/${slug}`;
    document.body.appendChild(i);
  }, 1000);

export const formatJobCandidatesStats = (statsMap) => ({
  interested: statsMap.interested,
  rejected: statsMap.rejected,
  not_interested: statsMap.not_interested + statsMap.not_interested_anonymous,
  pending: statsMap.pending,
  contacted:
    statsMap.contacted +
    statsMap.in_contact +
    statsMap.hold +
    statsMap.call_done,
  hired: statsMap.hired,
});
