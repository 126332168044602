import React, { useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Components
import { Text, Textarea, Label, Heading, Link } from '@lionstep/ui';
import CreateVersionModal from './CreateVersionModal';
import AllVersionsModal from './AllVersionsModal';
import InfoPlusIconWithTooltip from '../../../../components/refactoring/infoPlusIconWithTooltip';

// Constants
import { DATE_FORMAT } from '../../../../constants/date.const';

// Actions
import {
  getCurrentVersion,
  updateCurrentVersion,
  createVersion,
  getAllVersions,
} from '../../redux/actions/jobVersion.actions';

// Selectors
import {
  currentVersionSelector,
  isLoadingCurrentVersionSelector,
  allVersionsSelector,
  isLoadingAllVersionsSelector,
} from '../../redux/selectors/jobVersion.selectors';

// Styles
import styles from './jobVersion.module.scss';

const JobVersion = React.forwardRef(({ jobId }, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const jobVersion = useSelector(currentVersionSelector);
  const isLoadingJobVersion = useSelector(isLoadingCurrentVersionSelector);
  const allVersions = useSelector(allVersionsSelector);
  const isLoadingAllVersions = useSelector(isLoadingAllVersionsSelector);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showAllVersionsModal, setShowAllVersionsModal] = useState(false);

  useEffect(() => {
    dispatch(getCurrentVersion({ id: jobId }));
    dispatch(getAllVersions({ id: jobId }));
  }, [jobId, dispatch]);

  const [currentDescription, setCurrentDescription] = useState(
    jobVersion?.description ?? '',
  );

  useEffect(() => {
    if (jobVersion) {
      setCurrentDescription(jobVersion.description);
    }
  }, [jobVersion]);

  const handleVersionUpdate = async ({ id, version, description }) => {
    await dispatch(updateCurrentVersion({ id, version, description }));
  };

  const handleVersionCreation = async ({ id }) => {
    await dispatch(createVersion({ id }));
  };

  useImperativeHandle(ref, () => ({
    updateVersion: async () => {
      handleVersionUpdate({
        id: jobId,
        version: jobVersion.version,
        description: currentDescription,
      });
    },
    createVersion: async () => {
      handleVersionCreation({ id: jobId });
      setShowCreateModal(true);
    },
  }));

  if (!jobVersion) return null;

  return (
    <div ref={ref} className={styles.jobVersionSection}>
      <div className={styles.horizontalLine} />
      <div className={styles.versionTitleWrapper}>
        <Heading level={3} className={styles.versionTitle}>
          {t('job.versions.current.version_number', {
            number: jobVersion.version,
          })}
          <InfoPlusIconWithTooltip />
        </Heading>

        {allVersions.length > 1 && (
          <Link
            onClick={(e) => {
              e.preventDefault();
              setShowAllVersionsModal(true);
            }}
          >
            {t('job.versions.current.view_previous_btn')}
          </Link>
        )}
      </div>

      <Text className={styles.versionDate} size="small">
        {t('job.versions.current.date_created', {
          date: moment(jobVersion.created).format(DATE_FORMAT),
          interpolation: { escapeValue: false },
        })}
      </Text>

      <Label htmlFor="description">
        {t('job.versions.current.description_label')}
      </Label>
      <Textarea
        id="description"
        name="description"
        value={currentDescription}
        onChange={(e) => {
          setCurrentDescription(e.target.value);
        }}
        disabled={isLoadingJobVersion}
      />

      <CreateVersionModal
        show={showCreateModal}
        isLoading={isLoadingJobVersion}
        onVersionUpdate={(description) => {
          handleVersionUpdate({
            id: jobId,
            version: jobVersion.version,
            description,
          });
          setShowCreateModal(false);
        }}
        onClose={() => setShowCreateModal(false)}
      />

      <AllVersionsModal
        show={showAllVersionsModal}
        isLoading={isLoadingAllVersions}
        versions={allVersions}
        onClose={() => setShowAllVersionsModal(false)}
      />
    </div>
  );
});

JobVersion.propTypes = {
  jobId: PropTypes.number.isRequired,
};

export default JobVersion;
