import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, FormInput, FormSelectV2 } from '@lionstep/ui';

// Contants
import { CURRENCIES_OPTIONS } from '../../../../job.constants';

const Salary = () => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Grid.Row gutter={30}>
      <Grid.Col xs={24} md={8}>
        <FormInput
          error={errors.base_salary_min?.message}
          label={t('minimum_per_year')}
          name="base_salary_min"
          type="number"
          min={10000}
          step={1000}
        />
      </Grid.Col>
      <Grid.Col xs={24} md={8}>
        <FormInput
          error={errors.base_salary_max?.message}
          label={t('maximum_per_year')}
          name="base_salary_max"
          type="number"
          min={10000}
          step={1000}
        />
      </Grid.Col>
      <Grid.Col xs={24} md={8}>
        <FormSelectV2
          error={errors.currency?.message}
          label={t('currency')}
          name="currency"
          options={CURRENCIES_OPTIONS}
        />
      </Grid.Col>
      <Grid.Col xs={24}>
        <FormInput
          label={t('job_description_page.meta_section.bonus.label')}
          name="bonus"
          placeholder={t('job_description_page.meta_section.bonus.placeholder')}
          spacing={0}
        />
      </Grid.Col>
    </Grid.Row>
  );
};

export default Salary;
