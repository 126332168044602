import React from 'react';
import PropTypes from 'prop-types';

const CustomDiscountBonus = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 74 76"
    className={className}
  >
    <path
      id="Grey_1_"
      fill="#899198"
      d="M73.6,26.7l-7.7-12.7c-0.4-0.6-0.9-1-1.6-1.3l-3.2-1.2l-0.5-1.4c-0.2-0.7-0.7-1.2-1.3-1.6L53,5
        l1.9-3.5C55.1,1,55,0.4,54.5,0.1C54-0.1,53.4,0,53.1,0.5L51.2,4L45,0.4c-0.6-0.4-1.3-0.5-2-0.4L27.8,2.8c-0.9,0.2-1.6,0.7-2.1,1.5
        L0.7,47.5C0,48.7-0.2,50,0.2,51.3c0.3,1.3,1.2,2.4,2.3,3l31.2,18c0.8,0.5,1.6,0.7,2.5,0.7c1.4,0,2.7-0.6,3.7-1.6l11.8,4.3
        c0.6,0.2,1.2,0.3,1.8,0.3c0.7,0,1.4-0.2,2.1-0.5c1.1-0.5,2-1.4,2.4-2.6l15.9-43.8C74.1,28.3,74,27.5,73.6,26.7z M34.7,70.6l-31.2-18
        c-0.7-0.4-1.2-1-1.4-1.8C1.9,50,2,49.2,2.4,48.5L27.5,5.2c0.1-0.3,0.4-0.4,0.7-0.5L43.3,2c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0,0.5,0.1
        l6.3,3.6l-1.4,2.5c-2.6-0.9-5.6,0.2-7.1,2.7c-1.7,2.9-0.7,6.5,2.2,8.2c0.9,0.5,2,0.8,3,0.8c2.1,0,4.1-1.1,5.2-3
        c1.5-2.6,0.8-6-1.6-7.8L52,6.8l6.3,3.6c0.2,0.1,0.4,0.3,0.4,0.5l0.5,1.5c0,0.2,0.1,0.4,0.2,0.5l4.5,12.4c0.1,0.3,0.1,0.6-0.1,0.8
        L38.8,69.5C38,70.9,36.1,71.4,34.7,70.6z M47.5,12.9C47.7,13,47.8,13,48,13c0.4,0,0.7-0.2,0.9-0.5l0.8-1.5c1.4,1.2,1.8,3.3,0.8,5
        c-1.1,1.9-3.6,2.6-5.5,1.5c-1.9-1.1-2.6-3.6-1.5-5.5c0.7-1.3,2.1-2,3.5-2c0.3,0,0.6,0,0.9,0.1l-0.8,1.4C46.9,12,47,12.6,47.5,12.9z
        M72,28.5L56.1,72.3c-0.2,0.6-0.7,1.2-1.4,1.5c-0.7,0.3-1.5,0.4-2.3,0.1L41,69.7l24.6-42.5c0.4-0.8,0.5-1.7,0.2-2.5c0,0,0,0,0,0
        L62,14.1l1.6,0.6c0.2,0.1,0.4,0.2,0.5,0.4l7.7,12.7C72,28,72,28.2,72,28.5z"
    />
    <g id="Coloured_1_">
      <path
        fill={companyColour}
        d="M24,32.2c0.9,0.5,2,0.8,3,0.8c2.1,0,4.1-1.1,5.2-3c1.7-2.9,0.7-6.5-2.2-8.2c-2.9-1.7-6.5-0.7-8.2,2.2
          S21.1,30.5,24,32.2z M23.5,25c0.7-1.3,2.1-2,3.5-2c0.7,0,1.4,0.2,2,0.5c1.9,1.1,2.6,3.6,1.5,5.5s-3.6,2.6-5.5,1.5
          C23.1,29.4,22.4,26.9,23.5,25z"
      />
      <path
        fill={companyColour}
        d="M37,48.8c-2.9-1.7-6.5-0.7-8.2,2.2c-1.7,2.9-0.7,6.5,2.2,8.2c0.9,0.5,2,0.8,3,0.8c2.1,0,4.1-1.1,5.2-3
          C40.9,54.1,39.9,50.5,37,48.8z M37.5,56c-1.1,1.9-3.6,2.6-5.5,1.5c-1.9-1.1-2.6-3.6-1.5-5.5c0.7-1.3,2.1-2,3.5-2
          c0.7,0,1.4,0.2,2,0.5C37.9,51.6,38.6,54.1,37.5,56z"
      />
      <path
        fill={companyColour}
        d="M49.9,35c-0.3-1-0.9-1.9-1.9-2.4c-1-0.6-2.3-0.7-3.5-0.4l-30.1,8.5c-2.4,0.7-3.9,3-3.3,5.2
          c0.3,1,0.9,1.9,1.9,2.4c0.7,0.4,1.4,0.6,2.2,0.6c0.4,0,0.9-0.1,1.3-0.2l30.1-8.5C49,39.6,50.4,37.2,49.9,35z M46,38.3L16,46.9
          c-0.7,0.2-1.4,0.1-2-0.2c-0.5-0.3-0.8-0.7-0.9-1.2c-0.3-1.2,0.6-2.5,1.9-2.8L45,34.1c0.3-0.1,0.5-0.1,0.8-0.1
          c0.4,0,0.9,0.1,1.2,0.3c0.5,0.3,0.8,0.7,0.9,1.2C48.2,36.7,47.4,38,46,38.3z"
      />
    </g>
  </svg>
);

CustomDiscountBonus.defaultProps = {
  companyColour: '#ab1923',
};

CustomDiscountBonus.propTypes = {
  companyColour: PropTypes.string,
};

export default CustomDiscountBonus;
