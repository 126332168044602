import React from 'react';
import PropTypes from 'prop-types';

const WFH = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 80 80"
    className={className}
  >
    <g id="Grey">
      <path
        d="M75,31c-2.4,0-4.4,1.7-4.9,4H66V24c0-0.6-0.4-1-1-1s-1,0.4-1,1v11H48.9c-0.5-4.2-3.8-7.5-7.9-7.9V16h4
          c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4V9.9c2.3-0.5,4-2.5,4-4.9c0-2.8-2.2-5-5-5s-5,2.2-5,5c0,2.4,1.7,4.4,4,4.9V14H28c-0.6,0-1,0.4-1,1
          s0.4,1,1,1h11v11.1c-4.2,0.5-7.5,3.8-7.9,7.9H16v-9c0-0.6-0.4-1-1-1s-1,0.4-1,1v9H9.9c-0.5-2.3-2.5-4-4.9-4c-2.8,0-5,2.2-5,5
          s2.2,5,5,5c2.4,0,4.4-1.7,4.9-4H14v11.8L7.1,63.6c0,0,0,0,0,0c0,0,0,0,0,0.1C7,63.7,7,63.9,7,64c0,0,0,0,0,0v4c0,2.8,2.2,5,5,5h28
          c0.6,0,1-0.4,1-1s-0.4-1-1-1H12c-1.7,0-3-1.3-3-3v-3h18.4l1,1.9c0,0,0,0.1,0.1,0.1c0.4,0.6,1.2,1,2,1H40c0.6,0,1-0.4,1-1
          s-0.4-1-1-1h-9.6c-0.2,0-0.3-0.1-0.3-0.1l-1-2l1.1-3.6c0.1-0.2,0.2-0.3,0.2-0.3H40c0.6,0,1-0.4,1-1s-0.4-1-1-1h-9.6
          c-0.9,0-1.7,0.6-2.1,1.6l-1,3.4H9.6l6.1-13h48.7l6.1,13H64c-0.6,0-1,0.4-1,1s0.4,1,1,1h7v3c0,1.7-1.3,3-3,3h-4c-0.6,0-1,0.4-1,1
          s0.4,1,1,1h4c2.8,0,5-2.2,5-5v-4c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0-0.1c0,0,0,0,0,0L66,48.8V37h4.1c0.5,2.3,2.5,4,4.9,4
          c2.8,0,5-2.2,5-5S77.8,31,75,31z M37,5c0-1.7,1.3-3,3-3s3,1.3,3,3s-1.3,3-3,3S37,6.7,37,5z M5,39c-1.7,0-3-1.3-3-3s1.3-3,3-3
          s3,1.3,3,3S6.7,39,5,39z M40,29c3.9,0,7,3.1,7,7s-3.1,7-7,7s-7-3.1-7-7S36.1,29,40,29z M64,48H16V37h15.1c0.5,4.5,4.3,8,8.9,8
          s8.4-3.5,8.9-8H64V48z M75,39c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S76.7,39,75,39z"
        fill="#899198"
      />
      <path
        d="M37,37h2v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2h2c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2v-2c0-0.6-0.4-1-1-1s-1,0.4-1,1v2h-2
          c-0.6,0-1,0.4-1,1S36.4,37,37,37z"
        fill="#899198"
      />
    </g>
    <g id="Coloured">
      <path
        d="M46.5,65c1.9,0,3.5-1.6,3.5-3.5S48.4,58,46.5,58S43,59.6,43,61.5S44.6,65,46.5,65z M46.5,60
          c0.8,0,1.5,0.7,1.5,1.5S47.3,63,46.5,63S45,62.3,45,61.5S45.7,60,46.5,60z"
        fill={companyColour}
      />
      <path
        d="M52,15h-1c-0.6,0-1,0.4-1,1s0.4,1,1,1h1c0.6,0,1-0.4,1-1S52.6,15,52,15z"
        fill={companyColour}
      />
      <path
        d="M63,15h-6c-0.6,0-1,0.4-1,1s0.4,1,1,1h6c0.6,0,1-0.4,1-1S63.6,15,63,15z"
        fill={companyColour}
      />
      <path
        d="M61,54H43c-2.2,0-4,1.8-4,4v18c0,2.2,1.8,4,4,4h18c2.2,0,4-1.8,4-4V58C65,55.8,63.2,54,61,54z M43,56h18
          c1.1,0,2,0.9,2,2v15h-2.4l-5.7-9.5c-0.4-0.6-1.4-0.6-1.7,0L47.4,73H41V58C41,56.9,41.9,56,43,56z M58.2,73h-8.5l4.2-7.1L58.2,73z
          M61,78H43c-1.1,0-2-0.9-2-2v-1h22v1C63,77.1,62.1,78,61,78z"
        fill={companyColour}
      />
      <path
        d="M67,10H46c-1.1,0-2,0.9-2,2v11c0,1.1,0.9,2,2,2h2v1.9c0,0.8,0.4,1.5,1.1,1.8c0.3,0.1,0.6,0.2,0.9,0.2
          c0.5,0,0.9-0.2,1.3-0.5l4.1-3.4H67c1.1,0,2-0.9,2-2V12C69,10.9,68.1,10,67,10z M55,23c-0.2,0-0.5,0.1-0.6,0.2L50,26.9V24
          c0-0.6-0.4-1-1-1l-3,0l0-11l21,0l0,11H55z"
        fill={companyColour}
      />
      <path
        d="M28.9,11.6c0-0.1-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0-0.1l-10-8
          c-0.4-0.3-0.9-0.3-1.2,0l-10,8c0,0,0,0,0,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1C7,11.7,7,11.8,7,11.9
          c0,0,0,0.1,0,0.1v14c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0
          c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0,0.1,0.1C7.7,27,7.8,27,7.9,27h20c0.1,0,0.3,0,0.4-0.1c0,0,0,0,0.1,0c0.1-0.1,0.2-0.1,0.3-0.2
          c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1V12c0,0,0-0.1,0-0.1
          C28.9,11.8,28.9,11.7,28.9,11.6z M8.9,14.2l5.4,4.9l-5.4,4.7V14.2z M15.9,20.4l0.1,0.1c0.6,0.5,1.3,0.8,2,0.8c0.7,0,1.4-0.3,2-0.8
          l0.1-0.1l5.2,4.6H10.6L15.9,20.4z M21.6,19.1l5.3-4.8v9.5L21.6,19.1z M17.9,5.3l8.5,6.8l-7.7,7c-0.4,0.3-1,0.3-1.3,0l-7.8-7
          L17.9,5.3z"
        fill={companyColour}
      />
    </g>
  </svg>
);

WFH.defaultProps = {
  companyColour: '#ab1923',
};

WFH.propTypes = {
  companyColour: PropTypes.string,
};

export default WFH;
