import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '@lionstep/ui';
import { SketchPicker } from 'react-color';

// Hooks
import useClickOutside from '../../../../../../hooks/useClickOutside';

// Styles
import styles from './colorChooser.module.scss';

const ColorChooser = ({ color, onColorChange }) => {
  const { t } = useTranslation();

  const [showPicker, setShowPicker] = useState(false);
  const pickerWrapperRef = useRef();

  useClickOutside(pickerWrapperRef, () => {
    setShowPicker(false);
  });

  const handleOnPickerCloseClick = (event) => {
    event.stopPropagation();
    setShowPicker(false);
  };

  const handleOnColorChanged = ({ hex }) => {
    onColorChange(hex);
  };

  return (
    <Button
      typeHtml="button"
      className={styles.pickerBtn}
      onClick={() => setShowPicker(true)}
    >
      <span>{t('company_page.displayed_section.color.pick_color')}</span>

      {showPicker && (
        <span ref={pickerWrapperRef} className={styles.pickerWrapper}>
          <Icon
            name="CloseCircle"
            className={styles.closeIcon}
            onClick={handleOnPickerCloseClick}
          />

          <SketchPicker color={color} onChangeComplete={handleOnColorChanged} />
        </span>
      )}
    </Button>
  );
};

ColorChooser.defaultProps = {
  color: '',
};

ColorChooser.propTypes = {
  color: PropTypes.string,
  onColorChange: PropTypes.func.isRequired,
};

export default ColorChooser;
