import { pythonAxios, rubyAxios } from './http';

export const getCompanyApi = ({ id } = {}) =>
  pythonAxios({
    method: 'get',
    url: `/company/${id}`,
  });

export const putCompanyApi = ({ id, data } = {}) =>
  pythonAxios({
    method: 'put',
    url: `/company/${id}`,
    data,
  });

export const getCompanyPicturesApi = () =>
  pythonAxios({
    method: 'get',
    url: `/company/pictures`,
  });

export const postCompanyPictureApi = ({ data = {} } = {}) =>
  pythonAxios({
    method: 'post',
    url: `/company/picture`,
    data,
  });

export const deleteCompanyPictureApi = ({ data = {} } = {}) =>
  pythonAxios({
    method: 'delete',
    url: `/company/picture`,
    data,
  });

export const addCollaboratorMailingPermissionApi = ({ data = {} } = {}) =>
  rubyAxios({
    method: 'post',
    url: '/job_postings/add_mailing_permission',
    data,
  });

export const removeCollaboratorMailingPermissionApi = ({ data = {} } = {}) =>
  rubyAxios({
    method: 'post',
    url: '/job_postings/remove_mailing_permission.json',
    data,
  });

export const getCompanyInsightsApi = ({ params = {} }) =>
  pythonAxios({
    method: 'get',
    url: '/insights',
    params,
  });
