import * as types from '../types/adminActions.types';

const initialState = {
  isLoadingAccountManagers: false,
  accountManagers: [],
  cities: [],
  isLoadingCities: false,
  killerQuestions: [],
  isLoadingKillerQuestions: false,
  isUpdatingKillerQuestions: false,
  skills: [],
  isLoadingSkills: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ACCOUNT_MANAGERS_START:
      return {
        ...state,
        accountManagers: [],
        isLoadingAccountManagers: true,
      };

    case types.GET_ACCOUNT_MANAGERS_SUCCESS:
      return {
        ...state,
        isLoadingAccountManagers: false,
        accountManagers: action.payload,
      };

    case types.GET_ACCOUNT_MANAGERS_FAIL:
      return {
        ...state,
        isLoadingAccountManagers: false,
      };

    case types.GET_CITIES_START:
      return {
        ...state,
        cities: [],
        isLoadingCities: true,
      };

    case types.GET_CITIES_SUCCESS:
      return {
        ...state,
        isLoadingCities: false,
        cities: action.payload,
      };

    case types.GET_CITIES_FAIL:
      return {
        ...state,
        isLoadingCities: false,
      };

    case types.CLEAR_CITIES:
      return {
        ...state,
        cities: [],
      };

    // Killer Questions
    case types.GET_KILLER_QUESTIONS_START:
      return {
        ...state,
        killerQuestions: [],
        isLoadingKillerQuestions: true,
      };

    case types.GET_KILLER_QUESTIONS_SUCCESS:
      return {
        ...state,
        killerQuestions: action.payload,
        isLoadingKillerQuestions: false,
      };

    case types.GET_KILLER_QUESTIONS_FAIL:
      return {
        ...state,
        isLoadingKillerQuestions: false,
      };

    case types.UPDATE_KILLER_QUESTIONS_START:
      return {
        ...state,
        isUpdatingKillerQuestions: true,
      };

    case types.UPDATE_KILLER_QUESTIONS_SUCCESS:
      return {
        ...state,
        killerQuestions: action.payload,
        isUpdatingKillerQuestions: false,
      };

    case types.UPDATE_KILLER_QUESTIONS_FAIL:
      return {
        ...state,
        isUpdatingKillerQuestions: false,
      };

    // Skills
    case types.GET_SKILLS_START:
      return {
        ...state,
        skills: [],
        isLoadingSkills: true,
      };

    case types.GET_SKILLS_SUCCESS:
      return {
        ...state,
        skills: action.payload,
        isLoadingSkills: false,
      };

    case types.GET_SKILLS_FAIL:
      return {
        ...state,
        isLoadingSkills: false,
      };

    case types.CLEAR_ALL_SKILLS:
      return {
        ...state,
        skills: [],
      };

    default:
      return state;
  }
};
