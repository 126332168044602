import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Styles
import styles from './infoBox.module.scss';

const TimeToFirstValidation = ({ insights }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.infoBox}>
      <div className={styles.infoBoxValue}>
        <span className={styles.infoBoxValuePrimary}>
          {t('insights_page.time_to_first_validation.number_of_days', {
            number: insights.time_to_first_validation || 0,
          })}
        </span>
      </div>
      <div className={styles.infoBoxLabel}>
        {t('insights_page.time_to_first_validation.card_title')}
      </div>
    </div>
  );
};

TimeToFirstValidation.propTypes = {
  insights: PropTypes.shape().isRequired,
};

export default TimeToFirstValidation;
