export const PAGE_SIZE = 10;

export const JOB_ARCHIVED_STATUSES = {
  archived: 'archived',
  published: 'published',
};

export const JOB_DROPDOWN_ACTIONS = {
  deleteJob: 'deleteJob',
  archiveJob: 'archiveJob',
  publishJob: 'publishJob',
  jobMailingPermissions: 'jobMailingPermissions',
  shareJob: 'shareJob',
};
