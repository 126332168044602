import React from 'react';
import PropTypes from 'prop-types';

const Gym = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 74 74"
    className={className}
  >
    <title>Gym</title>
    <g id="Grey">
      <g id="Object2">
        <path
          d="M28,13h7a1,1,0,0,0,0-2H27a1,1,0,0,0-1,1V25a1,1,0,1,0,2,0Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M22,26a1,1,0,0,0,1-1V3.48a1.5,1.5,0,0,1,3,0V7a1,1,0,0,0,1,1h8a1,1,0,0,0,0-2H28V3.48a3.5,3.5,0,0,0-7,0V25A1,1,0,0,0,22,26Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M39,13h7V25a1,1,0,1,0,2,0V12a1,1,0,0,0-1-1H39a1,1,0,0,0,0,2Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M39,8h8a1,1,0,0,0,1-1V3.48a1.5,1.5,0,0,1,3,0V25a1,1,0,1,0,2,0V3.48a3.5,3.5,0,0,0-7,0V6H39a1,1,0,0,0,0,2Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M22,44a1,1,0,0,0,1-1V30a1,1,0,0,0-2,0V43A1,1,0,0,0,22,44Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M27,29a1,1,0,0,0-1,1V43a1,1,0,0,0,2,0V30A1,1,0,0,0,27,29Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M47,29a1,1,0,0,0-1,1V43a1,1,0,0,0,2,0V30A1,1,0,0,0,47,29Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M52,29a1,1,0,0,0-1,1V43a1,1,0,0,0,2,0V30A1,1,0,0,0,52,29Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M59,67H53V53a1,1,0,0,0-2,0V67H48V53.1a1,1,0,0,0-2,0V67H28V53a1,1,0,0,0-2,0V67H23V53.1a1,1,0,0,0-2,0V67H15a5,5,0,0,0-5,5v1a1,1,0,0,0,1,1H63a1,1,0,0,0,1-1V72A5,5,0,0,0,59,67ZM12,72a3,3,0,0,1,3-3H59a3,3,0,0,1,3,3Z"
          transform="translate(0 0)"
          fill="#899198"
        />
      </g>
    </g>
    <g id="Coloured">
      <g id="Object1">
        <path
          d="M54.47,42H43.82A3,3,0,0,0,41,40H33a3,3,0,0,0-2.81,2H19.53A3.51,3.51,0,0,0,16,45.48v5A3.51,3.51,0,0,0,19.53,54H30.18A3,3,0,0,0,33,56h8a3,3,0,0,0,2.81-2H54.47A3.51,3.51,0,0,0,58,50.52v-5A3.51,3.51,0,0,0,54.47,42ZM18,50.52v-5A1.51,1.51,0,0,1,19.53,44H30v8H19.53A1.51,1.51,0,0,1,18,50.52ZM42,53a1,1,0,0,1-1,1H33a1,1,0,0,1-1-1V43a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1Zm14-2.5A1.51,1.51,0,0,1,54.47,52H44V44H54.47A1.51,1.51,0,0,1,56,45.48Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M72.26,29.47h0L69.73,28A29.56,29.56,0,0,0,54.9,24H38V16.82A3,3,0,0,0,40,14V6a3,3,0,0,0-6,0v8a3,3,0,0,0,2,2.82V24H19.1A29.56,29.56,0,0,0,4.27,28L1.74,29.47A3.52,3.52,0,0,0,.47,34.25a3.47,3.47,0,0,0,4.76,1.28l2.53-1.47A22.61,22.61,0,0,1,19.11,31H54.89a22.61,22.61,0,0,1,11.35,3.06l2.53,1.47a3.48,3.48,0,0,0,4.76-1.28A3.51,3.51,0,0,0,72.26,29.47ZM36,6a1,1,0,0,1,2,0v8a1,1,0,1,1-2,0ZM71.8,33.25a1.48,1.48,0,0,1-2,.55l-2.53-1.47A24.62,24.62,0,0,0,54.89,29H19.11A24.62,24.62,0,0,0,6.75,32.33L4.22,33.8a1.46,1.46,0,0,1-1.12.15,1.48,1.48,0,0,1-.91-.7,1.51,1.51,0,0,1,.54-2.05l2.53-1.47A27.57,27.57,0,0,1,19.1,26H54.9a27.57,27.57,0,0,1,13.83,3.73l2.53,1.47A1.51,1.51,0,0,1,71.8,33.25Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
      </g>
    </g>
  </svg>
);

Gym.defaultProps = {
  companyColour: '#ab1923',
};

Gym.propTypes = {
  companyColour: PropTypes.string,
};

export default Gym;
