export const companySelector = (state) => state.newCompany.company.company;
export const isLoadingCompanySelector = (state) =>
  state.newCompany.company.isLoading;
export const isUpdatingCompanySelector = (state) =>
  state.newCompany.company.isUpdating;

export const companyPicturesSelector = (state) =>
  state.newCompany.company.pictures;
export const isLoadingCompanyPicturesSelector = (state) =>
  state.newCompany.company.isLoadingPictures;

export const isUploadingCompanyPicturesSelector = (state) =>
  state.newCompany.company.isUploadingPicture;

export const isDeletingCompanyPicturesSelector = (state) =>
  state.newCompany.company.isDeletingPicture;

export const locationsSelector = (state) => state.newCompany.company.locations;
export const isLoadingLocationsSelector = (state) =>
  state.newCompany.company.isLoadingLocations;

export const collaboratorsSelector = (state) =>
  state.newCompany.company.collaborators;
export const isLoadingCollaboratorsSelector = (state) =>
  state.newCompany.company.isLoadingCollaborators;

export const activeJobsTitlesSelector = (state) =>
  state.newCompany.company.activeJobsTitles;
