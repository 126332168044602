import React from 'react';

import {
  JOB_LANGUAGE_OPTIONS,
  JOB_YES_NO_OPTIONS,
  CONTRACT_TYPE_OPTIONS,
  DIRECT_REPORTS_OPTIONS,
  JOB_APPROACH_OPTIONS,
  YEARS_EXPERIENCE_OPTIONS,
  LANGUAGE_LEVELS_OPTIONS,
  REQUIRE_DESIRED_OPTIONS,
  EMPLOYMENT_RANGE_OPTIONS,
  HOME_DAYS_OPTIONS,
  DISPLAY_HIDE_OPTIONS,
  NOTICE_PERIOD_OPTIONS,
  CURRENCIES_OPTIONS,
} from '../../job.constants';

import { benefitsFormatting } from '../../../company/pages/Company/company.helpers';
import i18n from '../../../../i18n';
import {
  langShorthand,
  constantsArrayWithTranslatedLabel,
} from '../../../../support';

const JOB_EXTRA_INFO_FIELDS = [
  'original_url',
  'employment_type',
  'employment_type_max',
  'employment_type_min',
  'freelance',
  'homeoffice',
  'home_days',
  'diversity',
  'introduction',
  'summary',
  'your_role',
  'management_role',
  'span_of_control',
  'job_video',
  'job_video_caption',
  'requirements',
  'nice_to_have',
  'travel',
  'travel_importance',
  'travel_details',
  'prev_leadership',
  'leadership_importance',
  'contract_type',
  'start_date',
  'end_date',
  'team_desc',
  'superior_role',
  'team_language',
  'team_size',
  'additional_bonus',
  'contact_person',
  'contact_person_title',
  'can_be_disclosed',
  'replacement_name',
  'free_question',
  'questionnaire',
  // OLD but kept for backward compat
  'family_desc',
  'base_salary_max',
  'base_salary_min',
  'benefits',
  'bonus',
  'budget_control',
  'company_industry',
  'company_name',
  'currency',
  'functional_leadership',
  'further_education',
  'goals',
  'home_days',
  'leadership_experience',
  'peopleToManage', // TODO: check why is this duplicated on API (span_of_control is the same)
  'persist',
  'personal_leadership',
  'place_talent',
  'real_desc',
  'superior_role',
  'travel',
  'travel_locations',
  'additional_benefits',
  // not sent, just to combine into job_file
  'job_file_name',
  'job_file_path',
];

const JOB_DEFAULT_BENEFITS = [
  'health-ins',
  'wellness',
  'gym',
  'pension',
  'stock',
  'life-ins',
  'coffee-snacks',
  'cafeteria',
  'external-services',
  'transportation',
  'cars-bikes',
  'child-care',
  'maternity',
  'unpaid-time-off',
  'remote-work',
  'wfh',
  'equipment',
  'furniture',
  'learning',
  'pet-friendly',
];

const JOB_UNPROCCESSABLE_FIELDS = [
  'job_file',
  'analytics',
  'created',
  'updated',
  'account_manager',
  'account_manager_info',
  'archived',
  'comment',
  'complexity',
  'contactlist',
  'dashboard_longlist',
  'favourites_only',
  'first_check_interest_at',
  'hidden_search',
  'industries',
  'keywords',
  'owner_id',
  'public_url',
  'search_exclude_cities',
  'search_locations',
  'searcher_info',
  'shortlist',
  'skills_boolean',
  'slug',
  'mandatory_skills',
  'has_questions',
  'skill_groups', // proccessed separately as optional & mandatory groups
  'experience', // proccess as two different fields (min_experience & max_experience)
  'benefits_pool_selected',
  'benefits_custom_selected',
  'homeoffice',
];

export const jobMapperInput = (data, t) => ({
  ...data,
  job_file_name: data?.job_file?.name,
  job_file_path: data?.job_file?.data,
  job_language: data?.job_language?.mid
    ? constantsArrayWithTranslatedLabel(t, JOB_LANGUAGE_OPTIONS).find(
        (option) => option.value === data.job_language.mid,
      )
    : null,
  kombo_job: data?.kombo_job
    ? {
        value: data?.kombo_job.id,
        location: data?.kombo_job.location.raw,
        label: data?.kombo_job.name,
      }
    : null,
  message_template: data?.message_template?.[0]?.content ?? '',
  leadership_experience: data?.leadership_experience
    ? JOB_YES_NO_OPTIONS[0].value
    : JOB_YES_NO_OPTIONS[1].value,
  management_role: data?.management_role
    ? JOB_YES_NO_OPTIONS[0].value
    : JOB_YES_NO_OPTIONS[1].value,
  can_be_disclosed: data?.can_be_disclosed
    ? JOB_YES_NO_OPTIONS[0].value
    : JOB_YES_NO_OPTIONS[1].value,
  diversity: data?.diversity
    ? DISPLAY_HIDE_OPTIONS[0].value
    : DISPLAY_HIDE_OPTIONS[1].value,
  travel: data?.travel
    ? JOB_YES_NO_OPTIONS[0].value
    : JOB_YES_NO_OPTIONS[1].value,
  peopleToManage: data?.peopleToManage
    ? constantsArrayWithTranslatedLabel(t, DIRECT_REPORTS_OPTIONS).find(
        (peopleOption) => peopleOption.value === data.peopleToManage,
      )
    : constantsArrayWithTranslatedLabel(t, DIRECT_REPORTS_OPTIONS)[0],
  contract_type:
    constantsArrayWithTranslatedLabel(t, CONTRACT_TYPE_OPTIONS).find(
      (option) => option.value === data?.contract_type,
    ) || null,
  approach: data?.approach
    ? constantsArrayWithTranslatedLabel(t, JOB_APPROACH_OPTIONS).find(
        (option) => option.value === data.approach,
      )
    : null,
  min_experience: data?.experience?.[0]?.toString()
    ? YEARS_EXPERIENCE_OPTIONS.find(
        (option) => option.value === data.experience[0].toString(),
      )
    : '',
  max_experience: data?.experience?.[1]?.toString()
    ? YEARS_EXPERIENCE_OPTIONS.find(
        (option) => option.value === data.experience[1].toString(),
      )
    : '',
  titles:
    data?.titles.map((option) => ({
      label: option.name || option.mid,
      value: option,
    })) ?? [],
  additional_locations:
    data?.additional_locations?.map((option) => ({
      label: option.name,
      value: option,
    })) ?? [],
  talent_languages:
    data?.talent_languages.map((option) => ({
      level: constantsArrayWithTranslatedLabel(t, LANGUAGE_LEVELS_OPTIONS).find(
        (level) => level.value === option.level,
      ),
      importance: constantsArrayWithTranslatedLabel(
        t,
        REQUIRE_DESIRED_OPTIONS,
      ).find((importance) => importance.value === option.importance),
      language_id: {
        value: {
          display: option.display,
          id: option.id,
          mid: option.mid,
          name: option.name,
        },
        label: option.name || option.mid,
      },
    })) ?? [],
  optional_skills:
    data?.skill_groups
      ?.filter((g) => g.importance === 'optional')
      .map((g) => g.group[0])
      .map((option) => ({
        label: option.name || option.mid,
        value: option,
      })) ?? [],
  mandatory_skills_groups: data?.skill_groups?.filter(
    (g) => g.importance === 'mandatory',
  )?.length
    ? data.skill_groups
        .filter((g) => g.importance === 'mandatory')
        .map((g) => {
          const group = g.group.map((option) => ({
            label: option.name || option.mid,
            value: option,
          }));

          return { group };
        })
    : [{ group: [] }],
  leadership_importance: data?.leadership_importance
    ? constantsArrayWithTranslatedLabel(t, REQUIRE_DESIRED_OPTIONS).find(
        (option) => option.value === data.leadership_importance,
      )
    : null,
  employment_type_max: data?.employment_type_max
    ? EMPLOYMENT_RANGE_OPTIONS.find(
        (option) => option.value === data.employment_type_max,
      )
    : null,
  employment_type_min: data?.employment_type_min
    ? EMPLOYMENT_RANGE_OPTIONS.find(
        (option) => option.value === data.employment_type_min,
      )
    : null,
  travel_importance: data?.travel_importance
    ? constantsArrayWithTranslatedLabel(t, REQUIRE_DESIRED_OPTIONS).find(
        (option) => option.value === data.travel_importance,
      )
    : null,
  home_days: data?.home_days
    ? constantsArrayWithTranslatedLabel(t, HOME_DAYS_OPTIONS).find(
        (option) => option.value === data.home_days,
      )
    : HOME_DAYS_OPTIONS[0],
  job_location: data?.job_location
    ? {
        label: data.job_location.name || data.job_location.mid,
        value: data.job_location,
      }
    : null,
  introduction: data?.introduction ?? '',
  replacement_name: data?.replacement_name ?? '',
  questionnaire: data?.questionnaire ?? '',
  notice_period: Number.isInteger(data?.notice_period_months)
    ? NOTICE_PERIOD_OPTIONS.find(
        (option) => option.value === data.notice_period_months,
      )
    : NOTICE_PERIOD_OPTIONS[0],
  base_salary_max: data?.base_salary_max
    ? data?.base_salary_max.replace("'", '')
    : '',
  base_salary_min: data?.base_salary_min
    ? data?.base_salary_min.replace("'", '')
    : '',
  currency: data?.currency
    ? CURRENCIES_OPTIONS.find((option) => option.value === data?.currency)
    : CURRENCIES_OPTIONS[0],
  bonus: data?.bonus ?? '',
});

const formatExtraInfoData = (formData) => {
  const extraInfo = {
    job_file: null,
    additional_bonus: 0,
  };
  const extraInfoKeys = Object.keys(formData).filter(
    (key) =>
      !JOB_UNPROCCESSABLE_FIELDS.includes(key) &&
      JOB_EXTRA_INFO_FIELDS.includes(key),
  );

  extraInfoKeys.forEach((key) => {
    switch (key) {
      case 'job_file_name':
        if (!extraInfo.job_file) {
          extraInfo.job_file = {
            name: formData.job_file_name,
          };
        } else {
          extraInfo.job_file.name = formData.job_file_name;
        }
        break;
      case 'job_file_path':
        if (!extraInfo.job_file) {
          extraInfo.job_file = {
            data: formData.job_file_path,
          };
        } else {
          extraInfo.job_file.data = formData.job_file_path;
        }
        break;
      case 'leadership_experience':
        extraInfo.leadership_experience =
          formData.leadership_experience === 'yes';
        break;
      case 'management_role':
        extraInfo.management_role = formData.management_role === 'yes';
        break;
      case 'can_be_disclosed':
        extraInfo.can_be_disclosed = formData.can_be_disclosed === 'yes';
        break;
      case 'travel':
        extraInfo.travel = formData.travel === 'yes';
        break;
      case 'peopleToManage':
        extraInfo.peopleToManage = formData.peopleToManage?.value ?? null;
        break;
      case 'contract_type':
        extraInfo.contract_type = formData.contract_type?.value ?? null;
        break;
      case 'employment_type_max':
        extraInfo.employment_type_max =
          formData.employment_type_max?.value ?? null;
        break;
      case 'employment_type_min':
        extraInfo.employment_type_min =
          formData.employment_type_min?.value ?? null;
        break;
      case 'home_days':
        extraInfo.homeoffice = formData.home_days?.value !== '0';
        extraInfo.home_days =
          formData.home_days?.value === '0' ? null : formData.home_days?.value;
        break;
      case 'leadership_importance':
        extraInfo.leadership_importance =
          formData.leadership_importance?.value ?? null;
        break;
      case 'travel_importance':
        extraInfo.travel_importance = formData.travel_importance?.value ?? null;
        break;
      case 'diversity':
        extraInfo.diversity = formData.diversity === 'display';
        break;
      case 'base_salary_max':
        extraInfo.base_salary_max =
          formData.base_salary_max?.replace(/\d(?=(?:\d{3})+$)/g, "$&'") ||
          null;
        break;
      case 'base_salary_min':
        extraInfo.base_salary_min =
          formData.base_salary_min?.replace(/\d(?=(?:\d{3})+$)/g, "$&'") ||
          null;
        break;
      case 'currency':
        extraInfo.currency = formData.currency?.value;
        break;
      default:
        extraInfo[key] = formData[key];
    }
  });

  return extraInfo;
};

const formatJobData = (formData) => {
  const job = {
    benefits_custom_selected: [],
    benefits_pool_selected: [],
    skill_groups: [],
    experience: [],
  };
  const jobKeys = Object.keys(formData).filter(
    (key) =>
      !JOB_UNPROCCESSABLE_FIELDS.includes(key) &&
      !JOB_EXTRA_INFO_FIELDS.includes(key),
  );

  jobKeys.forEach((key) => {
    switch (key) {
      case 'job_language':
        job.job_language = {
          mid: formData.job_language.value,
        };
        break;
      case 'kombo_job':
        job.kombo_job_id = formData.kombo_job?.value || null;
        break;
      case 'message_template':
        job.message_template = formData.message_template
          ? [
              {
                content: formData.message_template,
                message_type: 'first',
                lang: langShorthand(formData.job_language.value),
              },
            ]
          : [];
        break;
      case 'approach':
        job.approach = formData.approach?.value ?? null;
        break;
      case 'benefits_checked':
        formData.benefits_checked.forEach((benefitId) => {
          if (JOB_DEFAULT_BENEFITS.includes(benefitId)) {
            job.benefits_pool_selected.push(benefitId);
          } else {
            job.benefits_custom_selected.push(benefitId);
          }
        });
        break;
      case 'job_location':
        job.job_location = formData.job_location?.value ?? null;
        break;
      case 'additional_locations':
        job.additional_locations =
          formData.additional_locations?.map((location) => location.value) ??
          [];
        break;
      case 'mandatory_skills_groups':
        formData.mandatory_skills_groups?.forEach(({ group }) => {
          if (group.length) {
            job.skill_groups.push({
              importance: 'mandatory',
              group: group.map((sg) => {
                // value coming from "creatable" multiselect
                if (typeof sg.value === 'string') {
                  return {
                    mid: sg.value,
                    name: sg.value,
                  };
                }
                return sg.value;
              }),
            });
          }
        });
        break;
      case 'optional_skills':
        formData.optional_skills?.forEach((optionSkill) => {
          job.skill_groups.push({
            importance: 'optional',
            group: [
              // value coming from "creatable" multiselect
              typeof optionSkill.value === 'string'
                ? {
                    mid: optionSkill.value,
                    name: optionSkill.value,
                  }
                : optionSkill.value,
            ],
          });
        });
        break;
      case 'titles':
        job.titles =
          formData.titles?.map((title) => {
            if (typeof title.value === 'string') {
              // value coming from "creatable" multiselect
              return {
                mid: title.value,
                name: title.value,
              };
            }
            return title.value;
          }) ?? [];
        break;
      case 'talent_languages':
        job.talent_languages = formData.talent_languages?.map(
          (languageOption) => ({
            level: languageOption.level?.value,
            importance: languageOption.importance?.value,
            ...languageOption.language_id.value,
          }),
        );
        break;
      case 'min_experience':
        if (formData.min_experience?.value) {
          job.experience[0] = Number(formData.min_experience.value);
        }
        break;
      case 'max_experience':
        if (formData.max_experience?.value) {
          job.experience[1] = Number(formData.max_experience.value);
        }
        break;
      case 'notice_period':
        job.notice_period_months = formData.notice_period?.value ?? null;
        break;
      default:
        job[key] = formData[key];
    }
  });
  return job;
};

export const jobMapperOutput = (formData) => ({
  extraInfo: formatExtraInfoData(formData),
  job: formatJobData(formData),
});

export const titlesMapper = (titles) =>
  titles.map((title) => ({
    label: title.name,
    value: { mid: title.mid, name: title.name },
  }));

export const skillsMapper = (skills) =>
  skills.map((skill) => ({
    label: skill.name,
    value: {
      mid: skill.mid,
      name: skill.name,
      id: skill.id,
      language_code: skill.language_code,
    },
  }));

export const languagesMapper = (languages) =>
  languages.map((language) => ({
    id: language.id,
    label: language.name,
    value: language,
  }));

export const calculateBenefitsOptions = (
  company,
  jobLanguageShorthand,
  initialChecked,
) => {
  const defaultBenefits = company.benefits_pool.filter((b) =>
    company.benefits_pool_selected?.includes(b.id),
  );
  const customBenefits =
    company.benefits_custom?.filter((b) => !b.deleted) ?? [];

  const mappedBenefitsKeys = benefitsFormatting(defaultBenefits);

  const defaultBenefitsValues = Object.keys(mappedBenefitsKeys)
    .map((benefitGroup) => {
      const values = [];

      mappedBenefitsKeys[benefitGroup].forEach((benefitId) => {
        values.push({
          value: benefitId,
          label: (
            <>
              {i18n.t(
                `company_page.displayed_section.benefits.${benefitGroup}.${benefitId}`,
                { lng: jobLanguageShorthand },
              )}
            </>
          ),
          hide: !initialChecked.includes(benefitId),
        });
      });

      return values;
    })
    .flat();

  const customBenefitsValues = customBenefits.map((benefit) => {
    const hasCorrectDescription = !!benefit.description.find(
      (d) => d.lang === jobLanguageShorthand,
    )?.content;

    return {
      value: benefit.id,
      label: hasCorrectDescription ? (
        benefit.description.find((d) => d.lang === jobLanguageShorthand).content
      ) : (
        <>
          <span
            style={{
              color: 'red',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          >
            Benefit translation missing
          </span>{' '}
          {benefit.description[0]?.content ||
            benefit.description[1]?.content ||
            benefit.description[2]?.content ||
            benefit.description[3]?.content}
        </>
      ),
      hide: !initialChecked.includes(benefit.id),
    };
  });

  return [...defaultBenefitsValues, ...customBenefitsValues];
};

export const calculateIntroduction = ({ languageCode, approach, company }) => {
  const approachQuery = approach.toLowerCase();

  return i18n.t(
    `job_description_page.summary_section.introduction_paragraph.default_values_${approachQuery}`,
    { lng: languageCode, company: company.company_name },
  );
};

export const calculateSummary = ({
  languageCode,
  approach,
  company,
  title,
}) => {
  const approachQuery = approach.toLowerCase();

  return i18n.t(
    `job_description_page.job_details.summary.default_value_${approachQuery}`,
    { lng: languageCode, company, title },
  );
};

export const calculateCustomQuestion = ({ languageCode, approach }) => {
  const approachQuery = approach.toLowerCase();

  return i18n.t(
    `job_description_page.meta_section.custom_question.default_value_${approachQuery}`,
    { lng: languageCode },
  );
};

export const calculateTeamDescription = ({ languageCode, approach }) => {
  const approachQuery = approach.toLowerCase();

  return i18n.t(
    `job_description_page.team_section.team_desc.default_value_${approachQuery}`,
    { lng: languageCode },
  );
};

export const calculateMessageTemplate = ({ languageCode, approach }) => {
  const approachQuery = approach.toLowerCase();

  return i18n.t(
    `job_description_page.summary_section.message_template.default_values_${approachQuery}`,
    { lng: languageCode },
  );
};
