import React from 'react';
import PropTypes from 'prop-types';

const PensionFund = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 70"
    className={className}
  >
    <title>Pension Fund</title>
    <g id="Grey">
      <g id="Object2">
        <path
          d="M71,6H56.17L45.48.12A1,1,0,0,0,44,1V2H36a1,1,0,0,0-1,1V6H1A1,1,0,0,0,0,7V61a1,1,0,0,0,1,1H4v5a1,1,0,0,0,1,1h9a1,1,0,0,0,1-1V62H35v5a1,1,0,0,0,1,1h8v1a1,1,0,0,0,1.48.88L57,63.54V67a1,1,0,0,0,1,1h9a1,1,0,0,0,1-1V62h3a1,1,0,0,0,1-1V7A1,1,0,0,0,71,6ZM13,66H6V62h7ZM35,55H7V35H35Zm0-22H7V13H35Zm0-22H6a1,1,0,0,0-1,1V56a1,1,0,0,0,1,1H35v3H2V8H35Zm9,55H37V4h7Zm2,1.31V2.69l18,9.9V16H63a1,1,0,0,0,0,2h1v4H63a1,1,0,0,0,0,2h1V45H63a1,1,0,0,0,0,2h1v4H63a1,1,0,0,0,0,2h1v4.41ZM66,66H59V62.44l.85-.47A.94.94,0,0,0,60,62h6Zm4-6H63.44l2-1.12A1,1,0,0,0,66,58V53h1a1,1,0,0,0,0-2H66V47h1a1,1,0,0,0,0-2H66V24h1a1,1,0,0,0,0-2H66V18h1a1,1,0,1,0,0-2H66V12a1,1,0,0,0-.52-.88L59.8,8H70Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M49,41a1,1,0,0,0,0,2,5,5,0,0,0,5-5V32a5,5,0,0,0-5-5,1,1,0,0,0,0,2,3,3,0,0,1,3,3v6A3,3,0,0,1,49,41Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M49,22a1,1,0,0,0,0,2,8,8,0,0,1,8,8v6a8,8,0,0,1-8,8,1,1,0,0,0,0,2A10,10,0,0,0,59,38V32A10,10,0,0,0,49,22Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <circle cx="40.5" cy="9.5" r="1.5" fill="#899198" />
        <circle cx="40.5" cy="15.5" r="1.5" fill="#899198" />
        <circle cx="40.5" cy="21.5" r="1.5" fill="#899198" />
        <circle cx="40.5" cy="48.5" r="1.5" fill="#899198" />
        <circle cx="40.5" cy="54.5" r="1.5" fill="#899198" />
        <circle cx="40.5" cy="60.5" r="1.5" fill="#899198" />
      </g>
    </g>
    <g id="Coloured">
      <g id="Object1">
        <path
          d="M32,17H10a1,1,0,0,0-1,1V34a1,1,0,0,0,2,0V31h5v3a1,1,0,0,0,2,0V19h6V34a1,1,0,0,0,2,0V31h5v3a1,1,0,0,0,2,0V18A1,1,0,0,0,32,17Zm-6,6h5v2H26ZM16,25H11V23h5Zm-5,4V27h5v2Zm5-8H11V19h5Zm15-2v2H26V19ZM26,29V27h5v2Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M32,39H10a1,1,0,0,0-1,1V56a1,1,0,0,0,2,0V53h5v3a1,1,0,0,0,2,0V41h6V56a1,1,0,0,0,2,0V53h5v3a1,1,0,0,0,2,0V40A1,1,0,0,0,32,39Zm-6,6h5v2H26ZM16,47H11V45h5Zm-5,4V49h5v2Zm5-8H11V41h5Zm15-2v2H26V41ZM26,51V49h5v2Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
      </g>
    </g>
  </svg>
);

PensionFund.defaultProps = {
  companyColour: '#ab1923',
};

PensionFund.propTypes = {
  companyColour: PropTypes.string,
};

export default PensionFund;
