import * as types from '../types/dashboard.types';

const initialState = {
  isLoading: false,
  jobs: [],
  totalJobs: 0,

  jobActionModal: null,
  selectedJob: null,
  isLoadingAddRemoveCollaborator: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_JOBS_START:
      return {
        ...state,
        isLoading: true,
      };

    case types.GET_JOBS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        jobs: action.payload.result,
        totalJobs: action.payload.count,
      };

    case types.GET_JOBS_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    /**
     * JOB DASHBOARD ACTIONS
     */
    case types.ARCHIVE_JOB_START:
    case types.DELETE_JOB_START:
    case types.PUBLISH_JOB_START:
      return {
        ...state,
        isLoading: true,
      };

    case types.ARCHIVE_JOB_FAIL:
    case types.DELETE_JOB_FAIL:
    case types.PUBLISH_JOB_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    /**
     * JOB DASHBOARD ACTIONS MODAL
     */
    case types.OPEN_JOB_ACTION_MODAL:
      return {
        ...state,
        jobActionModal: action.payload.modalType,
        selectedJob: action.payload.job,
      };

    case types.CLOSE_JOB_ACTION_MODAL:
      return {
        ...state,
        jobActionModal: null,
        selectedJob: null,
      };

    /**
     * JOB SHARE
     */
    case types.ADD_JOB_COLLABORATOR_START:
    case types.REMOVE_JOB_COLLABORATOR_START:
    case types.CREATE_AND_ADD_JOB_COLLABORATOR_START:
      return {
        ...state,
        isLoadingAddRemoveCollaborator: true,
      };

    case types.ADD_JOB_COLLABORATOR_SUCCESS:
    case types.REMOVE_JOB_COLLABORATOR_SUCCESS:
      return {
        ...state,
        jobs: state.jobs.map((job) => {
          if (job.id === action.payload.jobId) {
            return { ...job, users: action.payload.userIds };
          }
          return job;
        }),
        selectedJob: state.selectedJob
          ? {
              ...state.selectedJob,
              users: action.payload.userIds,
            }
          : null,
        isLoadingAddRemoveCollaborator: false,
      };

    case types.ADD_JOB_COLLABORATOR_FAIL:
    case types.REMOVE_JOB_COLLABORATOR_FAIL:
    case types.CREATE_AND_ADD_JOB_COLLABORATOR_FAIL:
      return {
        ...state,
        isLoadingAddRemoveCollaborator: false,
      };

    /** Reset store */
    case types.RESET_DASHBOARD_STORE:
      return { ...initialState };

    default:
      return state;
  }
};
