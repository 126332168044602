import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Label, Input, SelectV2 } from '@lionstep/ui';

// Constants
import {
  QUESTIONS_OPTIONS,
  QUESTIONS_TYPE,
} from '../../adminActions.constants';

const QuestionTitleAndType = ({
  supportedQuestionTypes,
  question,
  questionIndex,
  onQuestionValueChange,
  onQuestionTypeChange,
}) => {
  const type = QUESTIONS_OPTIONS.find((item) => item.value === question.type);
  const questionOptions = QUESTIONS_OPTIONS.filter((item) =>
    supportedQuestionTypes.includes(item.value),
  );
  const { t } = useTranslation();

  return (
    <Grid.Row gutter={30}>
      <Grid.Col xs={24} md={16} style={{ marginBottom: '2.25rem' }}>
        <Label htmlFor="title">{t('question')}</Label>
        <Input
          value={question.title}
          name="title"
          placeholder={t('Type your question here')}
          onChange={(e) =>
            onQuestionValueChange(questionIndex, 'title', e.target.value)
          }
        />
      </Grid.Col>

      <Grid.Col xs={24} md={8} style={{ marginBottom: '2.25rem' }}>
        <Label htmlFor="type">
          {t('key_points.questions.type_of_question')}
        </Label>
        <SelectV2
          options={questionOptions}
          name="type"
          value={type}
          onChange={(option) =>
            onQuestionTypeChange(questionIndex, option.value)
          }
        />
      </Grid.Col>
    </Grid.Row>
  );
};

QuestionTitleAndType.propTypes = {
  supportedQuestionTypes: PropTypes.arrayOf(
    PropTypes.oneOf([
      QUESTIONS_TYPE.yesNo,
      QUESTIONS_TYPE.multipleChoice,
      QUESTIONS_TYPE.skills,
      QUESTIONS_TYPE.openAnswer,
    ]),
  ).isRequired,
  question: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    hide: PropTypes.bool.isRequired,
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        choice: PropTypes.string.isRequired,
        expected_str: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  questionIndex: PropTypes.number.isRequired,
  onQuestionValueChange: PropTypes.func.isRequired,
  onQuestionTypeChange: PropTypes.func.isRequired,
};

export default QuestionTitleAndType;
