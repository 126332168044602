import * as types from '../types/jobVersion.types';

const initialState = {
  currentVersion: null,
  isLoadingCurrentVersion: false,
  allVersions: [],
  isLoadingAllVersions: false,
  isCreatingVersion: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_VERSION_STATE:
      return initialState;

    case types.GET_VERSIONS_START:
      return {
        ...state,
        isLoadingAllVersions: true,
      };

    case types.GET_VERSIONS_SUCCESS:
      return {
        ...state,
        isLoadingAllVersions: false,
        allVersions: action.payload,
      };

    case types.GET_VERSIONS_FAIL:
      return {
        ...state,
        isLoadingAllVersions: false,
      };

    case types.CREATE_VERSION_START:
      return {
        ...state,
        isCreatingVersion: true,
      };

    case types.GET_CURRENT_VERSION_START:
      return {
        ...state,
        isLoadingCurrentVersion: true,
      };

    case types.GET_CURRENT_VERSION_SUCCESS:
      return {
        ...state,
        isLoadingCurrentVersion: false,
        currentVersion: action.payload,
      };

    case types.GET_CURRENT_VERSION_FAIL:
      return {
        ...state,
        isLoadingCurrentVersion: false,
      };

    case types.CREATE_VERSION_SUCCESS:
      return {
        ...state,
        isCreatingVersion: false,
        currentVersion: action.payload,
        allVersions: [...state.allVersions, action.payload],
      };

    case types.CREATE_VERSION_FAIL:
      return {
        ...state,
        isCreatingVersion: false,
      };

    case types.UPDATE_VERSION_START:
      return {
        ...state,
        isLoadingCurrentVersion: true,
      };

    case types.UPDATE_VERSION_SUCCESS:
      return {
        ...state,
        isLoadingCurrentVersion: false,
        currentVersion: action.payload,
        allVersions: state.allVersions.map((version) => {
          if (version.version === action.payload.version) {
            return action.payload;
          }
          return version;
        }),
      };

    case types.UPDATE_VERSION_FAIL:
      return {
        ...state,
        isLoadingCurrentVersion: false,
      };

    default:
      return state;
  }
};
