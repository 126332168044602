import React from 'react';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

// Helpers
import { isLowerCase } from '../../../../../../support/helpers';

// Components
import OverflowWrapper from '../../../../../../components/refactoring/overflowWrapper';

// Styles
import styles from './contentList.module.scss';

const cx = classnames.bind(styles);

const ContentPillow = ({ item }) => (
  <div className={styles.contentItemPillowWrapper}>
    <OverflowWrapper id={item} content={item}>
      <div className={styles.contentPillowItem}>{item}</div>
    </OverflowWrapper>
  </div>
);

ContentPillow.propTypes = {
  item: PropTypes.string.isRequired,
};

// Pillows
const ContentPillows = ({ items }) => {
  const { t } = useTranslation();

  return items.length ? (
    items.map((item, index) => (
      <ContentPillow key={camelCase(`${item}-${index}`)} item={item} />
    ))
  ) : (
    <div className={styles.nothingToShow}>{t('no_info_to_show')}</div>
  );
};

ContentPillows.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

// LabelValue
const ContentLabelValue = ({ value }) => (
  <div className={styles.contentItemLabelValueWrapper}>
    <div className={styles.contentItemLabelValue}>
      {typeof value === 'object' ? (
        <>
          <div
            className={cx(styles.contentItemLabelValueTitle, {
              [styles.capitalize]: isLowerCase(value.title.trim().charAt(0)),
            })}
          >
            {value.title}
          </div>
          {value.subtitle && (
            <div
              className={cx(styles.contentItemLabelValueSubtitle, {
                [styles.capitalize]: isLowerCase(
                  value.subtitle.trim().charAt(0),
                ),
                [styles.contentItemLabelValueSubtitleNoPadding]:
                  !value.description,
              })}
            >
              {value.subtitle}
            </div>
          )}
          {value.description && (
            <div className={styles.contentItemLabelValueDescription}>
              {value.description}
            </div>
          )}
        </>
      ) : (
        value
      )}
    </div>
  </div>
);

ContentLabelValue.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      description: PropTypes.string,
    }),
  ]).isRequired,
};

const ContentList = ({ sectionTitle, sections, type, withLineBreak }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.contentCard}>
      {sectionTitle && (
        <div className={styles.sectionTitle}>{sectionTitle}</div>
      )}
      {sections.length ? (
        sections.map((section, index) => (
          <div key={`section-${String(index)}`}>
            <div className={styles.section}>
              {section.label && (
                <div className={styles.sectionLabel}>{section.label}</div>
              )}
              <div className={styles.sectionContent}>
                {type === 'pillows' && <ContentPillows items={section.value} />}
                {type === 'labelValue' && (
                  <ContentLabelValue value={section.value} />
                )}
              </div>
            </div>
            {withLineBreak && index < sections.length - 1 && (
              <div className={styles.sectionLineBreak} />
            )}
          </div>
        ))
      ) : (
        <div className={styles.section}>
          <div className={styles.nothingToShow}>{t('no_info_to_show')}</div>
        </div>
      )}
    </div>
  );
};

ContentList.defaultProps = {
  sectionTitle: '',
  withLineBreak: false,
};

ContentList.propTypes = {
  sectionTitle: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
  withLineBreak: PropTypes.bool,
};

export default ContentList;
