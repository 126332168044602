import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import { Tooltip } from '@lionstep/ui';

const OverflowWrapper = ({ children, id, content, place }) => {
  const childrenRef = useRef(null);

  const [isOverflown, setIsOverflown] = useState(false);
  useEffect(() => {
    const checkOverflow = () => {
      const element = childrenRef.current;
      setIsOverflown(element.scrollWidth > element.clientWidth);
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  const OverflowWrapperCmp = isOverflown ? Tooltip : React.Fragment;

  // to prevent Invalid prop `id` supplied to `React.Fragment`. error
  const overflowWrapperProps = {};
  if (isOverflown) {
    overflowWrapperProps.id = id.replaceAll('\n', '');
    overflowWrapperProps.content = content;
    overflowWrapperProps.place = place;
  }

  return (
    <OverflowWrapperCmp {...overflowWrapperProps}>
      {React.cloneElement(children, { ref: childrenRef })}
    </OverflowWrapperCmp>
  );
};

OverflowWrapper.defaultProps = {
  place: 'top',
};

OverflowWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  id: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  place: PropTypes.string,
};

export default OverflowWrapper;
