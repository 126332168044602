import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { Toaster } from '@lionstep/ui';
import moment from 'moment';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import Main from './Main';
import UserLeaveConfirmation from '../components/refactoring/userLeaveConfirmation';

const tagManagerArgs = {
  gtmId: 'GTM-WDT3KRK',
  dataLayerName: 'PageDataLayer',
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  /**
   * This is the logic to reload the page when user logout from another tab
   */
  useEffect(() => {
    window.addEventListener('storage', (e) => {
      if (
        (e.key === 'sessionId' && e.newValue === 'null') ||
        (e.key === 'sessionId' && e.newValue !== e.oldValue)
      ) {
        window.location.reload();
      }
    });

    return () => {
      window.removeEventListener('storage');
    };
  }, []);

  const { i18n } = useTranslation();

  /**
   * This state (confirmOpen) is related to React Router Prompt.
   * I was not able to move state  inside "UserLeaveConfirmation".
   * If you find solution for moving it inside "UserLeaveConfirmation"
   * feel free to make a change
   */
  const [confirmOpen, setConfirmOpen] = useState(true);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <Router
      getUserConfirmation={(message, callback) =>
        UserLeaveConfirmation({
          message,
          callback,
          confirmOpen,
          setConfirmOpen,
        })
      }
    >
      <QueryClientProvider client={queryClient}>
        <Main />
        <div id="react-router-prompt" />
        {process.env.REACT_APP_NODE_ENV === 'development' && (
          <ReactQueryDevtools />
        )}
      </QueryClientProvider>
      <Toaster position="top-right" />
    </Router>
  );
};

export default App;
