export const TEXT_COLOR = '#213057';

export const BAR_TOOLTIP = {
  backgroundColor: '#FFFFFF',
  bodyColor: '#213057',
  padding: 16,
  borderColor: '#E9E6DC',
  borderWidth: 1,
  cornerRadius: 8,
  callbacks: {
    label(context) {
      return `${context.formattedValue} ${context.dataset.label}`;
    },
    title() {
      return '';
    },
  },
};

export const BAR_COLORS = ['#5C88FF', '#66C1B0'];

export const DOUGHNUT_COLORS = [
  '#5C88FF',
  '#66C1B0',
  '#BB779C',
  '#A7A9AD',
  '#FCE5B3',
  '#F2919B',
];

export const EMPTY_DOUGHNUT = {
  color: '#E9E6DC',
  width: 50,
  radiusDecrease: 25,
};

export const DOUGHNUT_TOOLTIP = {
  backgroundColor: '#FFFFFF',
  bodyColor: '#213057',
  padding: 16,
  borderColor: '#E9E6DC',
  borderWidth: 1,
  cornerRadius: 8,
  callbacks: {
    label(context) {
      return `${context.formattedValue}% ${context.label}`;
    },
  },
};
