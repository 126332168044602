export const ENV = process.env.REACT_APP_NODE_ENV;

export const PYTHON_API = process.env.REACT_APP_PYTHON_URL;
export const RAILS_API = process.env.REACT_APP_RAILS_API_URL;
export const LAYER1_API = process.env.REACT_APP_LAYER1_API_URL;
export const FIREBASE_FUNCTIONS_URL =
  process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
export const RECAPTCHA_DEV_TOKEN = process.env.REACT_APP_RECAPTCHA_DEV_TOKEN;
export const SENTRY_DNS = process.env.REACT_APP_SENTRY;

export const TALENTPOOL_APP = process.env.REACT_APP_TALENTPOOL_URL;
export const CANDIDATE_PORTAL_APP = process.env.REACT_APP_CANDIDATE_PORTAL_URL;
export const JOBS_PORTAL_APP = process.env.REACT_APP_JOBS_PORTAL_URL;

export const LIONSTEP_INTERNAL_TOKEN =
  process.env.REACT_APP_LIONSTEP_INTERNAL_TOKEN;

export const ZENDESK_URL = 'https://lionstep.zendesk.com';
