export const GET_CANDIDATE_START = 'CANDIDATE::GET_CANDIDATE_START';
export const GET_CANDIDATE_SUCCESS = 'CANDIDATE::GET_CANDIDATE_SUCCESS';
export const GET_CANDIDATE_FAIL = 'CANDIDATE::GET_CANDIDATE_FAIL';

export const GET_CANDIDATE_PDF_REPORT_START =
  'CANDIDATE::GET_CANDIDATE_PDF_REPORT_START';
export const GET_CANDIDATE_PDF_REPORT_SUCCESS =
  'CANDIDATE::GET_CANDIDATE_PDF_REPORT_SUCCESS';
export const GET_CANDIDATE_PDF_REPORT_FAIL =
  'CANDIDATE::GET_CANDIDATE_PDF_REPORT_FAIL';

export const SEND_SMS_REMINDER_START = 'CANDIDATE::SEND_SMS_REMINDER_START';
export const SEND_SMS_REMINDER_SUCCESS = 'CANDIDATE::SEND_SMS_REMINDER_SUCCESS';
export const SEND_SMS_REMINDER_FAIL = 'CANDIDATE::SEND_SMS_REMINDER_FAIL';

export const HIRE_CANDIDATE_START = 'CANDIDATE::HIRE_CANDIDATE_START';
export const HIRE_CANDIDATE_SUCCESS = 'CANDIDATE::HIRE_CANDIDATE_SUCCESS';
export const HIRE_CANDIDATE_FAIL = 'CANDIDATE::HIRE_CANDIDATE_FAIL';

export const REJECT_CANDIDATE_START = 'CANDIDATE::REJECT_CANDIDATE_START';
export const REJECT_CANDIDATE_SUCCESS = 'CANDIDATE::REJECT_CANDIDATE_SUCCESS';
export const REJECT_CANDIDATE_FAIL = 'CANDIDATE::REJECT_CANDIDATE_FAIL';

export const FEEDBACK_CANDIDATE_START = 'CANDIDATE::FEEDBACK_CANDIDATE_START';
export const FEEDBACK_CANDIDATE_SUCCESS =
  'CANDIDATE::FEEDBACK_CANDIDATE_SUCCESS';
export const FEEDBACK_CANDIDATE_FAIL = 'CANDIDATE::FEEDBACK_CANDIDATE_FAIL';

export const RESET_CANDIDATE = 'CANDIDATE::RESET_CANDIDATE';

export const CANDIDATE_CREDIT_REFUNDED_SUCCESS =
  'CANDIDATE::CREDIT_REFUNDED_SUCCESS';
