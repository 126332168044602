export const GET_COMPANY_START = 'COMPANY::GET_COMPANY_START';
export const GET_COMPANY_SUCCESS = 'COMPANY::GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAIL = 'COMPANY::GET_COMPANY_FAIL';

export const UPDATE_COMPANY_START = 'COMPANY::UPDATE_COMPANY_START';
export const UPDATE_COMPANY_SUCCESS = 'COMPANY::UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAIL = 'COMPANY::UPDATE_COMPANY_FAIL';

export const GET_COMPANY_PICTURES_START = 'COMPANY::GET_COMPANY_PICTURES_START';
export const GET_COMPANY_PICTURES_SUCCESS =
  'COMPANY::GET_COMPANY_PICTURES_SUCCESS';
export const GET_COMPANY_PICTURES_FAIL = 'COMPANY::GET_COMPANY_PICTURES_FAIL';

export const UPLOAD_COMPANY_PICTURES_START =
  'COMPANY::UPLOAD_COMPANY_PICTURES_START';
export const UPLOAD_COMPANY_PICTURES_SUCCESS =
  'COMPANY::UPLOAD_COMPANY_PICTURES_SUCCESS';
export const UPLOAD_COMPANY_PICTURES_FAIL =
  'COMPANY::UPLOAD_COMPANY_PICTURES_FAIL';

export const DELETE_COMPANY_PICTURES_START =
  'COMPANY::DELETE_COMPANY_PICTURES_START';
export const DELETE_COMPANY_PICTURES_SUCCESS =
  'COMPANY::DELETE_COMPANY_PICTURES_SUCCESS';
export const DELETE_COMPANY_PICTURES_FAIL =
  'COMPANY::DELETE_COMPANY_PICTURES_FAIL';

export const GET_COMPANY_LOCATIONS_START =
  'COMPANY::GET_COMPANY_LOCATIONS_START';
export const GET_COMPANY_LOCATIONS_SUCCESS =
  'COMPANY::GET_COMPANY_LOCATIONS_SUCCESS';
export const GET_COMPANY_LOCATIONS_FAIL = 'COMPANY::GET_COMPANY_LOCATIONS_FAIL';

export const GET_COLLABORATORS_START = 'COMPANY::GET_COLLABORATORS_START';
export const GET_COLLABORATORS_SUCCESS = 'COMPANY::GET_COLLABORATORS_SUCCESS';
export const GET_COLLABORATORS_FAIL = 'COMPANY::GET_COLLABORATORS_FAIL';

export const ADD_COLLABORATOR_START = 'COMPANY::ADD_COLLABORATOR_START';
export const ADD_COLLABORATOR_SUCCESS = 'COMPANY::ADD_COLLABORATOR_SUCCESS';
export const ADD_COLLABORATOR_FAIL = 'COMPANY::ADD_COLLABORATOR_FAIL';

export const ADD_COLLABORATOR_MAILING_PERMISSION_START =
  'COMPANY::ADD_COLLABORATOR_MAILING_PERMISSION_START';
export const ADD_COLLABORATOR_MAILING_PERMISSION_SUCCESS =
  'COMPANY::ADD_COLLABORATOR_MAILING_PERMISSION_SUCCESS';
export const ADD_COLLABORATOR_MAILING_PERMISSION_FAIL =
  'COMPANY::ADD_COLLABORATOR_MAILING_PERMISSION_FAIL';

export const REMOVE_COLLABORATOR_MAILING_PERMISSION_START =
  'COMPANY::REMOVE_COLLABORATOR_MAILING_PERMISSION_START';
export const REMOVE_COLLABORATOR_MAILING_PERMISSION_SUCCESS =
  'COMPANY::REMOVE_COLLABORATOR_MAILING_PERMISSION_SUCCESS';
export const REMOVE_COLLABORATOR_MAILING_PERMISSION_FAIL =
  'COMPANY::REMOVE_COLLABORATOR_MAILING_PERMISSION_FAIL';

export const CLEAR_COMPANY = 'COMPANY::CLEAR_COMPANY';

export const GET_ACTIVE_JOBS_TITLES_START =
  'COMPANY::GET_ACTIVE_JOBS_TITLES_START';
export const GET_ACTIVE_JOBS_TITLES_SUCCESS =
  'COMPANY::GET_ACTIVE_JOBS_TITLES_SUCCESS';
export const GET_ACTIVE_JOBS_TITLES_FAIL =
  'COMPANY::GET_ACTIVE_JOBS_TITLES_FAIL';
