import { toast } from '@lionstep/ui';

// Types
import * as types from '../types/jobVersion.types';

// Api
import {
  postJobVersionApi,
  putCurrentJobVersionApi,
  getCurrentJobVersionApi,
  getAllJobVersionsApi,
} from '../../../../api/job.api';

export const getCurrentVersion =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.GET_CURRENT_VERSION_START });
      const { data } = await getCurrentJobVersionApi({ id });

      dispatch({
        type: types.GET_CURRENT_VERSION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({ type: types.GET_CURRENT_VERSION_FAIL });
      toast.error('Error getting current job version', {
        id: types.GET_CURRENT_VERSION_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const updateCurrentVersion =
  ({ id, version, description }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.UPDATE_VERSION_START });

      const { data } = await putCurrentJobVersionApi({
        id,
        version,
        description,
      });

      dispatch({
        type: types.UPDATE_VERSION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({ type: types.UPDATE_VERSION_FAIL });
      toast.error('Error updating current job version', {
        id: types.UPDATE_VERSION_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const createVersion =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.CREATE_VERSION_START });

      const { data } = await postJobVersionApi({ id });

      dispatch({ type: types.CREATE_VERSION_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: types.CREATE_VERSION_FAIL });
      toast.error('Error creating new job version', {
        id: types.CREATE_VERSION_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const getAllVersions =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.GET_VERSIONS_START });

      const { data } = await getAllJobVersionsApi({ id });

      dispatch({
        type: types.GET_VERSIONS_SUCCESS,
        payload: data.versions,
      });
    } catch (error) {
      dispatch({ type: types.GET_VERSIONS_FAIL });
      toast.error('Error getting job versions', {
        id: types.GET_VERSIONS_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };
