import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip, Icon } from '@lionstep/ui';
import { useTranslation } from 'react-i18next';

import styles from './preferredCompaniesTooltip.module.scss';

const cx = classnames.bind(styles);

const PreferredCompaniesTooltip = ({ candidate, className, iconClassName }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      id={`${candidate.id}-preferred-company`}
      place="top"
      content={`${t('candidates_page.candidate_card.preferred_companies')}: ${
        candidate.preferred_company
      }`}
      className={cx(styles.tooltip, className)}
    >
      <Icon
        className={cx(styles.icon, iconClassName)}
        name="PreferredCompany"
      />
    </Tooltip>
  );
};

PreferredCompaniesTooltip.defaultProps = {
  className: '',
  iconClassName: '',
};

PreferredCompaniesTooltip.propTypes = {
  candidate: PropTypes.shape({
    id: PropTypes.string,
    preferred_company: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

export default PreferredCompaniesTooltip;
