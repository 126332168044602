import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as BulgarianFlag } from '../../../assets/icons/flags/bulgarian.svg';
import { ReactComponent as CatalanFlag } from '../../../assets/icons/flags/catalan.svg';
import { ReactComponent as ChineseFlag } from '../../../assets/icons/flags/chinese.svg';
import { ReactComponent as CzechFlag } from '../../../assets/icons/flags/czech.svg';
import { ReactComponent as DanishFlag } from '../../../assets/icons/flags/danish.svg';
import { ReactComponent as DeutschFlag } from '../../../assets/icons/flags/deutsch.svg';
import { ReactComponent as EnglishFlag } from '../../../assets/icons/flags/english.svg';
import { ReactComponent as FinnishFlag } from '../../../assets/icons/flags/finnish.svg';
import { ReactComponent as FrenchFlag } from '../../../assets/icons/flags/french.svg';
import { ReactComponent as GermanFlag } from '../../../assets/icons/flags/german.svg';
import { ReactComponent as GreekFlag } from '../../../assets/icons/flags/greek.svg';
import { ReactComponent as HungarianFlag } from '../../../assets/icons/flags/hungarian.svg';
import { ReactComponent as ItalianFlag } from '../../../assets/icons/flags/italian.svg';
import { ReactComponent as NorwegianFlag } from '../../../assets/icons/flags/norwegian.svg';
import { ReactComponent as PolishFlag } from '../../../assets/icons/flags/polish.svg';
import { ReactComponent as PortugueseFlag } from '../../../assets/icons/flags/portuguese.svg';
import { ReactComponent as RomanianFlag } from '../../../assets/icons/flags/romanian.svg';
import { ReactComponent as RussianFlag } from '../../../assets/icons/flags/russian.svg';
import { ReactComponent as SerbianFlag } from '../../../assets/icons/flags/serbian.svg';
import { ReactComponent as SpanishFlag } from '../../../assets/icons/flags/spanish.svg';
import { ReactComponent as SwedishFlag } from '../../../assets/icons/flags/swedish.svg';
import { ReactComponent as UkrainianFlag } from '../../../assets/icons/flags/ukrainian.svg';

const allFlags = {
  bulgarian: BulgarianFlag,
  catalan: CatalanFlag,
  chinese: ChineseFlag,
  czech: CzechFlag,
  danish: DanishFlag,
  deutsch: DeutschFlag,
  english: EnglishFlag,
  finnish: FinnishFlag,
  french: FrenchFlag,
  german: GermanFlag,
  greek: GreekFlag,
  hungarian: HungarianFlag,
  italian: ItalianFlag,
  norwegian: NorwegianFlag,
  polish: PolishFlag,
  portuguese: PortugueseFlag,
  romanian: RomanianFlag,
  russian: RussianFlag,
  serbian: SerbianFlag,
  spanish: SpanishFlag,
  swedish: SwedishFlag,
  ukrainian: UkrainianFlag,
};

const Flag = ({ country, ...restProps }) => {
  if (!allFlags[country]) {
    return (
      <svg
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
      >
        <circle cx="10" cy="10" r="10" fill="#eae6db" />
      </svg>
    );
  }

  const Component = allFlags[country];

  return <Component {...restProps} />;
};

Flag.defaultProps = {
  country: '',
};

Flag.propTypes = {
  country: PropTypes.string,
};

export default Flag;
