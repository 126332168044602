import React from 'react';
import PropTypes from 'prop-types';

const Sabbatical = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    className={className}
  >
    <path
      id="Grey"
      d="M64,28.9C64,28.9,64,28.9,64,28.9c0-0.2-0.1-0.3-0.1-0.3c0,0,0-0.1,0-0.1c-0.1-0.1-0.1-0.2-0.2-0.3
        c0,0,0,0,0,0l-11-9c-0.4-0.3-1.1-0.3-1.4,0.1c-0.3,0.4-0.3,1.1,0.1,1.4l10,8.2L42,44.8l-7.4-6c-1.5-1.2-3.6-1.2-5,0l-7.4,6L2.6,29
        l10-8.2c0.4-0.4,0.5-1,0.1-1.4c-0.4-0.4-1-0.5-1.4-0.1l-11,9c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0.1,0,0.1
        c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1v31c0,2.2,1.8,4,4,4h56c2.2,0,4-1.8,4-4L64,28.9C64,29,64,29,64,28.9z M62,60
        c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V31.1L20.5,46L5.4,58.2c-0.4,0.3-0.5,1-0.2,1.4c0.3,0.4,1,0.5,1.4,0.2l24.1-19.5
        c0.7-0.6,1.8-0.6,2.5,0l24.1,19.5c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4c0.3-0.4,0.3-1.1-0.2-1.4L43.5,46L62,31.1V60z"
      fill="#899198"
    />
    <path
      id="Coloured"
      d="M49.3,3H45V1c0-0.6-0.4-1-1-1s-1,0.4-1,1v2h-6V1c0-0.6-0.4-1-1-1s-1,0.4-1,1v2h-6V1
        c0-0.6-0.4-1-1-1s-1,0.4-1,1v2h-6V1c0-0.6-0.4-1-1-1s-1,0.4-1,1v2h-4.3C12.7,3,11,4.8,11,7v27c0,0.6,0.4,1,1,1s1-0.4,1-1v-4h6v6h-3
        c-0.6,0-1,0.4-1,1s0.4,1,1,1h32c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3v-6h6v4c0,0.6,0.4,1,1,1s1-0.4,1-1V7C53,4.8,51.3,3,49.3,3z M29,28v-6
        h6v6H29z M35,30v6h-6v-6H35z M13,14h6v6h-6V14z M21,14h6v6h-6V14z M35,14v6h-6v-6H35z M27,22v6h-6v-6H27z M37,22h6v6h-6V22z M37,20
        v-6h6v6H37z M45,14h6v6h-6V14z M14.7,5H19v3h-1c-0.6,0-1,0.4-1,1s0.4,1,1,1h4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1V5h6v3h-1
        c-0.6,0-1,0.4-1,1s0.4,1,1,1h4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1V5h6v3h-1c-0.6,0-1,0.4-1,1s0.4,1,1,1h4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1
        V5h6v3h-1c-0.6,0-1,0.4-1,1s0.4,1,1,1h4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1V5h4.3c1,0,1.7,0.9,1.7,2v5H13V7C13,5.9,13.8,5,14.7,5z
          M13,28v-6h6v6H13z M21,30h6v6h-6V30z M43,36h-6v-6h6V36z M45,28v-6h6v6H45z"
      fill={companyColour}
    />
  </svg>
);

Sabbatical.defaultProps = {
  companyColour: '#ab1923',
};

Sabbatical.propTypes = {
  companyColour: PropTypes.string,
};

export default Sabbatical;
