import types from './types';

const initialState = {};

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_USER_COMPLETED: {
      localStorage.setItem('sessionId', action.payload.data.id_token);
      localStorage.setItem('refreshToken', action.payload.data.refresh_token);
      localStorage.setItem('logged', true);
      const loginTime = Math.floor(new Date().getTime() / 1000.0);
      localStorage.setItem('start', loginTime);
      return action.payload.data;
    }
    case types.SIGNUP_USER_COMPLETED: {
      localStorage.setItem('sessionId', action.payload.data.id_token);
      localStorage.setItem('refreshToken', action.payload.data.refresh_token);
      localStorage.setItem('logged', true);
      const startTime = Math.floor(new Date().getTime() / 1000.0);
      localStorage.setItem('start', startTime);
      return action.payload.data;
    }
    case types.LOGOUT_USER: {
      localStorage.setItem('sessionId', null);
      localStorage.setItem('refreshToken', null);
      localStorage.setItem('logged', false);
      return null;
    }
    case types.REFRESH_USER_TOKEN_COMPLETED: {
      const userData = state;
      userData.id_token = action.payload.id_token;
      userData.refresh_token = action.payload.refresh_token;
      userData.expires_in = action.payload.expires_in;
      localStorage.setItem('sessionId', action.payload.data.id_token);
      localStorage.setItem('refreshToken', action.payload.data.refresh_token);
      localStorage.setItem('logged', true);
      const refreshTime = Math.floor(new Date().getTime() / 1000.0);
      localStorage.setItem('start', refreshTime);
      return userData;
    }
    default:
      return state;
  }
};

export default userDataReducer;
