import React from 'react';
import PropTypes from 'prop-types';

const EquityIncentives = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 74 70"
    className={className}
  >
    <g id="Grey_1_">
      <path
        fill="#899198"
        d="M68.4,44.1C69.4,40.8,70,37.4,70,34C70,16.4,55.6,2,38,2c-4,0-7.8,0.7-11.5,2.1c-0.5,0.2-0.8,0.8-0.6,1.3
          L36.5,33H7c-0.6,0-1,0.4-1,1c0,17.6,14.4,32,32,32c3.4,0,6.8-0.6,10.1-1.6c2.7,3.4,6.8,5.6,11.4,5.6c8,0,14.5-6.5,14.5-14.5
          C74,50.9,71.8,46.7,68.4,44.1z M38,64C21.8,64,8.5,51.1,8,35h30c0.3,0,0.6-0.2,0.8-0.4c0.2-0.3,0.2-0.6,0.1-0.9l-10.8-28
          C31.3,4.6,34.6,4,38,4c16.5,0,30,13.5,30,30c0,3-0.5,6-1.4,8.9c-2.1-1.2-4.5-1.9-7.1-1.9c-8,0-14.5,6.5-14.5,14.5
          c0,2.6,0.7,5,1.9,7.1C44,63.5,41,64,38,64z M59.5,68C52.6,68,47,62.4,47,55.5S52.6,43,59.5,43S72,48.6,72,55.5S66.4,68,59.5,68z"
      />
      <path
        fill="#899198"
        d="M63,51.7c0,0.6,0.4,1,1,1s1-0.4,1-1c0-2.3-1.7-4.2-4-4.7V46c0-0.6-0.4-1-1-1s-1,0.4-1,1v1.1
          c-2.3,0.4-4,2.4-4,4.7s1.7,4.2,4,4.7v5.4c-1.2-0.4-2-1.4-2-2.6c0-0.6-0.4-1-1-1s-1,0.4-1,1c0,2.3,1.7,4.2,4,4.7V65c0,0.6,0.4,1,1,1
          s1-0.4,1-1v-1.1c2.3-0.4,4-2.4,4-4.7s-1.7-4.2-4-4.7v-5.4C62.2,49.5,63,50.6,63,51.7z M57,51.7c0-1.2,0.8-2.2,2-2.6v5.2
          C57.8,54,57,52.9,57,51.7z M63,59.2c0,1.2-0.8,2.2-2,2.6v-5.2C62.2,57,63,58.1,63,59.2z"
      />
    </g>
    <g id="Coloured_1_">
      <path
        fill={companyColour}
        d="M31.9,28.6l-3-7.4c0-0.2-0.1-0.4-0.2-0.6l-8-20c-0.1-0.3-0.3-0.5-0.5-0.6c-0.2-0.1-0.5-0.1-0.8,0
          C8,5.1,0.4,16.4,0,29c0,0.3,0.1,0.5,0.3,0.7S0.7,30,1,30h30c0.3,0,0.6-0.2,0.8-0.4S32.1,28.9,31.9,28.6z M2,28
          c0.1-1.7,0.4-3.4,0.8-5C3.5,22.8,4,22.2,4,21.5c0-0.4-0.2-0.8-0.5-1.1c0.2-0.5,0.3-1,0.5-1.5C4.2,19,4.3,19,4.5,19
          C5.3,19,6,18.3,6,17.5c0-0.6-0.3-1-0.8-1.3c0.2-0.4,0.5-0.9,0.7-1.3C6.1,15,6.3,15,6.5,15C7.3,15,8,14.3,8,13.5
          c0-0.4-0.2-0.8-0.5-1.1c0.3-0.5,0.7-0.9,1-1.4c0.8,0,1.5-0.7,1.5-1.5c0-0.1,0-0.1,0-0.2c1.2-1.4,2.6-2.6,4-3.7
          C14,6.4,14.7,7,15.5,7C16.3,7,17,6.3,17,5.5c0-0.6-0.4-1.2-0.9-1.4c1-0.6,2.1-1.2,3.2-1.8L20,4.1c-0.6,0.2-1,0.8-1,1.4
          C19,6.3,19.7,7,20.5,7c0.2,0,0.4,0,0.6-0.1l1,2.4c0,0.1,0,0.1,0,0.2c0,0.5,0.2,0.9,0.6,1.2l2.1,5.3c-0.1,0-0.2,0-0.2,0
          c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5c0.5,0,0.9-0.2,1.2-0.6l0.8,2c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.8,0.7,1.5,1.5,1.5c0,0,0,0,0,0
          l2,5H2z"
      />
      <circle fill={companyColour} cx="13.5" cy="9.5" r="1.5" />
      <circle fill={companyColour} cx="18.5" cy="9.5" r="1.5" />
      <circle fill={companyColour} cx="11.5" cy="13.5" r="1.5" />
      <circle fill={companyColour} cx="9.5" cy="17.5" r="1.5" />
      <circle fill={companyColour} cx="7.5" cy="21.5" r="1.5" />
      <circle fill={companyColour} cx="16.5" cy="13.5" r="1.5" />
      <circle fill={companyColour} cx="14.5" cy="17.5" r="1.5" />
      <circle fill={companyColour} cx="12.5" cy="21.5" r="1.5" />
      <circle fill={companyColour} cx="5.5" cy="25.5" r="1.5" />
      <circle fill={companyColour} cx="19.5" cy="17.5" r="1.5" />
      <circle fill={companyColour} cx="21.5" cy="13.5" r="1.5" />
      <circle fill={companyColour} cx="17.5" cy="21.5" r="1.5" />
      <circle fill={companyColour} cx="10.5" cy="25.5" r="1.5" />
      <circle fill={companyColour} cx="22.5" cy="21.5" r="1.5" />
      <circle fill={companyColour} cx="15.5" cy="25.5" r="1.5" />
      <circle fill={companyColour} cx="20.5" cy="25.5" r="1.5" />
      <circle fill={companyColour} cx="25.5" cy="25.5" r="1.5" />
    </g>
  </svg>
);

EquityIncentives.defaultProps = {
  companyColour: '#ab1923',
};

EquityIncentives.propTypes = {
  companyColour: PropTypes.string,
};

export default EquityIncentives;
