/* eslint-disable import/prefer-default-export */
import { getElementTextContent } from '../support';

export const testMaxChar = (value, maxChar) => {
  if (value) {
    return getElementTextContent(value).length <= maxChar;
  }

  return true;
};
