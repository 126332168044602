import { rubyAxios, pythonAxios, layer1Axios } from './http';

export const getCreditsTotalApi = () =>
  rubyAxios({
    method: 'get',
    url: '/credits.json',
  });

export const getCreditsHistoryApi = () =>
  rubyAxios({
    method: 'get',
    url: '/credits/history.json',
  });

export const getCreditsPackagesApi = () => {
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    return rubyAxios({
      method: 'get',
      url: '/packages.json',
    });
  }
  return layer1Axios({
    method: 'get',
    url: '/packages',
  });
};

export const postChargeCreditsApi = ({ data = {} } = {}) =>
  rubyAxios({
    method: 'post',
    url: '/credits/charge_services.json',
    data,
  });

export const postAddCreditsApi = ({ data = {} } = {}) =>
  rubyAxios({
    method: 'post',
    url: '/supplies/add.json',
    data,
  });

export const postCreditsRejectionRefundApi = ({ jobId, candidateId } = {}) =>
  pythonAxios({
    method: 'post',
    url: `/jobs/${jobId}/${candidateId}/revert_charged_credit`,
  });

export const getCreditsPurchasesApi = () =>
  rubyAxios({
    method: 'get',
    url: '/supplies/company_supplies.json',
  });

export const putPurchaseExpirationApi = ({ data } = {}) =>
  rubyAxios({
    method: 'put',
    url: `/supplies/${data.id}.json`,
    data,
  });
