import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

// Styles
import styles from './layout.module.scss';

const cx = classnames.bind(styles);

const SIDE_LINKS = [
  {
    key: 'general',
    label: 'company_page.general_section.title',
  },
  {
    key: 'displayed',
    label: 'company_page.displayed_section.title',
  },
  {
    key: 'kombo',
    label: 'company_page.kombo_section.title',
  },
];

const Layout = ({ company, children }) => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState();

  const handleOnSideMenuClick = (item) => {
    const element = document.getElementById(item.key);
    const topOffset =
      element.getBoundingClientRect().top + window.pageYOffset - 120;

    window.scrollTo({ top: topOffset, behavior: 'smooth' });
    setActiveItem(item.key);
  };

  return (
    <div className={styles.layout}>
      <div className={styles.sideMenu}>
        <div className={styles.menuWrapper}>
          <div className={styles.menu}>
            {SIDE_LINKS.filter(
              (item) =>
                !(item.key === 'kombo' && !company.enable_kombo_integration),
            ).map((item) => (
              <div
                key={item.key}
                className={cx(styles.menuItem, {
                  [styles.menuItemActive]: item.key === activeItem,
                })}
              >
                <span
                  className={styles.menuItemLink}
                  onClick={() => handleOnSideMenuClick(item)}
                >
                  {t(item.label)}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

Layout.defaultProps = {
  children: null,
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Layout;
