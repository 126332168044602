export const GET_CREDITS_TOTAL_START = 'CREDITS::GET_CREDITS_TOTAL_START';
export const GET_CREDITS_TOTAL_SUCCESS = 'CREDITS::GET_CREDITS_TOTAL_SUCCESS';
export const GET_CREDITS_TOTAL_FAIL = 'CREDITS::GET_CREDITS_TOTAL_FAIL';

export const GET_CREDITS_HISTORY_START = 'CREDITS::GET_CREDITS_HISTORY_START';
export const GET_CREDITS_HISTORY_SUCCESS =
  'CREDITS::GET_CREDITS_HISTORY_SUCCESS';
export const GET_CREDITS_HISTORY_FAIL = 'CREDITS::GET_CREDITS_HISTORY_FAIL';

export const GET_CREDITS_PACKAGES_START = 'CREDITS::GET_CREDITS_PACKAGES_START';
export const GET_CREDITS_PACKAGES_SUCCESS =
  'CREDITS::GET_CREDITS_PACKAGES_SUCCESS';
export const GET_CREDITS_PACKAGES_FAIL = 'CREDITS::GET_CREDITS_PACKAGES_FAIL';

export const REFUND_CREDIT_START = 'CREDITS::REFUND_CREDIT_START';
export const REFUND_CREDIT_SUCCESS = 'CREDITS::REFUND_CREDIT_SUCCESS';
export const REFUND_CREDIT_FAIL = 'CREDITS::REFUND_CREDIT_FAIL';

export const ADD_CREDITS_START = 'CREDITS::ADD_CREDITS_START';
export const ADD_CREDITS_SUCCESS = 'CREDITS::ADD_CREDITS_SUCCESS';
export const ADD_CREDITS_FAIL = 'CREDITS::ADD_CREDITS_FAIL';

export const CHARGE_CREDITS_START = 'CREDITS::CHARGE_CREDITS_START';
export const CHARGE_CREDITS_SUCCESS = 'CREDITS::CHARGE_CREDITS_SUCCESS';
export const CHARGE_CREDITS_FAIL = 'CREDITS::CHARGE_CREDITS_FAIL';

export const GET_CREDITS_PURCHASES_START =
  'CREDITS::GET_CREDITS_PURCHASES_START';
export const GET_CREDITS_PURCHASES_SUCCESS =
  'CREDITS::GET_CREDITS_PURCHASES_SUCCESS';
export const GET_CREDITS_PURCHASES_FAIL = 'CREDITS::GET_CREDITS_PURCHASES_FAIL';

export const UPDATE_PURCHASE_EXPIRATION_START =
  'CREDITS:UPDATE_PURCHASE_EXPIRATION_START';
export const UPDATE_PURCHASE_EXPIRATION_SUCCESS =
  'CREDITS:UPDATE_PURCHASE_EXPIRATION_SUCCESS';
export const UPDATE_PURCHASE_EXPIRATION_FAIL =
  'CREDITS:UPDATE_PURCHASE_EXPIRATION_FAIL';
