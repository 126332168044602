import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Input, SelectV2, Label, Icon } from '@lionstep/ui';
import classnames from 'classnames/bind';

// Constants
import { MULTIPLE_CHOICE_ANSWERS_TYPE } from '../../adminActions.constants';

// Styles
import styles from './questionMultipleChoice.module.scss';

const cx = classnames.bind(styles);

const MULTIPLE_CHOICE_OPTIONS = [
  { label: 'Not Valid', value: MULTIPLE_CHOICE_ANSWERS_TYPE.notValid },
  { label: 'Valid', value: MULTIPLE_CHOICE_ANSWERS_TYPE.valid },
  {
    label: 'Acceptable/Potential',
    value: MULTIPLE_CHOICE_ANSWERS_TYPE.acceptable,
  },
];

const QuestionMultipleChoice = ({
  question,
  questionIndex,
  onQuestionChoiceChange,
  onQuestionChoiceAdd,
  onQuestionChoiceDelete,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid.Row gutter={30} className={styles.multipleChoice}>
        {question.choices.map((cItem, cIndex) => (
          <Grid.Col
            xs={24}
            className={cx(styles.multipleChoiceItem, {
              [styles.multipleChoiceItemDelete]: question.choices.length > 1,
            })}
            key={`multi-choice-choice-${cIndex}`}
            data-testid={`multi-choice-choice-${cIndex}`}
          >
            <Grid.Row gutter={30}>
              <Grid.Col
                xs={question.choices.length > 1 ? 21 : 24}
                sm={question.choices.length > 1 ? 22 : 24}
                md={16}
                className={styles.multipleChoiceAnswer}
              >
                <Label htmlFor={`multi-choice-${questionIndex}`}>
                  {t('answers')}
                </Label>
                <Input
                  name={`multi-choice-choice-${questionIndex}-${cIndex}`}
                  data-testid={`multi-choice-choice-${questionIndex}-${cIndex}`}
                  placeholder={t('Type your answer here')}
                  value={cItem.choice}
                  onChange={(e) =>
                    onQuestionChoiceChange(
                      questionIndex,
                      cIndex,
                      e.target.value,
                      cItem.expected_str,
                    )
                  }
                />
              </Grid.Col>
              <Grid.Col
                xs={question.choices.length > 1 ? 21 : 24}
                sm={question.choices.length > 1 ? 22 : 24}
                md={question.choices.length > 1 ? 7 : 8}
                className={styles.multipleChoiceAnswerType}
              >
                <Label
                  htmlFor={`multi-choice-expected-${questionIndex}-${cIndex}`}
                >
                  {t('key_points.questions.type_of_answer')}
                </Label>
                <SelectV2
                  options={MULTIPLE_CHOICE_OPTIONS}
                  value={MULTIPLE_CHOICE_OPTIONS.find(
                    (option) => option.value === cItem.expected_str,
                  )}
                  name={`multi-choice-expected-${questionIndex}-${cIndex}`}
                  data-testid={`multi-choice-expected-${questionIndex}-${cIndex}`}
                  onChange={(item) =>
                    onQuestionChoiceChange(
                      questionIndex,
                      cIndex,
                      cItem.choice,
                      item.value,
                    )
                  }
                />
              </Grid.Col>
              {question.choices.length > 1 && (
                <span className={styles.deleteAnswerWrapper}>
                  <span
                    className={styles.deleteAnswer}
                    data-testid={`multi-choice-remove-${questionIndex}-${cIndex}`}
                    onClick={() =>
                      onQuestionChoiceDelete(questionIndex, cIndex)
                    }
                  >
                    <Icon name="CloseCircle" />
                  </span>
                </span>
              )}
            </Grid.Row>
          </Grid.Col>
        ))}
      </Grid.Row>

      <Grid.Row>
        <Grid.Col xs={24}>
          <div className={styles.addAnswerBtnWrapper}>
            <span
              className={styles.addAnswerBtn}
              onClick={() => onQuestionChoiceAdd(questionIndex)}
            >
              <Icon className={styles.addAnswerBtnIcon} name="Plus" />
              {t('Add Answer')}
            </span>
          </div>
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

QuestionMultipleChoice.propTypes = {
  question: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    hide: PropTypes.bool.isRequired,
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        choice: PropTypes.string.isRequired,
        expected_str: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  questionIndex: PropTypes.number.isRequired,
  onQuestionChoiceChange: PropTypes.func.isRequired,
  onQuestionChoiceAdd: PropTypes.func.isRequired,
  onQuestionChoiceDelete: PropTypes.func.isRequired,
};

export default QuestionMultipleChoice;
