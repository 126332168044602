export const USER_ROLES = {
  viewer: 'Viewer',
  admin: 'SiteAdministrator',
};

export const PUBLIC_ROUTES = [
  '/login',
  '/password-reset',
  '/register',
  '/auth',
  '/invite',
  '/browser-outdated',
];
