import React from 'react';
import PropTypes from 'prop-types';

const CommutingStiped = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 68 68"
    className={className}
  >
    <g id="Grey">
      <path
        d="M62,37.1v6.8c0,1.2,0.9,2.1,2,2.1h2c1.1,0,2-1,2-2.1v-6.8c0-1.2-0.9-2.1-2-2.1c0-1.7-1.5-3-3.3-3H59v-2v-4
          c0,0,0,0,0,0c0-0.2,0-0.4,0-0.6c-0.4-8.4-8.7-9.4-16-9.4s-15.6,1.1-16,9.4c0,0.2,0,0.4,0,0.6c0,0,0,0,0,0v4v2h-3.7
          c-1.8,0-3.3,1.3-3.3,3c-1.1,0-2,1-2,2.1v6.8c0,1.2,0.9,2.1,2,2.1h2c1.1,0,2-1,2-2.1v-6.8c0-1.2-0.9-2.1-2-2.1c0-0.5,0.6-1,1.3-1H27
          v13v10.7c0,1.7,1.3,3.1,3,3.2v3.7c0,0.5,0.1,0.9,0.3,1.3H22v-1.5c0-1.9-1.4-3.5-3.2-3.5H14V34.3c0-0.6-0.4-1-1-1s-1,0.4-1,1V61h-2
          V34c0-0.6-0.4-1-1-1s-1,0.4-1,1v27H3.2C1.4,61,0,62.6,0,64.5V67c0,0.6,0.4,1,1,1h20h12.4h1.2h16.8h1.2H67c0.6,0,1-0.4,1-1
          s-0.4-1-1-1H55.7c0.2-0.4,0.3-0.9,0.3-1.3V61c1.7-0.2,3-1.5,3-3.2V47V34h3.7c0.7,0,1.3,0.5,1.3,1C62.9,35,62,36,62,37.1z M22,43.9
          c0,0.1,0,0.1,0,0.1L20,44c0,0,0-0.1,0-0.1v-6.8c0-0.1,0-0.1,0-0.1l0.9,0c0,0,0,0,0,0c0,0,0,0,0,0l0.9,0c0,0,0,0.1,0,0.1V43.9z
          M57,27v2H29v-2H57z M29,31h28v15H29V31z M43,18c9.3,0,13.4,2,13.9,7H29.1C29.5,20,33.6,18,43,18z M2,66v-1.5C2,63.7,2.5,63,3.2,63
          h15.6c0.7,0,1.2,0.7,1.2,1.5V66H2z M33.4,66c-0.8,0-1.4-0.6-1.4-1.3V61h4v3.7c0,0.7-0.6,1.3-1.4,1.3H33.4z M48,61v3.7
          c0,0.5,0.1,0.9,0.3,1.3H37.7c0.2-0.4,0.3-0.9,0.3-1.3V61H48z M51.4,66c-0.8,0-1.4-0.6-1.4-1.3V61h4v3.7c0,0.7-0.6,1.3-1.4,1.3H51.4
          z M55.7,59H30.3c-0.7,0-1.3-0.6-1.3-1.3V48h28v9.7C57,58.4,56.4,59,55.7,59z M64,37.1c0-0.1,0-0.1,0-0.1l0.9,0c0,0,0,0,0,0
          c0,0,0,0,0,0l0.9,0c0,0,0,0.1,0,0.1v6.8c0,0.1,0,0.1,0,0.1L64,44c0,0,0-0.1,0-0.1V37.1z"
        fill="#899198"
      />
      <path
        d="M40,23h6c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1S39.4,23,40,23z"
        fill="#899198"
      />
      <path
        d="M16.2,7H29v0c-1.7,0.1-3,1.7-3,3.5c0,1.9,1.5,3.5,3.2,3.5h31.5c1.8,0,3.2-1.6,3.2-3.5C64,8.6,62.5,7,60.8,7H48
          v0c0.8-0.1,1.5-0.4,2.1-1C50.7,5.3,51,4.4,51,3.5C51,1.6,49.5,0,47.8,0H16.3c0,0,0,0,0,0c-0.9,0-1.7,0.4-2.3,1.1
          C13.3,1.7,13,2.6,13,3.5C13,5.4,14.5,7,16.2,7z M15.4,2.4C15.6,2.1,15.9,2,16.2,2h31.5C48.4,2,49,2.7,49,3.5c0,0.4-0.1,0.8-0.4,1.1
          C48.4,4.9,48.1,5,47.8,5H47c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1h13.7c0.7,0,1.3,0.7,1.3,1.5c0,0.8-0.6,1.5-1.2,1.5H29.3
          c-0.7,0-1.3-0.7-1.3-1.5C28,9.7,28.6,9,29.2,9H30c0.6,0,1-0.4,1-1V6c0-0.6-0.4-1-1-1H16.3C15.6,5,15,4.3,15,3.5
          C15,3.1,15.1,2.7,15.4,2.4z"
        fill="#899198"
      />
    </g>
    <g id="Coloured">
      <path
        d="M39.7,51.3c-0.3-0.8-1.1-1.3-1.9-1.3h-3.7c-1.2,0-2.1,1-2.1,2.2v2.5c0,1.2,0.9,2.2,2.1,2.2h4.8c0,0,0,0,0,0
          c0.7,0,1.4-0.4,1.8-1.1c0.3-0.6,0.4-1.4,0.1-2.1L39.7,51.3z M38.9,55l-4.8,0c0,0-0.1-0.1-0.1-0.3v-2.5c0-0.2,0.1-0.2,0.1-0.2l3.7,0
          c0,0,0,0,0.1,0.1l1.1,2.5C39,54.7,39,54.8,38.9,55z"
        fill={companyColour}
      />
      <path
        d="M51.9,50h-3.7c-0.8,0-1.6,0.5-1.9,1.3l-1.1,2.5c-0.3,0.7-0.3,1.5,0.1,2.1c0.4,0.7,1.1,1.1,1.8,1.1c0,0,0,0,0,0
          h4.8c1.2,0,2.1-1,2.1-2.2v-2.5C54,51,53.1,50,51.9,50z M52,54.8c0,0.2-0.1,0.2-0.1,0.2h-4.8v1L47,54.9c-0.1-0.1-0.1-0.2,0-0.3
          l1.1-2.5c0-0.1,0.1-0.1,0.1-0.1l3.7,0c0,0,0.1,0.1,0.1,0.3V54.8z"
        fill={companyColour}
      />
      <path
        d="M33,39c0.3,0,0.5-0.1,0.7-0.3l3-3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-3,3c-0.4,0.4-0.4,1,0,1.4
          C32.5,38.9,32.7,39,33,39z"
      />
      <path
        d="M42.3,34.3l-7,7c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l7-7c0.4-0.4,0.4-1,0-1.4
          S42.7,33.9,42.3,34.3z"
        fill={companyColour}
      />
      <path
        d="M14,23.7c0.6-0.7,1-1.6,1-2.7c0-2.2-1.8-4-4-4H9.2C8,17,7,18,7,19.2V24v4.8C7,30,8.1,31,9.4,31h2.1
          c2.5,0,4.5-1.8,4.5-4C16,25.6,15.2,24.4,14,23.7z M9,19.2C9,19.1,9.1,19,9.2,19H11c1.1,0,2,0.9,2,2s-0.9,2-2,2H9V19.2z M11.5,29
          H9.4C9.1,29,9,28.9,9,28.8V25h2h0.5c1.4,0,2.5,0.9,2.5,2S12.9,29,11.5,29z"
        fill={companyColour}
      />
      <path
        d="M11,13C4.9,13,0,17.9,0,24c0,6.1,4.9,11,11,11s11-4.9,11-11C22,17.9,17.1,13,11,13z M11,33c-5,0-9-4-9-9
          c0-5,4-9,9-9s9,4,9,9C20,29,16,33,11,33z"
        fill={companyColour}
      />
    </g>
  </svg>
);

CommutingStiped.defaultProps = {
  companyColour: '#ab1923',
};

CommutingStiped.propTypes = {
  companyColour: PropTypes.string,
};

export default CommutingStiped;
