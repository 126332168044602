import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import i18n from 'i18next';
import { Spinner } from '@lionstep/ui';

// Actions
import { setAppLanguage } from '../../../modules/app/redux/actions/language.actions';
import {
  getCompany,
  getCreditsTotal,
} from '../../../modules/company/redux/actions';

// Selectors
import { totalCreditsSelector } from '../../../modules/company/redux/selectors/credits.selectors';
import { isLoadingCompanySelector } from '../../../modules/company/redux/selectors/company.selectors';

// Components
import HeaderPostLogin from '../header/HeaderPostLogin';

// Queries
import { useUserQuery } from '../../../queries/userQueries';

// Styles
import styles from './postLoginLayout.module.scss';

const PostLoginLayout = ({ children }) => {
  const dispatch = useDispatch();

  /**
   * This is temporary hack for solving next problem:
   * - At first render, language is not picked from cookie and set to i18n
   * - After language is picked app from cookie and 128n is updated we have issue:
   *   - If we keep some translated values in useState, rerender will not update translation in useState value
   * - This way we are saying do not render anything until i18n language is updated
   *
   * This is temporary solution and needs to be moved on top level in App component
   */
  const [languageUpdated, setLanguageUpdated] = useState(false);

  const { data: user } = useUserQuery();
  const totalCredits = useSelector(totalCreditsSelector);
  const isLoadingCompany = useSelector(isLoadingCompanySelector);

  // Credentials
  useEffect(() => {
    dispatch(getCompany(user.company_id));
    dispatch(getCreditsTotal());
  }, [dispatch]);

  // Set language from cookie or invite link
  useEffect(() => {
    const cookies = new Cookies();
    const cookieLang = cookies.get('selectedLanguage');

    if (cookieLang) {
      i18n.changeLanguage(cookieLang);
      dispatch(setAppLanguage(cookieLang));
    }

    setLanguageUpdated(true);
  }, [dispatch]);

  if (!languageUpdated) return null;

  if (isLoadingCompany) return <Spinner size={100} position="absolute" />;

  return (
    <div className={styles.postLoginLayout}>
      <HeaderPostLogin
        className={styles.header}
        user={user}
        totalCredits={totalCredits}
      />
      <main className={styles.content}>{children}</main>
    </div>
  );
};

PostLoginLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default PostLoginLayout;
