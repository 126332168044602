import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { Grid, SelectMultiple, Label } from '@lionstep/ui';
import differenceBy from 'lodash/differenceBy';

// Actions
import { getAllSkills, clearAllSkills } from '../../../../redux/actions';

// Constants
import { MULTIPLE_CHOICE_ANSWERS_TYPE } from '../../adminActions.constants';

const QuestionSkills = ({
  question,
  questionIndex,
  onQuestionChoiceAdd,
  onQuestionChoiceDelete,
  skills,
  isLoadingSkills,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Helpers
  const mapSkillsToOptions = () =>
    skills.map((skill) => ({
      label: skill.name,
      value: skill.name,
    }));

  const mapSkillsChoicesToValue = () =>
    question.choices.map((choice) => ({
      label: choice.choice,
      value: choice.choice,
    }));

  const skillElementDiff = (currentSkills, newSkills) => {
    const skillDiff =
      newSkills.length > currentSkills.length
        ? differenceBy(newSkills, currentSkills, 'value')
        : differenceBy(currentSkills, newSkills, 'value');
    const skillIndex = skillDiff.length
      ? currentSkills.findIndex((item) => item.value === skillDiff[0].value)
      : -1;

    if (skillIndex === -1) {
      return {
        skill: skillDiff[0],
        skillIndex,
      };
    }

    return {
      skill: skillDiff[0],
      skillIndex,
    };
  };

  const [selectedSkills, setSelectedSkills] = useState(
    mapSkillsChoicesToValue(),
  );
  /**
   * This is over engineering but at this moment this is best solution
   *
   * @param {Array} values
   */
  const handleOnSelect = (newSkills) => {
    const { skill, skillIndex } = skillElementDiff(selectedSkills, newSkills);

    if (skillIndex === -1) {
      setSelectedSkills((currState) => [...currState, skill]);
      onQuestionChoiceAdd(
        questionIndex,
        skill.value,
        MULTIPLE_CHOICE_ANSWERS_TYPE.valid,
      );
    } else {
      setSelectedSkills((currState) =>
        currState.filter((_, index) => index !== skillIndex),
      );
      onQuestionChoiceDelete(questionIndex, skillIndex);
    }
  };

  const handleOnBlur = () => {
    dispatch(clearAllSkills());
  };

  const handleOnSearch = debounce(
    (value) => dispatch(getAllSkills(value)),
    500,
  );

  return (
    <Grid.Row>
      <Grid.Col xs={24}>
        <Label htmlFor={`skills-${questionIndex}`}>{t('skills')}</Label>
        {/* Refactor to select v2 once "creatable" part is implemanted */}
        <SelectMultiple
          autocomplete
          creatable
          placeholder={t('Search Skills')}
          options={mapSkillsToOptions()}
          loading={isLoadingSkills}
          showTags
          tagsPosition="top"
          value={selectedSkills}
          onSearch={handleOnSearch}
          onSelect={handleOnSelect}
          onBlur={handleOnBlur}
        />
      </Grid.Col>
    </Grid.Row>
  );
};

QuestionSkills.propTypes = {
  question: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    hide: PropTypes.bool.isRequired,
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        choice: PropTypes.string.isRequired,
        expected_str: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  questionIndex: PropTypes.number.isRequired,
  onQuestionChoiceAdd: PropTypes.func.isRequired,
  onQuestionChoiceDelete: PropTypes.func.isRequired,
  skills: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoadingSkills: PropTypes.bool.isRequired,
};

export default QuestionSkills;
