import React from 'react';
import PropTypes from 'prop-types';

const CustomHealthBonus = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 72"
    className={className}
  >
    <title>01</title>
    <g id="Coloured_1_">
      <path
        fill={companyColour}
        d="M15,30H7c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2v-3C17,30.9,16.1,30,15,30z M15,35l-8,0l0-3
          l8,0L15,35z"
      />
      <path
        fill={companyColour}
        d="M32,23h-3c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h3c1.1,0,2-0.9,2-2v-3C34,23.9,33.1,23,32,23z M32,28l-3,0l0-3
          l3,0L32,28z"
      />
      <path
        fill={companyColour}
        d="M38,25v3c0,1.1,0.9,2,2,2h3c1.1,0,2-0.9,2-2v-3c0-1.1-0.9-2-2-2h-3C38.9,23,38,23.9,38,25z M43,25l0,3l-3,0
          l0-3L43,25z"
      />
      <path
        fill={companyColour}
        d="M32,33h-3c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h3c1.1,0,2-0.9,2-2v-3C34,33.9,33.1,33,32,33z M32,38l-3,0l0-3
          l3,0L32,38z"
      />
      <path
        fill={companyColour}
        d="M32,43h-3c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h3c1.1,0,2-0.9,2-2v-3C34,43.9,33.1,43,32,43z M32,48l-3,0l0-3
          l3,0L32,48z"
      />
      <path
        fill={companyColour}
        d="M43,43h-3c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h3c1.1,0,2-0.9,2-2v-3C45,43.9,44.1,43,43,43z M43,48l-3,0l0-3
          l3,0L43,48z"
      />
      <path
        fill={companyColour}
        d="M40,40h3c1.1,0,2-0.9,2-2v-3c0-1.1-0.9-2-2-2h-3c-1.1,0-2,0.9-2,2v3C38,39.1,38.9,40,40,40z M40,35l3,0l0,3
          l-3,0L40,35z"
      />
      <path
        fill={companyColour}
        d="M15,40H7c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2v-3C17,40.9,16.1,40,15,40z M15,45l-8,0l0-3
          l8,0L15,45z"
      />
      <path
        fill={companyColour}
        d="M15,50H7c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2v-3C17,50.9,16.1,50,15,50z M15,55l-8,0l0-3
          l8,0L15,55z"
      />
      <path
        fill={companyColour}
        d="M65,30h-8c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2v-3C67,30.9,66.1,30,65,30z M65,35l-8,0l0-3
          l8,0L65,35z"
      />
      <path
        fill={companyColour}
        d="M65,40h-8c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2v-3C67,40.9,66.1,40,65,40z M65,45l-8,0l0-3
          l8,0L65,45z"
      />
      <path
        fill={companyColour}
        d="M65,50h-8c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2v-3C67,50.9,66.1,50,65,50z M65,55l-8,0l0-3
          l8,0L65,55z"
      />
      <path
        fill={companyColour}
        d="M40,16c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3v-3c0-0.6-0.4-1-1-1s-1,0.4-1,1v3h-3c-0.6,0-1,0.4-1,1s0.4,1,1,1h3v3
          c0,0.6,0.4,1,1,1s1-0.4,1-1v-3H40z"
      />
    </g>
    <path
      id="Grey_1_"
      fill="#899198"
      d="M70,22H52c0,0,0,0,0,0V7c0,0,0,0,0,0c1.1,0,2-0.9,2-2V2c0-1.1-0.9-2-2-2H20c-1.1,0-2,0.9-2,2v3
        c0,1.1,0.9,2,2,2c0,0,0,0,0,0v15c0,0,0,0,0,0H2c-1.1,0-2,0.9-2,2v41c0,1.1,0.9,2,2,2h18c0,0,0,0,0,0v3c0,1.1,0.9,2,2,2h7h14h7
        c1.1,0,2-0.9,2-2v-3c0,0,0,0,0,0h18c1.1,0,2-0.9,2-2V24C72,22.9,71.1,22,70,22z M20,2l32,0l0,3L20,5L20,2z M20,65L2,65l0-41l18,0
        L20,65z M36,64c0.6,0,1-0.4,1-1v-7l6,0l0,14l-6,0v-2c0-0.6-0.4-1-1-1s-1,0.4-1,1v2l-6,0l0-14l6,0v7C35,63.6,35.4,64,36,64z M45,70
        C45,70,45,70,45,70l0-14c0-1.1-0.9-2-2-2H29c-1.1,0-2,0.9-2,2v14c0,0,0,0,0,0l-5,0l0-63l28,0l0,63L45,70z M70,65l-18,0l0-41l18,0
        L70,65z"
    />
  </svg>
);

CustomHealthBonus.defaultProps = {
  companyColour: '#ab1923',
};

CustomHealthBonus.propTypes = {
  companyColour: PropTypes.string,
};

export default CustomHealthBonus;
