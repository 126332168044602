import React from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';

// Components
import { ReactComponent as EnglishFlag } from '../../../../assets/icons/flags/english.svg';
import { ReactComponent as GermanFlag } from '../../../../assets/icons/flags/deutsch.svg';
import { ReactComponent as SpanishFlag } from '../../../../assets/icons/flags/spanish.svg';
import { ReactComponent as FrenchFlag } from '../../../../assets/icons/flags/french.svg';

// Actions
import { setAppLanguage } from '../../../../modules/app/redux/actions/language.actions';

// Constants
const FLAGS = {
  en: <EnglishFlag />,
  de: <GermanFlag />,
  es: <SpanishFlag />,
  fr: <FrenchFlag />,
};

const useLanguageDropdown = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const LANGUAGES_ITEMS = [
    {
      label: t('english'),
      languageCode: 'en',
      flag: FLAGS.en,
    },
    {
      label: t('german'),
      languageCode: 'de',
      flag: FLAGS.de,
    },
    {
      label: t('spanish'),
      languageCode: 'es',
      flag: FLAGS.es,
    },
    {
      label: t('french'),
      languageCode: 'fr',
      flag: FLAGS.fr,
    },
  ];

  const handleOnLanguageMenuItemClick = (languageCode) => {
    if (i18n.language !== languageCode) {
      const cookies = new Cookies();

      i18n.changeLanguage(languageCode);
      cookies.set('selectedLanguage', languageCode);
      dispatch(setAppLanguage(languageCode));
    }
  };

  return { items: LANGUAGES_ITEMS, FLAGS, handleOnLanguageMenuItemClick };
};

export default useLanguageDropdown;
