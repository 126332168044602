const WINDOW_RESIZE = 'WINDOW_RESIZE';
const SET_ARCHIVED = 'SET_ARCHIVED';
const RESET_ARCHIVED = 'RESET_ARCHIVED';
const SAVE_NEW_TOKEN = 'SAVE_NEW_TOKEN';
const SET_JOBS_NUMBER = 'SET_JOBS_NUMBER';

export default {
  WINDOW_RESIZE,
  SAVE_NEW_TOKEN,
  SET_ARCHIVED,
  RESET_ARCHIVED,
  SET_JOBS_NUMBER,
};
