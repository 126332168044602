import { combineReducers } from 'redux';
import types from './types';

/* State shape

{
    redirectTo: string,
    busy: {
        general: bool,
        page: bool
    }
}

*/

// const redirectReducer = ( state = "", action ) => {
//     if ( action.type.endsWith( "_REDIRECTED" ) ) {
//         return action.payload;
//     }
//
//     return state;
// };

const generalReducer = (state = 0, action) => {
  if (action.type.endsWith('_REDIRECTED')) {
    return state - 1;
  }

  if (!action.async || !action.generalFetching) {
    return state;
  }

  if (action.type.endsWith('_COMPLETED') || action.type.endsWith('_FAILED')) {
    return state - 1;
  }

  return state + 1;
};

const contactingCandidateReducer = (state = false, action) => {
  if (action.type === 'ADD_TO_IN_CONTACT') {
    return true;
  }
  if (
    action.type === 'ADD_TO_IN_CONTACT_COMPLETED' ||
    action.type === 'ADD_TO_IN_CONTACT_FAILED'
  ) {
    return false;
  }
  return state;
};

const verifyingOobCodeReducer = (state = false, action) => {
  if (action.type === 'VERIFY_OOB_CODE') {
    return true;
  }
  if (
    action.type === 'VERIFY_OOB_CODE_COMPLETED' ||
    action.type === 'VERIFY_OOB_CODE_FAILED'
  ) {
    return false;
  }
  return state;
};

const gettingQuestionsReducer = (state = false, action) => {
  if (action.type === 'GET_QUESTIONS') {
    return true;
  }
  if (
    action.type === 'GET_QUESTIONS_COMPLETED' ||
    action.type === 'GET_QUESTIONS_FAILED'
  ) {
    return false;
  }
  return state;
};

const gettingSignupReducer = (state = false, action) => {
  if (action.type === 'GET_SIGNUP') {
    return true;
  }
  if (
    action.type === 'GET_SIGNUP_COMPLETED' ||
    action.type === 'GET_SIGNUP_FAILED'
  ) {
    return false;
  }
  return state;
};

const savingCompanyPicReducer = (state = false, action) => {
  if (action.type === 'SAVE_COMPANY_PIC') {
    return true;
  }
  if (
    action.type === 'SAVE_COMPANY_PIC_COMPLETED' ||
    action.type === 'SAVE_COMPANY_PIC_FAILED'
  ) {
    return false;
  }
  return state;
};

const gettingCollaboratorsReducer = (state = false, action) => {
  if (action.type === 'GET_COLLABORATORS') {
    return true;
  }
  if (action.type === 'GET_COLLABORATORS_COMPLETED') {
    return false;
  }
  return state;
};

const gettingUsersReducer = (state = false, action) => {
  if (action.type === 'GET_JOB_USERS') {
    return true;
  }
  if (action.type === 'GET_JOB_USERS_COMPLETED') {
    return false;
  }
  return state;
};

const shortlistCandidateReducer = (state = false, action) => {
  if (
    action.type === 'ADD_TO_SHORTLIST' ||
    action.type === 'REMOVE_FROM_SHORTLIST'
  ) {
    return true;
  }
  if (
    action.type === 'ADD_TO_SHORTLIST_COMPLETED' ||
    action.type === 'ADD_TO_SHORTLIST_FAILED' ||
    action.type === 'REMOVE_FROM_SHORTLIST_COMPLETED' ||
    action.type === 'REMOVE_FROM_SHORTLIST_FAILED'
  ) {
    return false;
  }
  return state;
};

const selectedForContactReducer = (state = false, action) => {
  if (action.type === 'ADD_TO_IN_CONTACT') {
    return action.payload.body[0];
  }
  if (
    action.type === 'ADD_TO_IN_CONTACT_COMPLETED' ||
    action.type === 'ADD_TO_IN_CONTACT_FAILED'
  ) {
    return '';
  }
  return state;
};

const selectedForBadMatchReducer = (state = false, action) => {
  if (action.type === 'REPORT_BAD_MATCH') {
    return action.payload.body.talent_ids[0];
  }
  if (
    action.type === 'REPORT_BAD_MATCH_COMPLETED' ||
    action.type === 'REPORT_BAD_MATCH_FAILED'
  ) {
    return '';
  }
  return state;
};

const windowSizeReducer = (state = 0, action) => {
  if (action.type === types.WINDOW_RESIZE) {
    return action.payload;
  }
  return state;
};

const flowErrors = (state = {}, action) => {
  if (action.type === 'GET_TALENTS_FAILED') {
    const newState = { ...state };
    newState.talentsFetchingError = action.payload.response.data;
    return newState;
  }
  return state;
};

const findingTalentsReducer = (state = false, action) => {
  if (
    action.type === 'SAVE_JOB' ||
    action.type === 'GET_TALENTS' ||
    action.type === 'GET_PAGINATED_TALENTS'
  ) {
    return true;
  }
  if (
    action.type === 'GET_TALENTS_COMPLETED' ||
    action.type === 'GET_TALENTS_FAILED'
  ) {
    return false;
  }
  if (
    action.type === 'GET_PAGINATED_TALENTS_COMPLETED' ||
    action.type === 'GET_PAGINATED_TALENTS_FAILED'
  ) {
    return false;
  }
  return state;
};

const gettingJobsReducer = (state = false, action) => {
  if (action.type === 'GET_JOBS') {
    return true;
  }
  if (
    action.type === 'GET_JOBS_COMPLETED' ||
    action.type === 'GET_JOBS_FAILED'
  ) {
    return false;
  }
  return state;
};

const reportingBadMatchReducer = (state = false, action) => {
  if (action.type === 'REPORT_BAD_MATCH') {
    return true;
  }
  if (
    action.type === 'REPORT_BAD_MATCH_COMPLETED' ||
    action.type === 'REPORT_BAD_MATCH_FAILED'
  ) {
    return false;
  }
  return state;
};

const savingCompanyInfoReducer = (state = false, action) => {
  if (action.type === 'UPDATE_COMPANY_INFO') {
    return true;
  }
  if (
    action.type === 'UPDATE_COMPANY_INFO_COMPLETED' ||
    action.type === 'GUPDATE_COMPANY_INFO_FAILED'
  ) {
    return false;
  }
  return state;
};

const gettingCreditsReducer = (state = false, action) => {
  if (action.type === 'GET_CREDITS_TOTAL') {
    return true;
  }
  if (
    action.type === 'GET_CREDITS_TOTAL_COMPLETED' ||
    action.type === 'GET_CREDITS_TOTAL_FAILED'
  ) {
    return false;
  }
  return state;
};

const archivedReducer = (state = false, action) => {
  if (action.type === 'SET_ARCHIVED') {
    return true;
  }
  if (action.type === 'RESET_ARCHIVED') {
    return false;
  }
  return state;
};

const jobsNumberReducer = (state = 0, action) => {
  if (action.type === 'SET_JOBS_NUMBER') {
    return action.payload;
  }
  if (action.type === 'GET_JOBS_COMPLETED') {
    return 0;
  }
  if (action.type === 'SET_ARCHIVED') {
    return 0;
  }
  return state;
};

const jobsNoReducer = (state = 0, action) => {
  if (action.type === 'GET_JOBS_COMPLETED') {
    return action.payload.data.count;
  }
  return state;
};

const busy = combineReducers({
  general: generalReducer,
});

const applicationReducer = combineReducers({
  busy,
  contactingCandidate: contactingCandidateReducer,
  verifyingOobCode: verifyingOobCodeReducer,
  shortlistCandidate: shortlistCandidateReducer,
  selectedForContact: selectedForContactReducer,
  selectedForBadMatch: selectedForBadMatchReducer,
  gettingCollaborators: gettingCollaboratorsReducer,
  windowSize: windowSizeReducer,
  findingTalents: findingTalentsReducer,
  gettingJobs: gettingJobsReducer,
  gettingUsers: gettingUsersReducer,
  gettingCredits: gettingCreditsReducer,
  archived: archivedReducer,
  jobsNumber: jobsNumberReducer,
  jobsNo: jobsNoReducer,
  savingCompanyInfo: savingCompanyInfoReducer,
  reportingBadMatch: reportingBadMatchReducer,
  savingCompanyPic: savingCompanyPicReducer,
  gettingQuestions: gettingQuestionsReducer,
  gettingSignup: gettingSignupReducer,
  flowErrors,
});

export default applicationReducer;
