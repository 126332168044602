import { toast } from '@lionstep/ui';

// Types
import * as types from './settings.types';

// Api
import {
  getJobsTitlesApi,
  addJobMailingPermissionApi,
  removeJobMailingPermissionApi,
  deleteJobUserApi,
  postChangeJobOwnerApi,
} from '../../../../api/job.api';
import { getCollaboratorsApi } from '../../../../api/user.api';

export const getJobsTitles = () => async (dispatch) => {
  try {
    dispatch({ type: types.GET_JOBS_TITLES_START });

    const { data } = await getJobsTitlesApi();

    dispatch({ type: types.GET_JOBS_TITLES_SUCCESS, payload: data.results });
  } catch (error) {
    dispatch({ type: types.GET_JOBS_TITLES_FAIL });
    toast.error('Error while fetching jobs', {
      id: types.GET_JOBS_TITLES_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const getCollaborators = () => async (dispatch) => {
  try {
    dispatch({ type: types.GET_COLLABORATORS_START });

    const { data } = await getCollaboratorsApi();

    dispatch({
      type: types.GET_COLLABORATORS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: types.GET_COLLABORATORS_FAIL });
    toast.error('Error while fetching collaborators', {
      id: types.GET_COLLABORATORS_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const addMailingPermission = (data, userRefetch) => async (dispatch) => {
  try {
    dispatch({ type: types.ADD_MAILING_PERMISSION_START });

    await addJobMailingPermissionApi({ data });

    dispatch({ type: types.ADD_MAILING_PERMISSION_SUCCESS });
    // Temporary until we switch mailing permission to queries
    userRefetch();
  } catch (error) {
    dispatch({ type: types.ADD_MAILING_PERMISSION_FAIL });
    toast.error(error.response?.data?.message, {
      id: types.ADD_MAILING_PERMISSION_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const removeMailingPermission =
  (data, userRefetch) => async (dispatch) => {
    try {
      dispatch({ type: types.REMOVE_MAILING_PERMISSION_START });

      await removeJobMailingPermissionApi({ data });

      dispatch({ type: types.REMOVE_MAILING_PERMISSION_SUCCESS });
      // Temporary until we switch mailing permission to queries
      userRefetch();
    } catch (error) {
      dispatch({ type: types.REMOVE_MAILING_PERMISSION_FAIL });
      toast.error(error.response?.data?.message, {
        id: types.REMOVE_MAILING_PERMISSION_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const postChangeJobOwner = (data) => async (dispatch) => {
  try {
    dispatch({ type: types.CHANGE_JOB_OWNER_START });

    await postChangeJobOwnerApi({ data });

    dispatch({ type: types.CHANGE_JOB_OWNER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: types.CHANGE_JOB_OWNER_FAIL });
    toast.error('Error while changing owner', {
      id: types.CHANGE_JOB_OWNER_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const removeJobCollaborator = (data) => async (dispatch) => {
  try {
    dispatch({ type: types.REMOVE_JOB_COLLABORATOR_START });

    await deleteJobUserApi(data);

    dispatch({
      type: types.REMOVE_JOB_COLLABORATOR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: types.REMOVE_JOB_COLLABORATOR_FAIL });
    toast.error('Error while leaving job', {
      id: types.REMOVE_JOB_COLLABORATOR_FAIL,
      statusCode: error?.response?.status,
    });
  }
};
