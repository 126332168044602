import React, { useState, createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Label, ButtonLink, FormEditor, FormTextarea } from '@lionstep/ui';
import classnames from 'classnames/bind';

// Helpers
import { languageShorthandToFull } from '../../../../../../support';

// Styles
import styles from './showHideTranslation.module.scss';

const cx = classnames.bind(styles);

// Context
const ShowHideTranslationContext = createContext();

const useShowHideTranslation = () => {
  const context = useContext(ShowHideTranslationContext);

  if (context === undefined) {
    throw new Error(
      'useShowHideTranslation must be used within a ShowHideTranslationProvider',
    );
  }

  return context;
};

const ShowHideTranslationItem = ({
  index,
  isEditor,
  translationPlaceholder,
  language,
  name,
  ...restProps
}) => {
  const { showTranslations, onTranslationsToggle } = useShowHideTranslation();

  const textAreaClassNames = cx(styles.textArea, {
    hideTextarea: !showTranslations && index !== 0,
  });
  const flagClassNames = cx(styles.flag, {
    showPlaceholder: !showTranslations && index !== 0,
  });
  const translationPlaceholderClassNames = cx(styles.translationPlaceholder, {
    showPlaceholder: !showTranslations,
  });

  return (
    <div className={styles.translations}>
      <div className={styles.container}>
        {isEditor ? (
          <FormEditor
            formItemClassName={textAreaClassNames}
            name={name}
            {...restProps}
          />
        ) : (
          <FormTextarea
            formItemClassName={textAreaClassNames}
            name={name}
            {...restProps}
          />
        )}

        {index !== 0 && (
          <div
            className={translationPlaceholderClassNames}
            onClick={onTranslationsToggle}
          >
            {translationPlaceholder?.replace(/(<([^>]+)>)/gi, '')}
          </div>
        )}

        <img
          alt={language}
          className={flagClassNames}
          src={`/flags/${languageShorthandToFull(language)}.svg`}
        />
      </div>
    </div>
  );
};

ShowHideTranslationItem.defaultProps = {
  isEditor: false,
};

ShowHideTranslationItem.propTypes = {
  index: PropTypes.number.isRequired,
  isEditor: PropTypes.bool,
  translationPlaceholder: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const ShowHideTranslationLabel = ({ children, ...restProps }) => (
  <Label {...restProps}>{children}</Label>
);

ShowHideTranslationLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const ShowHideTranslation = ({ children, className, ...restProps }) => {
  const [showTranslations, setShowTranslations] = useState(false);

  const handleOnTranslationsToggle = () => {
    setShowTranslations((curr) => !curr);
  };

  const value = useMemo(
    () => ({
      showTranslations,
      onTranslationsToggle: setShowTranslations,
    }),
    [showTranslations, setShowTranslations],
  );

  return (
    <div className={cx(styles.translationContainer, className)} {...restProps}>
      <ShowHideTranslationContext.Provider value={value}>
        {children}
        <ButtonLink onClick={handleOnTranslationsToggle}>
          {showTranslations ? 'Hide Translations' : 'Show Translations'}
        </ButtonLink>
      </ShowHideTranslationContext.Provider>
    </div>
  );
};

ShowHideTranslation.defaultProps = {
  className: '',
};

ShowHideTranslation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

ShowHideTranslation.Label = ShowHideTranslationLabel;
ShowHideTranslation.Label.displayName = 'ShowHideTranslationLabel';
ShowHideTranslation.Item = ShowHideTranslationItem;
ShowHideTranslation.Item.displayName = 'ShowHideTranslation';

export default ShowHideTranslation;
