import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button, FormInput, Icon } from '@lionstep/ui';
import classnames from 'classnames/bind';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

// Queries
import {
  useQuestionsQuery,
  useQuestionsMutation,
} from '../../../../../../queries/jobQueries';

// Helpers
import {
  optionalQuestionsInputMapper,
  optionalQuestionsOutputMapper,
} from './optionalQuestions.helper';

// Styles
import styles from './optionalQuestions.module.scss';

const cx = classnames.bind(styles);

const schema = Yup.object().shape({
  questions: Yup.array().of(
    Yup.object().shape({
      text: Yup.string().required('Question can not be empty'),
    }),
  ),
});

const SortableQuestionDragHandler = SortableHandle(() => (
  <span className={styles.dragHandler}>
    <Icon className={styles.dragHandlerIcon} name="DragHandler" />
  </span>
));

const SortableQuestionItem = SortableElement(({ children, ...restProps }) => (
  <div {...restProps}>{children}</div>
));

const SortableQuestionList = SortableContainer(({ children }) => (
  <div>{children}</div>
));

const OptionalQuestions = forwardRef(
  ({ isOptionalQuestionsEnabled, disableQuestions }, ref) => {
    const { id } = useParams();
    const { t } = useTranslation();

    const { data: questions } = useQuestionsQuery(
      {
        id,
        questionType: 'optional_questions',
      },
      { enabled: false },
    );

    const { mutate: updateQuestions } = useQuestionsMutation({
      id,
      questionType: 'optional_questions',
    });

    const formMethods = useForm({
      defaultValues: optionalQuestionsInputMapper(questions),
      resolver: yupResolver(schema),
    });

    const {
      fields: questionFields,
      append: onQuestionAppend,
      remove: onQuestionRemove,
      move: onQuestionMove,
    } = useFieldArray({
      control: formMethods.control,
      name: 'questions',
    });

    useEffect(() => {
      formMethods.reset(optionalQuestionsInputMapper(questions));
    }, [questions]);

    useEffect(() => {
      const formValues = formMethods.getValues();

      if (isOptionalQuestionsEnabled && !formValues.questions.length) {
        onQuestionAppend({ text: '' });
      }
    }, [isOptionalQuestionsEnabled, questions]);

    const {
      handleSubmit,
      formState: { errors },
    } = formMethods;

    const handleDeleteOptionalQuestion = (index) => {
      const isOnlyOneQuestionLeft = questionFields.length === 1;

      onQuestionRemove(index);

      if (isOptionalQuestionsEnabled && isOnlyOneQuestionLeft) {
        disableQuestions();
      }
    };

    const handleOnFormSubmit = () => {
      handleSubmit((data) => {
        const formattedData = optionalQuestionsOutputMapper(data.questions);
        updateQuestions({ data: { ...formattedData } });
      })();
    };

    useImperativeHandle(ref, () => ({
      onOptionalQuestionsSubmit: handleOnFormSubmit,
      onOptionalQuestionsValidation: async (shouldScrollToError = false) => {
        const isValid = await formMethods.trigger();

        if (!isValid) {
          const element = document.getElementById('optionalQuestions');

          if (element && shouldScrollToError) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }

        return isValid;
      },
    }));

    const handleOnSortEnd = ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        onQuestionMove(oldIndex, newIndex);
      }
    };

    return (
      <div ref={ref}>
        <FormProvider {...formMethods}>
          <SortableQuestionList useDragHandle onSortEnd={handleOnSortEnd}>
            {questionFields.map((question, index) => (
              <SortableQuestionItem
                index={index}
                key={question.id}
                className={styles.questionsInputWrapper}
              >
                {questionFields.length > 1 && <SortableQuestionDragHandler />}
                <span className={styles.questionsInput}>
                  <FormInput
                    label={`${t('question')} ${index + 1}`}
                    name={`questions.${index}.text`}
                    placeholder={t('optional_questions.placeholder')}
                    error={errors.questions?.[index]?.text.message}
                  />
                </span>
                <span
                  className={cx(styles.questionsRemove)}
                  onClick={() => handleDeleteOptionalQuestion(index)}
                >
                  <Icon name="CloseCircle" />
                </span>
              </SortableQuestionItem>
            ))}
          </SortableQuestionList>
          <div>
            <Button onClick={() => onQuestionAppend({ text: '' })} ghost block>
              {t('add_question')}
            </Button>
          </div>
        </FormProvider>
      </div>
    );
  },
);

OptionalQuestions.propTypes = {
  isOptionalQuestionsEnabled: PropTypes.bool.isRequired,
  disableQuestions: PropTypes.func.isRequired,
};

export default OptionalQuestions;
