import React from 'react';
import PropTypes from 'prop-types';

const RemoteWork = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 58 70"
    className={className}
  >
    <path
      id="Coloured"
      d="M32,27h-2c-0.4,0-0.7,0.2-0.9,0.6l-2,4.3L0.7,41.9c-0.5,0.2-0.8,0.8-0.6,1.3
        c0.2,0.5,0.8,0.8,1.3,0.6l26.8-10.2c0.2-0.1,0.4-0.3,0.5-0.5l1.9-4.1H31v6.6L8.5,60.1c-0.4,0.4-0.3,1,0.1,1.4
        c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l5.9-6.4h6.6c3,0,5.5-2.5,5.5-5.6c0-2-1-3.7-2.6-4.7l7.3-8c0.2-0.2,0.3-0.4,0.3-0.7
        v-8C33,27.4,32.6,27,32,27z M22.5,53h-4.7l6.3-6.9c1.2,0.6,2,1.8,2,3.2C26,51.4,24.4,53,22.5,53z"
      fill={companyColour}
    />
    <g id="Grey">
      <path
        d="M29,0C13,0,0,13.1,0,29.1v11.7C0,56.9,13,70,29,70s29-13.1,29-29.1V29.1C58,13.1,45,0,29,0z M29,2
          c9.3,0,17.5,4.8,22.4,12H6.6C11.5,6.8,19.7,2,29,2z M2,40.9V29.1c0-4.8,1.2-9.2,3.4-13.1h47.2c2.2,3.9,3.4,8.4,3.4,13.1v11.7
          c0,3-0.5,5.9-1.4,8.5c0,0-0.1,0-0.1,0c-0.2-3.5-3.2-6.4-6.8-6.4c-3.4,0-6.3,2.5-6.8,5.7c-3.3,0.2-5.9,2.9-5.9,6.1
          c0,3.4,2.8,6.1,6.3,6.1h5.8c-4.8,4.3-11.1,7-18.1,7C14.1,68,2,55.8,2,40.9z M42.8,49.7c0-2.6,2.2-4.7,4.8-4.7
          c2.7,0,4.8,2.1,4.8,4.7c0,0.1,0,0.1,0,0.2c0,0.4,0.2,0.9,0.7,1c0.2,0.1,0.5,0.2,0.7,0.3c-1.2,2.9-2.8,5.5-4.9,7.7h-7.8
          c-2.4,0-4.3-1.9-4.3-4.1s1.9-4.1,4.3-4.1c0.1,0,0.2,0,0.3,0l0.2,0c0.2,0,0.4,0,0.6-0.1c0.3-0.2,0.6-0.5,0.6-0.9
          C42.9,49.7,42.9,49.7,42.8,49.7z"
        fill="#899198"
      />
      <path
        d="M19.6,22.9c-0.2-2.2-2.2-3.9-4.5-3.9c-2.1,0-3.9,1.4-4.4,3.3h-0.3C8,22.3,6,24.3,6,26.7S8,31,10.5,31h7
          c2.5,0,4.5-1.9,4.5-4.3C22,25.1,21.1,23.6,19.6,22.9z M17.5,29h-7C9.1,29,8,28,8,26.7s1.1-2.3,2.5-2.3h1.2c0.6,0,1-0.4,1-1
          c0-1.3,1.1-2.3,2.5-2.3s2.5,1,2.5,2.3c0,0,0,0.1,0,0.2c0,0.5,0.3,0.9,0.7,1c1,0.3,1.7,1.2,1.7,2.2C20,28,18.9,29,17.5,29z"
        fill="#899198"
      />
    </g>
  </svg>
);

RemoteWork.defaultProps = {
  companyColour: '#ab1923',
};

RemoteWork.propTypes = {
  companyColour: PropTypes.string,
};

export default RemoteWork;
