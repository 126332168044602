import React from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import classnames from 'classnames/bind';

// Styles
import styles from './candidatePlaceholder.module.scss';

const cx = classnames.bind(styles);

const CandidatePlaceholder = ({ children, className, position }) => (
  <div
    className={cx(
      styles.placeholder,
      `position${startCase(position)}`,
      className,
    )}
  >
    <div className={styles.icon}>
      <span className={styles.iconCircle} />
      <span className={styles.iconLineOne} />
      <span className={styles.iconLineTwo} />
    </div>
    <div className={styles.text}>{children}</div>
  </div>
);

CandidatePlaceholder.defaultProps = {
  className: '',
  position: 'bottom',
};

CandidatePlaceholder.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  position: PropTypes.oneOf(['bottom', 'top', 'right', 'left']),
};

export default CandidatePlaceholder;
