import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Spinner, Table, Checkbox, Icon, Dropdown } from '@lionstep/ui';
import { Helmet } from 'react-helmet';
import classnames from 'classnames/bind';

// Actions
import {
  getJobsTitles,
  getCollaborators,
  addMailingPermission,
  removeMailingPermission,
  removeJobCollaborator,
  postChangeJobOwner,
} from './settings.actions';

// Selectors
import {
  jobsTitlesSelector,
  isLoadingJobsTitlesSelector,
  collaboratorsSelector,
  isLoadingCollaboratorsSelector,
  isUpdatingStatusSelector,
  isUpdatingOwnerSelector,
} from './settings.selectors';

// Components
import PageHeading from '../../../../components/refactoring/pageHeading/PageHeading';
import ChangeOwnerModal from './components/ChangeOwnerModal';
import LeaveJobModal from './components/LeaveJobModal';
import ContentCard from '../../../../components/refactoring/contentCard';

// Queries
import { useUserQuery } from '../../../../queries/userQueries';

// Hooks
import useIsLionstepAdmin from '../../../../hooks/useIsLionstepAdmin';

// Styles
import styles from './settings.module.scss';

const cx = classnames.bind(styles);

const Settings = () => {
  const isLionstepAdmin = useIsLionstepAdmin();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const jobTitles = useSelector(jobsTitlesSelector);
  const isLoadingJobsTitles = useSelector(isLoadingJobsTitlesSelector);
  const collaborators = useSelector(collaboratorsSelector);
  const isLoadingCollaborators = useSelector(isLoadingCollaboratorsSelector);
  const isUpdatingStatus = useSelector(isUpdatingStatusSelector);
  const isUpdatingOwner = useSelector(isUpdatingOwnerSelector);
  const { data: user, refetch: userRefetch } = useUserQuery();
  const [showChangeOwnerModal, setShowChangeOwnerModal] = useState(false);
  const [showLeaveJobModal, setShowLeaveJobModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  // Fetch
  useEffect(() => {
    dispatch(getJobsTitles());
    dispatch(getCollaborators());
  }, []);

  // Status update
  const isUserOnMailingList = (job) =>
    user.job_mailings.includes(job.job_id.toString());

  const handleOnStatusUpdateChange = (e, job) => {
    const data = {
      job_id: String(job.job_id),
      user_id: user.local_id,
    };

    if (e.target.checked) {
      dispatch(addMailingPermission(data, userRefetch));
    } else {
      dispatch(removeMailingPermission(data, userRefetch));
    }
  };

  // Change owner
  const handleOnChangeOwnerModalToggle = (job = null) => {
    setShowChangeOwnerModal((curr) => !curr);
    setSelectedJob(job);
  };

  const handleOnJobOwnerChange = async (data) => {
    await dispatch(postChangeJobOwner(data));
    setShowChangeOwnerModal(false);
    setSelectedJob(null);
  };

  // Leave Job
  const handleOnLeaveJobModalToggle = (job = null) => {
    setShowLeaveJobModal((curr) => !curr);
    setSelectedJob(job);
  };

  const handleOnLeaveJob = async () => {
    await dispatch(
      removeJobCollaborator({
        jobId: selectedJob.job_id,
        userId: user.local_id,
      }),
    );

    if (isUserOnMailingList(selectedJob)) {
      const data = {
        job_id: String(selectedJob.job_id),
        user_id: user.local_id,
      };

      await dispatch(removeMailingPermission(data, user));
    }
    setShowLeaveJobModal(false);
    setSelectedJob(null);
  };

  // Render
  const renderDropdownTrigger = ({ disabled, ...restProps }) => (
    <Icon
      name="Settings"
      className={cx(styles.actionIcon, {
        [styles.actionIconDisabled]: disabled,
      })}
      {...restProps}
    />
  );

  const isOwner = (job) => job.owner_id === user.local_id;

  if (isLoadingJobsTitles || isLoadingCollaborators) {
    return <Spinner position="fixed" size={100} />;
  }

  return (
    <Grid.Container className={styles.jobSettings}>
      <Helmet>
        <title>{t('job_settings')} | Lionstep</title>
      </Helmet>

      {(isUpdatingStatus || isUpdatingOwner) && (
        <Spinner position="fixed" size={100} />
      )}
      <Grid.Row>
        <Grid.Col
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          <PageHeading>{t('job_settings')}</PageHeading>
        </Grid.Col>
        <Grid.Col
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          {!!jobTitles.length && (
            <ContentCard>
              <Table>
                <Table.Header>
                  <Table.HeaderRow>
                    <Table.HeaderCell style={{ width: '50%' }}>
                      {t('job_title')}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: '16.66%' }}>
                      {t('role')}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      textAlign="center"
                      style={{ width: '16.66%' }}
                    >
                      {t('status_updates')}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      textAlign="center"
                      style={{ width: '16.66%' }}
                    >
                      {t('actions')}
                    </Table.HeaderCell>
                  </Table.HeaderRow>
                </Table.Header>
                <Table.Body>
                  {jobTitles
                    .filter((jobTitle) =>
                      jobTitle.users.includes(user.local_id),
                    )
                    .map((jobTitle) => (
                      <Table.Row key={jobTitle.job_id}>
                        <Table.Cell style={{ whiteSpace: 'break-spaces' }}>
                          {`${jobTitle.opening_title} ${'in'} ${
                            jobTitle.location_city
                          }`}{' '}
                          - {jobTitle.job_id}
                        </Table.Cell>
                        <Table.Cell>
                          {isOwner(jobTitle) ? 'Owner' : 'Collaborator'}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <Checkbox
                            label=""
                            name={String(jobTitle.job_id)}
                            checked={isUserOnMailingList(jobTitle)}
                            onChange={(e) =>
                              handleOnStatusUpdateChange(e, jobTitle)
                            }
                          />
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <Dropdown
                            disabled={!isOwner(jobTitle) && isLionstepAdmin}
                            trigger={() =>
                              renderDropdownTrigger({
                                disabled: !isOwner(jobTitle) && isLionstepAdmin,
                              })
                            }
                          >
                            {isOwner(jobTitle) ? (
                              <Dropdown.Item
                                itemKey="changeOwner"
                                onClick={() =>
                                  handleOnChangeOwnerModalToggle(jobTitle)
                                }
                              >
                                {t('change_owner')}
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                itemKey="leaveJob"
                                onClick={() =>
                                  handleOnLeaveJobModalToggle(jobTitle)
                                }
                              >
                                {t('leave_job')}
                              </Dropdown.Item>
                            )}
                          </Dropdown>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </ContentCard>
          )}
          <ChangeOwnerModal
            job={selectedJob}
            user={user}
            show={showChangeOwnerModal}
            collaborators={collaborators}
            onClose={handleOnChangeOwnerModalToggle}
            onConfirm={handleOnJobOwnerChange}
          />
          <LeaveJobModal
            show={showLeaveJobModal}
            onClose={handleOnLeaveJobModalToggle}
            onConfirm={handleOnLeaveJob}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

export default Settings;
