export const GET_CURRENT_VERSION_START =
  'JOB_VERSION::GET_CURRENT_VERSION_START';
export const GET_CURRENT_VERSION_SUCCESS =
  'JOB_VERSION::GET_CURRENT_VERSION_SUCCESS';
export const GET_CURRENT_VERSION_FAIL = 'JOB_VERSION::GET_CURRENT_VERSION_FAIL';

export const GET_VERSIONS_START = 'JOB_VERSION::GET_VERSIONS_START';
export const GET_VERSIONS_SUCCESS = 'JOB_VERSION::GET_VERSIONS_SUCCESS';
export const GET_VERSIONS_FAIL = 'JOB_VERSION::GET_VERSIONS_FAIL';

export const CREATE_VERSION_START = 'JOB_VERSION::CREATE_VERSION_START';
export const CREATE_VERSION_SUCCESS = 'JOB_VERSION::CREATE_VERSION_SUCCESS';
export const CREATE_VERSION_FAIL = 'JOB_VERSION::CREATE_VERSION_FAIL';

export const UPDATE_VERSION_START = 'JOB_VERSION::UPDATE_VERSION_START';
export const UPDATE_VERSION_SUCCESS = 'JOB_VERSION::UPDATE_VERSION_SUCCESS';
export const UPDATE_VERSION_FAIL = 'JOB_VERSION::UPDATE_VERSION_FAIL';

export const RESET_VERSION_STATE = 'JOB_VERSION::RESET_VERSION_STATE';
