import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Styles
import styles from './infoBox.module.scss';

const NetPromoterScore = ({ insights }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.infoBox}>
      <div className={styles.infoBoxValue}>
        <span className={styles.infoBoxValuePrimary}>
          {insights.nps !== null ? insights.nps.toFixed(0) : 'NA'}
        </span>{' '}
        / {insights.nps_count || 0}{' '}
        {t('insights_page.candidate_net_promoter_score.number_of_candidates')}
      </div>
      <div className={styles.infoBoxLabel}>
        {t('insights_page.candidate_net_promoter_score.card_title')}
      </div>
    </div>
  );
};

NetPromoterScore.propTypes = {
  insights: PropTypes.shape().isRequired,
};

export default NetPromoterScore;
