import { combineReducers } from 'redux';

import jobReducer from './reducers/job.reducer';
import adminActionsReducer from './reducers/adminActions.reducer';
import jobVersionReducer from './reducers/jobVersion.reducer';
import analyticsReducer from '../pages/Analytics/analytics.reducer';
import insightsReducer from '../pages/Insights/insights.reducer';
import candidateReducer from '../pages/Candidate/candidate.reducer';

export default combineReducers({
  job: jobReducer,
  adminActions: adminActionsReducer,
  jobVersion: jobVersionReducer,
  analytics: analyticsReducer,
  insights: insightsReducer,
  candidate: candidateReducer,
});
