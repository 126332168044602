import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames/bind';

// Hooks
import useIsLionstepAdmin from '../../../../../../hooks/useIsLionstepAdmin';

// Components
import NoElasticSearchBadge from '../../../../../../components/refactoring/noElasticSearchBadge';

// Styles
import styles from './validatedCandidatesCard.module.scss';

const cx = classnames.bind(styles);

const ValidatedCandidatesCard = ({ job, candidate }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isLionstepAdmin = useIsLionstepAdmin();

  const openCandidatePage = () => {
    if (candidate.paid) {
      history.push(`/edit-job/${job.id}/candidates/${candidate.id}`);
    }
  };

  return (
    <div
      className={cx(styles.candidate, 'scrollerCards', {
        [styles.notPaidCard]: !candidate.paid,
      })}
      key={candidate.id}
      onClick={openCandidatePage}
    >
      <div className={styles.candidateImageWrapper}>
        <div className={styles.candidateImageContainer}>
          <img
            className={styles.candidateImage}
            src={
              candidate.image_url || `/img/dummyImages/${candidate.dummy_image}`
            }
            onError={(img) => {
              // eslint-disable-next-line no-param-reassign
              img.target.src = `/img/dummyImages/${candidate.dummy_image}`;
            }}
            alt={candidate.full_name || ''}
          />
          {!candidate.has_es_profile && isLionstepAdmin && (
            <NoElasticSearchBadge id={candidate.id} />
          )}
        </div>
      </div>
      <div className={styles.candidateName}>{candidate.full_name}</div>
      <div className={styles.candidatePosition}>{candidate.position}</div>

      {/* Credit not paid overlay */}
      {!candidate.paid && (
        <div className={styles.notPaidContent}>
          <div className={styles.notPaidTitle}>{t('interested_candidate')}</div>
          <div className={styles.notPaidDesc}>{t('no_credits_message')}</div>
        </div>
      )}
    </div>
  );
};

ValidatedCandidatesCard.propTypes = {
  job: PropTypes.shape({ id: PropTypes.number }).isRequired,
  candidate: PropTypes.shape({
    id: PropTypes.number,
    full_name: PropTypes.string,
    position: PropTypes.string,
    image_url: PropTypes.string,
    dummy_image: PropTypes.string,
    has_es_profile: PropTypes.bool,
    paid: PropTypes.bool,
  }).isRequired,
};

export default ValidatedCandidatesCard;
