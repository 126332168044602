import React from 'react';
import PropTypes from 'prop-types';

const StockPlan = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 66"
    className={className}
  >
    <title>05</title>
    <g id="Grey">
      <g id="Object2">
        <path
          d="M64.5,24H52V1a1,1,0,0,0-1-1H11.94a1,1,0,0,0-.26.05l-.09,0a1,1,0,0,0-.29.2l-11,11a1,1,0,0,0-.2.29l0,.09a1,1,0,0,0-.05.26S0,12,0,12V58a8,8,0,0,0,8,8H58a8,8,0,0,0,8-8V25.5A1.5,1.5,0,0,0,64.5,24ZM11,3.41V11H3.41ZM8,64a6,6,0,0,1-6-6V13H12a1,1,0,0,0,1-1V2H50V58a8,8,0,0,0,2.73,6Zm56-6a6,6,0,0,1-12,0V26H64Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M17,11H35a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H17a1,1,0,0,0-1,1v4A1,1,0,0,0,17,11Zm1-4H34V9H18Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M25,14v3a1,1,0,1,0,2,0V14a1,1,0,0,0-2,0Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M47,27H44a1,1,0,0,0,0,2h3a1,1,0,0,0,0-2Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M38,20a1,1,0,0,0,.71-.29l2-2a1,1,0,0,0-1.41-1.41l-2,2A1,1,0,0,0,38,20Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M14.29,19.71a1,1,0,0,0,1.41-1.41l-2-2a1,1,0,0,0-1.41,1.41Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M8,29a1,1,0,1,0,0-2H5a1,1,0,0,0,0,2Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M6,39a1,1,0,0,0,0,2H9a1,1,0,0,0,0-2Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M15,44H6a1,1,0,0,0,0,2h9a1,1,0,0,0,0-2Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M15,49H6a1,1,0,0,0,0,2h9a1,1,0,0,0,0-2Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M13,55a1,1,0,0,0-1-1H6a1,1,0,0,0,0,2h6A1,1,0,0,0,13,55Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M19,59H8a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M47,59H23a1,1,0,0,0,0,2H47a1,1,0,0,0,0-2Z"
          transform="translate(0 0)"
          fill="#899198"
        />
      </g>
    </g>
    <g id="Coloured">
      <g id="Object1">
        <path
          d="M40.82,28.55a.9.9,0,0,0,.14-.34,1,1,0,0,0,0-.09s0,0,0-.06a1,1,0,0,0,0-.24,1.46,1.46,0,0,0-.2-.46v0l-6-7A1,1,0,0,0,34,20H18a1,1,0,0,0-.76.35l-6,7v0a.81.81,0,0,0-.2.46,1,1,0,0,0,0,.24s0,0,0,.06,0,.05,0,.08a1,1,0,0,0,.15.35s0,.05,0,.08l14,17,0,0a1,1,0,0,0,.23.18l.09.06a1,1,0,0,0,.82,0l.09-.06a1,1,0,0,0,.24-.18l0,0,14-17A.94.94,0,0,0,40.82,28.55ZM18.46,22h1.36l-.68,4.1-4.69.59Zm-4.53,6.77,5.4-.67,4.46,12.65ZM25,38.16,21.41,28H25ZM25,26H21.18l.67-4H25Zm8.54-4,4,4.69-4.69-.59L32.18,22ZM27,22h3.15l.67,4H27Zm0,6h3.59L27,38.16Zm1.21,12.74,4.46-12.65,5.4.67Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M44.39,55.08a9.05,9.05,0,0,1-1.26-.66c2.68-2.12,4.43-6.21,3.66-8.84A3.63,3.63,0,0,0,43,43a5.09,5.09,0,0,0-5.25,4.21,8.37,8.37,0,0,0,1.87,6.72,11,11,0,0,1-7.51-.55A3.67,3.67,0,0,1,30,50a1,1,0,0,0-2,0,5.62,5.62,0,0,0,3.16,5.14,12.94,12.94,0,0,0,10.06.37,10.62,10.62,0,0,0,2.39,1.41A1,1,0,0,0,44,57a1,1,0,0,0,.39-1.92Zm-2.86-2a6.43,6.43,0,0,1-1.83-5.49A3.11,3.11,0,0,1,43,45c1.37,0,1.72.65,1.87,1.15C45.39,47.91,43.93,51.51,41.54,53.11Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
      </g>
    </g>
  </svg>
);

StockPlan.defaultProps = {
  companyColour: '#ab1923',
};

StockPlan.propTypes = {
  companyColour: PropTypes.string,
};

export default StockPlan;
