import * as types from './settings.types';

const initialState = {
  jobsTitles: [],
  isLoadingJobsTitles: false,
  collaborators: [],
  isLoadingCollaborators: false,
  isUpdatingStatus: false,
  isUpdatingOwner: false,
  isRemovingJobCollaborator: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_JOBS_TITLES_START:
      return {
        ...state,
        jobsTitles: [],
        isLoadingJobsTitles: true,
      };
    case types.GET_JOBS_TITLES_SUCCESS:
      return {
        ...state,
        jobsTitles: action.payload,
        isLoadingJobsTitles: false,
      };
    case types.GET_JOBS_TITLES_FAIL:
      return {
        ...state,
        isLoadingJobsTitles: false,
      };

    case types.GET_COLLABORATORS_START:
      return {
        ...state,
        collaborators: [],
        isLoadingCollaborators: true,
      };
    case types.GET_COLLABORATORS_SUCCESS:
      return {
        ...state,
        collaborators: action.payload,
        isLoadingCollaborators: false,
      };
    case types.GET_COLLABORATORS_FAIL:
      return {
        ...state,
        isLoadingCollaborators: false,
      };

    case types.ADD_MAILING_PERMISSION_START:
    case types.REMOVE_MAILING_PERMISSION_START:
      return {
        ...state,
        isUpdatingStatus: true,
      };

    case types.ADD_MAILING_PERMISSION_SUCCESS:
    case types.ADD_MAILING_PERMISSION_FAIL:
    case types.REMOVE_MAILING_PERMISSION_SUCCESS:
    case types.REMOVE_MAILING_PERMISSION_FAIL:
      return {
        ...state,
        isUpdatingStatus: false,
      };

    case types.CHANGE_JOB_OWNER_START:
      return {
        ...state,
        isUpdatingOwner: true,
      };
    case types.CHANGE_JOB_OWNER_SUCCESS:
      return {
        ...state,
        jobsTitles: state.jobsTitles.map((job) => {
          if (job.job_id === action.payload.jobId) {
            return {
              ...job,
              owner_id: action.payload.newOwnerId,
            };
          }

          return job;
        }),
        isUpdatingOwner: false,
      };

    case types.CHANGE_JOB_OWNER_FAIL:
      return {
        ...state,
        isUpdatingOwner: false,
      };

    case types.REMOVE_JOB_COLLABORATOR_START:
      return {
        ...state,
        isRemovingJobCollaborator: true,
      };
    case types.REMOVE_JOB_COLLABORATOR_SUCCESS:
      return {
        ...state,
        jobsTitles: state.jobsTitles.filter(
          (job) => job.job_id !== action.payload.jobId,
        ),
        isRemovingJobCollaborator: false,
      };
    case types.REMOVE_JOB_COLLABORATOR_FAIL:
      return {
        ...state,
        isRemovingJobCollaborator: false,
      };

    default:
      return { ...state };
  }
};
