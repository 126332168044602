import * as Yup from 'yup';

// Constant
import {
  QUESTIONS_TYPE,
  MULTIPLE_CHOICE_ANSWERS_TYPE,
} from '../../adminActions.constants';

export const initialQuestionsMapperInput = (initialQuestions) => {
  let questions = [];

  if (initialQuestions.length) {
    questions = initialQuestions.map((question) => ({
      id: question.question_id,
      choices: question.choices.map((choice) => ({
        choice: choice.choice,
        expected_str: choice.expected_str,
      })),
      hide: question.hide,
      title: question.title,
      type: question.question_type,
    }));
  }

  return questions;
};

export const initialQuestionsMapperOutput = (questions) =>
  questions.map((question, questionIndex) => {
    const outputQuestion = {
      choices: question.choices,
      hide: question.hide,
      question_type: question.type,
      sort_order: questionIndex,
      title: question.title,
    };

    /**
     * Questions which comes from API have ID's as INTEGERS
     * When we add new question we are generating question ID using uuid which is STRING.
     * We don't want to send id for newly created questions, for new questions ID should be generated on BE.
     */
    if (question.id && typeof question.id === 'number') {
      outputQuestion.question_id = question.id;
    }

    return outputQuestion;
  });

// Question Validation
export const questionValidation = async (questions) => {
  const promises = [];
  const errors = [];

  const questionValidationSchema = Yup.object().shape({
    title: Yup.string().required(),
    choices: Yup.array()
      .of(
        Yup.object().shape({
          choice: Yup.string().required(),
          expected_str: Yup.string().required(),
        }),
      )
      .when('type', {
        is: (val) =>
          val === QUESTIONS_TYPE.skills ||
          val === QUESTIONS_TYPE.multipleChoice,
        then: Yup.array().min(1),
      })
      .when('type', {
        is: (val) => val === QUESTIONS_TYPE.multipleChoice,
        then: Yup.array().test('at-least-one-expected-true', '', (values) =>
          values.some(
            (value) =>
              value.expected_str === MULTIPLE_CHOICE_ANSWERS_TYPE.valid,
          ),
        ),
      }),
  });

  for (let i = 0; i < questions.length; i++) {
    promises.push(
      questionValidationSchema.validate(questions[i], { abortEarly: false }),
    );
  }

  const resolvePromises = await Promise.allSettled(promises);

  for (let i = 0; i < resolvePromises.length; i++) {
    if (resolvePromises[i].status === 'rejected') {
      errors.push(`question-${i}`);
    }
  }

  return errors;
};
