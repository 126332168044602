import { citiesMapper } from '../../pages/AdminActions/adminActions.helper';
import {
  titlesMapper,
  skillsMapper,
  languagesMapper,
} from '../../components/JobForm/job.helper';

export const jobSelector = (state) => state.newJob.job.job;
export const isLoadingJobSelector = (state) => state.newJob.job.isLoading;

export const extraInfoSelector = (state) => state.newJob.job.extraInfo;
export const isLoadingExtraInfoSelector = (state) =>
  state.newJob.job.isLoadingExtraInfo;

export const titlesSelector = (state) => titlesMapper(state.newJob.job.titles);
export const titlesLoadingSelector = (state) =>
  state.newJob.job.isLoadingTitles;

export const skillsSelector = (state) => skillsMapper(state.newJob.job.skills);
export const isLoadingMandatorySkillsSelector = (state) =>
  state.newJob.job.isLoadingMandatorySkills;
export const isLoadingOptionalSkillsSelector = (state) =>
  state.newJob.job.isLoadingOptionalSkills;

export const locationsSelector = (state) =>
  citiesMapper(state.newJob.job.locations);
export const isLoadingMainLocationsSelector = (state) =>
  state.newJob.job.isLoadingMainLocations;
export const isLoadingAdditionalLocationsSelector = (state) =>
  state.newJob.job.isLoadingAdditionalLocations;

export const languagesSelector = (state) =>
  languagesMapper(state.newJob.job.languages);
export const languagesLoadingSelector = (state) =>
  state.newJob.job.isLoadingLanguages;

export const jobFileSelector = (state) => state.newJob.job.jobFile;
export const jobFileLoadingSelector = (state) =>
  state.newJob.job.isLoadingJobFile;
