// The types file contains the names of the actions that you are dispatching in your application.
// As a good practice, you should try to scope the names based on the feature they belong to.
// This helps when debugging more complex applications.
const LOGIN_USER = 'LOGIN_USER';
const LOGIN_USER_COMPLETED = 'LOGIN_USER_COMPLETED';
const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
const SIGNUP_USER = 'SIGNUP_USER';
const SIGNUP_USER_COMPLETED = 'SIGNUP_USER_COMPLETED';
const SIGNUP_USER_FAILED = 'SIGNUP_USER_FAILED';
const RESET_PASSWORD = 'RESET_PASSWORD';
const RESET_PASSWORD_COMPLETED = 'RESET_PASSWORD_COMPLETED';
const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
const VERIFY_OOB_CODE = 'VERIFY_OOB_CODE';
const VERIFY_OOB_CODE_COMPLETED = 'VERIFY_OOB_CODE_COMPLETED';
const VERIFY_OOB_CODE_FAILED = 'VERIFY_OOB_CODE_FAILED';
const REFRESH_USER_TOKEN = 'REFRESH_USER_TOKEN';
const REFRESH_USER_TOKEN_COMPLETED = 'REFRESH_USER_TOKEN_COMPLETED';
const REFRESH_USER_TOKEN_FAILED = 'REFRESH_USER_TOKEN_FAILED';
const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const CHANGE_PASSWORD_COMPLETED = 'CHANGE_PASSWORD_COMPLETED';
const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
const VERIFY_SIGNUP_COLLABORATOR = 'VERIFY_SIGNUP_COLLABORATOR';
const VERIFY_SIGNUP_COLLABORATOR_COMPLETED =
  'VERIFY_SIGNUP_COLLABORATOR_COMPLETED';
const VERIFY_SIGNUP_COLLABORATOR_FAILED = 'VERIFY_SIGNUP_COLLABORATOR_FAILED';
const SIGNUP_COLLABORATOR = 'SIGNUP_COLLABORATOR';
const SIGNUP_COLLABORATOR_COMPLETED = 'SIGNUP_COLLABORATOR_COMPLETED';
const SIGNUP_COLLABORATOR_FAILED = 'SIGNUP_COLLABORATOR_FAILED';
const LOGOUT_USER = 'LOGOUT_USER';

export default {
  LOGIN_USER,
  LOGIN_USER_COMPLETED,
  LOGIN_USER_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_COMPLETED,
  RESET_PASSWORD_FAILED,
  VERIFY_OOB_CODE,
  VERIFY_OOB_CODE_COMPLETED,
  VERIFY_OOB_CODE_FAILED,
  REFRESH_USER_TOKEN,
  REFRESH_USER_TOKEN_COMPLETED,
  REFRESH_USER_TOKEN_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_COMPLETED,
  CHANGE_PASSWORD_FAILED,
  LOGOUT_USER,
  SIGNUP_USER,
  SIGNUP_USER_COMPLETED,
  SIGNUP_USER_FAILED,
  VERIFY_SIGNUP_COLLABORATOR,
  VERIFY_SIGNUP_COLLABORATOR_COMPLETED,
  VERIFY_SIGNUP_COLLABORATOR_FAILED,
  SIGNUP_COLLABORATOR,
  SIGNUP_COLLABORATOR_COMPLETED,
  SIGNUP_COLLABORATOR_FAILED,
};
