// read about Object.prototype.toString.call at https://toddmotto.com/understanding-javascript-types-and-reliable-type-checking/#true-object-types
import axios from 'axios';

import { PYTHON_API } from '../config';

const baseURL = PYTHON_API || 'https://dev-python-api.lionstep.com';

function addHeaders() {
  if (typeof document !== 'undefined') {
    const sessionId = localStorage.getItem('sessionId');
    if (typeof sessionId !== 'undefined') {
      return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${sessionId}`,
      };
    }
  }
  return {
    // Accept: "application/json",
    // "Content-Type": "application/json"
  };
}

function requestHeaders(extraHeaders) {
  return { ...addHeaders(), ...extraHeaders };
}

export default (url, method, extraHeaders, body) => {
  const details = {
    baseURL,
    method,
    url,
    data: body,
    headers: requestHeaders(extraHeaders),
    responseType: 'json', // options are 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream',
  };
  return axios(details);
};
