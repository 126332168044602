import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import i18n from 'i18next';
import { isEmpty } from 'lodash';

// Actions
import { setAppLanguage } from '../../../modules/app/redux/actions/language.actions';

// Selectors

// Hooks
import useSearchParams from '../../../hooks/useSearchParams';

// Components
import HeaderPreLogin from '../header/HeaderPreLogin';

// Queries
import { useUserQuery } from '../../../queries/userQueries';

// Styles
import styles from './preLoginLayout.module.scss';

const PreLoginLayout = ({ children }) => {
  const history = useHistory();
  const { params } = useSearchParams();
  const dispatch = useDispatch();
  const { data: user } = useUserQuery();

  // Set language from cookie or invite link
  useEffect(() => {
    if (params.language || params.lang) {
      i18n.changeLanguage(params.language || params.lang);
      dispatch(setAppLanguage(params.language || params.lang));
    } else {
      const cookies = new Cookies();
      const cookieLang = cookies.get('selectedLanguage');

      if (cookieLang) {
        i18n.changeLanguage(cookieLang);
        dispatch(setAppLanguage(cookieLang));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(user)) {
      history.push('/');
    }
  }, [user]);

  return (
    <div className={styles.preLoginLayout}>
      <HeaderPreLogin className={styles.header} />
      <main className={styles.content}>{children}</main>
    </div>
  );
};

export default PreLoginLayout;
