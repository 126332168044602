/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from '@lionstep/ui';

// Api
import { pythonAxios } from '../api/http';

// Constants
import {
  CANDIDATES_PER_PAGE,
  CANDIDATES_STATUSES,
} from '../modules/job/job.constants';

// Helper
import { formatJobCandidatesStats } from '../modules/job/pages/Candidates/candidates.helpers';

// === JOB CANDIDATES START === //
const getJobCandidates = ({ jobId, params = null } = {}) =>
  pythonAxios({
    method: 'get',
    url: `/jobs/${jobId}/contact-talents`,
    params,
  })
    .then((response) => response.data)
    .catch((error) => {
      toast.error('There was an error while fetching candidates', {
        id: 'FETCH_JOB_CANDIDATES',
        statusCode: error?.response?.status,
      });
    });

export const useJobCandidatesQuery = ({
  jobId,
  status,
  offset = 0,
  limit = CANDIDATES_PER_PAGE,
  filter = null,
}) => {
  const key = [
    'jobCandidates',
    jobId,
    `status-${status}`,
    `offset-${offset}`,
    `limit-${limit}`,
    `filter-${filter}`,
  ];

  return useQuery(key, () => {}, { enabled: false, staleTime: 600000 });
};

export const useJobCandidatesMutation = ({ jobId }) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['jobCandidates', jobId],
    ({ status, offset = 0, limit = CANDIDATES_PER_PAGE, filter = null }) => {
      const key = [
        'jobCandidates',
        jobId,
        `status-${status}`,
        `offset-${offset}`,
        `limit-${limit}`,
        `filter-${filter}`,
      ];
      const data = queryClient.getQueryData(key);

      if (!data) {
        const params = { status, offset, limit };

        if (filter && status === CANDIDATES_STATUSES.not_interested.value) {
          params.not_interested_reason = filter;
        }

        return getJobCandidates({ jobId, params });
      }

      return data;
    },
    {
      onSuccess: async (data, params) => {
        const { status, offset, limit = CANDIDATES_PER_PAGE, filter } = params;
        const key = [
          'jobCandidates',
          jobId,
          `status-${status}`,
          `offset-${offset}`,
          `limit-${limit}`,
          `filter-${filter}`,
        ];

        queryClient.setQueryData(key, () => data);
      },
    },
  );
};
// === JOB CANDIDATES END === //

// === JOB CANDIDATES STATUS START === //
const getJobCandidatesStatus = ({ jobId } = {}) =>
  pythonAxios({
    method: 'get',
    url: `/jobs/${jobId}/contact-talents/status-aggregations`,
  })
    .then((response) => formatJobCandidatesStats(response.data))
    .catch((error) => {
      toast.error('There was an error while fetching candidates statuses', {
        id: 'FETCH_JOB_CANDIDATES_STATUS_AGGREGATION',
        statusCode: error?.response?.status,
      });
    });

export const useJobCandidatesStatusQuery = ({ jobId }) => {
  const key = ['jobCandidatesStatus', jobId];

  return useQuery(key, () => getJobCandidatesStatus({ jobId }), {
    placeholderData: {},
    staleTime: 600000,
  });
};
// === JOB CANDIDATES STATUS END === //

// === JOB CANDIDATES NOT INTERESTED STATUS START === //
const getJobCandidatesNotInterestedStatus = ({ jobId } = {}) =>
  pythonAxios({
    method: 'get',
    url: `/jobs/${jobId}/contact-talents/not-interested`,
  })
    .then((response) => response.data)
    .catch((error) => {
      toast.error('There was an error while fetching candidates statuses ', {
        id: 'FETCH_JOB_CANDIDATES_NOT_INTERESTED_STATUS_AGGREGATION',
        statusCode: error?.response?.status,
      });
    });

export const useJobCandidatesNotInterestedStatusQuery = ({ jobId }) => {
  const key = ['jobCandidatesNotInterestedStatus', jobId];

  return useQuery(key, () => getJobCandidatesNotInterestedStatus({ jobId }), {
    enabled: false,
    placeholderData: {},
    staleTime: 600000,
  });
};
// === JOB CANDIDATES NOT INTERESTED STATUS END === //
