export const htmlDoughnutChartLegendPlugin = (
  chartId,
  legendContainerId,
  styles,
  percentage = true,
) => ({
  beforeInit: (chart) => {
    if (chart.canvas.id === chartId) {
      const ul = document.createElement('ul');
      ul.classList.add(styles.legendList);

      chart.data.labels.forEach((label, i) => {
        ul.innerHTML += `
          <li class="${styles.legendItem}">
            <span class="${styles.legendColor}" style="background-color: ${
          chart.data.datasets[0].backgroundColor[i]
        }"></span>
            <span class="${styles.legendPercentage}">${
          chart.data.datasets[0].data[i]
        } ${percentage ? '%' : ''}</span>
            <span class="${styles.legendText}">${label}</span>
          </li>
        `;
      });

      return document.getElementById(legendContainerId).appendChild(ul);
    }

    return null;
  },
});

export const htmlBarChartLegendPlugin = (
  chartId,
  legendContainerId,
  styles,
) => ({
  beforeInit: (chart) => {
    if (chart.canvas.id === chartId) {
      const ul = document.createElement('ul');
      ul.classList.add(styles.legendList);

      chart.data.datasets.forEach((dataset) => {
        ul.innerHTML += `
          <li class="${styles.legendItem}">
            <span class="${styles.legendColor}" style="background-color: ${dataset.backgroundColor}"></span>
            <span class="${styles.legendText}">${dataset.label}</span>
          </li>
        `;
      });

      return document.getElementById(legendContainerId).appendChild(ul);
    }

    return null;
  },
});

export const emptyDoughnutPlugin = {
  id: 'emptyDoughnut',
  afterDraw(chart, args, options) {
    const { datasets } = chart.data;
    const { color, width, radiusDecrease } = options;
    let hasData = false;

    for (let i = 0; i < datasets.length; i += 1) {
      const dataset = datasets[i];

      hasData =
        dataset.data.length > 0 && dataset.data.some((item) => item !== '0');
    }
    if (!hasData) {
      const {
        chartArea: { left, top, right, bottom },
        ctx,
      } = chart;
      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;
      const r = Math.min(right - left, bottom - top) / 2;

      ctx.beginPath();
      ctx.lineWidth = width || 2;
      ctx.strokeStyle = color || 'rgba(255, 128, 0, 0.5)';
      ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
      ctx.stroke();
    }
  },
};
