import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Heading,
  Button,
  FormInput,
  FormSelectV2,
  Spinner,
  toast,
} from '@lionstep/ui';

// Components
import ContentCard from '../../../../../../components/refactoring/contentCard';

// Constants
import { USER_ROLES } from '../../../../../../constants/user.const';

// API
import { addCollaboratorApi } from '../../../../../../api/user.api';

// Styles
import styles from './addCollaborator.module.scss';

const AddCollaborator = ({ onSuccess }) => {
  const { t } = useTranslation();

  // CONST
  const ROLE_OPTIONS = [
    { value: USER_ROLES.admin, label: t('administrator') },
    { value: USER_ROLES.viewer, label: t('Viewer') },
  ];

  const LANGUAGE_OPTIONS = [
    { value: 'en', label: t('languages_short.en') },
    { value: 'de', label: t('languages_short.de') },
    { value: 'es', label: t('languages_short.es') },
    { value: 'fr', label: t('languages_short.fr') },
  ];

  // Form
  const schema = Yup.object().shape({
    displayName: Yup.string()
      .required('This field is required')
      .matches('\\w+\\s+\\w+', 'Please provide your full name'),
    email: Yup.string().email().required('This field is required'),
  });

  const formMethods = useForm({
    defaultValues: {
      displayName: '',
      email: '',
      role: ROLE_OPTIONS[1],
      language: LANGUAGE_OPTIONS[0],
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  // Add collaborator
  const handleAddCollaboratorOnSuccess = ({ data }) => {
    reset();
    onSuccess(data.local_id);
  };

  const handleAddCollaboratorOnError = (error) => {
    toast.error('There was an error while adding collaborator.', {
      id: 'UPDATE_COLLABORATOR_ROLE',
      statusCode: error?.response?.status,
    });
  };

  const { mutate, isLoading } = useMutation(addCollaboratorApi, {
    onSuccess: handleAddCollaboratorOnSuccess,
    onError: handleAddCollaboratorOnError,
  });

  const handleOnSubmit = (formData) => {
    const [first_name, last_name] = formData.displayName.split(' ');

    mutate({
      first_name,
      last_name,
      email: formData.email,
      role: formData.role.value,
      language: formData.language.value,
    });
  };

  return (
    <ContentCard>
      {isLoading && <Spinner position="fixed" size={100} />}
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Grid.Row gutter={30}>
            <Grid.Col xs={24}>
              <Heading level={3} className={styles.title}>
                {t('collaborator_page.add_collaborator.title')}
              </Heading>
            </Grid.Col>
            <Grid.Col xs={24} sm={12} md={8}>
              <FormInput
                label={t('collaborator_page.add_collaborator.full_name_label')}
                name="displayName"
                placeholder="John Doe"
                error={errors.displayName?.message}
              />
            </Grid.Col>
            <Grid.Col xs={24} sm={12} md={8}>
              <FormInput
                label={t('collaborator_page.add_collaborator.work_email_label')}
                name="email"
                placeholder="John Doe"
                error={errors.email?.message}
              />
            </Grid.Col>
            <Grid.Col xs={12} md={8} lg={4}>
              <FormSelectV2
                name="role"
                label={t('collaborator_page.add_collaborator.role_label')}
                options={ROLE_OPTIONS}
                error={errors.role?.message}
              />
            </Grid.Col>
            <Grid.Col xs={12} md={8} lg={4}>
              <FormSelectV2
                name="language"
                label={t('collaborator_page.add_collaborator.language_label')}
                options={LANGUAGE_OPTIONS}
                error={errors.language?.message}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row gutter={30}>
            <Grid.Col xs={24} sm={12} md={8}>
              <Button block typeHtml="submit">
                {t('send_invitation')}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </form>
      </FormProvider>
    </ContentCard>
  );
};

export default AddCollaborator;
