import React from 'react';
import PropTypes from 'prop-types';

const LifeInsurance = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
    className={className}
  >
    <title>Life Insurance</title>
    <g id="Grey">
      <g id="Object2">
        <path
          d="M29,56V53.73a11.73,11.73,0,0,0-2.53-7.43,8.25,8.25,0,0,0-3.78-2.73,24.28,24.28,0,0,1-6.85-4.28,5.25,5.25,0,0,0-6.38-.21l-2.62-6A3.59,3.59,0,0,0,.58,32.6,3.45,3.45,0,0,0,.33,36l4.6,9.89a12.07,12.07,0,0,0,2.52,3.55l7.3,7.13A2.21,2.21,0,0,0,14,58.2v9.6A2.18,2.18,0,0,0,16.15,70H28.85A2.18,2.18,0,0,0,31,67.8V58.2A2.18,2.18,0,0,0,29,56ZM6.75,45l-4.6-9.89a1.46,1.46,0,0,1,.11-1.43A1.59,1.59,0,0,1,5,33.92l2.63,6a13.48,13.48,0,0,0,2.93,4.23l5.36,5.24A1,1,0,1,0,17.34,48L12,42.71a11.55,11.55,0,0,1-1.53-1.85,3.29,3.29,0,0,1,4.12,0A26.25,26.25,0,0,0,22,45.44a6.32,6.32,0,0,1,2.92,2.09A9.74,9.74,0,0,1,27,53.72V56H17l-8.2-8A10.08,10.08,0,0,1,6.75,45ZM29,67.8a.18.18,0,0,1-.15.2H16.15a.18.18,0,0,1-.15-.2V58.2a.18.18,0,0,1,.15-.2H28.85a.18.18,0,0,1,.15.2Z"
          transform="translate(-0.01 0)"
          fill="#899198"
        />
        <path
          d="M26,62H24a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z"
          transform="translate(-0.01 0)"
          fill="#899198"
        />
        <path
          d="M69.43,32.6a3.59,3.59,0,0,0-6.27.51l-2.62,6a5.27,5.27,0,0,0-6.41.24,24.2,24.2,0,0,1-6.77,4.23,8.3,8.3,0,0,0-3.82,2.74A11.73,11.73,0,0,0,41,53.72V56a2.18,2.18,0,0,0-2,2.18v9.6A2.18,2.18,0,0,0,41.15,70H53.85A2.18,2.18,0,0,0,56,67.8V58.2a2.21,2.21,0,0,0-.74-1.65l7.3-7.13a12.06,12.06,0,0,0,2.52-3.55L69.68,36A3.45,3.45,0,0,0,69.43,32.6ZM54,67.8a.18.18,0,0,1-.15.2H41.15a.18.18,0,0,1-.15-.2V58.2a.18.18,0,0,1,.15-.2H53.85a.18.18,0,0,1,.15.2ZM67.86,35.13,63.26,45a10,10,0,0,1-2.1,3L53,56H43V53.7a9.75,9.75,0,0,1,2.11-6.17,6.37,6.37,0,0,1,3-2.11,26.14,26.14,0,0,0,7.36-4.58,3.31,3.31,0,0,1,4.15,0A11.54,11.54,0,0,1,58,42.71L52.67,48a1,1,0,0,0,1.4,1.43l5.36-5.24a13.46,13.46,0,0,0,2.93-4.23l2.63-6a1.59,1.59,0,0,1,2.77-.22A1.46,1.46,0,0,1,67.86,35.13Z"
          transform="translate(-0.01 0)"
          fill="#899198"
        />
        <path
          d="M46,62H43.83a1,1,0,0,0,0,2H46a1,1,0,0,0,0-2Z"
          transform="translate(-0.01 0)"
          fill="#899198"
        />
        <path
          d="M54.42,31.76C58.25,28.34,63,24.08,63,15.38,63,5.89,57.25,0,48,0h-.08A15.74,15.74,0,0,0,35,6.77,15.71,15.71,0,0,0,22,0C12.75,0,7,5.89,7,15.38c0,8.7,4.75,13,8.57,16.38.6.54,1.19,1.07,1.74,1.59,4,3.82,16.53,14,17.06,14.43a1,1,0,0,0,1.26,0c.53-.43,13.08-10.61,17.06-14.43C53.24,32.82,53.82,32.3,54.42,31.76ZM35,45.71C32.35,43.55,22.11,35.18,18.69,31.9c-.56-.54-1.17-1.08-1.79-1.64C13.2,26.95,9,23.18,9,15.38,9,7,13.86,2,22,2h.07A13.75,13.75,0,0,1,34.12,9.15a1,1,0,0,0,1.75,0A13.75,13.75,0,0,1,47.93,2H48c8.14,0,13,5,13,13.38,0,7.81-4.2,11.57-7.91,14.89-.62.55-1.22,1.09-1.78,1.63C47.88,35.18,37.65,43.55,35,45.71Z"
          transform="translate(-0.01 0)"
          fill="#899198"
        />
      </g>
    </g>
    <g id="Coloured">
      <g id="Object1">
        <path
          d="M46,32h0a1,1,0,0,1-1-.76l-3-11.89L41,24.22A1,1,0,0,1,40,25H33.62l-1.72,3.45A1,1,0,0,1,30,28.24L29,24l-2,7.31A1,1,0,0,1,26,32a1,1,0,0,1-1-.76l-3-11.89L21,24.22A1,1,0,0,1,20,25H2a1,1,0,0,1,0-2H19.2L21,14.78A1,1,0,0,1,22,14a1,1,0,0,1,1,.76L26,27l2-7.31A1,1,0,0,1,29,19h0a1,1,0,0,1,1,.76l1.34,5.38.79-1.58A1,1,0,0,1,33,23h6.2L41,14.78A1,1,0,0,1,42,14a1,1,0,0,1,1,.76L46,27l2-7.31a1,1,0,0,1,1.93,0l1.34,5.38.79-1.58A1,1,0,0,1,53,23H68a1,1,0,0,1,0,2H53.62l-1.72,3.45A1,1,0,0,1,50,28.24L49,24l-2,7.31A1,1,0,0,1,46,32Z"
          transform="translate(-0.01 0)"
          fill={companyColour}
        />
      </g>
    </g>
  </svg>
);

LifeInsurance.defaultProps = {
  companyColour: '#ab1923',
};

LifeInsurance.propTypes = {
  companyColour: PropTypes.string,
};

export default LifeInsurance;
