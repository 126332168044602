import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Heading, Dropdown, Icon, Spinner, toast } from '@lionstep/ui';
import classnames from 'classnames/bind';
import { useMutation, useQueryClient } from '@tanstack/react-query';

// Api
import {
  updateCollaboratorRoleApi,
  deleteCollaboratorApi,
} from '../../../../../../api/user.api';

// Components
import ContentCard from '../../../../../../components/refactoring/contentCard';

// Hooks
import useIsLionstepAdmin from '../../../../../../hooks/useIsLionstepAdmin';

// Connsstants
import { USER_ROLES } from '../../../../../../constants/user.const';

// Styles
import styles from './collaboratorsTable.module.scss';

const cx = classnames.bind(styles);

const CollaboratorsTable = ({ isError, collaborators, userData }) => {
  const { t } = useTranslation();
  const isLionstepAdmin = useIsLionstepAdmin();
  const queryClient = useQueryClient();

  // Change collaborator role
  const handleUpdateCollaboratorRoleOnError = (error) => {
    toast.error('There was an error updating collaborator.', {
      id: 'UPDATE_COLLABORATOR_ROLE',
      statusCode: error?.response?.status,
    });
  };

  const handleUpdateCollaboratorRoleOnSuccess = (data, variables) => {
    toast.success('Collaborator role successfully updated.', {
      id: 'UPDATE_COLLABORATOR_ROLE',
    });

    const { user_id, role_name } = variables.data;

    queryClient.setQueryData(['userCollaborators'], (currentData) =>
      currentData.map((collaborator) =>
        collaborator.local_id === user_id
          ? {
              ...collaborator,
              role:
                role_name === USER_ROLES.viewer
                  ? USER_ROLES.viewer
                  : USER_ROLES.admin,
            }
          : collaborator,
      ),
    );
  };

  const { mutate: updateCollaborator, isLoading: isLoadingUpdateCollaborator } =
    useMutation(updateCollaboratorRoleApi, {
      onError: handleUpdateCollaboratorRoleOnError,
      onSuccess: handleUpdateCollaboratorRoleOnSuccess,
    });

  const handleUpdateCollaboratorRole = (collaborator, role) => {
    updateCollaborator({
      data: { role_name: role, user_id: collaborator.local_id },
    });
  };

  // Delete Collaborator
  const handleDeleteCollaboratorOnError = (error) => {
    toast.error('There was an error while deleting collaborator', {
      id: 'DELETING_COLLABORATOR',
      statusCode: error?.response?.status,
    });
  };

  const handleDeleteCollaboratorOnSuccess = (data, variables) => {
    toast.success('Collaborator successfully deleted.', {
      id: 'DELETE_COLLABORATOR',
    });

    queryClient.setQueryData(['userCollaborators'], (currentData) =>
      currentData.filter(
        (collaborator) => collaborator.local_id !== variables.id,
      ),
    );
  };

  const { mutate: deleteCollaborator, isLoading: isLoadingDeleteCollaborator } =
    useMutation(deleteCollaboratorApi, {
      onError: handleDeleteCollaboratorOnError,
      onSuccess: handleDeleteCollaboratorOnSuccess,
    });

  const handleDeleteCollaborator = (id) => {
    deleteCollaborator({ id });
  };

  const renderDropdownTrigger = (props) => (
    <Icon
      name="Settings"
      className={cx(styles.actionIcon, {
        [styles.actionIconActive]: props.isOpen,
      })}
      {...props}
    />
  );

  return (
    <ContentCard>
      <Heading level={3} className={styles.title}>
        {t('collaborator_page.collaborators.title')}
      </Heading>
      {(isLoadingDeleteCollaborator || isLoadingUpdateCollaborator) && (
        <Spinner position="fixed" size={100} />
      )}
      <Table>
        <Table.Header>
          <Table.HeaderRow>
            <Table.HeaderCell style={{ width: '20%' }}>
              {t('collaborator_page.collaborators.table_name')}
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: '35%' }}>
              {t('collaborator_page.collaborators.table_email')}
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: '15%' }}>
              {t('collaborator_page.collaborators.table_role')}
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: '15%' }}>
              {t('collaborator_page.collaborators.table_comment')}
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: '15%' }} textAlign="center">
              {t('collaborator_page.collaborators.table_actions')}
            </Table.HeaderCell>
          </Table.HeaderRow>
        </Table.Header>
        <Table.Body>
          {!isError ? (
            collaborators
              .filter(
                (collaborator) =>
                  isLionstepAdmin ||
                  collaborator.email.split('@')[1] !== 'lionstep.com',
              )
              .map((collaborator, index) => (
                <Table.Row>
                  <Table.Cell data-testid={`name-${index}`}>
                    {collaborator.display_name}
                  </Table.Cell>
                  <Table.Cell data-testid={`email-${index}`}>
                    {collaborator.email}
                  </Table.Cell>
                  <Table.Cell data-testid={`role-${index}`}>
                    {collaborator.role === 'Viewer'
                      ? t('Viewer')
                      : t('administrator')}
                  </Table.Cell>
                  <Table.Cell
                    className={cx({
                      [styles.confirmed]: !collaborator.confirmed,
                    })}
                    data-testid={`comment-${index}`}
                  >
                    {!collaborator.confirmed
                      ? t(
                          'collaborator_page.collaborators.table_pending_invitation',
                        )
                      : '⎯'}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="center"
                    data-testid={`action-${index}`}
                  >
                    {userData.email !== collaborator.email && (
                      <Dropdown trigger={renderDropdownTrigger}>
                        <Dropdown.Item
                          onClick={() =>
                            handleUpdateCollaboratorRole(
                              collaborator,
                              collaborator.role === USER_ROLES.admin
                                ? USER_ROLES.viewer
                                : USER_ROLES.admin,
                            )
                          }
                        >
                          {collaborator.role === USER_ROLES.admin
                            ? t('change_to_viewer')
                            : t('change_to_admin')}
                        </Dropdown.Item>
                        {userData.email !== collaborator.email && (
                          <Dropdown.Item
                            onClick={() =>
                              handleDeleteCollaborator(collaborator.local_id)
                            }
                          >
                            {t('delete_collaborator')}
                          </Dropdown.Item>
                        )}
                      </Dropdown>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={5} textAlign="center">
                NO DATA
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </ContentCard>
  );
};

export default CollaboratorsTable;
