import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown, Icon } from '@lionstep/ui';
import classnames from 'classnames/bind';

// Hooks
import useIsLionstepAdmin from '../../../../../../hooks/useIsLionstepAdmin';

// Actions
import { openJobActionModal } from '../../../../redux/actions/dashboard.actions';

// Const
import { JOB_DROPDOWN_ACTIONS } from '../../../../dashboard.constants';

// Queries
import { useUserQuery } from '../../../../../../queries/userQueries';

// Constatns
import { USER_ROLES } from '../../../../../../constants/user.const';

// Style
import styles from './jobDropdown.module.scss';

const cx = classnames.bind(styles);

const JobDropdown = ({ job, archived }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: user } = useUserQuery();
  const isLionstepAdmin = useIsLionstepAdmin();
  const isAdmin = user.role === USER_ROLES.admin;
  const isJobOwner = job.owner_id === user.local_id;
  const hasCandidatesInFunnel =
    Object.values(job.contacts).reduce((acc, curr) => acc + curr, 0) > 0;

  const handelOnShareJobClick = () => {
    dispatch(
      openJobActionModal({
        modalType: JOB_DROPDOWN_ACTIONS.shareJob,
        job,
      }),
    );
  };

  const handleOnPublishJobClick = () =>
    dispatch(
      openJobActionModal({
        modalType: JOB_DROPDOWN_ACTIONS.publishJob,
        job,
      }),
    );

  const handleOnArchiveJobClick = () =>
    dispatch(
      openJobActionModal({
        modalType: JOB_DROPDOWN_ACTIONS.archiveJob,
        job,
      }),
    );

  const handleOnDeleteJobClick = () =>
    dispatch(
      openJobActionModal({
        modalType: JOB_DROPDOWN_ACTIONS.deleteJob,
        job,
      }),
    );

  const handleOnMailingPermissionClick = () =>
    dispatch(
      openJobActionModal({
        modalType: JOB_DROPDOWN_ACTIONS.jobMailingPermissions,
        job,
      }),
    );

  const handleOnAnalyticsClick = () => {
    history.push(`/edit-job/${job.id}/analytics`);
  };

  const handleOnDuplicateJobClick = () => {
    history.push(`/duplicate-job/${job.id}`);
  };

  const renderDropdownTrigger = ({ isOpen }) => (
    <Icon
      name="Settings"
      size={20}
      className={cx(styles.settingsIcon, {
        [styles.settingsIconActive]: isOpen,
      })}
    />
  );

  return (
    <Dropdown
      className={styles.actionDropdown}
      data-testid={`dropdown-${job.id}`}
      trigger={renderDropdownTrigger}
    >
      {!archived && (
        <Dropdown.Item
          className={styles.actionDropdownItem}
          data-testid={`dropdown-share-job-${job.id}`}
          onClick={handelOnShareJobClick}
        >
          {t('dashboard_page.job_card.actions.share')}
        </Dropdown.Item>
      )}
      {isLionstepAdmin && (
        <Dropdown.Item
          className={styles.actionDropdownItem}
          data-testid={`dropdown-duplicate-job-${job.id}`}
          onClick={handleOnDuplicateJobClick}
        >
          {t('dashboard_page.job_card.actions.duplicate_job')}
        </Dropdown.Item>
      )}
      {(isAdmin || isJobOwner) && archived && (
        <Dropdown.Item
          className={styles.actionDropdownItem}
          data-testid={`dropdown-publish-job-${job.id}`}
          onClick={handleOnPublishJobClick}
        >
          {t('dashboard_page.job_card.actions.publish')}
        </Dropdown.Item>
      )}
      {(isAdmin || isJobOwner) && !archived && (
        <Dropdown.Item
          className={styles.actionDropdownItem}
          data-testid={`dropdown-archive-job-${job.id}`}
          onClick={handleOnArchiveJobClick}
        >
          {t('dashboard_page.job_card.actions.archive')}
        </Dropdown.Item>
      )}
      {(isAdmin || isJobOwner) && !hasCandidatesInFunnel && (
        <Dropdown.Item
          className={styles.actionDropdownItem}
          data-testid={`dropdown-delete-job-${job.id}`}
          onClick={handleOnDeleteJobClick}
        >
          {t('dashboard_page.job_card.actions.delete')}
        </Dropdown.Item>
      )}
      {isLionstepAdmin && (
        <Dropdown.Item
          className={styles.actionDropdownItem}
          data-testid={`dropdown-job-mailing-permissions-${job.id}`}
          onClick={handleOnMailingPermissionClick}
        >
          {t('dashboard_page.job_card.actions.mailing_permissions')}
        </Dropdown.Item>
      )}
      {isLionstepAdmin && (
        <Dropdown.Item
          className={styles.actionDropdownItem}
          onClick={handleOnAnalyticsClick}
        >
          Analytics
        </Dropdown.Item>
      )}
    </Dropdown>
  );
};

JobDropdown.defaultProps = {
  archived: false,
};

JobDropdown.propTypes = {
  job: PropTypes.shape().isRequired,
  archived: PropTypes.bool,
};

export default JobDropdown;
