import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import { Accordion, useAccordion, Icon } from '@lionstep/ui';
import classnames from 'classnames/bind';

// Components
import InfoPlusIconWithTooltip from '../../../../../../components/refactoring/infoPlusIconWithTooltip';

// Styles
import styles from './tamTitles.module.scss';

const cx = classnames.bind(styles);

const ACCORDION_ITEM_KEY = 'tam-titles';

const TamTitlesHeader = ({ children }) => {
  const { collapsedItems } = useAccordion();

  const isCollapsed = collapsedItems.includes(ACCORDION_ITEM_KEY);

  return (
    <span>
      <Icon
        name="ArrowRight"
        className={cx(styles.accordionHeaderArrow, {
          [styles.accordionHeaderArrowExpanded]: !isCollapsed,
        })}
      />
      {children}
      <InfoPlusIconWithTooltip />
    </span>
  );
};

const TamTitles = ({ tamTitles }) => {
  const { t } = useTranslation();

  return (
    <Accordion collapseAll className={cx(styles.accordion)}>
      <Accordion.Item
        itemKey={ACCORDION_ITEM_KEY}
        className={cx(styles.accordionItem)}
      >
        <Accordion.ItemHeader className={cx(styles.accordionHeader)}>
          <TamTitlesHeader>
            {t('tam_titles.label')} ({tamTitles.length})
          </TamTitlesHeader>
        </Accordion.ItemHeader>
        <Accordion.ItemBody className={cx(styles.accordionBody)}>
          {tamTitles.map((title) => (
            <span
              key={camelCase(title)}
              className={cx(styles.accordionBodyItem)}
            >
              {title}
            </span>
          ))}
        </Accordion.ItemBody>
      </Accordion.Item>
    </Accordion>
  );
};

TamTitles.propTypes = {
  tamTitles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default TamTitles;
