import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import { Modal, Text } from '@lionstep/ui';

// Styles
import styles from './jobInfoModal.module.scss';

const JobInfoModal = ({ infoModalType, onClose, show }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onClose={onClose} className={styles.jobInfoModal}>
      <Modal.Header onClose={onClose}>
        {infoModalType === 'job_language' && 'Adjust job language'}
        {infoModalType === 'can_be_disclosed' && 'Adjust disclose company name'}
        {infoModalType === 'approach' && 'Adjust approach'}
      </Modal.Header>

      <Modal.Body className={styles.modalBody}>
        <Text className={styles.modalBodyAttention}>
          {t('job_description_page.change_info_modal.title')}
        </Text>
        <Text className={styles.modalBodyInfo}>
          {infoModalType === 'job_language' &&
            t('job_description_page.change_info_modal.job_language_change')}
          {infoModalType === 'can_be_disclosed' &&
            t('job_description_page.change_info_modal.disclose_change')}
          {infoModalType === 'approach' &&
            t('job_description_page.change_info_modal.approach_change')}
        </Text>
      </Modal.Body>

      <Modal.Footer
        confirmText={t('close')}
        confirmButtonGhost
        onConfirm={onClose}
      />
    </Modal>
  );
};

JobInfoModal.propTypes = {
  infoModalType: PropTypes.oneOf([
    'job_language',
    'can_be_disclosed',
    'approach',
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default JobInfoModal;
