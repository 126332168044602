import React from 'react';
import PropTypes from 'prop-types';

const PetFriendlyOffice = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 56"
    className={className}
  >
    <g id="Coloured_1_">
      <circle cx="14" cy="20" r="1" fill={companyColour} />
      <path
        d="M18,25c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S19.7,25,18,25z M18,29c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1
    S18.6,29,18,29z"
        fill={companyColour}
      />
      <path
        d="M57,24c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S58.7,24,57,24z M57,28c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1
    S57.6,28,57,28z"
        fill={companyColour}
      />
      <circle cx="27" cy="19" r="1" fill={companyColour} />
      <circle cx="25" cy="32" r="1" fill={companyColour} />
      <circle cx="51" cy="22" r="1" fill={companyColour} />
      <circle cx="56" cy="19" r="1" fill={companyColour} />
      <path
        d="M49,31c-3.1,0-5.8,1.3-7.1,3.2c-1.2-1.4-3.2-2.2-5.6-2.2c-0.6,0-1.7,0-2.1,0.9c-0.4,0.8,0.2,1.6,0.7,2.3
          c0.4,0.6,1,1.3,1,1.9s-0.5,1.3-1,1.9c-0.5,0.7-1.1,1.4-0.7,2.3c0.4,0.9,1.6,0.9,2.1,0.9c2.4,0,4.4-0.9,5.6-2.2
          c1.3,1.9,4,3.2,7.1,3.2c4.4,0,8-2.7,8-6S53.4,31,49,31z M36.5,40c0.6-0.8,1.3-1.8,1.3-3s-0.7-2.2-1.3-3c2.5,0.1,4.5,1.4,4.5,3
          S39,39.9,36.5,40z M49,41c-3.3,0-6-1.8-6-4s2.7-4,6-4s6,1.8,6,4S52.3,41,49,41z"
        fill={companyColour}
      />
    </g>
    <path
      id="Grey_1_"
      d="M60.4,7C61.3,6.8,62,6,62,5c0-2.8-2.2-5-5-5H9C6.2,0,4,2.2,4,5c0,1,0.7,1.8,1.6,2
        c-1.4,2.1-2.5,4.3-3.4,6.7c0,0.1-0.1,0.2-0.1,0.2C0.7,17.6,0,21.5,0,25.5c0,13,7.7,25,19.6,30.4c0,0,0,0,0,0C19.7,56,19.8,56,20,56
        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h26c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.3,0,0.4-0.1c0,0,0,0,0,0
        C58.3,50.5,66,38.5,66,25.5C66,18.8,64,12.5,60.4,7z M58,7c1.5,2,2.7,4.1,3.6,6.4c-13.1,2.2-22.6,0.7-31.1-0.6
        c-8.3-1.3-16.1-2.5-25.7-0.2c0.9-2,2-3.9,3.3-5.6H58z M9,2h48c1.7,0,3,1.3,3,3H6C6,3.3,7.3,2,9,2z M45.8,54h-0.7
        c0.1-0.7,0.3-1.3,0.6-2c0.5-1.5,1-3,0.2-4.5c-0.3-0.5-0.9-0.7-1.3-0.4c-0.5,0.3-0.7,0.9-0.4,1.3c0.4,0.7,0.1,1.5-0.4,2.9
        C43.5,52.2,43.2,53,43,54h-1.2c-0.1-0.3-0.2-0.6-0.4-0.9c-0.3-0.6-0.5-1.1-0.5-2c0-0.6-0.4-1-0.9-1.1c-0.5,0-1,0.4-1.1,0.9
        c-0.1,1.5,0.4,2.4,0.7,3c0,0,0,0,0,0H27.9c-0.1-1.4,0.3-2.5,0.8-3.8c0.9-2.5,1.9-5.3,0.3-10.5c-0.2-0.5-0.7-0.8-1.3-0.7
        c-0.5,0.2-0.8,0.7-0.7,1.3c1.4,4.5,0.6,6.9-0.2,9.2c-0.5,1.4-1,2.8-0.9,4.5h-3c-0.1-1.6,0.5-3.2,1-5c0.9-2.9,1.9-6.2,0-9.5
        c-0.3-0.5-0.9-0.6-1.4-0.3c-0.5,0.3-0.6,0.9-0.3,1.4c1.5,2.5,0.7,5.1-0.2,7.9c0,0.2-0.1,0.3-0.1,0.5c-0.2-0.4-0.4-0.8-0.7-1.2
        c-1.5-2.6-2.9-4.8-0.2-11.4c0.2-0.5,0-1.1-0.6-1.3c-0.5-0.2-1.1,0-1.3,0.6c-3,7.5-1.3,10.3,0.4,13.1c0.7,1.1,1.3,2.1,1.6,3.5
        c0,0,0,0,0,0c-0.1,0.6-0.1,1.2-0.1,1.8h-0.7C9.2,48.8,2,37.7,2,25.5c0-3.6,0.6-7.2,1.8-10.5c9.9-2.7,17.5-1.5,26.4-0.2
        c5.4,0.8,11.3,1.7,18.2,1.7c4.2,0,8.8-0.3,13.9-1.2C63.4,18.5,64,22,64,25.5C64,37.7,56.8,48.8,45.8,54z"
      fill="#899198"
    />
  </svg>
);

PetFriendlyOffice.defaultProps = {
  companyColour: '#ab1923',
};

PetFriendlyOffice.propTypes = {
  companyColour: PropTypes.string,
};

export default PetFriendlyOffice;
