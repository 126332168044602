import React from 'react';
import PropTypes from 'prop-types';

const HealthInsurace = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 66"
    className={className}
  >
    <title>Health Insurance</title>
    <g id="Grey">
      <g id="Object2">
        <path
          d="M49,39a1,1,0,0,0,1-1V15s0,0,0-.06a1,1,0,0,0-.05-.26,1,1,0,0,0,0-.1,1,1,0,0,0-.19-.28h0l-14-14h0A1,1,0,0,0,35.42.1l-.09,0A1,1,0,0,0,35.05,0H3.18A3.21,3.21,0,0,0,0,3.21V62.8A3.21,3.21,0,0,0,3.18,66H46.82A3.21,3.21,0,0,0,50,62.79v-.94a1,1,0,0,0-2,0v.93A1.2,1.2,0,0,1,46.82,64H3.18A1.2,1.2,0,0,1,2,62.79V3.21A1.2,1.2,0,0,1,3.18,2H34V13.95A2.08,2.08,0,0,0,36.08,16H48V38A1,1,0,0,0,49,39ZM36.08,14a.08.08,0,0,1-.08-.08V3.42L46.59,14Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M22,5H6A1,1,0,0,0,6,7H22a1,1,0,0,0,0-2Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M30,7a1,1,0,0,0,0-2H27a1,1,0,0,0,0,2Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M6,13h8a1,1,0,0,0,0-2H6a1,1,0,0,0,0,2Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M19,12a1,1,0,0,0,1,1H30a1,1,0,0,0,0-2H20A1,1,0,0,0,19,12Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M6,19H9a1,1,0,0,0,0-2H6a1,1,0,0,0,0,2Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M21,54a1,1,0,0,0-1-1H6a1,1,0,0,0,0,2H20A1,1,0,0,0,21,54Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M38,53H26a1,1,0,0,0,0,2H38a1,1,0,0,0,0-2Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M6,58a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z"
          transform="translate(0 0)"
          fill="#899198"
        />
        <path
          d="M16,60H38a1,1,0,0,0,0-2H16a1,1,0,0,0,0,2Z"
          transform="translate(0 0)"
          fill="#899198"
        />
      </g>
    </g>
    <g id="Coloured">
      <g id="Object1">
        <path
          d="M45,36a1,1,0,0,0,0-2H43.21A12.94,12.94,0,0,0,44,29.37C44,23,40.14,19,33.93,19a10.57,10.57,0,0,0-8.43,4.17A10.52,10.52,0,0,0,17.14,19h-.06C10.86,19,7,23,7,29.37A13,13,0,0,0,7.79,34H5a1,1,0,0,0,0,2H8.78a20,20,0,0,0,3.9,4.32c.39.35.76.68,1.11,1,2.6,2.5,10.73,9.15,11.08,9.43a1,1,0,0,0,1.26,0c.35-.28,8.49-6.93,11.08-9.43.35-.34.73-.68,1.12-1A20,20,0,0,0,42.22,36ZM9,29.37C9,24.13,12,21,17.08,21h0a8.55,8.55,0,0,1,7.49,4.48,1,1,0,0,0,1.75,0A8.51,8.51,0,0,1,33.92,21C39,21,42,24.13,42,29.37A10.61,10.61,0,0,1,41,34H32a1,1,0,0,0-.89.55l-.19.37-2.06-3.44a1,1,0,0,0-1.81.2l-1.91,5.74L23,28.76a1,1,0,0,0-1.92-.07L19.28,34H10A10.62,10.62,0,0,1,9,29.37Zm28,9.46c-.41.36-.8.72-1.17,1.07-2.12,2-8.32,7.16-10.32,8.8-2-1.64-8.2-6.76-10.33-8.8-.37-.35-.76-.7-1.16-1.07A23.46,23.46,0,0,1,11.21,36H20a1,1,0,0,0,.95-.68l.91-2.74L24,41.24A1,1,0,0,0,25,42h0a1,1,0,0,0,.95-.68l2.31-6.94,1.88,3.14A1,1,0,0,0,31,38a1,1,0,0,0,.86-.55L32.62,36h7.17A23.51,23.51,0,0,1,37,38.83Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M65.4,39.92l-11-4.83a1,1,0,0,0-.8,0l-11,4.83a1,1,0,0,0-.6.92V54.08a6.84,6.84,0,0,0,2.82,5.51l8.59,6.22a1,1,0,0,0,1.17,0l8.59-6.22A6.84,6.84,0,0,0,66,54.08V40.83A1,1,0,0,0,65.4,39.92ZM64,54.07A4.83,4.83,0,0,1,62,58l-8,5.8L46,58h0a4.83,4.83,0,0,1-2-3.89V41.49l10-4.39,10,4.39Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M47.63,51.22a1,1,0,1,0-1.25,1.56l5,4a1,1,0,0,0,1.4-.15l9-11a1,1,0,0,0-1.55-1.27L51.85,54.6Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
      </g>
    </g>
  </svg>
);

HealthInsurace.defaultProps = {
  companyColour: '#ab1923',
};

HealthInsurace.propTypes = {
  companyColour: PropTypes.string,
};

export default HealthInsurace;
