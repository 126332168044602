import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Spinner } from '@lionstep/ui';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useIsFetching } from '@tanstack/react-query';

// Actions
import { getCreditsPackages, getPurchases } from '../../redux/actions';

// Hooks
import useIsLionstepAdmin from '../../../../hooks/useIsLionstepAdmin';

// Components
import AddCreditsCard from './components/AddCreditsCard';
import AllPurchasesCard from './components/AllPurchasesCard';
import InfoPlusIconWithTooltip from '../../../../components/refactoring/infoPlusIconWithTooltip';
import PlanCard from './components/PlanCard';
import PageHeading from '../../../../components/refactoring/pageHeading/PageHeading';

// Queries
import { COMPANY_QUERY_KEYS } from '../../../../queries/companyQueries';

// Styles
import styles from './purchases.module.scss';

const Purchases = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isFetchingCompanyPlan = useIsFetching({
    queryKey: [COMPANY_QUERY_KEYS.plan],
  });
  const isLionstepAdmin = useIsLionstepAdmin();

  useEffect(() => {
    if (!isLionstepAdmin) {
      history.push('/');
    } else {
      dispatch(getCreditsPackages());
      dispatch(getPurchases());
    }
  }, []);

  return (
    <Grid.Container className={styles.purchases}>
      <Helmet>
        <title>{t('purchases_page.title')} | Lionstep</title>
      </Helmet>

      {!!isFetchingCompanyPlan && <Spinner position="absolute" size="100" />}

      <Grid.Row gutter={30}>
        <Grid.Col
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          <PageHeading>
            {t('purchases_page.title')}
            <InfoPlusIconWithTooltip />
          </PageHeading>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row gutter={[30, 30]}>
        <Grid.Col
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          <PlanCard />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row gutter={[30, 30]}>
        <Grid.Col
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          <AddCreditsCard />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row gutter={[30, 30]}>
        <Grid.Col
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          <AllPurchasesCard />
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

export default Purchases;
