import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@lionstep/ui';
import classnames from 'classnames/bind';

// Styles
import styles from './pageHeading.module.scss';

const cx = classnames.bind(styles);

const PageHeading = ({ className, children, additionalInfo }) => (
  <>
    {additionalInfo && (
      <p className={styles.additionalInfo}>{additionalInfo}</p>
    )}
    <Heading level={2} className={cx(styles.pageHeading, className)}>
      {children}
    </Heading>
  </>
);

PageHeading.defaultProps = {
  additionalInfo: '',
  className: '',
};

PageHeading.propTypes = {
  additionalInfo: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default PageHeading;
