import React from 'react';
import PropTypes from 'prop-types';

const TopEquipement = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 68 68"
    className={className}
  >
    <g id="Coloured_1_">
      <circle cx="6.5" cy="61.5" r="1.5" fill={companyColour} />
      <circle cx="11.5" cy="61.5" r="1.5" fill={companyColour} />
      <path
        d="M56.7,21H51c-0.6,0-1,0.4-1,1s0.4,1,1,1h5.6c0.7,0,1.3,0.6,1.3,1.4v25.3c0,0.8-0.6,1.4-1.3,1.4H11.3
          c-0.7,0-1.3-0.6-1.3-1.4V24.4c0-0.8,0.6-1.4,1.3-1.4H17c0.6,0,1-0.4,1-1s-0.4-1-1-1h-5.6C9.5,21,8,22.5,8,24.4v25.3
          c0,1.9,1.5,3.4,3.3,3.4h45.3c1.8,0,3.3-1.5,3.3-3.4V24.4C60,22.5,58.5,21,56.7,21z"
        fill={companyColour}
      />
      <path
        d="M26.4,20.8l3.7,2.6l-1.4,4.3c-0.1,0.4,0,0.9,0.4,1.1c0.3,0.3,0.8,0.3,1.2,0l3.7-2.7l3.7,2.7
          c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.3-0.3,0.5-0.7,0.4-1.1l-1.4-4.3l3.7-2.6c0.4-0.3,0.5-0.7,0.4-1.1
          c-0.1-0.4-0.5-0.7-1-0.7h-4.6l-1.4-4.3C34.8,14.3,34.4,14,34,14s-0.8,0.3-0.9,0.7L31.6,19H27c-0.4,0-0.8,0.3-1,0.7
          C25.9,20.1,26.1,20.5,26.4,20.8z M32.3,21c0.4,0,0.8-0.3,0.9-0.7l0.7-2.1l0.7,2.1c0.1,0.4,0.5,0.7,0.9,0.7h2.2l-1.8,1.3
          c-0.4,0.3-0.5,0.7-0.4,1.1l0.7,2.1l-1.8-1.3c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2l-1.8,1.3l0.7-2.1c0.1-0.4,0-0.9-0.4-1.1
          L30.1,21H32.3z"
        fill={companyColour}
      />
    </g>
    <g id="Grey_1_">
      <path
        d="M25,44H43c2.2,0,4-1.8,4-4V4c0-2.2-1.8-4-4-4h-1.5h-15H25c-2.2,0-4,1.8-4,4V40C21,42.2,22.8,44,25,44z M38.5,2
          l-0.7,1.7C37.7,3.9,37.5,4,37.4,4h-6.9c-0.1,0-0.2-0.1-0.3-0.3L29.5,2H38.5z M23,4c0-1.1,0.9-2,2-2h2.3l1.1,2.5
          C28.8,5.4,29.6,6,30.6,6h6.9c0.9,0,1.8-0.6,2.2-1.5L40.7,2H43c1.1,0,2,0.9,2,2V40c0,1.1-0.9,2-2,2H25c-1.1,0-2-0.9-2-2V4z"
        fill="#899198"
      />
      <path
        d="M66,56h-1V24c0-4.4-3.6-8-8-8h-6c-0.6,0-1,0.4-1,1s0.4,1,1,1h6c3.3,0,6,2.7,6,6v32H48.4H19.6H5V24
          c0-3.3,2.7-6,6-6h6c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6c-4.4,0-8,3.6-8,8v32H2c-1.1,0-2,0.9-2,2v3c0,3.9,3.1,7,7,7h54c3.9,0,7-3.1,7-7
          v-3C68,56.9,67.1,56,66,56z M45.6,58l-0.9,2.7c-0.1,0.2-0.2,0.3-0.3,0.3H23.6c-0.1,0-0.2-0.1-0.3-0.2L22.4,58H45.6z M66,61
          c0,2.8-2.2,5-5,5H7c-2.8,0-5-2.2-5-5l0-3l2,0c0,0,0,0,0,0h16.3l1.1,3.5c0.4,0.9,1.2,1.5,2.2,1.5h20.9c0.9,0,1.8-0.6,2.2-1.6
          l1.1-3.4H64c0,0,0,0,0,0l2,0V61z"
        fill="#899198"
      />
    </g>
  </svg>
);

TopEquipement.defaultProps = {
  companyColour: '#ab1923',
};

TopEquipement.propTypes = {
  companyColour: PropTypes.string,
};

export default TopEquipement;
