import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Button,
  Spinner,
  Grid,
  Heading,
  Text,
  FormInput,
  toast,
} from '@lionstep/ui';

// Operations
import userDataOperations from '../../../redux/ducks/userData/operations';

// Helpers
import { getRecaptcha } from '../../../support';

// Hooks
import useSearchParams from '../../../hooks/useSearchParams';

// Components
import { ReactComponent as Logo } from '../../../assets/icons/lionstep-logo.svg';

// Queries
import {
  useUserLoginMutation,
  useSignupCollaboratorMutation,
} from '../../../queries/userQueries';

// Styles
import styles from './setInvitePassword.module.scss';

const SetInvitePassword = () => {
  const [showSpinner, setShowSpinner] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { verifySignupCollaborator } = userDataOperations;
  const { mutate: loginUser, isLoading: isLoadingLoginUser } =
    useUserLoginMutation();
  const { mutate: signupCollaborator, isLoading: isLoadingSignupCollaborator } =
    useSignupCollaboratorMutation();

  const { params } = useSearchParams();

  const [tokenVerified, setTokenVerified] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string().email().required(t('Required')),
    name: Yup.string()
      .matches(/\w+\s+\w+/, t('Please provide your full name'))
      .required(t('Required')),
    password: Yup.string()
      .min(8, 'Password must be 8+ characters long')
      .matches(
        /^(?=.*\d)(?=.*[A-Z]).{8,}$/,
        'Password must contain at least one uppercase letter and one number',
      )
      .required(t('Required')),
  });

  const formMethods = useForm({
    defaultValues: {
      name: '',
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const token = await getRecaptcha();
        const { data } = await dispatch(
          verifySignupCollaborator({
            token: params.token,
            recaptcha_token: token,
          }),
        );
        setValue('name', `${data.first_name} ${data.last_name}`);
        setValue('email', data.email);
        setTokenVerified(true);
        setShowSpinner(false);
      } catch {
        setShowSpinner(false);
      }
    };

    verifyToken();
  }, [dispatch]);

  const onSubmit = async ({ name, email, password }) => {
    const [firstName, lastName] = name.split(' ');

    signupCollaborator(
      {
        data: {
          email,
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          password,
          recaptcha_token: await getRecaptcha(),
          token: params.token,
        },
      },
      {
        onSuccess: async () => {
          loginUser(
            {
              data: {
                email: email.trim(),
                password: password.trim(),
                recaptcha_token: await getRecaptcha(),
              },
            },
            {
              onSuccess: () => {
                history.push('/');
              },
              onError: (error) => {
                toast.error('Password could not be set', {
                  id: 'SIGNUP_COLLABORATOR_FAIL',
                  statusCode: error?.response?.status,
                });
              },
            },
          );
        },
      },
    );
  };

  const calculateTextToShow = () => {
    if (!showSpinner) {
      if (!tokenVerified) {
        return t('The invitation is no longer valid');
      }
      return t('Please set you password so you can login in the the future');
    }
    return '';
  };

  if (showSpinner && !tokenVerified)
    return <Spinner position="fixed" size={100} />;

  return (
    <Grid.Container>
      <Grid.Row className={styles.invitePassword} gutter={{ xs: 24, sm: 0 }}>
        <Grid.Col
          xs={24}
          md={{ span: 14, offset: 5 }}
          lg={{ span: 12, offset: 6 }}
          xl={{ span: 8, offset: 8 }}
        >
          {(showSpinner ||
            isLoadingLoginUser ||
            isLoadingSignupCollaborator) && (
            <Spinner position="fixed" size={100} />
          )}

          <Helmet>
            <title>Lionstep</title>
          </Helmet>

          <Logo className={styles.invitePasswordLogo} />

          <Heading
            className={styles.invitePasswordTitle}
            level={1}
            type="secondary"
          >
            {t('Welcome')} {getValues('name')}
          </Heading>

          <Text className={styles.invitePasswordInfo}>
            {calculateTextToShow()}
          </Text>

          {tokenVerified && (
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormInput
                  label={t('name')}
                  name="name"
                  placeholder={t('Name')}
                  error={errors.name?.message}
                />

                <FormInput
                  label={t('password')}
                  name="password"
                  type="password"
                  placeholder={t('Password')}
                  error={errors.password?.message}
                />

                <Button
                  className={styles.invitePasswordSubmitButton}
                  block
                  typeHtml="submit"
                >
                  {t('Save Changes and Login')}
                </Button>
              </form>
            </FormProvider>
          )}

          <Link className={styles.invitePasswordLoginLink} to="/login">
            {t('Back to login')}
          </Link>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

export default SetInvitePassword;
