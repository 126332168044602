/* eslint-disable import/prefer-default-export */
export const formatJobCandidatesStats = (statsMap) => ({
  interested: statsMap.interested,
  rejected: statsMap.rejected,
  not_interested: statsMap.not_interested + statsMap.not_interested_anonymous,
  pending: statsMap.pending,
  contacted:
    statsMap.contacted +
    statsMap.in_contact +
    statsMap.hold +
    statsMap.call_done,
  hired: statsMap.hired,
});
