import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, RadioButton, Label } from '@lionstep/ui';

// Constants
import { MULTIPLE_CHOICE_ANSWERS_TYPE } from '../../adminActions.constants';

// Styles

const QuestionYesNo = ({ question, questionIndex }) => {
  const { t } = useTranslation();

  return (
    <Grid.Row gutter={30}>
      <Grid.Col xs={24}>
        <Label htmlFor={`yes-no-choice-${questionIndex}`}>{t('answers')}</Label>
      </Grid.Col>
      {question.choices.map((cItem, cIndex) => (
        <Grid.Col xs={24} md="auto" key={`yes-no-choice-${cIndex}`}>
          <RadioButton
            checked={cItem.expected_str === MULTIPLE_CHOICE_ANSWERS_TYPE.valid}
            disabled
            inputAlign
            label={
              cItem.expected_str === MULTIPLE_CHOICE_ANSWERS_TYPE.valid
                ? t('extra_questions.yes_no_values.yes')
                : t('extra_questions.yes_no_values.no')
            }
            name={`yes-no-choice-${questionIndex}-${cIndex}`}
            value={cItem.expected_str}
          />
        </Grid.Col>
      ))}
    </Grid.Row>
  );
};

QuestionYesNo.propTypes = {
  question: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    hide: PropTypes.bool.isRequired,
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        choice: PropTypes.string.isRequired,
        expected_str: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  questionIndex: PropTypes.number.isRequired,
};

export default QuestionYesNo;
