import { pythonAxios } from './http';

export const uploadDocumentApi = ({ data } = {}) =>
  pythonAxios({
    method: 'post',
    url: '/upload/documents',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });

export const deleteDocumentApi = ({ filename }) =>
  pythonAxios({
    method: 'delete',
    url: `/upload/documents/${filename}`,
  });
