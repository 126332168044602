import React from 'react';
import PropTypes from 'prop-types';

const DentalCoverage = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 60"
    className={className}
  >
    <g id="Grey">
      <path
        fill="#899198"
        d="M29,43h18c3.3,0,6-2.7,6-6v-4c0-2.2-1.8-4-4-4h-1.7c-0.6,0-1,0.4-1,1s0.4,1,1,1H49c1.1,0,2,0.9,2,2v4
          c0,2.2-1.8,4-4,4H29c-2.2,0-4-1.8-4-4v-4c0-1.1,0.9-2,2-2h1.7c0.6,0,1-0.4,1-1s-0.4-1-1-1H27c-2.2,0-4,1.8-4,4v4
          C23,40.3,25.7,43,29,43z"
      />
      <path
        fill="#899198"
        d="M69,5H7C5.9,5,5,5.9,5,7v46c0,1.1,0.9,2,2,2h62c1.1,0,2-0.9,2-2V7C71,5.9,70.1,5,69,5z M69,53L7,53L7,7l62,0
          L69,53z"
      />
      <path
        fill="#899198"
        d="M70,0H6C2.7,0,0,2.7,0,6v48c0,3.3,2.7,6,6,6h64c3.3,0,6-2.7,6-6V6C76,2.7,73.3,0,70,0z M74,54c0,2.2-1.8,4-4,4
          H6c-2.2,0-4-1.8-4-4V6c0-2.2,1.8-4,4-4h64c2.2,0,4,1.8,4,4V54z"
      />
      <path
        fill="#899198"
        d="M12,13h6c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1S11.4,13,12,13z"
      />
      <path
        fill="#899198"
        d="M12,17h6c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1S11.4,17,12,17z"
      />
      <path
        fill="#899198"
        d="M12,21h6c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1S11.4,21,12,21z"
      />
      <path
        fill="#899198"
        d="M12,49h2c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1S11.4,49,12,49z"
      />
      <path
        fill="#899198"
        d="M17,48c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2C17.4,47,17,47.4,17,48z"
      />
      <path
        fill="#899198"
        d="M24,49h2c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1S23.4,49,24,49z"
      />
      <path
        fill="#899198"
        d="M32,47h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2c0.6,0,1-0.4,1-1S32.6,47,32,47z"
      />
      <path
        fill="#899198"
        d="M38,47h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2c0.6,0,1-0.4,1-1S38.6,47,38,47z"
      />
      <path
        fill="#899198"
        d="M44,47h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2c0.6,0,1-0.4,1-1S44.6,47,44,47z"
      />
      <path
        fill="#899198"
        d="M50,47h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2c0.6,0,1-0.4,1-1S50.6,47,50,47z"
      />
      <path
        fill="#899198"
        d="M54,49h2c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1S53.4,49,54,49z"
      />
      <path
        fill="#899198"
        d="M60,49h2c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1S59.4,49,60,49z"
      />
      <path
        fill="#899198"
        d="M64,15c0.6,0,1-0.4,1-1v-2c0-0.6-0.4-1-1-1s-1,0.4-1,1v2C63,14.6,63.4,15,64,15z"
      />
      <path
        fill="#899198"
        d="M64,21c0.6,0,1-0.4,1-1v-2c0-0.6-0.4-1-1-1s-1,0.4-1,1v2C63,20.6,63.4,21,64,21z"
      />
      <path
        fill="#899198"
        d="M64,27c0.6,0,1-0.4,1-1v-2c0-0.6-0.4-1-1-1s-1,0.4-1,1v2C63,26.6,63.4,27,64,27z"
      />
      <path
        fill="#899198"
        d="M64,33c0.6,0,1-0.4,1-1v-2c0-0.6-0.4-1-1-1s-1,0.4-1,1v2C63,32.6,63.4,33,64,33z"
      />
      <path
        fill="#899198"
        d="M64,39c0.6,0,1-0.4,1-1v-2c0-0.6-0.4-1-1-1s-1,0.4-1,1v2C63,38.6,63.4,39,64,39z"
      />
      <path
        fill="#899198"
        d="M64,45c0.6,0,1-0.4,1-1v-2c0-0.6-0.4-1-1-1s-1,0.4-1,1v2C63,44.6,63.4,45,64,45z"
      />
    </g>
    <g id="Coloured">
      <path
        fill={companyColour}
        d="M31.9,39c-0.5,0-0.9-0.1-1.4-0.3l-0.3-0.1c-1.7-0.6-2.7-2.4-2.4-4.2c0.2-1.3,0.2-2.7-0.1-4
          c-0.3-1.6-0.8-3.2-1.4-4.7c-0.3-0.6-0.6-1.3-1-1.9c-1.1-1.6-1.5-3.6-1.3-5.5c0.5-3.9,3.6-6.9,7.5-7.4c0.4,0,0.7-0.1,1.1-0.1
          c0,0,0.1,0,0.1,0l0,0c1.6,0,3.2,0.5,4.5,1.3c0.4,0.3,1,0.3,1.5,0c1.7-1,3.8-1.5,5.7-1.2c3.8,0.5,6.9,3.5,7.4,7.3c0,0,0,0,0,0
          c0.2,1.9-0.2,3.9-1.2,5.5c-0.4,0.6-0.8,1.3-1,1.9c-0.6,1.5-1.1,3-1.4,4.6c-0.3,1.4-0.3,2.7-0.1,4c0.3,1.8-0.8,3.7-2.5,4.2l-0.1,0.1
          c-1,0.4-2.2,0.3-3.2-0.2c-0.9-0.5-1.6-1.3-1.9-2.4l-1-3.8c-0.2-0.7-0.8-1.2-1.5-1.3c-0.7,0.1-1.3,0.6-1.5,1.3l-1,3.7
          c-0.3,1.1-1,1.9-2,2.4C33.1,38.9,32.5,39,31.9,39z M32.7,13C32.7,13,32.6,13,32.7,13c-0.3,0-0.6,0-0.9,0.1c-3,0.4-5.3,2.7-5.7,5.6
          c-0.2,1.4,0.2,2.9,1,4.1c0.5,0.7,0.9,1.4,1.2,2.2c0.7,1.6,1.2,3.3,1.5,5c0.3,1.6,0.4,3.2,0.1,4.8c-0.2,0.8,0.3,1.7,1.1,2l0.3,0.1
          c0.5,0.2,1,0.2,1.5-0.1c0.5-0.2,0.8-0.6,0.9-1.1l1-3.7c0.4-1.5,1.7-2.6,3.3-2.8c0,0,0.1,0,0.2,0c1.6,0.1,2.9,1.2,3.3,2.8l1,3.7
          c0.1,0.5,0.5,0.9,0.9,1.1c0.5,0.3,1.1,0.3,1.5,0.1l0.2-0.1c0.9-0.3,1.4-1.1,1.2-2c-0.3-1.6-0.3-3.2,0.1-4.8c0.3-1.7,0.9-3.4,1.5-5
          c0.3-0.8,0.7-1.5,1.2-2.2c0.8-1.2,1.1-2.7,0.9-4.1c-0.4-2.9-2.8-5.2-5.7-5.6c-1.6-0.2-3.1,0.1-4.5,0.9c-1.1,0.7-2.5,0.7-3.6,0
          C35.2,13.4,34,13,32.7,13C32.7,13,32.7,13,32.7,13z"
      />
    </g>
  </svg>
);

DentalCoverage.defaultProps = {
  companyColour: '#ab1923',
};

DentalCoverage.propTypes = {
  companyColour: PropTypes.string,
};

export default DentalCoverage;
