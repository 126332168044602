import * as types from './analytics.types';

const initialState = {
  data: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_FEEDBACK_ANALYTICS_START:
      return {
        ...state,
        data: null,
        isLoading: true,
      };

    case types.GET_FEEDBACK_ANALYTICS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };

    case types.GET_FEEDBACK_ANALYTICS_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case types.CLEAR_FEEDBACK_ANALYTICS_DATA:
      return { ...initialState };

    default:
      return state;
  }
};
