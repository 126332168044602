import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames/bind';
import { Icon } from '@lionstep/ui';

// Components
import HeaderLanguageDropdown from './components/HeaderLanguageDropdown';
import HeaderProfileDropdown from './components/HeaderProfileDropdown';
import { ReactComponent as LogoDesktop } from '../../../assets/icons/lionstep-logo-white.svg';
import { ReactComponent as LogoMobile } from '../../../assets/icons/lionstep-icon-white.svg';
import MobileDrawer from './components/MobileDrawer';

// Hooks
import useMediaQuery from '../../../hooks/useMediaQuery';
import useSearchParams from '../../../hooks/useSearchParams';
import useIsLionstepAdmin from '../../../hooks/useIsLionstepAdmin';

// Styles
import styles from './headerPostLogin.module.scss';

const cx = classnames.bind(styles);

const HeaderPostLogin = ({ user, totalCredits, className }) => {
  const { t } = useTranslation();
  const { params } = useSearchParams();
  const { pathname } = useLocation();
  const isLionstepAdmin = useIsLionstepAdmin();

  const [showMobileDrawer, setShowMobileDrawer] = useState(false);
  const { activeBreakpoints } = useMediaQuery();

  const handleOnMobileSideDrawerToggle = () => {
    setShowMobileDrawer((prevShow) => !prevShow);
  };

  const handleOnMobileSideDrawerClose = () => {
    setShowMobileDrawer(false);
  };

  useEffect(() => {
    if (activeBreakpoints.includes('lg') && showMobileDrawer) {
      handleOnMobileSideDrawerClose();
    }
  }, [activeBreakpoints]);

  return (
    <nav className={cx(styles.header, className)}>
      {/* LEFT */}
      <div className={styles.headerLeft}>
        <Link to="/">
          <LogoDesktop className={styles.logoDesktop} />

          <LogoMobile className={styles.logoMobile} />
        </Link>
      </div>

      {/* MIDDLE */}
      <div className={styles.headerMiddle}>
        {/* MIDDLE LEFT */}
        <div className={styles.middleLeft}>
          <Link
            className={cx(styles.headerLink, {
              [styles.headerLinkActive]: pathname === '/' && !params.archived,
            })}
            to="/"
          >
            <Icon className={styles.headerLinkIcon} name="Dashboard" />
            <span className={styles.headerLinkLabel}>{t('dashboard')}</span>
          </Link>
          <span className={styles.headerLinkSpacer} />
          <Link
            className={cx(styles.headerLink, {
              [styles.headerLinkActive]: !!params.archived,
            })}
            to={{ pathname: '/', search: 'archived=true' }}
          >
            <Icon className={styles.headerLinkIcon} name="Archive" />
            <span className={styles.headerLinkLabel}>{t('archived_jobs')}</span>
          </Link>
        </div>

        {/* MIDDLE RIGHT */}
        <div className={styles.middleRight}>
          {/* CREDITS */}
          <div className={styles.creditDesktop}>
            <span className={styles.creditLabel}>{t('credits')}</span>
            <span className={styles.creditValue}>{totalCredits || 0}</span>
          </div>
          <div className={styles.creditMobile}>
            <span className={styles.creditMobileLabel}>
              {`${totalCredits || 0} ${t('credits')}`}
            </span>
          </div>

          {/* LOGGED IN AS */}
          <div className={styles.loggedInInfoDesktop}>
            <span className={styles.loggedInInfoLabel}>
              {t('header.logged_in_as')}
            </span>
            <span
              className={styles.loggedInInfoValue}
              data-testid="header-username"
            >
              {user.display_name}
              {isLionstepAdmin && (
                <Icon name="InfoPlus" className={styles.loggedInInfoIcon} />
              )}
            </span>
          </div>
        </div>
      </div>

      {/* RIGHT */}
      <div
        className={cx(styles.headerRight, {
          [styles.headerRightMobileActive]: showMobileDrawer,
        })}
      >
        <span className={styles.languageDropdownDesktop}>
          <HeaderLanguageDropdown />
        </span>
        <span className={styles.profileDropdownDesktop}>
          <HeaderProfileDropdown user={user} />
        </span>

        <Icon
          name="MoreHorizontal"
          size={45}
          className={styles.viewMoreMobile}
          onClick={handleOnMobileSideDrawerToggle}
        />

        <MobileDrawer
          show={showMobileDrawer}
          user={user}
          onClose={handleOnMobileSideDrawerClose}
        />
      </div>
    </nav>
  );
};

HeaderPostLogin.defaultProps = {
  className: '',
};

HeaderPostLogin.propTypes = {
  className: PropTypes.string,
  totalCredits: PropTypes.number.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    local_id: PropTypes.string.isRequired,
    profile: PropTypes.shape().isRequired,
  }).isRequired,
};

export default HeaderPostLogin;
