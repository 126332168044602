import React from 'react';
import PropTypes from 'prop-types';

const ChildCareStipend = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 68 70"
    className={className}
  >
    <path
      id="Grey"
      d="M67.8,21.4c-0.3-0.9-1-1.8-1.9-2.2c-10.5-5.3-20.3-7.6-28.8-8.1C38.8,10.1,40,8.2,40,6
        c0-3.3-2.7-6-6-6s-6,2.7-6,6c0,2.1,1.1,4,2.7,5c-15.4,0.5-26,6.3-28.9,8.1c-0.9,0.6-1.6,1.5-1.8,2.5c-0.2,0.9,0,1.9,0.6,2.7
        c1.1,1.5,3.2,2,4.8,1.1c1.1-0.6,3.3-1.9,6.5-3.1V38h2V21.5c4.8-1.7,11.3-3.2,19-3.3V50h2V18.2c5.5,0.1,11.6,1.1,18,3.2V41h2V22.2
        c2.5,0.9,5.1,2.1,7.7,3.4c0.5,0.3,1.1,0.4,1.6,0.4c1.3,0,2.5-0.7,3.2-1.9C68,23.3,68.1,22.3,67.8,21.4z M34,2c2.2,0,4,1.8,4,4
        s-1.8,4-4,4s-4-1.8-4-4S31.8,2,34,2z M65.8,23.1c-0.4,0.8-1.4,1.1-2.2,0.7c-11-5.7-21.3-7.6-30.1-7.6c-15.2,0-26.2,5.7-29.1,7.4
        c-0.7,0.4-1.7,0.2-2.2-0.5C2,22.7,2,22.3,2,22c0.1-0.5,0.4-0.9,0.9-1.2C7.6,18,32.5,4.6,65.1,21c0.4,0.2,0.7,0.6,0.9,1
        C66.1,22.4,66,22.8,65.8,23.1z"
      fill="#899198"
    />
    <g id="Coloured">
      <path
        d="M44.1,56.3l-6.4-0.9l-2.9-5.8c-0.3-0.7-1.5-0.7-1.8,0l-2.9,5.8l-6.4,0.9c-0.4,0.1-0.7,0.3-0.8,0.7
          c-0.1,0.4,0,0.8,0.3,1l4.6,4.5l-1.1,6.4c-0.1,0.4,0.1,0.8,0.4,1c0.3,0.2,0.7,0.3,1.1,0.1l5.7-3l5.7,3C39.9,70,40,70,40.2,70
          c0.2,0,0.4-0.1,0.6-0.2c0.3-0.2,0.5-0.6,0.4-1l-1.1-6.4l4.6-4.5c0.3-0.3,0.4-0.7,0.3-1S44.5,56.3,44.1,56.3z M38.3,61.4
          C38.1,61.6,38,62,38,62.3l0.8,4.9l-4.4-2.3c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1l-4.4,2.3l0.8-4.9c0.1-0.3-0.1-0.7-0.3-0.9
          L26.2,58l4.9-0.7c0.3,0,0.6-0.3,0.8-0.5l2.2-4.4l2.2,4.4c0.1,0.3,0.4,0.5,0.8,0.5l4.9,0.7L38.3,61.4z"
        fill={companyColour}
      />
      <path
        d="M31,38h6c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1S30.4,38,31,38z"
        fill={companyColour}
      />
      <path
        d="M10,30h6c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1S9.4,30,10,30z"
        fill={companyColour}
      />
      <path
        d="M51,32h6c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1S50.4,32,51,32z"
        fill={companyColour}
      />
      <path
        d="M13,37c-5,0-9,4-9,9s4,9,9,9c5,0,9-4,9-9S18,37,13,37z M13,53c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7
    S16.9,53,13,53z"
        fill={companyColour}
      />
      <path
        d="M62,46.7L56.3,41c-1.2-1.2-3.4-1.2-4.7,0L46,46.7c-0.6,0.6-1,1.5-1,2.3c0,0.9,0.3,1.7,1,2.3l5.7,5.7
          c0.6,0.6,1.5,1,2.3,1s1.7-0.3,2.3-1l5.7-5.7c0.6-0.6,1-1.5,1-2.3C63,48.1,62.7,47.3,62,46.7z M60.6,49.9l-5.7,5.7
          c-0.5,0.5-1.3,0.5-1.9,0l-5.7-5.7C47.1,49.7,47,49.3,47,49s0.1-0.7,0.4-0.9l5.7-5.7c0.3-0.3,0.6-0.4,0.9-0.4s0.7,0.1,0.9,0.4
          l5.7,5.7c0.2,0.2,0.4,0.6,0.4,0.9S60.9,49.7,60.6,49.9z"
        fill={companyColour}
      />
    </g>
  </svg>
);

ChildCareStipend.defaultProps = {
  companyColour: '#ab1923',
};

ChildCareStipend.propTypes = {
  companyColour: PropTypes.string,
};

export default ChildCareStipend;
