/* eslint-disable import/prefer-default-export */
import { toast } from '@lionstep/ui';

import * as types from './insights.types';

import { getInsightsByJobIdApi } from '../../../../api/insights.api';

export const getInsightsByJobId =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.GET_INSIGHTS_BY_JOB_ID_START });

      const { data } = await getInsightsByJobIdApi({ id });

      dispatch({ type: types.GET_INSIGHTS_BY_JOB_ID_SUCCESS, payload: data });
    } catch (error) {
      toast.error('Error getting insights data', {
        id: types.GET_INSIGHTS_BY_JOB_ID_FAILS,
        statusCode: error?.response?.status,
      });
      dispatch({ type: types.GET_INSIGHTS_BY_JOB_ID_FAILS });
    }
  };
