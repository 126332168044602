import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Spinner } from '@lionstep/ui';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import classnames from 'classnames/bind';
import orderBy from 'lodash/orderBy';

// Actions
import {
  getFeedbackAnalytics,
  clearFeedbackAnalytics,
} from './analytics.actions';
import { getInsightsByJobId } from '../Insights/insights.actions';

// Helpers
import {
  candidateFunnelFormatter,
  newSourcedCandidateFormatter,
  validationByChannelFormatter,
  candidateByStageFormatter,
} from './analytics.helper';

// Component
import ContentCard from '../../../../components/refactoring/contentCard';
import PageHeading from '../../../../components/refactoring/pageHeading/PageHeading';
import InfoPlusIconWithTooltip from '../../../../components/refactoring/infoPlusIconWithTooltip';
import CandidateFunnelChart from '../../components/Charts/CandidateFunnelChart';
import NewSourcedCandidateChart from '../../components/Charts/NewSourcedCandidateChart';
import ValidatedByChannelChart from '../../components/Charts/ValidatedByChannelChart';
import CandidateByStageChart from '../../components/Charts/CandidateByStageChart';

// Constants
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
} from '../../../../constants/date.const';
import { JOB_STATUSES_KEY_VALUE } from '../../../../constants';

// Selectors
import {
  feedbackAnalyticsSelector,
  isLoadingSelector,
} from './analytics.selectors';
import { jobSelector } from '../../redux/selectors/job.selectors';
import { insightsSelector } from '../Insights/insights.selectors';

// Styles
import styles from './analytics.module.scss';

const cx = classnames.bind(styles);

const Analytics = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const feedbackAnalytics = useSelector(feedbackAnalyticsSelector);
  const isLoading = useSelector(isLoadingSelector);
  const job = useSelector(jobSelector);
  const insights = useSelector(insightsSelector);

  useEffect(() => {
    dispatch(getFeedbackAnalytics({ id }));
    dispatch(getInsightsByJobId({ id }));

    return () => dispatch(clearFeedbackAnalytics());
  }, []);

  return (
    <Grid.Container className={styles.analytics}>
      <Helmet>
        <title>{t('analytics_page.title')} | Lionstep</title>
      </Helmet>

      {isLoading && <Spinner position="fixed" size={100} />}

      <Grid.Row>
        <Grid.Col xs={24}>
          <PageHeading additionalInfo={job?.opening_title}>
            {t('analytics_page.title')}
            <InfoPlusIconWithTooltip />
          </PageHeading>
        </Grid.Col>
      </Grid.Row>
      {feedbackAnalytics && (
        <Grid.Row>
          <Grid.Col xs={24}>
            <ContentCard>
              <h5>
                <strong>General Data:</strong>
              </h5>
              <ul>
                <li>{`Company: ${feedbackAnalytics.company ?? '/'}`}</li>
                <li>{`Job Title: ${feedbackAnalytics.position ?? '/'}`}</li>
                <li>{`Start Date of Search: ${
                  feedbackAnalytics.first_search_date
                    ? moment(feedbackAnalytics.first_search_date).format(
                        DATE_FORMAT,
                      )
                    : '/'
                }`}</li>
                <li>{`Contacted Candidates (active sourcing): ${
                  feedbackAnalytics.candidates_extension ?? '/'
                }`}</li>
                <li>{`Candidates from Job Platforms: ${
                  feedbackAnalytics.candidates_anonymous ?? '/'
                }`}</li>
                <li>{`Candidates Contacted in Total: ${
                  feedbackAnalytics.candidates_contacted ?? '/'
                }`}</li>
                <li>{`Candidates in the Funnel: ${
                  feedbackAnalytics.candidates_in_contact ?? '/'
                }`}</li>
                <li>
                  {`Validations: ${feedbackAnalytics.validated?.length ?? '/'}`}
                  {!!feedbackAnalytics.validated?.length && (
                    <ul>
                      {feedbackAnalytics.validated.map((item, index) => (
                        <li
                          key={`validated-${String(index)}-${item.ticket_id}`}
                        >
                          {`${item.first_name} ${
                            item.last_name
                          } - Validated at ${moment(item.date).format(
                            DATE_FORMAT,
                          )} - Zendesk ticket `}
                          <a
                            href={`https://lionstep.zendesk.com/agent/tickets/${item.ticket_id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {`#${item.ticket_id}`}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
                <li>
                  {`Scheduled calls: ${
                    feedbackAnalytics.scheduled_calls?.length ?? '/'
                  }`}
                  {!!feedbackAnalytics.scheduled_calls?.length && (
                    <ul>
                      {feedbackAnalytics.scheduled_calls.map((item, index) => (
                        <li
                          key={`scheduled-calls-${String(index)}-${
                            item.ticket_id
                          }`}
                        >
                          {`${moment(item.datetime).format(
                            DATE_TIME_FORMAT,
                          )} - Zendesk ticket `}
                          <a
                            href={`https://lionstep.zendesk.com/agent/tickets/${item.ticket_id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {`#${item.ticket_id}`}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
                <li>
                  {`Calls done: ${feedbackAnalytics.call_done?.amount ?? '/'}`}
                  {!!feedbackAnalytics.call_done?.ticket_ids?.length && (
                    <ul>
                      {feedbackAnalytics.call_done.ticket_ids.map(
                        (item, index) => (
                          <li key={`calls-done-${String(index)}-${item}`}>
                            <a
                              href={`https://lionstep.zendesk.com/agent/tickets/${item}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {`#${item}`}
                            </a>
                          </li>
                        ),
                      )}
                    </ul>
                  )}
                </li>
                <li>{`Conversion Rate Viewed JD/ CI: ${
                  feedbackAnalytics.viewed_per_check_interest
                    ? `${Math.abs(
                        feedbackAnalytics.viewed_per_check_interest * 100,
                      ).toFixed(2)}%`
                    : '/'
                }`}</li>
                <li>{`Conversion Rate Salary Sub./ Total JD View percent: ${
                  feedbackAnalytics.submitted_salary_per_total_viewed
                    ? `${Math.abs(
                        feedbackAnalytics.submitted_salary_per_total_viewed *
                          100,
                      ).toFixed(2)}%`
                    : '/'
                }`}</li>
                {feedbackAnalytics.status_history?.length && (
                  <li>
                    Status history:
                    <ul>
                      {feedbackAnalytics.status_history.map((item) => (
                        <li key={item.status}>
                          {JOB_STATUSES_KEY_VALUE[item.status]}:{' '}
                          {moment(item.changed_status_date).format(DATE_FORMAT)}
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
              </ul>

              <br />

              <h5>
                <strong>Dropped out Candidates Statistics:</strong>
              </h5>
              {feedbackAnalytics.dropped_out?.length ? (
                <ul>
                  {orderBy(
                    feedbackAnalytics.dropped_out,
                    ['percentage'],
                    ['desc'],
                  ).map((item) => (
                    <li key={`dropped-out-${item.status}`}>
                      {`${Number(item.percentage).toFixed(2)}% ${t(
                        `candidate_responses.${item.status}`,
                      )}`}
                      {!!item.reasons?.length && (
                        <ul>
                          {orderBy(
                            item.reasons,
                            ['not_interested_date'],
                            ['desc'],
                          ).map((reason) => (
                            <li key={`${item.status}-${reason.ticket_id}`}>
                              <a
                                href={`https://lionstep.zendesk.com/agent/tickets/${reason.ticket_id}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {`#${reason.ticket_id}`}
                              </a>
                              {reason.not_interested_date
                                ? ` - Dropped out at ${moment(
                                    reason.not_interested_date,
                                  ).format(DATE_FORMAT)}`
                                : ''}

                              {` - ${reason.reason || '/'}`}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <ul>
                  <li>No dropped out candidates</li>
                </ul>
              )}

              <br />

              <h5>
                <strong>Searches Feedback:</strong>
              </h5>
              <ul>
                <li>{`Last Search Date: ${
                  feedbackAnalytics.last_search_date
                    ? moment(feedbackAnalytics.last_search_date).format(
                        DATE_FORMAT,
                      )
                    : '/'
                }`}</li>
                <li>{`Candidates contacted in the last round: ${
                  feedbackAnalytics.candidates_contacted_last_round ?? '/'
                }`}</li>
                <li>{`Comments: ${feedbackAnalytics.comments ?? '/'}`}</li>
                <li>{`Quality Check: ${
                  feedbackAnalytics.quality_check || '/'
                }`}</li>
                <li>
                  {`Last three searches: ${
                    feedbackAnalytics.last_three_searches?.length ? '' : '/'
                  }`}
                  {!!feedbackAnalytics.last_three_searches?.length && (
                    <ul>
                      {feedbackAnalytics.last_three_searches.map(
                        (searchItem, index) => (
                          <li key={`last-three-searches-${String(index)}`}>
                            {`Count of profiles found: ${searchItem.count} - ${
                              searchItem.date
                                ? moment(searchItem.date).format(DATE_FORMAT)
                                : '/'
                            }`}
                          </li>
                        ),
                      )}
                    </ul>
                  )}
                </li>
              </ul>
            </ContentCard>
          </Grid.Col>
        </Grid.Row>
      )}
      {insights && (
        <Grid.Row gutter={[30, 30]}>
          <Grid.Col xs={24}>
            <ContentCard className={styles.contentCard}>
              <CandidateFunnelChart
                hasLegend
                formatterFunc={candidateFunnelFormatter}
                insights={insights}
              />
            </ContentCard>
          </Grid.Col>
          <Grid.Col xs={24} xl={12}>
            <ContentCard className={styles.contentCard}>
              <CandidateByStageChart
                formatterFunc={candidateByStageFormatter}
                insights={insights}
              />
            </ContentCard>
          </Grid.Col>
          <Grid.Col xs={24} xl={12}>
            <ContentCard className={styles.contentCard}>
              <ValidatedByChannelChart
                formatterFunc={validationByChannelFormatter}
                insights={insights}
              />
            </ContentCard>
          </Grid.Col>
          <Grid.Col xs={24} xl={12}>
            <ContentCard
              className={cx(styles.contentCard, styles.cardFullHeight)}
            >
              <NewSourcedCandidateChart
                hasLegend
                formatterFunc={newSourcedCandidateFormatter}
                insights={insights}
              />
            </ContentCard>
          </Grid.Col>
        </Grid.Row>
      )}
    </Grid.Container>
  );
};

export default Analytics;
