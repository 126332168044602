import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { Card, Grid, Icon, Tooltip } from '@lionstep/ui';
import moment from 'moment';
import classnames from 'classnames/bind';

// Selectors
import { totalCreditsSelector } from '../../../../redux/selectors/credits.selectors';

// Queries
import { COMPANY_QUERY_KEYS } from '../../../../../../queries/companyQueries';

// Constants
import {
  PLAN_OPTIONS,
  SUBSCRIPTION_PLAN_VALUES,
} from '../../../../company.constants';
import { DATE_FORMAT } from '../../../../../../constants/date.const';

// Styles
import styles from './totalCreditsCard.module.scss';

const cx = classnames.bind(styles);

const TotalCreditsCard = () => {
  const { t } = useTranslation();
  const totalCredits = useSelector(totalCreditsSelector);
  const queryClient = useQueryClient();
  const companyPlan = queryClient.getQueryData([COMPANY_QUERY_KEYS.plan]);

  const handleCalculateAvailableJobs = (isSubscriptionPlan) => {
    let availableJobs = 0;

    if (isSubscriptionPlan) {
      const diff = companyPlan.slots - companyPlan.active_jobs;

      if (diff >= 0) {
        availableJobs = diff;
      }
    }

    return availableJobs;
  };

  const isSubscriptionPlan = SUBSCRIPTION_PLAN_VALUES.includes(
    companyPlan.name,
  );
  const planName = PLAN_OPTIONS.find(
    (plan) => plan.value === companyPlan.name,
  ).label;
  const availableJobs = handleCalculateAvailableJobs(isSubscriptionPlan);

  return (
    <Card className={styles.totalCreditsCard} data-testid="total-credits-card">
      <div className={styles.totalCreditsTextWrapper}>
        <span
          className={styles.totalCreditsNumber}
          data-testid="available-credits-number"
        >
          {totalCredits}
        </span>
        <span
          className={styles.totalCreditsLabel}
          data-testid="available-credits-label"
        >
          {t('credits_page.available_credits.label')}
        </span>
      </div>
      {companyPlan.name && (
        <Grid.Row className={styles.planWrapper} data-testid="plan-wrapper">
          <Grid.Col xs={24} md={8} lg={6} className={styles.planItem}>
            <span
              className={cx({
                [styles.activeJobsNumber]: !isSubscriptionPlan,
                [styles.activeJobsSubscriptionNumber]: isSubscriptionPlan,
              })}
            >
              {isSubscriptionPlan ? (
                <span data-testid="parallel-jobs-available">
                  <span>{availableJobs}</span>
                  <span>/</span>
                  <span>{companyPlan.slots}</span>
                </span>
              ) : (
                <span data-testid="number-of-active-jobs">
                  {companyPlan.active_jobs}
                </span>
              )}
            </span>
            <span className={styles.activeJobsLabel}>
              {isSubscriptionPlan ? (
                <>
                  {t('credits_page.plan.job_slots_available')}
                  <Tooltip
                    id="job-slots-available"
                    place="top"
                    content={
                      <span className={styles.activeJobsTooltipContent}>
                        {t('credits_page.plan.job_slots_available_tooltip')}
                      </span>
                    }
                  >
                    <Icon
                      name="QuestionMarkCircle"
                      className={styles.activeJobsTooltipIcon}
                    />
                  </Tooltip>
                </>
              ) : (
                t('credits_page.plan.active_jobs')
              )}
            </span>
          </Grid.Col>
          <Grid.Col xs={24} md={8} lg={6} className={styles.planItem}>
            <span
              className={styles.activePlanName}
              data-testid="active-plan-name"
            >
              {t(planName)}
            </span>
            <span className={styles.activePlanLabel}>
              {t('credits_page.plan.plan')}
            </span>
          </Grid.Col>
          {companyPlan.valid_until && (
            <Grid.Col xs={24} md={8} lg={6} className={styles.planItem}>
              <span
                className={styles.activePlanValidityDate}
                data-testid="plan-expiration-date"
              >
                {moment(companyPlan.valid_until).format(DATE_FORMAT)}
              </span>
              <span className={styles.activePlanValidityLabel}>
                {t('credits_page.plan.valid_until')}
              </span>
            </Grid.Col>
          )}
          {isSubscriptionPlan && availableJobs > 0 && (
            <Grid.Col xs={24}>
              <div className={styles.planMessage} data-testid="plan-message">
                <Icon name="CheckCircle" className={styles.planMessageIcon} />
                {t(
                  availableJobs > 1
                    ? 'credits_page.plan.job_slots_available_message.plural'
                    : 'credits_page.plan.job_slots_available_message.singular',
                  {
                    availableJobs,
                  },
                )}
              </div>
            </Grid.Col>
          )}
        </Grid.Row>
      )}
    </Card>
  );
};

export default TotalCreditsCard;
