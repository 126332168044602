import { pythonAxios } from './http';

/**
 * SKILLS
 */
export const getAllSkillsApi = ({ value, languageCode = 'en,de,es,fr' } = {}) =>
  pythonAxios({
    method: 'get',
    url: `/es/skills?item_name=${encodeURIComponent(
      value,
    )}*&language_code=${languageCode}`,
  });

/**
 * LANGUAGES
 */
export const getLanguagesApi = ({ value, languageCode = 'en,de,es,fr' } = {}) =>
  pythonAxios({
    method: 'get',
    url: `/es/languages?item_name=${encodeURIComponent(
      value,
    )}*&language_code=${languageCode}`,
  });

/**
 * TITLES
 */
export const getTitlesApi = ({ value, languageCode = 'en,de,es,fr' } = {}) =>
  pythonAxios({
    method: 'get',
    url: `/es/titles?item_name=${encodeURIComponent(
      value,
    )}*&language_code=${languageCode}`,
  });
