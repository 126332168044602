import ReactDOM from 'react-dom';
import React from 'react';
import { Modal } from '@lionstep/ui';

// Styles
import styles from './userLeaveConfirmation.module.scss';

const UserLeaveConfirmation = ({
  message,
  callback,
  confirmOpen,
  setConfirmOpen,
}) => {
  const container = document.getElementById('react-router-prompt');

  const handleConfirm = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
    setConfirmOpen(false);
  };

  const handleCancel = () => {
    ReactDOM.unmountComponentAtNode(container);
    callback();
    setConfirmOpen(false);
  };

  const { header, content, confirmText, cancelText } = JSON.parse(message);

  ReactDOM.render(
    <Modal show={confirmOpen} onClose={handleCancel}>
      {header && <Modal.Header>{header}</Modal.Header>}
      {content && (
        <Modal.Body>
          <p className={styles.body}>{content}</p>
        </Modal.Body>
      )}
      <Modal.Footer
        confirmText={confirmText}
        closeText={cancelText}
        onClose={handleCancel}
        onConfirm={handleConfirm}
      />
    </Modal>,
    container,
  );
};

export default UserLeaveConfirmation;
