/* eslint-disable */

import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';

// Constants
import { USER_ROLES } from '../../constants/user.const';

// Queries
import { useUserQuery } from '../../queries/userQueries';

// Hooks
import useIsLionstepAdmin from '../../hooks/useIsLionstepAdmin';

const Footer = () => {
  const { data: user } = useUserQuery();
  const isLionstepAdmin = useIsLionstepAdmin();
  let tagManagerArgs = {};

  useEffect(() => {
    if (window._chatlio && user) {
      window._chatlio.identify(user.local_id, {
        name: user.display_name,
        email: user.email,
      });
    }
  }, [window._chatlio, user]);

  if (user) {
    const getUserRole = () => {
      let role = 'viewer';

      if (isLionstepAdmin && user.role === USER_ROLES.admin) {
        role = 'superadministrator';
      } else if (user.role === USER_ROLES.admin) {
        role = 'administrator';
      }

      return role;
    };

    const userRole = getUserRole();

    tagManagerArgs = {
      dataLayer: {
        event: 'user',
        logged_in: Boolean(user), // either true or false (boolean)
        email: user.email, // required
        userId: user.local_id || '', // optional
        company: user.company_name,
        industry: user.industry,
        isHr: user.is_hr,
        name: user.display_name,
        user_role: userRole,
      },
      dataLayerName: 'PageDataLayer',
    };
  }

  return (
    <div style={{ position: 'relative' }}>
      <chatlio-widget widgetid="9e85c3ec-9736-4599-73b6-fbf64f0f7586" />
      {!!user && Object.keys(user).length > 0 && (
        <div style={{ position: 'absolute', left: 0, bottom: 0, opacity: 0 }}>
          {TagManager.dataLayer(tagManagerArgs)}
        </div>
      )}
    </div>
  );
};

export default Footer;
