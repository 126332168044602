import * as Yup from 'yup';
import { testMaxChar } from '../../../../helpers/validationHelpers';

import i18n from '../../../../i18n';

export const publishJobSchema = Yup.object().shape(
  {
    // General
    original_url: Yup.string().when('job_file_name', (fileName) => {
      if (!fileName) {
        return Yup.string()
          .required(i18n.t('Required'))
          .url('Must be a valid url');
      }
      return Yup.string();
    }),
    job_file_name: Yup.string()
      .nullable()
      .test('job_file_format', 'The allowed format is pdf.', (value) => {
        if (value) {
          return value.endsWith('.pdf');
        }
        return true;
      }),
    job_file_data: Yup.string().nullable(),
    job_language: Yup.object()
      .nullable()
      .shape({
        mid: Yup.string(),
      })
      .required(i18n.t('Required')),
    diversity: Yup.string(),
    job_video: Yup.string().url('must be valid URL'),
    job_video_caption: Yup.string().max(100),
    introduction: Yup.string()
      .test('editor-max-characters', 'Max 600 characters', (value) =>
        testMaxChar(value, 600),
      )
      .required(i18n.t('Required')),
    message_template: Yup.string()
      .test('editor-max-characters', 'Max 300 characters', (value) =>
        testMaxChar(value, 300),
      )
      .nullable(),
    kombo_job: Yup.object().nullable().shape({
      value: Yup.string(),
    }),

    // Summary
    opening_title: Yup.string().max(70).required(i18n.t('Required')),
    summary: Yup.string().required(i18n.t('Required')),
    your_role: Yup.string().required(i18n.t('Required')),
    requirements: Yup.string().required(i18n.t('Required')),
    nice_to_have: Yup.string().nullable(),
    talent_languages: Yup.array().of(
      Yup.object().shape({
        language_id: Yup.object().shape().required(i18n.t('Required')),
        level: Yup.object().shape().required(i18n.t('Required')),
        importance: Yup.object().shape().required(i18n.t('Required')),
      }),
    ),
    free_question: Yup.string().nullable(),
    questionnaire: Yup.string().nullable(),
    titles: Yup.array().required(i18n.t('Required')),
    min_experience: Yup.object()
      .shape()
      .nullable()
      .required(i18n.t('Required')),
    max_experience: Yup.object()
      .shape()
      .nullable()
      .required(i18n.t('Required')),
    mandatory_skills_groups: Yup.array()
      .of(
        Yup.object().shape({
          group: Yup.array().of(Yup.object()).required(i18n.t('Required')),
        }),
      )
      .required(i18n.t('Required')),
    optional_skills: Yup.array().nullable(),
    // eslint-disable-next-line react/forbid-prop-types
    benefits_checked: Yup.array(),
    additional_benefits: Yup.string().nullable(),

    can_be_disclosed: Yup.string().required(i18n.t('Required')),
    replacement_name: Yup.string().nullable(),
    approach: Yup.object().shape().nullable().required(i18n.t('Required')),
    job_location: Yup.object()
      .nullable()
      .shape({
        name: Yup.string(),
        placeId: Yup.string(),
      })
      .required(i18n.t('Required')),
    additional_locations: Yup.array().nullable(),
    contract_type: Yup.object().shape().nullable(),
    start_date: Yup.string().when('contract_type', {
      is: ({ value }) => value === 'Limited',
      then: Yup.string().nullable().required(i18n.t('Required')),
      otherwise: Yup.string().nullable(),
    }),
    end_date: Yup.string().when('contract_type', {
      is: ({ value }) => value === 'Limited',
      then: Yup.string().nullable().required(i18n.t('Required')),
      otherwise: Yup.string().nullable(),
    }),
    notice_period: Yup.object().shape().nullable().required(i18n.t('Required')),
    employment_type: Yup.string().nullable(),
    employment_type_min: Yup.object()
      .shape()
      .when(['employment_type', 'employment_type_max'], {
        is: (employmentType, employmentTypeMax) =>
          employmentType === 'parttime' && Boolean(employmentTypeMax?.value),
        then: Yup.object().shape().nullable().required(i18n.t('Required')),
        otherwise: Yup.object().shape().nullable(),
      })
      .nullable(),
    employment_type_max: Yup.object()
      .shape()
      .when(['employment_type', 'employment_type_min'], {
        is: (employmentType, employmentTypeMin) =>
          employmentType === 'parttime' && Boolean(employmentTypeMin?.value),
        then: Yup.object().shape().nullable().required(i18n.t('Required')),
        otherwise: Yup.object().shape().nullable(),
      })
      .nullable(),
    leadership_experience: Yup.mixed().oneOf(['yes', 'no']).nullable(),
    leadership_importance: Yup.object().shape().nullable(),
    management_role: Yup.mixed()
      .oneOf(['yes', 'no'])
      .required(i18n.t('Required')),
    peopleToManage: Yup.object().shape().nullable(),
    travel: Yup.mixed().oneOf(['yes', 'no']).nullable(),
    travel_importance: Yup.object()
      .shape()
      .when('travel', {
        is: true,
        then: Yup.object().shape().required(i18n.t('Required')),
        otherwise: Yup.object().shape().nullable(),
      }),
    travel_details: Yup.string().nullable(),
    freelance: Yup.boolean().nullable(),
    home_days: Yup.object().shape().nullable(),
    fully_remote: Yup.boolean().nullable(),

    // Team
    team_desc: Yup.string().nullable(),
    superior_role: Yup.string().nullable(),
    team_language: Yup.string().nullable(),
    team_size: Yup.string().nullable(),
    contact_person: Yup.string().required(i18n.t('Required')),
    contact_person_title: Yup.string().required(i18n.t('Required')),

    // Salary
    base_salary_min: Yup.string()
      .required(i18n.t('Required'))
      .test(
        'min_salary',
        'Must be minimum 10000',
        (value) => Number(value) >= 10000,
      )
      .matches(/^\d*$/g, 'Must contain only numbers')
      .when('base_salary_max', (baseSalaryMax, sch) =>
        sch.test({
          test: (baseSalaryMin) => {
            if (baseSalaryMax && baseSalaryMin) {
              if (parseInt(baseSalaryMax, 10) < 10000) return true;

              return !(
                parseInt(baseSalaryMin, 10) > parseInt(baseSalaryMax, 10)
              );
            }
            return true;
          },
          message: 'Must be less than maximum salary',
        }),
      ),
    base_salary_max: Yup.string()
      .nullable()
      .matches(/^\d*$/g, 'Must contain only numbers')
      .test('max_salary', 'Must be minimum 10000', (value) => {
        if (!value) return true;

        return Number(value) >= 10000;
      }),
  },
  ['employment_type_min', 'employment_type_max'],
);

export const draftJobSchema = Yup.object().shape(
  {
    job_file_name: Yup.string()
      .nullable()
      .test('job_file_format', 'The allowed format is pdf.', (value) => {
        if (value) {
          return value.endsWith('.pdf');
        }
        return true;
      }),
    job_language: Yup.object()
      .nullable()
      .shape({
        mid: Yup.string(),
      })
      .required(i18n.t('Required')),
    kombo_job: Yup.object().nullable().shape({
      value: Yup.string(),
    }),

    // Summary
    talent_languages: Yup.array().of(
      Yup.object().shape({
        language_id: Yup.object().shape().required(i18n.t('Required')),
        level: Yup.object().shape().required(i18n.t('Required')),
        importance: Yup.object().shape().required(i18n.t('Required')),
      }),
    ),
    opening_title: Yup.string().max(70).required(i18n.t('Required')),
    approach: Yup.object().shape().nullable().required(i18n.t('Required')),
    base_salary_min: Yup.string().matches(
      /^\d*$/g,
      'Must contain only numbers',
    ),
    base_salary_max: Yup.string().matches(
      /^\d*$/g,
      'Must contain only numbers',
    ),
    employment_type: Yup.string().nullable(),
    employment_type_min: Yup.object()
      .shape()
      .when(['employment_type', 'employment_type_max'], {
        is: (employmentType, employmentTypeMax) =>
          employmentType === 'parttime' && Boolean(employmentTypeMax?.value),
        then: Yup.object().shape().nullable().required(i18n.t('Required')),
        otherwise: Yup.object().shape().nullable(),
      })
      .nullable(),
    employment_type_max: Yup.object()
      .shape()
      .when(['employment_type', 'employment_type_min'], {
        is: (employmentType, employmentTypeMin) =>
          employmentType === 'parttime' && Boolean(employmentTypeMin?.value),
        then: Yup.object().shape().nullable().required(i18n.t('Required')),
        otherwise: Yup.object().shape().nullable(),
      })
      .nullable(),
  },
  ['employment_type_min', 'employment_type_max'],
);
