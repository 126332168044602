import React from 'react';
import * as Sentry from '@sentry/react';

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    // render fallback UI
    return (
      <Sentry.ErrorBoundary
        fallback={
          <div className="snap" onClick={() => Sentry.showReportDialog()}>
            <p style={{ fontSize: '16px' }}>
              {"We're sorry — something's gone wrong."}
            </p>
            <p style={{ fontSize: '16px' }}>
              Our team has been notified, but <underline>click here</underline>{' '}
              fill out a report.
            </p>
          </div>
        }
      >
        {this.props.children}
      </Sentry.ErrorBoundary>
    );
  }
}

export default ErrorBoundary;
