import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

// Styles
import styles from './validatedCandidatesHeader.module.scss';

const cx = classnames.bind(styles);

const ValidatedCandidatesHeader = ({ totalCandidates }) => {
  const { t } = useTranslation();

  return (
    <>
      <span className={styles.headerTitle}>
        {t('dashboard_page.new_validated_candidates.title')}
      </span>
      <span
        className={cx(styles.totalCandidates, {
          [styles.totalCandidatesActive]: totalCandidates,
        })}
      >
        {totalCandidates}
      </span>
    </>
  );
};

ValidatedCandidatesHeader.propTypes = {
  totalCandidates: PropTypes.number.isRequired,
};

export default ValidatedCandidatesHeader;
