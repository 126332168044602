/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import {
  TEXT_COLOR,
  BAR_TOOLTIP,
  EMPTY_DOUGHNUT,
  BAR_COLORS,
  DOUGHNUT_COLORS,
  DOUGHNUT_TOOLTIP,
} from '../../components/Charts/charts.const';
import {
  htmlDoughnutChartLegendPlugin,
  htmlBarChartLegendPlugin,
  emptyDoughnutPlugin,
} from '../../components/Charts/charts.helper';

const calculateDataValueToPercentage = ({ data, formattedData }) => {
  const rejectionValueSum = Object.values(data).reduce(
    (acc, curr) => acc + curr,
    0,
  );

  return formattedData.map((item) => ({
    ...item,
    value: ((item.value / rejectionValueSum || 0) * 100).toFixed(0),
  }));
};

export const candidateFunnelFormatter = ({ candidateFunnel, styles, t }) => {
  const maxValue = Math.max(
    candidateFunnel.extension_checked_interest +
      candidateFunnel.description_view_from_non_extension,
    candidateFunnel.description_view_from_extension +
      candidateFunnel.description_view_from_non_extension,
  );

  const datasetInternal = [
    candidateFunnel.extension_checked_interest,
    candidateFunnel.description_view_from_extension,
    candidateFunnel.sent_salary_validation,
    candidateFunnel.validated_killer_question,
    candidateFunnel.call_done,
    candidateFunnel.total_validated_count,
    candidateFunnel.total_rejected_count,
    candidateFunnel.hired,
  ];

  const datasetExternal = [
    candidateFunnel.description_view_from_non_extension,
    candidateFunnel.description_view_from_non_extension,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ];

  const data = {
    labels: [
      t('insights_page.candidate_funnel.labels.sourcing'),
      t('insights_page.candidate_funnel.labels.job_page'),
      t('insights_page.candidate_funnel.labels.salary_check'),
      t('insights_page.candidate_funnel.labels.key_points'),
      t('insights_page.candidate_funnel.labels.call_completed'),
      t('insights_page.candidate_funnel.labels.validated'),
      t('insights_page.candidate_funnel.labels.rejected'),
      t('insights_page.candidate_funnel.labels.hired'),
    ],
    datasets: [
      {
        label: t(
          'insights_page.candidate_funnel.legend.number_of_active_sourcing_candidates',
        ),
        backgroundColor: BAR_COLORS[0],
        data: datasetInternal,
        borderRadius: 5,
      },
      {
        label: t(
          'insights_page.candidate_funnel.legend.candidates_multi_channels',
        ),
        backgroundColor: BAR_COLORS[1],
        data: datasetExternal,
        borderRadius: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: BAR_TOOLTIP,
      annotation: {
        annotations: datasetInternal.map((value, index) => ({
          type: 'label',
          id: `label${index}`,
          backgroundColor: '#F8F8F4',
          borderWidth: 1,
          borderColor: '#E9E6DC',
          borderShadowColor: '#00000014',
          shadowBlur: 3,
          borderRadius: 99,
          padding: {
            top: 2,
            bottom: 2,
            left: 6,
            right: 6,
          },
          xValue: index,
          yValue: () => value + datasetExternal[index],
          yAdjust: -15,
          content: () => value + datasetExternal[index],
          color: TEXT_COLOR,
          font: {
            size: 11,
            weight: 500,
          },
        })),
      },
    },
    scales: {
      x: {
        stacked: true,
        font: {
          size: 12,
        },
        ticks: {
          color: TEXT_COLOR,
        },
      },
      y: {
        stacked: true,
        font: {
          size: 12,
        },
        ticks: {
          color: TEXT_COLOR,
        },
        suggestedMax: maxValue + maxValue / 10,
      },
    },
  };

  const plugins = [
    htmlBarChartLegendPlugin(
      'candidateFunnelChart',
      'candidateFunnelLegend',
      styles,
    ),
  ];

  return {
    id: 'candidateFunnelChart',
    data,
    options,
    plugins,
  };
};

export const newSourcedCandidateFormatter = ({
  candidatesInternal,
  candidatesExternal,
  styles,
  t,
}) => {
  const datasetInternal = [];
  const datasetExternal = [];
  const labels = [];

  // local copy to store all intervals
  const localAllRecords = [
    ...candidatesInternal.map((record) => ({ ...record, isInternal: true })),
    ...candidatesExternal.map((record) => ({
      ...record,
      isInternal: false,
    })),
  ].sort((first, second) => {
    if (second.date > first.date) return 1;
    if (second.date < first.date) return -1;
    return 0;
  });

  if (candidatesInternal.length || candidatesExternal.length) {
    const reducedAllByMonth = localAllRecords.reduce((acc, curr) => {
      const date = moment(curr.date).format('YYYY-MM');

      const foundRecordIndex = acc.findIndex((rec) => rec.date === date);

      if (foundRecordIndex !== -1) {
        acc[foundRecordIndex] = {
          ...acc[foundRecordIndex],
          countInternal: curr.isInternal
            ? acc[foundRecordIndex].countInternal + curr.count
            : acc[foundRecordIndex].countInternal,
          countExternal: !curr.isInternal
            ? acc[foundRecordIndex].countExternal + curr.count
            : acc[foundRecordIndex].countExternal,
        };
      } else {
        acc.push({
          date,
          countInternal: curr.isInternal ? curr.count : 0,
          countExternal: !curr.isInternal ? curr.count : 0,
        });
      }

      return acc;
    }, []);

    // showing last 3 months at most
    reducedAllByMonth.slice(0, 3).forEach((record) => {
      const monthName = moment(record.date).format('MMMM');

      labels.unshift(monthName);
      datasetInternal.unshift(record.countInternal);
      datasetExternal.unshift(record.countExternal);
    });
  }

  const data = {
    labels,
    datasets: [
      {
        label: t('insights_page.validation_by_channel.legend.active_sourcing'),
        backgroundColor: BAR_COLORS[0],
        data: datasetInternal,
        borderRadius: 5,
      },
      {
        label: t('insights_page.validation_by_channel.legend.multi_channel'),
        backgroundColor: BAR_COLORS[1],
        data: datasetExternal,
        borderRadius: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: BAR_TOOLTIP,
    },
    scales: {
      x: {
        stacked: true,
        font: {
          size: 12,
        },
        ticks: {
          color: TEXT_COLOR,
        },
      },
      y: {
        stacked: true,
        font: {
          size: 12,
        },
        ticks: {
          color: TEXT_COLOR,
        },
      },
    },
  };

  const plugins = [
    htmlBarChartLegendPlugin(
      'newSourcedCandidateChart',
      'newSourcedCandidateLegend',
      styles,
    ),
  ];

  return {
    id: 'newSourcedCandidateChart',
    data,
    options,
    plugins,
  };
};

const validationByChannelHtmlLegendPlugin = (
  chartId,
  legendContainerId,
  styles,
  values,
) => ({
  beforeInit: (chart) => {
    if (chart.canvas.id === chartId) {
      const ul = document.createElement('ul');
      ul.classList.add(styles.legendList);

      chart.data.labels.forEach((label, i) => {
        ul.innerHTML += `
          <li class="${styles.legendItem}">
            <span class="${styles.legendColor}" style="background-color: ${chart.data.datasets[0].backgroundColor[i]}"></span>
            <span class="${styles.legendPercentage}">${values[i]} </span>
            <span class="${styles.legendText}">${label}</span>
          </li>
        `;
      });

      return document.getElementById(legendContainerId).appendChild(ul);
    }

    return null;
  },
});

export const validationByChannelFormatter = ({
  activeSourcing,
  multiChanel,
  styles,
  t,
}) => {
  const activeSourcingPercentage = activeSourcing
    ? ((activeSourcing / (activeSourcing + multiChanel)) * 100).toFixed(0)
    : '0';
  const multiChanelPercentage = multiChanel
    ? ((multiChanel / (activeSourcing + multiChanel)) * 100).toFixed(0)
    : '0';
  const data = {
    labels: [
      t('insights_page.validation_by_channel.legend.active_sourcing'),
      t('insights_page.validation_by_channel.legend.multi_channel'),
    ],
    datasets: [
      {
        data: [activeSourcingPercentage, multiChanelPercentage],
        backgroundColor: [DOUGHNUT_COLORS[0], DOUGHNUT_COLORS[1]],
      },
    ],
  };

  const options = {
    responsive: true,

    plugins: {
      emptyDoughnut: EMPTY_DOUGHNUT,
      legend: {
        display: false,
      },
      tooltip: DOUGHNUT_TOOLTIP,
    },
  };

  const plugins = [
    validationByChannelHtmlLegendPlugin(
      'validationByChannelChart',
      'validationByChannelLegend',
      styles,
      [activeSourcing, multiChanel],
    ),
    emptyDoughnutPlugin,
  ];

  return {
    id: 'validationByChannelChart',
    data,
    options,
    plugins,
  };
};

// Candidate by stage
const groupCandidateByStage = ({ candidateByStage, t }) => [
  {
    label: t('candidates_page.statuses.validated'),
    value: candidateByStage.interested,
  },
  {
    label: t('candidates_page.statuses.rejected'),
    value: candidateByStage.rejected,
  },
  {
    label: t('candidates_page.statuses.not_interested'),
    value: candidateByStage.not_interested,
  },
  {
    label: t('candidates_page.statuses.call_scheduled'),
    value: candidateByStage.pending,
  },
  {
    label: t('candidates_page.statuses.contacted'),
    value:
      candidateByStage.contacted +
      candidateByStage.in_contact +
      candidateByStage.hold,
  },
  {
    label: t('candidates_page.statuses.call_done'),
    value: candidateByStage.call_done,
  },
  {
    label: t('candidates_page.statuses.hired'),
    value: candidateByStage.hired,
  },
];

export const candidateByStageFormatter = ({ candidateByStage, t, styles }) => {
  let stages = groupCandidateByStage({ candidateByStage, t });
  stages = calculateDataValueToPercentage({
    data: candidateByStage,
    formattedData: stages,
  });

  const data = {
    labels: stages.map((reason) => reason.label),
    datasets: [
      {
        data: stages.map((reason) => reason.value),
        backgroundColor: [
          DOUGHNUT_COLORS[0],
          DOUGHNUT_COLORS[1],
          DOUGHNUT_COLORS[2],
          DOUGHNUT_COLORS[3],
          DOUGHNUT_COLORS[4],
          DOUGHNUT_COLORS[3],
          DOUGHNUT_COLORS[5],
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      emptyDoughnut: EMPTY_DOUGHNUT,
      legend: {
        display: false,
      },
      tooltip: DOUGHNUT_TOOLTIP,
    },
  };

  const plugins = [
    htmlDoughnutChartLegendPlugin(
      'candidateByStageChart',
      'candidateByStageLegend',
      styles,
    ),
    emptyDoughnutPlugin,
  ];

  return {
    id: 'candidateByStageChart',
    data,
    options,
    plugins,
  };
};
