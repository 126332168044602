import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  Button,
  Spinner,
  Grid,
  Heading,
  Text,
  FormInput,
  LinkButton,
  toast,
} from '@lionstep/ui';

// Operations
import userDataOperations from '../../../redux/ducks/userData/operations';

// Helpers
import { getRecaptcha } from '../../../support';

// Components
import { ReactComponent as Logo } from '../../../assets/icons/lionstep-logo.svg';

// Styles
import styles from './forgotPassword.module.scss';

const ForgotPassword = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { resetPassword } = userDataOperations;

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(t('You must enter a valid email address'))
      .required(t('Required')),
  });

  const formMethods = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    getValues,
    formState: { errors },
  } = formMethods;

  const onSubmit = async ({ email }) => {
    try {
      setShowSpinner(true);
      const token = await getRecaptcha();

      await dispatch(
        resetPassword({
          email: email.trim(),
          recaptcha_token: token,
        }),
      );

      setShowSpinner(false);
      setEmailSent(true);
    } catch (error) {
      setShowSpinner(false);
      toast.error(t('User not found'), {
        id: 'PASSWORD_RESET_FAIL',
        statusCode: error?.response?.status,
      });
    }
  };

  const renderBackToLoginBtn = (props) => <LinkButton block {...props} />;

  return (
    <Grid.Container>
      <Grid.Row className={styles.forgotPassword} gutter={{ xs: 24, sm: 0 }}>
        <Grid.Col
          xs={24}
          md={{ span: 14, offset: 5 }}
          lg={{ span: 12, offset: 6 }}
          xl={{ span: 8, offset: 8 }}
        >
          {showSpinner && <Spinner position="fixed" size={100} />}

          <Helmet>
            <title>Lionstep</title>
          </Helmet>

          <Logo className={styles.forgotPasswordLogo} />

          <Heading
            className={styles.forgotPasswordTitle}
            level={1}
            type="secondary"
          >
            {emailSent
              ? t('Password reset link sent')
              : t('Forgot your password')}
          </Heading>

          <Text className={styles.forgotPasswordInfo}>
            {emailSent
              ? `${t('Check')} ${getValues('email')} ${t(
                  'for the reset password link and follow the instructions.',
                )}`
              : t(
                  'Enter the email address associated with your account, and we’ll email you a link to reset your password',
                )}
          </Text>

          {!emailSent && (
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormInput
                  label={t('work_email')}
                  name="email"
                  placeholder={t('Enter your email')}
                  error={errors.email?.message}
                />

                <Button
                  className={styles.forgotPasswordSubmitButton}
                  block
                  typeHtml="submit"
                >
                  {t('Reset password')}
                </Button>
              </form>
            </FormProvider>
          )}

          {emailSent ? (
            <Link to="/login" component={renderBackToLoginBtn}>
              {t('Back to login')}
            </Link>
          ) : (
            <Link className={styles.forgotPasswordLoginLink} to="/login">
              {t('Back to login')}
            </Link>
          )}
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

export default ForgotPassword;
