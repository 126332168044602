import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

// Styles
import styles from './contentCard.module.scss';

const cx = classnames.bind(styles);

const ContentCard = ({ className, children, ...restProps }) => (
  <div className={cx(styles.contentCard, className)} {...restProps}>
    {children}
  </div>
);

ContentCard.defaultProps = {
  className: '',
};

ContentCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ContentCard;
