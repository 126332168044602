import { load } from 'recaptcha-v3';

import { ENV, RECAPTCHA_DEV_TOKEN, RECAPTCHA_SITE_KEY } from '../config';

const copyTextToClipboard = (text) => {
  const copyText = document.createElement('textarea');
  copyText.value = text;
  copyText.setAttribute('readonly', '');
  copyText.style.position = 'absolute';
  copyText.style.left = '-9999px';
  document.body.appendChild(copyText);
  copyText.select();
  document.execCommand('copy');
  document.body.removeChild(copyText);
};

function detectBrowser() {
  navigator.sayswho = (function () {
    const ua = navigator.userAgent;
    let tem;
    let M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
      ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return `IE ${tem[1] || ''}`;
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    tem = ua.match(/version\/(\d+)/i);
    if (tem != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  })();

  return navigator.sayswho;
}

const getRecaptcha = async () => {
  if (ENV === 'development' || ENV === 'qa') {
    return RECAPTCHA_DEV_TOKEN;
  }

  const recaptcha = await load(RECAPTCHA_SITE_KEY);

  return recaptcha.execute();
};

const getElementTextContent = (htmlString) => {
  const el = document.createElement('div');
  el.innerHTML = htmlString;
  return el.textContent.trim();
};

export const langShorthand = (lang) => {
  if (lang === 'english') {
    return 'en';
  }
  if (lang === 'german' || lang === 'deutsch') {
    return 'de';
  }
  if (lang === 'spanish' || lang === 'español') {
    return 'es';
  }
  if (lang === 'french' || lang === 'français') {
    return 'fr';
  }
  return 'en';
};

const languageShorthandToFull = (shorthand) => {
  switch (shorthand) {
    case 'en':
      return 'english';
    case 'de':
      return 'german';
    case 'fr':
      return 'french';
    case 'es':
      return 'spanish';
    default:
      return 'english';
  }
};

const languageShorthandToOriginalFull = (shorthand) => {
  switch (shorthand) {
    case 'en':
      return 'english';
    case 'de':
      return 'deutsch';
    case 'fr':
      return 'français';
    case 'es':
      return 'español';
    default:
      return 'english';
  }
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function constantsArrayWithTranslatedLabel(t, arr) {
  return arr.map((obj) => ({
    ...obj,
    label: t(obj.label),
  }));
}

export {
  copyTextToClipboard,
  detectBrowser,
  getRecaptcha,
  getElementTextContent,
  languageShorthandToFull,
  languageShorthandToOriginalFull,
  toBase64,
  constantsArrayWithTranslatedLabel,
};
