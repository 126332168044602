import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Text } from '@lionstep/ui';

// Styles
import styles from './leaveJobModal.module.scss';

const LeaveJobModal = ({ onClose, show, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onClose={onClose}>
      <Modal.Header>
        {t('job_settings_page.leave_job_modal.title')}
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Text className={styles.modalBodyText}>
          {t('job_settings_page.leave_job_modal.text')}
        </Text>
      </Modal.Body>
      <Modal.Footer
        confirmText={t('confirm')}
        closeText={t('cancel')}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </Modal>
  );
};

export default LeaveJobModal;
