import React from 'react';
import PropTypes from 'prop-types';

const TimeoffMovingExams = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 66"
    className={className}
  >
    <g id="Coloured">
      <path
        fill={companyColour}
        d="M19.5,32C11.5,32,5,38.5,5,46.5S11.5,61,19.5,61S34,54.5,34,46.5S27.5,32,19.5,32z M19.5,59
          C12.6,59,7,53.4,7,46.5S12.6,34,19.5,34S32,39.6,32,46.5S26.4,59,19.5,59z"
      />
      <path
        fill={companyColour}
        d="M21,45.6V37c0-0.6-0.4-1-1-1s-1,0.4-1,1v9c0,0.3,0.1,0.5,0.3,0.7l6,6c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3
          c0.4-0.4,0.4-1,0-1.4L21,45.6z"
      />
    </g>
    <g id="Grey">
      <path
        fill="#899198"
        d="M16,8c0.6,0,1-0.4,1-1V1c0-0.6-0.4-1-1-1s-1,0.4-1,1v6C15,7.6,15.4,8,16,8z"
      />
      <path
        fill="#899198"
        d="M50,8c0.6,0,1-0.4,1-1V1c0-0.6-0.4-1-1-1s-1,0.4-1,1v6C49,7.6,49.4,8,50,8z"
      />
      <path
        fill="#899198"
        d="M61,6h-7c-0.6,0-1,0.5-1,1c0,1.7-1.3,3-3,3c-0.8,0-1.6-0.3-2.1-0.9C47.3,8.6,47,7.8,47,7
          c0-0.3-0.1-0.5-0.3-0.7S46.3,6,46,6H20c-0.6,0-1,0.4-1,1c0,1.7-1.3,3-3,3s-3-1.3-3-3c0-0.6-0.4-1-1-1H5c-2.8,0-5,2.2-5,5v50
          c0,2.8,2.2,5,5,5h56c2.8,0,5-2.2,5-5V11C66,8.2,63.8,6,61,6z M5,8h6.1c0.5,2.3,2.5,4,4.9,4s4.4-1.7,4.9-4h24.2
          c0.2,1,0.7,1.8,1.4,2.5c0.9,0.9,2.2,1.5,3.5,1.5c2.4,0,4.4-1.7,4.9-4H61c1.7,0,3,1.3,3,3v7H2v-7C2,9.3,3.3,8,5,8z M61,64H5
          c-1.7,0-3-1.3-3-3V20h62v41C64,62.7,62.7,64,61,64z"
      />
      <path
        fill="#899198"
        d="M61.2,32.6L59,31.2V25c0-1.1-0.9-2-2-2h-3c-1.1,0-2,0.9-2,2v1.7l-5.4-3.5c-0.3-0.2-0.8-0.2-1.2,0l-14.6,9.4
          c-0.7,0.4-1,1.3-0.8,2.1S31,36,31.8,36H58v17c0,0.6-0.4,1-1,1h-5v-9c0-1.7-1.3-3-3-3h-5c-1.7,0-3,1.3-3,3v9h-7c-0.6,0-1,0.4-1,1
          s0.4,1,1,1h8h9h6c1.7,0,3-1.3,3-3V36h0.2c0.8,0,1.5-0.5,1.8-1.3C62.2,33.9,61.8,33.1,61.2,32.6z M43,54v-9c0-0.6,0.4-1,1-1h5
          c0.6,0,1,0.4,1,1v9H43z M54,25l3,0v4.9L54,28L54,25z M46,25.2L59.6,34H32.4L46,25.2z"
      />
    </g>
  </svg>
);

TimeoffMovingExams.defaultProps = {
  companyColour: '#ab1923',
};

TimeoffMovingExams.propTypes = {
  companyColour: PropTypes.string,
};

export default TimeoffMovingExams;
