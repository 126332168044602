import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import { Modal, Text } from '@lionstep/ui';

import styles from './publishConfirmModal.module.scss';

const PublishConfirmModal = ({ show, onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Modal className={styles.modal} show={show} onClose={onCancel}>
      <Modal.Header>{t('job_description_page.publish_job')}</Modal.Header>

      <Modal.Body className={styles.modalBody}>
        <Text className={styles.modalBodyText}>
          {t('job_description_page.confirm_publish_modal.title')}
        </Text>
        <Text className={styles.modalBodyText}>
          {t('job_description_page.confirm_publish_modal.description')}
        </Text>
      </Modal.Body>

      <Modal.Footer
        onClose={onCancel}
        confirmText={t('job_description_page.publish_job')}
        closeText={t('job_description_page.confirm_publish_modal.cancel')}
        onConfirm={onConfirm}
      />
    </Modal>
  );
};

PublishConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default PublishConfirmModal;
