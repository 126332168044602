import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button, Spinner, Tooltip } from '@lionstep/ui';

// Components
import { ReactComponent as DocumentIcon } from '../../../../../../assets/icons/Document.svg';
import CandidatePDFReportModal from '../candidatePDFReportModal/CandidatePDFReportModal';

// Constants
import { DATE_TIME_FORMAT } from '../../../../../../constants/date.const';
import { USER_ROLES } from '../../../../../../constants/user.const';

// Api
import { pythonAxios } from '../../../../../../api/http';

// Selectors
import { isLoadingCandidatePDFReportSelector } from '../../candidate.selectors';

// Queries
import { useUserQuery } from '../../../../../../queries/userQueries';

// Hooks
import useIsLionstepAdmin from '../../../../../../hooks/useIsLionstepAdmin';

// Actions
import {
  sendSmsReminder,
  getCandidatePDFReport,
} from '../../candidate.actions';

// Styles
import styles from './contentDocuments.module.scss';

const ContentDocuments = ({ job, candidate }) => {
  const {
    extra_info: extraInfo,
    documents_with_original_filename: originalDocuments = [],
    documents_special_with_original_filename: specialDocuments = [],
  } = candidate;

  const [isCandidatePDFReportModalOpen, setIsCandidatePDFReportModalOpen] =
    useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLionstepAdmin = useIsLionstepAdmin();

  const { data: user } = useUserQuery({ options: { enabled: false } });

  const isLoadingCandidatePDFReport = useSelector(
    isLoadingCandidatePDFReportSelector,
  );

  const handleDownloadSingleDocument = (singleDocument) => {
    const documentFileNameSplit = singleDocument.filename.split('/').length;
    const documentFileName =
      documentFileNameSplit > 1
        ? singleDocument.filename.split('/')[documentFileNameSplit - 1]
        : singleDocument.filename;

    setTimeout(async () => {
      const downloadedDocument = await pythonAxios({
        method: 'GET',
        responseType: 'blob',
        url: `/upload/candidate*${candidate.id}*documents/${decodeURIComponent(
          documentFileName,
        )}`,
      });
      const url = window.URL.createObjectURL(
        new Blob([downloadedDocument.data]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        singleDocument.original_filename || singleDocument,
      );
      document.body.appendChild(link);
      link.click();
    }, 100);
  };

  const handleDownloadAllDocuments = async ({ hideSalary = true }) => {
    // PDF report
    await dispatch(
      getCandidatePDFReport({
        jobId: job.id,
        candidateId: candidate.id,
        data: {
          hide_salary: hideSalary,
        },
      }),
    );

    // All documents
    [...originalDocuments, ...specialDocuments].forEach(
      handleDownloadSingleDocument,
    );
  };

  const handleDownloadAllDocumentsCheck = () => {
    if (isLionstepAdmin) {
      setIsCandidatePDFReportModalOpen(true);
    } else if (user?.role === USER_ROLES.admin) {
      handleDownloadAllDocuments({ hideSalary: false });
    } else if (user?.role === USER_ROLES.viewer) {
      handleDownloadAllDocuments({ hideSalary: true });
    }
  };

  const handleReminderInfoCalc = () => {
    let firstReminderDate = ''; // 48h after was validated
    let isReminderSent = false;
    let sendReminderDate = ''; // 48h after was validated
    let timeDiffFromValidatedAtInHours = '';
    let validatedAt = '';

    if (extraInfo.validated_at || extraInfo.updated_at) {
      validatedAt = extraInfo.validated_at || extraInfo.updated_at;
      timeDiffFromValidatedAtInHours = moment
        .duration(moment().diff(validatedAt))
        .asHours();
      firstReminderDate = moment(validatedAt)
        .add(48, 'hours')
        .format(DATE_TIME_FORMAT);

      if (extraInfo.sent_reminder_sms_at) {
        isReminderSent = true;
        sendReminderDate = moment(extraInfo.sent_reminder_sms_at).format(
          DATE_TIME_FORMAT,
        );
      }
    }

    return {
      firstReminderDate,
      isReminderSent,
      sendReminderDate,
      timeDiffFromValidatedAtInHours,
    };
  };

  // Render methods
  const renderDocumentItem = (document) => (
    <div className={styles.sectionItem} key={document.filename}>
      <Tooltip
        id={document.filename}
        place="top"
        content={document.original_filename}
      >
        <div
          className={styles.sectionItemContent}
          onClick={() => handleDownloadSingleDocument(document)}
        >
          <span>
            <DocumentIcon className={styles.sectionItemIcon} />
          </span>
          <span className={styles.sectionItemText}>
            {document.original_filename}
          </span>
        </div>
      </Tooltip>
    </div>
  );

  const renderNoDocumentMessages = () => {
    const {
      firstReminderDate,
      isReminderSent,
      sendReminderDate,
      timeDiffFromValidatedAtInHours,
    } = handleReminderInfoCalc();

    const firstReminderMessage =
      timeDiffFromValidatedAtInHours >= 48
        ? `${t('reminder_was_sent')} ${firstReminderDate}`
        : `${t('reminder_will_be_sent')} ${firstReminderDate}`;
    const sendReminderDateMessage = `${t(
      'reminder_were_sent',
    )} ${firstReminderDate} ${t('and')} ${sendReminderDate} \\n${t(
      'remind_extra_text',
    )}`;
    const disableRemindCandidateButton = !(
      timeDiffFromValidatedAtInHours >= 96 && !isReminderSent
    );

    return (
      <div className={styles.sectionItemNoData}>
        <div className={styles.sectionItemNoDataTitle}>
          {t('candidate_not_uploaded_docs')}
        </div>
        {!isReminderSent && firstReminderDate && (
          <div className={styles.sectionItemNoDataTitle}>
            {firstReminderMessage}
          </div>
        )}
        {isReminderSent && sendReminderDate && (
          <div className={styles.sectionItemNoDataTitle}>
            {sendReminderDateMessage}
          </div>
        )}

        {firstReminderDate && (
          <div className={styles.sectionItemNoDataRemindBtn}>
            <Button
              minWidth={false}
              disabled={disableRemindCandidateButton}
              onClick={sendSmsReminder}
            >
              {t('remind_candidate')}
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.contentCard}>
      {isLoadingCandidatePDFReport && <Spinner position="absolute" size={50} />}
      <div className={styles.sectionTitle}>
        Documents uploaded by the Candidate
      </div>
      <div className={styles.section}>
        {[...originalDocuments, ...specialDocuments].length > 0
          ? [...originalDocuments, ...specialDocuments].map(renderDocumentItem)
          : renderNoDocumentMessages()}
      </div>
      {(!!originalDocuments.length || !!specialDocuments.length) && (
        <div className={styles.downloadAll}>
          <Button
            minWidth={false}
            disabled={isLoadingCandidatePDFReport}
            ghost
            onClick={handleDownloadAllDocumentsCheck}
          >
            {t('download_all_documents')}
          </Button>
        </div>
      )}
      <CandidatePDFReportModal
        isOpen={isCandidatePDFReportModalOpen}
        onClose={() => setIsCandidatePDFReportModalOpen(false)}
        onConfirm={({ hideSalary }) => {
          setIsCandidatePDFReportModalOpen(false);
          handleDownloadAllDocuments({ hideSalary });
        }}
      />
    </div>
  );
};

ContentDocuments.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  candidate: PropTypes.shape({
    status: PropTypes.string.isRequired,
    extra_info: PropTypes.shape({
      validated_at: PropTypes.string,
      updated_at: PropTypes.string,
      sent_reminder_sms_at: PropTypes.string,
    }),
    documents_with_original_filename: PropTypes.arrayOf(PropTypes.shape({})),
    documents_special_with_original_filename: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }).isRequired,
};

export default ContentDocuments;
