import { useState, useEffect } from 'react';

// Queries
import { useUserQuery } from '../queries/userQueries';

const checkEmail = (user) => {
  if (user?.email) {
    const userEmail = user.email;
    if (userEmail.includes('info+') && userEmail.includes('@lionstep.com')) {
      return true;
    }
  }
  return false;
};

function useIsLionstepAdmin() {
  const { data: user } = useUserQuery();

  const [isAdmin, setIsAdmin] = useState(checkEmail(user));
  useEffect(() => {
    setIsAdmin(checkEmail(user));
  }, [user]);

  return isAdmin;
}

export default useIsLionstepAdmin;
