import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import { Modal, Text, Textarea, Label, Spinner } from '@lionstep/ui';

// Styles
import styles from './createVersionModal.module.scss';

const CreateVersionModal = ({ show, isLoading, onVersionUpdate, onClose }) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState('');

  const submitVersionUpdate = () => {
    onVersionUpdate(description);
  };

  return (
    <Modal show={show} onClose={onClose} className={styles.createVersionModal}>
      <Modal.Header>{t('job.versions.new_version.title')}</Modal.Header>

      <Modal.Body className={styles.createVersionModalBody}>
        {isLoading && <Spinner fixed size={100} />}

        <Text className={styles.createVersionDescription}>
          {t('job.versions.new_version.description')}
        </Text>

        <div className={styles.createVersionTextarea}>
          <Label htmlFor="description">
            {t('job.versions.new_version.label')}
          </Label>
          <Textarea
            id="description"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </Modal.Body>

      <Modal.Footer
        className={styles.createModalFooter}
        confirmText={t('job.versions.new_version.submit_btn')}
        closeText={t('cancel')}
        onClose={onClose}
        onConfirm={submitVersionUpdate}
      />
    </Modal>
  );
};

CreateVersionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onVersionUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateVersionModal;
