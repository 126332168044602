import React from 'react';
import { Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import PreLoginLayout from './refactoring/layouts/PreLoginLayout';
import ErrorBoundary from './ErrorBoundary';

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <ErrorBoundary>
        <PreLoginLayout>
          <Component {...props} />
        </PreLoginLayout>
      </ErrorBoundary>
    )}
  />
);

export default withTranslation()(PublicRoute);
