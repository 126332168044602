export const dashboardJobsSelector = (state) =>
  state.newDashboard.dashboard.jobs;

export const dashboardTotalJobsSelector = (state) =>
  state.newDashboard.dashboard.totalJobs;

export const dashboardIsLoadingSelector = (state) =>
  state.newDashboard.dashboard.isLoading;

export const jobActionModalSelector = (state) =>
  state.newDashboard.dashboard.jobActionModal;

export const selectedJobSelector = (state) =>
  state.newDashboard.dashboard.selectedJob;

export const isLoadingAddRemoveCollaboratorSelector = (state) =>
  state.newDashboard.dashboard.isLoadingAddRemoveCollaborator;
