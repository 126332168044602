import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames/bind';
import { Text, LinkButton } from '@lionstep/ui';

import { ReactComponent as Logo } from '../../../assets/icons/lionstep-logo.svg';

// Components
import HeaderLanguageDropdown from './components/HeaderLanguageDropdown';

// Styles
import styles from './headerPreLogin.module.scss';

const cx = classnames.bind(styles);

const HeaderPreLogin = ({ className }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const renderLoginBtn = (props) => (
    <LinkButton ghost minWidth={false} size="small" {...props} />
  );

  return (
    <div className={cx(styles.header, className)}>
      <div className={styles.headerLeft}>
        <Link to="/">
          <Logo className={styles.logo} />
        </Link>
      </div>
      <div className={styles.headerRight}>
        {pathname === '/register' && (
          <>
            <Text className={styles.loginText} size="small">
              {`${t('already_account')}`}
            </Text>
            <Link
              to="/login"
              className={styles.loginButton}
              component={renderLoginBtn}
            >
              {t('login')}
            </Link>
          </>
        )}
        <HeaderLanguageDropdown menuSecondary />
      </div>
    </div>
  );
};

HeaderPreLogin.defaultProps = {
  className: '',
};

HeaderPreLogin.propTypes = {
  className: PropTypes.string,
};

export default HeaderPreLogin;
