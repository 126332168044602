import React from 'react';
import PropTypes from 'prop-types';

import styles from './fieldInfo.module.scss';

const FieldInfo = ({ info }) =>
  info ? (
    <p data-testid="field-info" className={styles.fieldInfo}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="15.5"
        viewBox="0 0 16 16"
        enableBackground="new 0 0 16 16"
        xmlSpace="preserve"
      >
        <g>
          <path
            fill="#C2A34A"
            d="M15.72,12.02L9.79,2.05C9.61,1.74,9.35,1.49,9.04,1.31C8.05,0.76,6.78,1.09,6.21,2.05l-5.93,9.98
          C0.1,12.33,0,12.68,0,13.03c0,1.11,0.93,2.01,2.07,2.01h11.86l0-0.01c0.36,0,0.72-0.09,1.03-0.27
          C15.95,14.21,16.29,12.99,15.72,12.02z M13.93,13.74H2.07c-0.13,0-0.26-0.03-0.37-0.1c-0.35-0.2-0.47-0.63-0.27-0.97L7.36,2.7
          C7.49,2.48,7.74,2.34,8,2.34c0.26,0,0.51,0.13,0.64,0.36l5.93,9.97c0.06,0.11,0.1,0.23,0.1,0.36
          C14.67,13.42,14.34,13.74,13.93,13.74z"
          />
          <path
            fill="#C2A34A"
            d="M8,10.01c0.31,0,0.56-0.28,0.56-0.62V5.64C8.56,5.3,8.31,5.02,8,5.02S7.44,5.3,7.44,5.64v3.75 C7.44,9.73,7.69,10.01,8,10.01z"
          />
          <ellipse fill="#C2A34A" cx="8" cy="11.81" rx="0.62" ry="0.6" />
        </g>
      </svg>
      {info}
    </p>
  ) : null;

FieldInfo.defaultProps = {
  info: '',
};

FieldInfo.propTypes = {
  info: PropTypes.string,
};

export default FieldInfo;
