import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';

import * as reducers from './ducks';
import api from './middlewares/api';

import { ENV, SENTRY_DNS } from '../config';

const configureStore = () => {
  const enhancers = [];

  if (ENV === 'production' || ENV === 'qa') {
    const sentryReduxEnhancer = Sentry.createReduxEnhancer({
      dsn: SENTRY_DNS,
      normalizeDepth: 10,
    });
    enhancers.push(sentryReduxEnhancer);
  }

  const middleware = [api, thunk];

  const appReducer = combineReducers({
    ...reducers,
  });

  const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT_USER') {
      return appReducer(undefined, action);
    }

    return appReducer(state, action);
  };

  if (ENV === 'qa' || ENV === 'development') {
    // eslint-disable-next-line no-underscore-dangle
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers,
  );

  const store = createStore(rootReducer, composedEnhancers);

  return store;
};

const store = configureStore({});

export default store;
