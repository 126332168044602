import React from 'react';
import PropTypes from 'prop-types';

const ExternalServices = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    className={className}
  >
    <title>09</title>
    <g id="Grey">
      <path
        id="Object2"
        d="M64,28.92a1,1,0,0,0,0-.1,1,1,0,0,0-.08-.25l0-.07a1,1,0,0,0-.23-.26l0,0L55,21.51V4a4,4,0,0,0-4-4H13A4,4,0,0,0,9,4V21.51L.39,28.21l0,0a1,1,0,0,0-.23.26l0,.07a1.06,1.06,0,0,0-.1.36S0,29,0,29V60a4,4,0,0,0,4,4H60a4,4,0,0,0,4-4V29S64,28.95,64,28.92ZM55,24l6.39,5L55,34.19ZM11,4a2,2,0,0,1,2-2H51a2,2,0,0,1,2,2V35.81L42,44.75l-7.44-6a4,4,0,0,0-5,0l-7.44,6L11,35.81ZM9,24V34.19L2.61,29ZM62,60a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V31.1L20.46,46,5.37,58.22a1,1,0,0,0,1.26,1.56L30.74,40.3a2,2,0,0,1,2.52,0L57.37,59.78a1,1,0,0,0,1.26-1.56L43.54,46,62,31.1Z"
        transform="translate(0 0)"
        fill="#899198"
      />
    </g>
    <g id="Coloured">
      <g id="Object1">
        <path
          d="M47.25,20,48,16.89a1,1,0,0,0-.2-.86l-2-2.5L45,10.42a1,1,0,0,0-.55-.68l-3-1.39-2-2.5a1,1,0,0,0-.77-.37H35.39l-3-1.39a1,1,0,0,0-.85,0l-3,1.39H25.32a1,1,0,0,0-.77.37l-2,2.5-3,1.39a1,1,0,0,0-.55.68l-.73,3.11-2,2.5a1,1,0,0,0-.2.86L16.75,20,16,23.11a1,1,0,0,0,.2.86l2,2.5L19,29.58a1,1,0,0,0,.55.68l3,1.39,2,2.5a1,1,0,0,0,.77.37h3.29l3,1.39a1,1,0,0,0,.85,0l3-1.39h3.29a1,1,0,0,0,.77-.37l2-2.5,3-1.39a1,1,0,0,0,.55-.68l.73-3.11,2-2.5a1,1,0,0,0,.2-.86Zm-1.33,3.09L44,25.38a1,1,0,0,0-.2.41l-.67,2.87-2.73,1.28a1,1,0,0,0-.35.27L38.2,32.51h-3a1,1,0,0,0-.42.09L32,33.9l-2.74-1.29a1,1,0,0,0-.42-.09h-3L23.91,30.2a1,1,0,0,0-.35-.27l-2.73-1.28-.67-2.87a1,1,0,0,0-.2-.41l-1.88-2.3.67-2.86a1,1,0,0,0,0-.46l-.67-2.86L20,14.62a1,1,0,0,0,.2-.41l.67-2.87,2.73-1.28a1,1,0,0,0,.35-.27L25.8,7.49h3a1,1,0,0,0,.42-.09L32,6.1l2.74,1.29a1,1,0,0,0,.42.09h3l1.89,2.31a1,1,0,0,0,.35.27l2.73,1.28.67,2.87a1,1,0,0,0,.2.41l1.88,2.3-.67,2.86a1,1,0,0,0,0,.46Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M38.29,12.29l-14,14a1,1,0,1,0,1.41,1.41l14-14a1,1,0,0,0-1.41-1.41Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M27.5,19A3.5,3.5,0,1,0,24,15.5,3.5,3.5,0,0,0,27.5,19Zm0-5A1.5,1.5,0,1,1,26,15.5,1.5,1.5,0,0,1,27.5,14Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M36.5,21A3.5,3.5,0,1,0,40,24.5,3.5,3.5,0,0,0,36.5,21Zm0,5A1.5,1.5,0,1,1,38,24.5,1.5,1.5,0,0,1,36.5,26Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
      </g>
    </g>
  </svg>
);

ExternalServices.defaultProps = {
  companyColour: '#ab1923',
};

ExternalServices.propTypes = {
  companyColour: PropTypes.string,
};

export default ExternalServices;
