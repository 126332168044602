export const RESET_JOB = 'JOB::RESET_JOB';

export const GET_JOB_START = 'JOB::GET_JOB_START';
export const GET_JOB_SUCCESS = 'JOB::GET_JOB_SUCCESS';
export const GET_JOB_FAIL = 'JOB::GET_JOB_FAIL';

export const CREATE_JOB_START = 'JOB::CREATE_JOB_START';
export const CREATE_JOB_SUCCESS = 'JOB::CREATE_JOB_SUCCESS';
export const CREATE_JOB_FAIL = 'JOB::CREATE_JOB_FAIL';

export const UPDATE_JOB_ACCOUNT_MANAGER_START =
  'JOB::UPDATE_JOB_ACCOUNT_MANAGER_START';
export const UPDATE_JOB_ACCOUNT_MANAGER_START_SUCCESS =
  'JOB:UPDATE_JOB_ACCOUNT_MANAGER_START_SUCCESS';
export const UPDATE_JOB_ACCOUNT_MANAGER_START_FAIL =
  'JOB::UPDATE_JOB_ACCOUNT_MANAGER_START_FAIL';

export const UPDATE_JOB_PERSONALITY_CHECK_START =
  'JOB::UPDATE_JOB_PERSONALITY_CHECK_START';
export const UPDATE_JOB_PERSONALITY_CHECK_SUCCESS =
  'JOB::UPDATE_JOB_PERSONALITY_CHECK_SUCCESS';
export const UPDATE_JOB_PERSONALITY_CHECK_FAIL =
  'JOB::UPDATE_JOB_PERSONALITY_CHECK_FAIL';

export const UPDATE_JOB_START = 'JOB::UPDATE_JOB_START';
export const UPDATE_JOB_SUCCESS = 'JOB::UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_FAIL = 'JOB::UPDATE_JOB_FAIL';

export const GET_EXTRA_INFO_START = 'JOB::GET_EXTRA_INFO_START';
export const GET_EXTRA_INFO_SUCCESS = 'JOB::GET_EXTRA_INFO_SUCCESS';
export const GET_EXTRA_INFO_FAIL = 'JOB::GET_EXTRA_INFO_FAIL';

export const UPDATE_EXTRA_INFO_START = 'JOB::UPDATE_EXTRA_INFO_START';
export const UPDATE_EXTRA_INFO_SUCCESS = 'JOB::UPDATE_EXTRA_INFO_SUCCESS';
export const UPDATE_EXTRA_INFO_FAIL = 'JOB::UPDATE_EXTRA_INFO_FAIL';

export const SET_JOB_USERS_START = 'JOB::SET_JOB_USERS_START';
export const SET_JOB_USERS_SUCCESS = 'JOB::SET_JOB_USERS_SUCCESS';
export const SET_JOB_USERS_FAIL = 'JOB::SET_JOB_USERS_FAIL';

export const CREATE_DEFAULT_KILLER_QUESTIONS_START =
  'JOB::CREATE_DEFAULT_KILLER_QUESTIONS_START';
export const CREATE_DEFAULT_KILLER_QUESTIONS_SUCCESS =
  'JOB::CREATE_DEFAULT_KILLER_QUESTIONS_SUCCESS';
export const CREATE_DEFAULT_KILLER_QUESTIONS_FAIL =
  'JOB::CREATE_DEFAULT_KILLER_QUESTIONS_FAIL';

export const CREATE_DEFAULT_OPTIONAL_QUESTIONS_START =
  'JOB::CREATE_DEFAULT_OPTIONAL_QUESTIONS_START';
export const CREATE_DEFAULT_OPTIONAL_QUESTIONS_SUCCESS =
  'JOB::CREATE_DEFAULT_OPTIONAL_QUESTIONS_SUCCESS';
export const CREATE_DEFAULT_OPTIONAL_QUESTIONS_FAIL =
  'JOB::CREATE_DEFAULT_OPTIONAL_QUESTIONS_FAIL';

export const GET_JOB_LONGLIST_START = 'JOB::GET_JOB_LONGLIST_START';
export const GET_JOB_LONGLIST_SUCCESS = 'JOB::GET_JOB_LONGLIST_SUCCESS';
export const GET_JOB_LONGLIST_FAIL = 'JOB::GET_JOB_LONGLIST_FAIL';

export const GET_TITLES_START = 'JOB::GET_TITLES_START';
export const GET_TITLES_SUCCESS = 'JOB::GET_TITLES_SUCCESS';
export const GET_TITLES_FAIL = 'JOB::GET_TITLES_FAIL';

export const CLEAR_TITLES = 'JOB::CLEAR_TITLES';

export const GET_SKILLS_START = 'JOB::GET_SKILLS_START';
export const GET_SKILLS_SUCCESS = 'JOB::GET_SKILLS_SUCCESS';
export const GET_SKILLS_FAIL = 'JOB::GET_SKILLS_FAIL';

export const CLEAR_SKILLS = 'JOB::CLEAR_SKILLS';

export const GET_LOCATIONS_START = 'JOB::GET_LOCATIONS_START';
export const GET_LOCATIONS_SUCCESS = 'JOB::GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAIL = 'JOB::GET_LOCATIONS_FAIL';

export const CLEAR_LOCATIONS = 'JOB::CLEAR_LOCATIONS';

export const GET_LANGUAGES_START = 'JOB::GET_LANGUAGES_START';
export const GET_LANGUAGES_SUCCESS = 'JOB::GET_LANGUAGES_SUCCESS';
export const GET_LANGUAGES_FAIL = 'JOB::GET_LANGUAGES_FAIL';

export const CLEAR_LANGUAGES = 'JOB::CLEAR_LANGUAGES';

export const UPLOAD_JOB_FILE_START = 'JOB::UPLOAD_JOB_FILE_START';
export const UPLOAD_JOB_FILE_SUCCESS = 'JOB::UPLOAD_JOB_FILE_SUCCESS';
export const UPLOAD_JOB_FILE_FAIL = 'JOB::UPLOAD_JOB_FILE_FAIL';

export const DELETE_JOB_FILE_START = 'JOB::DELETE_JOB_FILE_START';
export const DELETE_JOB_FILE_SUCCESS = 'JOB::DELETE_JOB_FILE_SUCCESS';
export const DELETE_JOB_FILE_FAIL = 'JOB::DELETE_JOB_FILE_FAIL';

export const SEND_NEW_JOB_PUBLISHED_EMAIL_START =
  'JOB::SEND_NEW_JOB_PUBLISHED_EMAIL_START';
export const SEND_NEW_JOB_PUBLISHED_EMAIL_SUCCESS =
  'JOB::SEND_NEW_JOB_PUBLISHED_EMAIL_SUCCESS';
export const SEND_NEW_JOB_PUBLISHED_EMAIL_FAIL =
  'JOB::SEND_NEW_JOB_PUBLISHED_EMAIL_FAIL';
