import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Heading, Table, ButtonLink, Spinner } from '@lionstep/ui';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Selectors
import {
  purchasesSelector,
  isLoadingPurchasesSelector,
} from '../../../../redux/selectors/credits.selectors';

// Components
import EditExpirationDateModal from '../EditExpirationDateModal';

// Constants
import { DATE_FORMAT } from '../../../../../../constants/date.const';

// Styles
import styles from './allPurchasesCard.module.scss';

const AllPurchasesCard = () => {
  const { t } = useTranslation();
  const purchases = useSelector(purchasesSelector);
  const isLoading = useSelector(isLoadingPurchasesSelector);
  const [selectedPurchase, setSelectedPurchase] = useState(null);

  const handleOnSelectedPurchase = (id) => {
    setSelectedPurchase(id);
  };

  const handleOnModalClose = () => {
    setSelectedPurchase(null);
  };

  return (
    <Card className={styles.purchasesCard}>
      {isLoading && <Spinner position="fixed" size={100} />}

      <Heading className={styles.purchasesTitle} level={4}>
        All Purchases
      </Heading>

      <Table>
        <Table.Header>
          <Table.HeaderRow>
            <Table.HeaderCell>Added At</Table.HeaderCell>
            <Table.HeaderCell>Package credits</Table.HeaderCell>
            <Table.HeaderCell>Remaining credits</Table.HeaderCell>
            <Table.HeaderCell>Removed</Table.HeaderCell>
            <Table.HeaderCell>Removed at</Table.HeaderCell>
            <Table.HeaderCell>Comment</Table.HeaderCell>
            <Table.HeaderCell>Expires at</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.HeaderRow>
        </Table.Header>

        <Table.Body>
          {purchases.map((purchase, index) => (
            <Table.Row
              key={purchase.id}
              data-testid={`table-purchase-row-${index}`}
            >
              <Table.Cell>
                {moment(purchase.created_at).format(DATE_FORMAT)}
              </Table.Cell>
              <Table.Cell>{purchase.package_credits}</Table.Cell>
              <Table.Cell>{purchase.valid_credits}</Table.Cell>
              <Table.Cell>{purchase.removed ? t('yes') : t('no')}</Table.Cell>
              <Table.Cell>
                {purchase.removed_at
                  ? moment(purchase.removed_at).format(DATE_FORMAT)
                  : '⎯'}
              </Table.Cell>
              <Table.Cell>
                <div className={styles.fixedCell}>{purchase.comment}</div>
              </Table.Cell>
              <Table.Cell>
                {moment(purchase.availability).format(DATE_FORMAT)}
              </Table.Cell>
              <Table.Cell>
                <ButtonLink onClick={() => handleOnSelectedPurchase(purchase)}>
                  Edit
                </ButtonLink>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <EditExpirationDateModal
        selectedPurchase={selectedPurchase}
        onClose={handleOnModalClose}
      />
    </Card>
  );
};

export default AllPurchasesCard;
