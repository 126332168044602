import * as types from './candidate.types';

const initialState = {
  data: null,
  isLoading: false,
  isLoadingUpdate: false,
  isLoadingPDFReport: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CANDIDATE_START:
      return {
        ...state,
        data: null,
        isLoading: true,
      };

    case types.GET_CANDIDATE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };

    case types.GET_CANDIDATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    /**
     * CANDIDATE PDF REPORT
     */
    case types.GET_CANDIDATE_PDF_REPORT_START:
      return {
        ...state,
        isLoadingPDFReport: true,
      };

    case types.GET_CANDIDATE_PDF_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingPDFReport: false,
      };

    case types.GET_CANDIDATE_PDF_REPORT_FAIL:
      return {
        ...state,
        isLoadingPDFReport: false,
      };

    /**
     * CANDIDATE FEEDBACK
     */
    case types.FEEDBACK_CANDIDATE_START:
      return {
        ...state,
        isLoadingUpdate: true,
      };

    case types.FEEDBACK_CANDIDATE_SUCCESS:
      return {
        ...state,
        isLoadingUpdate: false,
        data: {
          ...state.data,
          feedback_history: [
            ...state.data.feedback_history,
            { feedback: action.payload.feedback },
          ],
        },
      };

    case types.FEEDBACK_CANDIDATE_FAIL:
      return {
        ...state,
        isLoadingUpdate: false,
      };

    /**
     * HIRE CANDIDATE
     */
    case types.HIRE_CANDIDATE_START:
      return {
        ...state,
        isLoadingUpdate: true,
      };

    case types.HIRE_CANDIDATE_SUCCESS:
      return {
        ...state,
        isLoadingUpdate: false,
        data: {
          ...state.data,
          status: action.payload.status,
        },
      };

    case types.HIRE_CANDIDATE_FAIL:
      return {
        ...state,
        isLoadingUpdate: false,
      };

    /**
     * REJECT CANDIDATE
     */
    case types.REJECT_CANDIDATE_START:
      return {
        ...state,
        isLoadingUpdate: true,
      };

    case types.REJECT_CANDIDATE_SUCCESS:
      return {
        ...state,
        isLoadingUpdate: false,
        data: {
          ...state.data,
          status: action.payload.status,
          rejection_reason: action.payload.comment,
          rejected_by_email: action.payload.rejected_by_email,
          rejected_by_name: action.payload.rejected_by_name,
        },
      };

    case types.REJECT_CANDIDATE_FAIL:
      return {
        ...state,
        isLoadingUpdate: false,
      };

    /**
     * REFUNDED CREDIT
     */
    case types.CANDIDATE_CREDIT_REFUNDED_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          credit_refunded: true,
        },
      };

    /**
     * RESET CANDIDATE
     */
    case types.RESET_CANDIDATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};
