import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Text, SelectV2 } from '@lionstep/ui';

// Styles
import styles from './changeOwnerModal.module.scss';

const ChangeOwnerModal = ({
  job,
  user,
  collaborators,
  onClose,
  show,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);

  const handleOnSelectChange = (value) => {
    setSelectedCollaborator(value);
  };

  const handleGenerateDropdownOptions = () => {
    const dropdownValues = [];
    const dropdownValuesArr = [];

    if (collaborators !== null) {
      const filteredCollaborators = collaborators.filter(
        (col) => col.local_id !== user.local_id,
      );

      filteredCollaborators.forEach((entry) => {
        dropdownValues.push(entry);
      });

      if (dropdownValues.length > 0) {
        dropdownValues.map((entry) =>
          dropdownValuesArr.push({
            value: entry.local_id,
            label: entry.display_name,
          }),
        );
      }
    }

    return dropdownValuesArr;
  };

  return (
    <Modal show={show} onClose={onClose}>
      <Modal.Header>{t('change_owner')}</Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <div className={styles.bodyContainer}>
          <Text className={styles.bodyText}>{t('choose_new_owner')}</Text>
          <SelectV2
            placeholder={t('invite_collaborators')}
            options={handleGenerateDropdownOptions()}
            maxMenuHeight="170px"
            onChange={handleOnSelectChange}
          />
        </div>
      </Modal.Body>
      <Modal.Footer
        confirmText={t('change_owner')}
        closeText={t('cancel')}
        onConfirm={() => {
          if (selectedCollaborator) {
            onConfirm({
              jobId: job.job_id,
              newOwnerId: selectedCollaborator.value,
            });
          }
        }}
        onClose={onClose}
      />
    </Modal>
  );
};

export default ChangeOwnerModal;
