export const GET_ACCOUNT_MANAGERS_START =
  'ADMIN_ACTIONS::GET_ACCOUNT_MANAGERS_START';
export const GET_ACCOUNT_MANAGERS_SUCCESS =
  'ADMIN_ACTIONS::GET_ACCOUNT_MANAGERS_SUCCESS';
export const GET_ACCOUNT_MANAGERS_FAIL =
  'ADMIN_ACTIONS::GET_ACCOUNT_MANAGERS_FAIL';

export const GET_CITIES_START = 'ADMIN_ACTIONS::GET_CITIES_START';
export const GET_CITIES_SUCCESS = 'ADMIN_ACTIONS::GET_CITIES_SUCCESS';
export const GET_CITIES_FAIL = 'ADMIN_ACTIONS::GET_CITIES_FAIL';

export const CLEAR_CITIES = 'ADMIN_ACTIONS::CLEAR_CITIES';

export const GET_KILLER_QUESTIONS_START =
  'ADMIN_ACTIONS::GET_KILLER_QUESTIONS_START';
export const GET_KILLER_QUESTIONS_SUCCESS =
  'ADMIN_ACTIONS::GET_KILLER_QUESTIONS_SUCCESS';
export const GET_KILLER_QUESTIONS_FAIL =
  'ADMIN_ACTIONS::GET_KILLER_QUESTIONS_FAIL';

export const UPDATE_KILLER_QUESTIONS_START =
  'ADMIN_ACTIONS::UPDATE_KILLER_QUESTIONS_START';
export const UPDATE_KILLER_QUESTIONS_SUCCESS =
  'ADMIN_ACTIONS::UPDATE_KILLER_QUESTIONS_SUCCESS';
export const UPDATE_KILLER_QUESTIONS_FAIL =
  'ADMIN_ACTIONS::UPDATE_KILLER_QUESTIONS_FAIL';

export const GET_SKILLS_START = 'ADMIN_ACTIONS::GET_SKILLS_START';
export const GET_SKILLS_SUCCESS = 'ADMIN_ACTIONS::GET_SKILLS_SUCCESS';
export const GET_SKILLS_FAIL = 'ADMIN_ACTIONS::GET_SKILLS_FAIL';

export const CLEAR_ALL_SKILLS = 'ADMIN_ACTIONS::CLEAR_ALL_SKILLS';
