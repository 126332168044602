import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Scroller, Icon } from '@lionstep/ui';

// Constants
import { CANDIDATE_DROPPED_OUT_REASONS } from '../../../../job.constants';

// Hooks
import useSearchParams from '../../../../../../hooks/useSearchParams';

// Styles
import styles from './candidateFilter.module.scss';

const cx = classnames.bind(styles);

const CandidateFilter = ({ filterStats, onFilterChange }) => {
  const { t } = useTranslation();
  const { params } = useSearchParams();

  const [activeFilter, setActiveFilter] = useState(null);

  useEffect(() => {
    if (params.filter) {
      setActiveFilter(params.filter);
    }
  }, []);

  const calculateFilterOptions = () => {
    const sortedItems = Object.entries(filterStats).sort(
      (firstFilter, secondFilter) => secondFilter[1] - firstFilter[1],
    );
    const filterItems = sortedItems.reduce((acc, curr, index) => {
      const [filterValue, filterCount] = curr;
      const translation = CANDIDATE_DROPPED_OUT_REASONS.find(
        (item) => item.value === filterValue,
      );

      if (index <= 5) {
        acc[index] = {
          label: t(translation.label),
          count: filterCount,
          value: filterValue,
        };
      } else {
        acc[6] = {
          label: t('candidates_page.rejection_reason_filters.other'),
          count: (acc[6] ? acc[6].count : 0) + filterCount,
          value: acc[6] ? `${acc[6].value},${filterValue}` : filterValue,
        };
      }

      return acc;
    }, []);

    return [
      {
        label: t('candidates_page.rejection_reason_filters.all'),
        value: null,
      },
      ...filterItems,
    ];
  };

  const handleOnItemClick = (value) => {
    const currFilter = activeFilter !== value ? value : null;

    setActiveFilter(currFilter);
    onFilterChange(currFilter);
  };

  const renderScrollerControlLeft = (props) => (
    <span
      {...props}
      className={cx(styles.candidateFilterArrowLeft, {
        [styles.candidateFilterArrowMaxLeft]: props.scrolledMaxLeft,
      })}
    >
      <Icon name="ArrowLeft" size={20} />
    </span>
  );

  const renderScrollControlRight = (props) => (
    <span
      {...props}
      className={cx(styles.candidateFilterArrowRight, {
        [styles.candidateFilterArrowMaxRight]: props.scrolledMaxRight,
      })}
    >
      <Icon name="ArrowRight" size={20} />
    </span>
  );

  return (
    <Scroller
      scrollerWrapperClassName={styles.candidateFilter}
      contentId="candidateFilter"
      scrollControlLeft={renderScrollerControlLeft}
      scrollControlRight={renderScrollControlRight}
    >
      <Scroller.Item>
        <span className={styles.candidateFilterTitle}>Filter:</span>
      </Scroller.Item>

      {calculateFilterOptions().map(({ value, label, count }) => (
        <Scroller.Item key={String(value)}>
          <span className={styles.candidateFilterItemWrapper}>
            <span
              className={cx(styles.candidateFilterItem, {
                [styles.candidateFilterItemEmpty]:
                  activeFilter !== value && count === 0,
                [styles.candidateFilterItemActive]: activeFilter === value,
              })}
              onClick={() => handleOnItemClick(value)}
            >
              <span className={styles.candidateFilterItemText}>{label}</span>
              {value && (
                <span className={styles.candidateFilterItemCount}>{count}</span>
              )}
            </span>
          </span>
        </Scroller.Item>
      ))}
    </Scroller>
  );
};

CandidateFilter.propTypes = {
  filterStats: PropTypes.shape().isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

export default CandidateFilter;
