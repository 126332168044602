import React from 'react';
import PropTypes from 'prop-types';

const CoffeSnacks = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 74 66"
    className={className}
  >
    <title>Coffee Snacks</title>
    <g id="Grey">
      <path
        id="Object2"
        d="M73.59,23.91,68.17,13.23A5.91,5.91,0,0,0,62.87,10H58V6.81A2.23,2.23,0,0,0,56,4.6L13.82,0a1.64,1.64,0,0,0-1.58.77,1.59,1.59,0,0,0,.11,1.81L21,13.35v7.23c-5.55,5.47-8.66,11.47-9,17.36a1,1,0,0,0,2,.11c.39-6.76,4.72-12.42,8.41-16.06H56.59A23.69,23.69,0,0,1,64,39c0,14-10.76,25-24.51,25h0a24.28,24.28,0,0,1-4.12-.35,1,1,0,0,0-1.18,1,1,1,0,0,0,.85,1,26.25,26.25,0,0,0,4.45.38h.05C54.36,66,66,54.14,66,39a25.64,25.64,0,0,0-8-18.41V17h3.35a1.46,1.46,0,0,1,1.32.8l4.8,9.31a3.45,3.45,0,0,0,2.31,1.8,3.44,3.44,0,0,0,3.81-5ZM23,20V14H52a1,1,0,0,0,0-2H22.48l-8-9.9L55.77,6.59A.24.24,0,0,1,56,6.8V20ZM71.46,26.7a1.49,1.49,0,0,1-1.23.26,1.45,1.45,0,0,1-1-.77l-4.8-9.31A3.45,3.45,0,0,0,61.35,15H58V12h4.87a3.92,3.92,0,0,1,3.52,2.14l5.43,10.7A1.5,1.5,0,0,1,71.46,26.7Z"
        transform="translate(0 0)"
        fill="#899198"
      />
    </g>
    <g id="Coloured">
      <g id="Object1">
        <path
          d="M49.5,44A3.5,3.5,0,1,0,53,47.5,3.5,3.5,0,0,0,49.5,44Zm0,5A1.5,1.5,0,1,1,51,47.5,1.5,1.5,0,0,1,49.5,49Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M49.12,41.07a1.14,1.14,0,0,0,.13.27l.18.23.22.18a1.13,1.13,0,0,0,.27.14,1,1,0,0,0,.27.09l.3,0a1.26,1.26,0,0,0,.57-.12,1.73,1.73,0,0,0,.49-.32A1.55,1.55,0,0,0,52,40.5a1.5,1.5,0,0,0-.12-.57,1.35,1.35,0,0,0-.81-.81,1.5,1.5,0,0,0-1.63.32,1.73,1.73,0,0,0-.31.5,1.26,1.26,0,0,0-.12.57,2,2,0,0,0,0,.3A1,1,0,0,0,49.12,41.07Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M55.92,42.62a.9.9,0,0,0-.54-.54,1,1,0,0,0-1.09.21,1.16,1.16,0,0,0-.21.33A.84.84,0,0,0,54,43a1.36,1.36,0,0,0,0,.2.64.64,0,0,0,.06.18.76.76,0,0,0,.09.18l.12.15.15.12a.76.76,0,0,0,.18.09.63.63,0,0,0,.18.06l.2,0a.84.84,0,0,0,.38-.08,1.15,1.15,0,0,0,.33-.21A1,1,0,0,0,56,43,1,1,0,0,0,55.92,42.62Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
        <path
          d="M54.72,25.3A1,1,0,0,0,54,25H24a1,1,0,0,0-.72.31A22.13,22.13,0,0,0,17.15,37H4.27A2.25,2.25,0,0,0,2,39.22v8.56A13.12,13.12,0,0,0,8.49,59H1a1,1,0,0,0-1,1,6,6,0,0,0,5.93,6H33.07a6,6,0,0,0,5.84-5H39A22.08,22.08,0,0,0,61,39,20.86,20.86,0,0,0,54.72,25.3ZM4,47.78V39.22A.25.25,0,0,1,4.27,39H11v2.7L9.17,44.45A1,1,0,0,0,9,45v3.8A2.2,2.2,0,0,0,11.2,51h3.6A2.2,2.2,0,0,0,17,48.8V45a1,1,0,0,0-2,0v3.8a.2.2,0,0,1-.2.2H11.2a.2.2,0,0,1-.2-.2V45.3l1.83-2.75A1,1,0,0,0,13,42V39H34.73a.25.25,0,0,1,.27.22v8.56a11.19,11.19,0,0,1-7.13,10.33,11.88,11.88,0,0,1-4.53.89H15.69A11.48,11.48,0,0,1,4,47.78ZM33.07,64H5.93a4,4,0,0,1-3.8-3H36.88A4,4,0,0,1,33.07,64ZM39,59H30.54A13.08,13.08,0,0,0,37,47.78V39.22A2.25,2.25,0,0,0,34.73,37H19.17a20.14,20.14,0,0,1,5.26-10H53.57A18.49,18.49,0,0,1,59,39,20.06,20.06,0,0,1,39,59Z"
          transform="translate(0 0)"
          fill={companyColour}
        />
      </g>
    </g>
  </svg>
);

CoffeSnacks.defaultProps = {
  companyColour: '#ab1923',
};

CoffeSnacks.propTypes = {
  companyColour: PropTypes.string,
};

export default CoffeSnacks;
