import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Button,
  Spinner,
  Grid,
  Heading,
  Text,
  FormInput,
  toast,
} from '@lionstep/ui';

// Operations
import userDataOperations from '../../../redux/ducks/userData/operations';

// Helpers
import { getRecaptcha } from '../../../support';

// Hooks
import useSearchParams from '../../../hooks/useSearchParams';

// Components
import { ReactComponent as Logo } from '../../../assets/icons/lionstep-logo.svg';

// Styles
import styles from './resetPassword.module.scss';

const ResetPassword = () => {
  const [showSpinner, setShowSpinner] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { verifyOobCode, changePassword } = userDataOperations;

  const { params } = useSearchParams();

  const [email, setEmail] = useState('');
  const [codeVerified, setCodeVerified] = useState(false);

  const schema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password must be 8+ characters long')
      .matches(
        /^(?=.*\d)(?=.*[A-Z]).{8,}$/,
        'Password must contain at least one uppercase letter and one number',
      )
      .required(t('Required')),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match',
    ),
  });

  const formMethods = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    const verifyOobCodeFn = async () => {
      try {
        const token = await getRecaptcha();

        const { data } = await dispatch(
          verifyOobCode({
            oobCode: params.oobCode,
            recaptcha_token: token,
          }),
        );

        setCodeVerified(true);
        setEmail(data.email);
        setShowSpinner(false);
      } catch {
        setShowSpinner(false);
      }
    };

    verifyOobCodeFn();
  }, []);

  const onSubmit = async ({ password }) => {
    try {
      setShowSpinner(true);
      const token = await getRecaptcha();

      await dispatch(
        changePassword({
          password: password.trim(),
          recaptcha_token: token,
          oobCode: params.oobCode,
        }),
      );

      setShowSpinner(false);
      history.push('/login');
      toast.success(t('Password was successfully changed.'), {
        id: 'RESET_PASSWORD_SUCCESS',
      });
    } catch (error) {
      setShowSpinner(false);
      toast.error('Error reseting password', {
        id: 'RESET_PASSWORD_FAIL',
        statusCode: error?.response?.status,
      });
    }
  };

  const calculateTextToShow = () => {
    if (!showSpinner) {
      if (!codeVerified) {
        return t('The reset link has expired.');
      }
      return `${t(
        'Enter a new password for the Lionstep account associated with the email address',
      )} ${email}`;
    }
    return '';
  };

  if (showSpinner && !codeVerified)
    return <Spinner position="fixed" size={100} />;

  return (
    <Grid.Container>
      <Grid.Row className={styles.resetPassword} gutter={{ xs: 24, sm: 0 }}>
        <Grid.Col
          xs={24}
          md={{ span: 14, offset: 5 }}
          lg={{ span: 12, offset: 6 }}
          xl={{ span: 8, offset: 8 }}
        >
          {showSpinner && <Spinner position="fixed" size={100} />}

          <Helmet>
            <title>Set Password | Lionstep</title>
          </Helmet>

          <Logo className={styles.resetPasswordLogo} />

          <Heading
            className={styles.resetPasswordTitle}
            level={1}
            type="secondary"
          >
            {t('Enter a New Password')}
          </Heading>

          <Text className={styles.resetPasswordInfo}>
            {calculateTextToShow()}
          </Text>

          {!showSpinner && codeVerified && (
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormInput
                  label={t('password')}
                  name="password"
                  placeholder={t('password')}
                  type="password"
                  error={errors.password?.message}
                />
                <FormInput
                  label={t('Confirm password')}
                  name="confirmPassword"
                  placeholder={t('Confirm password')}
                  type="password"
                  error={errors.confirmPassword?.message}
                />

                <Button
                  className={styles.resetPasswordSubmitButton}
                  block
                  typeHtml="submit"
                >
                  {t('Reset password')}
                </Button>
              </form>
            </FormProvider>
          )}

          <Link className={styles.resetPasswordLoginLink} to="/login">
            {t('Back to login')}
          </Link>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

export default ResetPassword;
