import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from '@lionstep/ui';

// Api
import { pythonAxios, rubyAxios } from '../api/http';

const JOB_QUERY_KEYS = {
  createJob: 'createJob',
  updateJobPatch: 'updateJobPatch',
  extraInfo: 'extraInfo',
  question: 'question',
  jobVersions: 'jobVersions',
  jobUser: 'jobUser',
  jobMailingPermissions: 'jobMailingPermissions',
  jobTitles: 'jobTitles',
  aiTitleSuggestion: 'aiTitleSuggestion',
};

// === JOB START === //
// Create Job
const postJob = ({ data: jobData }) =>
  pythonAxios({
    method: 'POST',
    url: '/jobs',
    data: jobData,
  }).then(({ data }) => data);

export const useJobCreateMutation = ({ options = {} } = {}) =>
  useMutation([JOB_QUERY_KEYS.createJob], postJob, { ...options });

const postPatch = ({ id, data: jobData }) =>
  pythonAxios({
    method: 'PATCH',
    url: `/jobs/${id}`,
    data: jobData,
  }).then(({ data }) => data);

// TODO: we can update job using PATCH and PUT, at one point this
// should be the done with a same method. At that moment, please
// remove method name from hook name
export const useJobUpdatePatchMutation = ({ options = {} } = {}) =>
  useMutation([JOB_QUERY_KEYS.updateJobPatch], postPatch, { ...options });
// === JOB START === //

// === EXTRA INFO START === //
// Update Extra info
const postExtraInfo = ({ id, data }) =>
  pythonAxios({
    method: 'POST',
    url: `/jobs/${id}/extra_info`,
    data: {
      extra_info: data,
    },
  });

export const useJobExtraInfoMutation = ({ options = {} } = {}) =>
  useMutation([JOB_QUERY_KEYS.extraInfo], postExtraInfo, { ...options });
// === EXTRA INFO START === //

// === VERSIONS START === //
// Create job version
const postJobVersion = ({ id }) =>
  pythonAxios({
    method: 'POST',
    url: `/jobs/${id}/versions`,
    data: {},
  });

export const useJobCreateVersionsMutation = ({ options = {} } = {}) =>
  useMutation([JOB_QUERY_KEYS.jobVersions], postJobVersion, { ...options });
// === QUESTIONS EMD === //

// === QUESTIONS START === //
// Get Questions
const getQuestions = ({ id, questionType }) =>
  pythonAxios({
    method: 'GET',
    url: `/jobs/${id}/questions/${questionType}`,
  }).then(({ data }) => data.questions);

export const useQuestionsQuery = ({ id, questionType, options = {} }) =>
  useQuery(
    [JOB_QUERY_KEYS.question, id, questionType],
    () => getQuestions({ id, questionType }),
    {
      ...options,
    },
  );

// Update Questions
const postQuestions = ({ id, questionType, data: questionsData }) =>
  pythonAxios({
    method: 'POST',
    url: `/jobs/${id}/questions/${questionType}`,
    data: questionsData,
  }).then(({ data }) => data.questions);

export const useQuestionsMutation = ({ id, questionType, options = {} }) =>
  useMutation(
    [JOB_QUERY_KEYS.question, id, questionType],
    ({ id: jobId, data }) =>
      postQuestions({ id: jobId || id, questionType, data }),
    { ...options },
  );
// === QUESTIONS END === //

// === JOB USERS START === //
const postUserToJob = ({ id, userId }) =>
  pythonAxios({
    method: 'POST',
    url: `/jobs/${id}/user/${userId}`,
  }).then(({ data }) => data);

export const useUserToJobMutation = ({ options = {} } = {}) =>
  useMutation([JOB_QUERY_KEYS.jobUser], postUserToJob, { ...options });
// === JOB USERS END === //

// === JOB MAILING PERMISSIONS START === //
const postJobMailingPermissions = ({ data }) =>
  rubyAxios({
    method: 'POST',
    url: '/job_postings/add_mailing_permission',
    data,
  });

export const useJobMailingPermissionsMutation = ({ options = {} } = {}) =>
  useMutation(
    [JOB_QUERY_KEYS.jobMailingPermissions],
    postJobMailingPermissions,
    { ...options },
  );
// === JOB MAILING PERMISSIONS END === //

// === JOB TITLES START === //
const getJobsTitles = () =>
  pythonAxios({
    method: 'GET',
    url: '/jobs/titles',
  }).then(({ data }) => data.results);

export const useJobTitles = ({ options = {} } = {}) =>
  useQuery([JOB_QUERY_KEYS.jobTitles], getJobsTitles, {
    ...options,
    onError: (error) => {
      toast.error('There was an error while fetching jobs list', {
        id: 'FETCH_JOBS_LIST',
        statusCode: error?.response?.status,
      });

      if (options.onError) {
        options.onError(error);
      }
    },
  });
// === JOB TITLES END === //

// === JOB SEARCH TITLES AI SUGGESTION START === //
const getAISearchTitles = ({ id, language }) =>
  pythonAxios({
    method: 'GET',
    url: `jobs/${id}/predict/search-titles`,
    params: {
      language,
    },
  }).then(({ data }) => data.result);

export const useAISearchTitles = ({ id, language, options = {} } = {}) =>
  useQuery(
    [JOB_QUERY_KEYS.aiTitleSuggestion, id],
    () => getAISearchTitles({ id, language }),
    {
      ...options,
      onError: (error) => {
        toast.error(
          'There was an error while fetching search title suggestions',
          {
            id: 'FETCH_AI_TITLES_SUGGESTIONS',
            statusCode: error?.response?.status,
          },
        );

        if (options.onError) {
          options.onError(error);
        }
      },
    },
  );
// === JOB SEARCH TITLES AI SUGGESTION END === //
