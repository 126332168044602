import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Button,
  Spinner,
  Grid,
  Heading,
  Text,
  FormInput,
  Link as LinkUi,
  Gdpr,
  toast,
} from '@lionstep/ui';

import { ReactComponent as Logo } from '../../../assets/icons/lionstep-logo.svg';

// Helpers
import { detectBrowser, getRecaptcha } from '../../../support';

// Config
import { CANDIDATE_PORTAL_APP } from '../../../config';

// Queries
import { useUserLoginMutation } from '../../../queries/userQueries';

// Styles
import styles from './login.module.scss';

const browsersList = ['IE 10'];

const LoginForm = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { mutate: loginUser, isLoading: isLoadingLoginUser } =
    useUserLoginMutation();

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(t('You must enter a valid email address'))
      .required(t('Required')),
    password: Yup.string().required(t('Required')),
  });

  const formMethods = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  // TODO: move this to upper scope
  useEffect(() => {
    const browserName = detectBrowser();

    if (browsersList.includes(browserName)) {
      history.push('/browser-outdated');
    }
  }, []);

  const onSubmit = async ({ email, password }) => {
    const token = await getRecaptcha();

    loginUser(
      {
        data: {
          email: email.trim(),
          password: password.trim(),
          recaptcha_token: token,
        },
      },
      {
        onSuccess: () => {
          history.push('/');
        },
        onError: (error) => {
          toast.error(t('login_error'), {
            id: 'LOGIN_FAIL',
            statusCode: error?.response?.status,
          });
        },
      },
    );
  };

  return (
    <Grid.Container>
      <Grid.Row className={styles.login} gutter={{ xs: 24, sm: 0 }}>
        <Grid.Col
          xs={24}
          md={{ span: 14, offset: 5 }}
          lg={{ span: 12, offset: 6 }}
          xl={{ span: 8, offset: 8 }}
        >
          {isLoadingLoginUser && <Spinner position="fixed" size={100} />}

          <Helmet>
            <title>Login | Lionstep</title>
          </Helmet>

          <Logo className={styles.logo} />

          <Heading className={styles.title} level={1} type="secondary">
            {t('sign_into_account')}
          </Heading>

          <Text className={styles.info}>{t('enter_details')}</Text>

          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormInput
                label={t('work_email')}
                name="email"
                placeholder={t('Enter your email')}
                error={errors.email?.message}
              />
              <FormInput
                label={t('password')}
                name="password"
                placeholder={t('Enter your password')}
                type="password"
                error={errors.password?.message}
              />
              <Link
                className={styles.forgotPassword}
                to="/password-reset"
                data-testid="forgot-pass-link"
              >
                {t('forgot_password')}
              </Link>
              <Button
                className={styles.submitButton}
                block
                typeHtml="submit"
                data-testid="login-btn"
              >
                {t('log_in_email')}
              </Button>
            </form>
          </FormProvider>
          <Gdpr
            className={styles.termsAndConditions}
            acceptText={t('gdpr.accept')}
            termsText={t('gdpr.terms_and_conditions')}
            acknowledgeText={t('gdpr.acknowledge')}
            policyText={t('gdpr.privacy_policy')}
            fromText={t('gdpr.from')}
          />
          <Text
            className={styles.candidatePortalLink}
            data-testid="candidate-portal-link"
          >
            {t('candidate_portal_hint')}{' '}
            <LinkUi
              href={CANDIDATE_PORTAL_APP}
              target="_blank"
              rel="noopener noreferrer"
            >
              {CANDIDATE_PORTAL_APP}
            </LinkUi>
          </Text>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

export default LoginForm;
