import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Heading } from '@lionstep/ui';

// Styles
import styles from './salaryRangeChart.module.scss';

// Components
import NoDataLabel from '../../../../components/Charts/NoDataLabel';

const SalaryRangeChart = ({ extraInfo, insights }) => {
  const { t } = useTranslation();
  const salaryAskedSorted = insights.salary_asked.sort((a, b) => a - b);

  const salaryAskedAverage =
    salaryAskedSorted.reduce((acc, curr) => acc + curr, 0) /
    salaryAskedSorted.length;

  const calculateMinSalary = () => {
    if (extraInfo.base_salary_min) {
      return Number(extraInfo.base_salary_min.replace("'", ''));
    }

    return null;
  };

  const calculateMaxSalary = () => {
    if (extraInfo.base_salary_max) {
      return Number(extraInfo.base_salary_max.replace("'", ''));
    }

    return null;
  };

  const calculateMinOfferedPercentage = () => {
    let minOfferedPercentage = 0;
    const minSalary = calculateMinSalary();

    if (salaryAskedSorted.length) {
      if (salaryAskedSorted.length === 1 && minSalary >= salaryAskedSorted[0]) {
        minOfferedPercentage = (
          ((salaryAskedSorted[0] - minSalary) / salaryAskedSorted[0]) *
          100
        ).toFixed(0);
      } else {
        minOfferedPercentage = (
          ((minSalary - salaryAskedSorted[0]) /
            (salaryAskedSorted[salaryAskedSorted.length - 1] -
              salaryAskedSorted[0])) *
          100
        ).toFixed(0);
      }
    }

    return minOfferedPercentage < 0 || minOfferedPercentage > 100
      ? 0
      : minOfferedPercentage;
  };

  const calculateMaxOfferedPercentage = () => {
    let maxOfferedPercentage = 100;
    const maxSalary = calculateMaxSalary();

    if (!maxSalary) {
      return maxOfferedPercentage;
    }

    if (salaryAskedSorted.length) {
      if (salaryAskedSorted.length === 1 && maxSalary >= salaryAskedSorted[0]) {
        maxOfferedPercentage = (
          ((salaryAskedSorted[0] - maxSalary) / salaryAskedSorted[0]) *
          100
        ).toFixed(0);
      } else {
        maxOfferedPercentage = (
          ((maxSalary - salaryAskedSorted[0]) /
            (salaryAskedSorted[salaryAskedSorted.length - 1] -
              salaryAskedSorted[0])) *
          100
        ).toFixed(0);
      }
    }

    return maxOfferedPercentage > 100 || maxOfferedPercentage < 0
      ? 100
      : maxOfferedPercentage;
  };

  const calculateAveragePercentage = () => {
    let averagePercentage = 50;

    if (salaryAskedSorted.length > 2) {
      averagePercentage = (
        ((salaryAskedAverage - salaryAskedSorted[0]) /
          (salaryAskedSorted[salaryAskedSorted.length - 1] -
            salaryAskedSorted[0])) *
        100
      ).toFixed(0);
    }

    return averagePercentage;
  };

  const calculateStat = () => {
    const minSalary = calculateMinSalary();
    const maxSalary = calculateMaxSalary();

    const stat = salaryAskedSorted.reduce(
      (acc, curr) => {
        if (curr < minSalary) {
          return {
            ...acc,
            lowerThenOffered: acc.lowerThenOffered + 1,
          };
        }

        if (maxSalary && curr > maxSalary) {
          return {
            ...acc,
            higherThenOffered: acc.higherThenOffered + 1,
          };
        }

        // TODO: What if there is no maxSalary
        return {
          ...acc,
          inRange: acc.inRange + 1,
        };
      },
      {
        lowerThenOffered: 0,
        inRange: 0,
        higherThenOffered: 0,
      },
    );

    return stat;
  };

  const noData = !insights.salary_asked.length;
  const minSalary = calculateMinSalary();
  const maxSalary = calculateMaxSalary();
  const { lowerThenOffered, inRange, higherThenOffered } = calculateStat();

  return (
    <div>
      <Heading level={4} className={styles.salaryTitle}>
        {t('insights_page.salary.title')}
        {noData && <NoDataLabel />}
      </Heading>
      <div className={styles.salaryContainer}>
        <div className={styles.salaryStat}>
          <div className={styles.salaryStatItem}>
            <span className={styles.salaryStatValue}>{lowerThenOffered}</span>
            <span className={styles.salaryStatLabel}>
              {t('insights_page.salary.lower_than_offered')}
            </span>
          </div>
          <div className={styles.salaryStatItem}>
            <span className={styles.salaryStatValue}>{inRange}</span>
            <span className={styles.salaryStatLabel}>
              {t('insights_page.salary.in_range')}
            </span>
            <span className={styles.salaryStatLabelSecondary}>
              {`${Number(minSalary / 1000).toFixed(0)}K - ${
                maxSalary ? `${Number(maxSalary / 1000).toFixed(0)}K` : 'NA'
              }`}
            </span>
          </div>
          <div className={styles.salaryStatItem}>
            <span className={styles.salaryStatValue}>{higherThenOffered}</span>
            <span className={styles.salaryStatLabel}>
              {t('insights_page.salary.higher_than_offered')}
            </span>
          </div>
        </div>
        <div className={styles.minMaxAskedContainer}>
          <div className={styles.minMaxAskedValue}>
            {salaryAskedSorted.length
              ? `${Number(salaryAskedSorted[0] / 1000).toFixed(0)}K (${t(
                  'insights_page.salary.min',
                )})`
              : ''}
          </div>
          <div className={styles.minMaxAskedValue}>
            {salaryAskedSorted.length
              ? `${Number(
                  salaryAskedSorted[salaryAskedSorted.length - 1] / 1000,
                ).toFixed(0)}K (${t('insights_page.salary.max')})`
              : ''}
          </div>
        </div>

        <div className={styles.rangeChartContainer}>
          <div className={styles.rangeChartAsked}>
            <div
              className={styles.rangeChartAskedAverage}
              style={{ left: `${calculateAveragePercentage()}%` }}
            >
              <span className={styles.rangeChartAskedAverageValue}>
                {salaryAskedAverage
                  ? `${Number(salaryAskedAverage / 1000).toFixed(0)}K`
                  : 0}
              </span>{' '}
              ({t('insights_page.salary.average')})
            </div>
          </div>
          <div
            className={styles.rangeChartMinMax}
            style={{
              left: `${calculateMinOfferedPercentage()}%`,
              right: `${100 - calculateMaxOfferedPercentage()}%`,
            }}
          >
            <div className={styles.rangeChartMinValue}>
              {`${Number(minSalary / 1000).toFixed(0)}K`}
            </div>
            <div className={styles.rangeChartMaxValue}>
              {`${maxSalary ? `${Number(maxSalary / 1000).toFixed(0)}K` : ''}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SalaryRangeChart.propTypes = {
  extraInfo: PropTypes.shape().isRequired,
  insights: PropTypes.shape().isRequired,
};

export default SalaryRangeChart;
