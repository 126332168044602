import React from 'react';
import PropTypes from 'prop-types';

const CustomTimeoffBonus = ({ companyColour = '#ab1923', ...restProps }) => (
  <svg {...restProps} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 72">
    <path
      id="Grey"
      fill="#899198"
      d="M62.1,5H56V1c0-0.6-0.4-1-1-1s-1,0.4-1,1v4H35V1c0-0.6-0.4-1-1-1s-1,0.4-1,1v4H14V1c0-0.6-0.4-1-1-1
        s-1,0.4-1,1v4H5.9C2.6,5,0,7.6,0,10.8v49.4C0,63.4,2.6,66,5.9,66H39c0.6,0,1-0.4,1-1s-0.4-1-1-1H15v-5h20c0.6,0,1-0.4,1-1
        s-0.4-1-1-1h-5v-7.9h6.1c0.6,0,1-0.4,1-1s-0.4-1-1-1H30V40h9v2c0,0.6,0.4,1,1,1s1-0.4,1-1V30h9v7c0,0.6,0.4,1,1,1s1-0.4,1-1v-7h7.8
        c0.1,0,0.2,0.1,0.2,0.2V39c0,0.6,0.4,1,1,1s1-0.4,1-1v-8.8c0-1.2-1-2.2-2.2-2.2H8.2C7,28,6,29,6,30.2V51H2V24h64v21c0,0.6,0.4,1,1,1
        s1-0.4,1-1V10.8C68,7.6,65.4,5,62.1,5z M28,57h-9v-7.9h9V57z M28,47.1h-9V40h9V47.1z M8,40h9v7.1H8V40z M19,38v-8h9v8H19z M39,38h-9
        v-8h9V38z M8.2,30H17v8H8v-7.8C8,30.1,8.1,30,8.2,30z M8,49.1h9V57h-2v-0.3c0-3.2-2.6-5.7-5.7-5.7H8V49.1z M9.3,53
        c2.1,0,3.7,1.7,3.7,3.7v5.9L3.4,53H9.3z M11.6,64H5.9C3.7,64,2,62.3,2,60.2v-5.8L11.6,64z M2,22V10.8C2,8.7,3.7,7,5.9,7H12v2.1
        C9.7,9.6,8,11.6,8,14c0,2.8,2.2,5,5,5s5-2.2,5-5c0-2.4-1.7-4.4-4-4.9V7h19v2.1c-2.3,0.5-4,2.5-4,4.9c0,2.8,2.2,5,5,5s5-2.2,5-5
        c0-2.4-1.7-4.4-4-4.9V7h19v2.1c-2.3,0.5-4,2.5-4,4.9c0,2.8,2.2,5,5,5s5-2.2,5-5c0-2.4-1.7-4.4-4-4.9V7h6.1c2.1,0,3.9,1.7,3.9,3.8V22
        H2z M13,14c0.6,0,1-0.4,1-1v-1.8c1.2,0.4,2,1.5,2,2.8c0,1.7-1.3,3-3,3s-3-1.3-3-3c0-1.3,0.8-2.4,2-2.8V13C12,13.6,12.4,14,13,14z
        M34,14c0.6,0,1-0.4,1-1v-1.8c1.2,0.4,2,1.5,2,2.8c0,1.7-1.3,3-3,3s-3-1.3-3-3c0-1.3,0.8-2.4,2-2.8V13C33,13.6,33.4,14,34,14z
        M55,14c0.6,0,1-0.4,1-1v-1.8c1.2,0.4,2,1.5,2,2.8c0,1.7-1.3,3-3,3s-3-1.3-3-3c0-1.3,0.8-2.4,2-2.8V13C54,13.6,54.4,14,55,14z"
    />
    <g id="Coloured">
      <path
        fill={companyColour}
        d="M70,54c0-9.9-8.1-18-18-18s-18,8.1-18,18c0,3.2,0.9,6.2,2.3,8.8c0,0.1,0,0.1,0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.2
          c1.5,2.5,3.7,4.7,6.2,6.2c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c2.6,1.5,5.6,2.3,8.8,2.3c3.2,0,6.2-0.8,8.8-2.3
          c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1c2.6-1.5,4.7-3.6,6.2-6.2c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0-0.1,0.1-0.2
          C69.1,60.2,70,57.2,70,54z M60.8,67.3l-0.7-1.2c-0.3-0.5-0.9-0.6-1.4-0.4c-0.5,0.3-0.6,0.9-0.4,1.4l0.7,1.2
          c-1.9,0.9-3.9,1.5-6.1,1.6V69c0-0.6-0.4-1-1-1s-1,0.4-1,1v0.9c-2.2-0.1-4.2-0.7-6.1-1.6l0.7-1.2c0.3-0.5,0.1-1.1-0.4-1.4
          s-1.1-0.1-1.4,0.4l-0.7,1.2c-1.8-1.2-3.3-2.7-4.5-4.4l1.2-0.7c0.5-0.3,0.6-0.9,0.4-1.4c-0.3-0.5-0.9-0.6-1.4-0.4l-1.2,0.7
          c-0.9-1.9-1.5-4-1.7-6.2H37c0.6,0,1-0.4,1-1s-0.4-1-1-1h-0.9c0.1-2.1,0.7-4.2,1.6-6l1.3,0.7c0.2,0.1,0.3,0.1,0.5,0.1
          c0.3,0,0.7-0.2,0.9-0.5c0.3-0.5,0.1-1.1-0.4-1.4l-1.3-0.7c1.2-1.8,2.7-3.3,4.5-4.5l0.8,1.3c0.2,0.3,0.5,0.5,0.9,0.5
          c0.2,0,0.3,0,0.5-0.1c0.5-0.3,0.6-0.9,0.4-1.4l-0.8-1.3c1.9-1,4-1.5,6.2-1.7V39c0,0.6,0.4,1,1,1s1-0.4,1-1v-0.9
          c2.2,0.1,4.3,0.7,6.2,1.7l-0.8,1.3c-0.3,0.5-0.1,1.1,0.4,1.4c0.2,0.1,0.3,0.1,0.5,0.1c0.3,0,0.7-0.2,0.9-0.5l0.8-1.3
          c1.8,1.2,3.3,2.7,4.5,4.5L64.1,46c-0.5,0.3-0.6,0.9-0.4,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l1.3-0.7
          c0.9,1.8,1.4,3.9,1.6,6H67c-0.6,0-1,0.4-1,1s0.4,1,1,1h0.9c-0.1,2.2-0.7,4.3-1.7,6.2l-1.2-0.7c-0.5-0.3-1.1-0.1-1.4,0.4
          c-0.3,0.5-0.1,1.1,0.4,1.4l1.2,0.7C64.1,64.6,62.6,66.2,60.8,67.3z"
      />
      <path
        fill={companyColour}
        d="M55.4,56c0.4-0.6,0.6-1.3,0.6-2c0-1.9-1.3-3.4-3-3.9V44c0-0.6-0.4-1-1-1s-1,0.4-1,1v6.1c-1.7,0.4-3,2-3,3.9
          c0,2.2,1.8,4,4,4c0.7,0,1.4-0.2,2-0.6l4.3,4.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L55.4,56z M50,54
          c0-1.1,0.9-2,2-2s2,0.9,2,2s-0.9,2-2,2S50,55.1,50,54z"
      />
    </g>
  </svg>
);

CustomTimeoffBonus.defaultProps = {
  companyColour: '#ab1923',
};

CustomTimeoffBonus.propTypes = {
  companyColour: PropTypes.string,
};

export default CustomTimeoffBonus;
