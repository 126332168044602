import React from 'react';
import PropTypes from 'prop-types';

const CustomFinancialBonus = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 72"
    className={className}
  >
    <g id="Coloured">
      <path
        fill={companyColour}
        d="M66,15.6C66,9,54.2,4,38.5,4S11,9,11,15.6C11,22.4,23.3,28,38.5,28S66,22.4,66,15.6z M30.1,25.4l3-1.4
          c0.8-0.3,1.3-0.9,1.4-1.6l0.1-1.3c0.1-0.5-0.2-0.9-0.7-1.1c-2.1-0.7-3.2-1.8-3.5-3.4c-0.1-0.4-0.4-0.7-0.8-0.8
          c-0.5-0.1-0.6-0.3-0.6-0.3l0.2-0.7c0-0.1,0.3-0.4,0.9-0.6c0.3-0.1,0.5-0.3,0.6-0.6c0.2-0.6,0.6-1.1,1-1.5c0.2-0.2,0.3-0.3,0.5-0.4
          c0.4-0.4,0.7-0.7,0.8-1.2c0,0,0,0,0,0c1.5,0.5,3.2,0.6,4.3,0.5c0.2,0,0.4,0,0.5,0c4,0,6.3,0.8,7.1,2.6c0.1,0.3,0.4,0.5,0.6,0.5
          c0.6,0.2,0.9,0.5,0.9,0.6l0.2,0.7c0,0-0.2,0.2-0.6,0.3c-0.4,0.1-0.7,0.4-0.8,0.8c-0.3,1.6-1.4,2.8-3.5,3.4
          c-0.5,0.1-0.7,0.6-0.7,1.1l0.1,1.3c0.1,0.7,0.6,1.3,1.3,1.6l3.5,1.5c-2.4,0.3-5,0.5-7.8,0.5C35.5,26,32.7,25.8,30.1,25.4z M13,15.6
          C13,10.3,24.4,6,38.5,6S64,10.3,64,15.6c0,3.6-5.5,7.4-14.1,9.3l-6.3-2.8c0,0-0.1,0-0.1-0.1l0-0.4c2.5-1,3.5-2.7,3.9-4.1
          c0.6-0.2,1-0.6,1.3-1c0.3-0.5,0.3-1,0.2-1.6l-0.2-0.7c-0.2-0.8-0.9-1.4-1.9-1.8C45,9.4,40.9,9,37.9,9c-0.2,0-0.4,0-0.6,0
          c-1.4,0.1-2.6-0.1-3.6-0.4c-0.5-0.2-0.7-0.3-0.8-0.4c-0.4-0.3-1-0.3-1.4-0.1c-0.4,0.2-0.6,0.6-0.6,1c0,0.2,0,0.5,0.1,0.8
          c0,0.1,0,0.1,0,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.3-0.5,0.5c-0.5,0.5-0.9,1.1-1.2,1.7c-1,0.4-1.6,1-1.9,1.8l-0.2,0.7
          c-0.2,0.5-0.1,1.1,0.2,1.6c0.3,0.5,0.7,0.8,1.3,1c0.4,1.4,1.4,3.1,3.9,4.1l0,0.4c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0l-5.5,2.7
          C18.3,22.9,13,19.1,13,15.6z"
      />
      <path
        fill={companyColour}
        d="M63.9,40c-0.4-0.3-1.1-0.3-1.4,0.2s-0.3,1.1,0.2,1.4c1.6,1.3,2.4,2.6,2.4,4C65,50.5,54.5,56,39.5,56
          c-9.6,0-18.7-2.4-23-6.2c-0.4-0.4-1-0.3-1.4,0.1c-0.4,0.4-0.3,1.1,0.1,1.4C20,55.5,29.3,58,39.5,58C54.7,58,67,52.4,67,45.6
          C67,43.5,65.9,41.6,63.9,40z"
      />
    </g>
    <path
      id="Grey"
      fill="#899198"
      d="M72,44.9c-0.2-3.5-2.6-6.7-7-9.3V35c3.8-2.7,6-6,6-9.6V15c0,0,0-0.1,0-0.1C70.4,6.4,56.7,0,38.5,0
        S6.6,6.4,6,14.9c0,0,0,0.1,0,0.1v3.3c-3.7,2.4-5.7,5.3-5.9,8.5C0,26.8,0,26.9,0,27v10.4c0,3.9,2.6,7.4,7,10.3v7.7
        C7,64.6,21.6,72,39.5,72c1.2,0,2.3,0,3.4-0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0C59.3,71,72,63.9,72,55.4V45C72,45,72,44.9,72,44.9z
        M69,25.4c0,2.8-1.9,5.5-5,7.8v-7.5c2.1-1.4,3.8-2.9,5-4.5V25.4z M38.5,2C55.6,2,69,7.9,69,15.4C69,23.2,54.7,30,38.5,30
        S8,23.2,8,15.4C8,7.9,21.4,2,38.5,2z M58,38.7c1.8-0.7,3.5-1.5,5-2.4v1.1c0,2.8-1.9,5.5-5,7.8V38.7z M57,36.8V29
        c1.8-0.6,3.5-1.4,5-2.2v7.6C60.5,35.3,58.8,36.1,57,36.8z M51,41c0.6-0.2,1.2-0.5,1.8-0.7c1.1-0.3,2.2-0.6,3.2-0.9v7.1
        c-1.5,0.9-3.2,1.7-5,2.3V41z M50,38.8v-7.9c1.7-0.3,3.4-0.8,5-1.2v7.8C53.4,38,51.7,38.5,50,38.8z M44,42.9c1.7-0.3,3.4-0.8,5-1.2
        v7.9c-1.6,0.5-3.3,1-5,1.3V42.9z M43,39.8v-8c1.7-0.1,3.4-0.3,5-0.6v7.9C46.4,39.5,44.7,39.7,43,39.8z M37,43.8
        c1.7-0.1,3.4-0.3,5-0.6v8c-1.6,0.3-3.3,0.5-5,0.6V43.8z M36,39.9v-8c0.8,0,1.7,0.1,2.5,0.1s1.7,0,2.5-0.1v8c-0.8,0-1.7,0.1-2.5,0.1
        S36.8,40,36,39.9z M34,31.8v8c-1.7-0.1-3.4-0.3-5-0.6v-7.9C30.6,31.5,32.3,31.7,34,31.8z M27,30.9v7.9c-1.7-0.4-3.4-0.8-5-1.3v-7.8
        C23.6,30.2,25.3,30.6,27,30.9z M20,29v7.8c-1.8-0.7-3.5-1.5-5-2.3v-7.6C16.5,27.7,18.2,28.4,20,29z M8,21.1c1.2,1.7,2.9,3.2,5,4.5
        v7.5c-3.1-2.3-5-5-5-7.8V21.1z M6,20.7v4.7C6,33.9,18.7,41,35,41.9c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.3,0,0.4,0c-1,0-1.9,0.1-2.9,0.1
        C16.3,42,2,35.2,2,27.4C2,24.5,3.9,22.3,6,20.7z M35,43.9v8c-0.8,0-1.7,0.1-2.5,0.1s-1.7,0-2.5-0.1v-8c0.8,0,1.7,0.1,2.5,0.1
        C33.3,44,34.2,44,35,43.9z M28,43.8v8c-1.7-0.1-3.4-0.3-5-0.6v-7.9C24.6,43.5,26.3,43.7,28,43.8z M21,42.9v7.9
        c-1.7-0.4-3.4-0.8-5-1.3v-7.8C17.6,42.2,19.3,42.6,21,42.9z M14,41v7.8c-1.8-0.7-3.5-1.5-5-2.4v-7.6C10.5,39.7,12.2,40.4,14,41z
        M2,37.4v-4.3c1.2,1.7,2.9,3.2,5,4.5v7.5C3.9,42.9,2,40.2,2,37.4z M14,63.2c-3.1-2.3-5-5-5-7.8v-4.3c1.2,1.7,2.9,3.2,5,4.6V63.2z
        M21,66.8c-1.8-0.7-3.5-1.5-5-2.3v-7.6c1.5,0.8,3.2,1.5,5,2.2V66.8z M28,68.8c-1.7-0.4-3.4-0.8-5-1.3v-7.8c1.6,0.5,3.3,0.9,5,1.2
        V68.8z M35,69.8c-1.7-0.1-3.4-0.3-5-0.6v-7.9c1.6,0.3,3.3,0.5,5,0.6V69.8z M42,69.9c-0.8,0-1.7,0.1-2.5,0.1s-1.7,0-2.5-0.1v-8
        c0.8,0,1.7,0.1,2.5,0.1c0.8,0,1.7,0,2.5-0.1V69.9z M49,69.2c-1.6,0.3-3.3,0.5-5,0.6v-8c1.7-0.1,3.4-0.3,5-0.6V69.2z M56,67.5
        c-1.6,0.5-3.3,1-5,1.3v-7.9c1.7-0.3,3.4-0.8,5-1.2V67.5z M63,64.5c-1.5,0.9-3.2,1.7-5,2.3V59c1.8-0.6,3.5-1.4,5-2.2V64.5z M70,55.4
        c0,2.8-1.9,5.5-5,7.8v-7.5c2.1-1.4,3.8-2.9,5-4.5V55.4z M39.5,60c-9.4,0-18.1-2.2-23.8-5.6c-0.1-0.1-0.2-0.2-0.3-0.2
        c-2.2-1.4-3.9-2.9-5-4.6c5.8,2.8,13.6,4.5,22.2,4.5C50.1,54,64.4,46.9,65,38c2.5,1.7,5,4.2,5,7.4C70,53.2,55.7,60,39.5,60z"
    />
  </svg>
);

CustomFinancialBonus.defaultProps = {
  companyColour: '#ab1923',
};

CustomFinancialBonus.propTypes = {
  companyColour: PropTypes.string,
};

export default CustomFinancialBonus;
