import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Heading } from '@lionstep/ui';
import { Doughnut } from 'react-chartjs-2';

// Components
import NoDataLabel from './NoDataLabel';

// Styles
import styles from './charts.module.scss';

const RejectedReasonsChart = ({ insights, formatterFunc }) => {
  const { t } = useTranslation();

  const config = formatterFunc({
    rejectionReasons: insights.rejection_reasons,
    t,
    styles,
  });

  const noData = Object.values(insights.rejection_reasons).every(
    (value) => !value,
  );

  return (
    <>
      <Heading level={4} className={styles.doughnutTitle}>
        {t('insights_page.rejection_reasons.title')}
        {noData && <NoDataLabel />}
      </Heading>
      <div className={styles.doughnutContainer}>
        <div className={styles.doughnutContainerChart}>
          <Doughnut {...config} />
        </div>
        <div
          id="rejectionReasonLegend"
          className={styles.doughnutContainerLegend}
        />
      </div>
    </>
  );
};

RejectedReasonsChart.propTypes = {
  formatterFunc: PropTypes.func.isRequired,
  insights: PropTypes.shape().isRequired,
};

export default RejectedReasonsChart;
