import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Button,
  FormInput,
  FormSelectV2,
  FormCheckbox,
  FormRadioButton,
  Grid,
  Link,
  Text,
} from '@lionstep/ui';

// Helpers
import industries from '../../../support/static/industries_display.json';

// Styles
import styles from './register.module.scss';

const RegisterForm = ({ onSubmit }) => {
  const cookies = new Cookies();
  const selectedLanguage = cookies.get('selectedLanguage') || 'en';

  const { t } = useTranslation();

  const schema = Yup.object().shape({
    email: Yup.string().email().required(t('Required')),
    name: Yup.string()
      // https://stackoverflow.com/a/57754227
      .matches(
        // TODO: move RegExp to some constants file
        /[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+\s+[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+/,
        t('Please provide your full name'),
      )
      .required(t('Required')),
    password: Yup.string()
      .min(8, 'Password must be 8+ characters long')
      .matches(
        // TODO: move RegExp to some constants file
        /^(?=.*\d)(?=.*[A-Z]).{8,}$/,
        'Password must contain at least one uppercase letter and one number',
      )
      .required(t('Required')),
    company: Yup.string().required(t('Required')),
    industry: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(t('Required')),
    isHr: Yup.string().required(t('Required')),
    agreeWithTerms: Yup.boolean().oneOf([true], t('Required')),
  });

  const formMethods = useForm({
    defaultValues: {
      email: '',
      name: '',
      password: '',
      company: '',
      industry: null,
      isHr: '',
      agreeWithTerms: false,
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const handleOnSubmit = (data) => {
    // TODO: check about this language if can be taken from somewhere else
    // eslint-disable-next-line no-shadow
    const cookies = new Cookies();
    // eslint-disable-next-line no-shadow
    const selectedLanguage = cookies.get('selectedLanguage') || 'en';

    const payload = {
      email: data.email,
      first_name: data.name.split(' ')[0],
      last_name: data.name.split(' ').slice(1).join(' ') || '',
      password: data.password,
      company: data.company,
      industry: data.industry.value,
      isHr: data.isHr === 'yes',
      language: selectedLanguage,
    };

    if (onSubmit) {
      onSubmit(payload);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <FormInput
          label={t('work_email')}
          name="email"
          placeholder="example@email.com"
          error={errors.email?.message}
        />
        <FormInput
          label={t('name')}
          name="name"
          placeholder="Jane Doe"
          error={errors.name?.message}
        />
        <FormInput
          label={t('password')}
          name="password"
          type="password"
          placeholder={`8+ ${t('characters')}`}
          error={errors.password?.message}
        />
        <FormInput
          label={t('company_name')}
          name="company"
          placeholder="Example Inc."
          error={errors.company?.message}
        />
        <FormSelectV2
          label={t('area_of_hire')}
          name="industry"
          placeholder={t('Select industry')}
          options={industries.map((ind) => ({
            value: ind.mid,
            label: ind.display[selectedLanguage],
          }))}
          error={errors.industry?.message}
        />
        <FormRadioButton
          label={t('customization')}
          name="isHr"
          radioType="button"
          options={[
            { label: t('in_hr'), value: 'yes' },
            { label: t('not_in_hr'), value: 'no' },
          ]}
          renderTemplate={(elements) => (
            <Grid.Row gutter={{ xs: [0, 8], lg: 16 }}>
              {elements.map((element) => (
                <Grid.Col key={element.props.label} xs={24} lg={12}>
                  {element}
                </Grid.Col>
              ))}
            </Grid.Row>
          )}
          error={errors.isHr?.message}
        />
        <Button
          block
          typeHtml="submit"
          style={{ marginBottom: '1rem' }}
          data-testid="register-btn"
        >
          {t('start_trial')}
        </Button>
        <FormCheckbox
          name="agreeWithTerms"
          data-testid="agree-with-terms"
          options={[
            {
              label: (
                <Text className={styles.termsAndConditionsLabel} size="small">
                  <span>{`${t('by_proceeding')} `}</span>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                    href="https://lionstep.com/en/terms-of-service"
                  >
                    {t('terms_of_service')}
                  </Link>
                  <span>, </span>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://lionstep.com/en/terms"
                  >
                    {t('terms_and_conditions')}
                  </Link>
                  {` ${t('and')} `}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://lionstep.com/en/privacy"
                  >
                    {` ${t('privacy_policy')}`}
                  </Link>
                  .
                </Text>
              ),
            },
          ]}
          error={errors.agreeWithTerms?.message}
        />
      </form>
    </FormProvider>
  );
};

RegisterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default RegisterForm;
