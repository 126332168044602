import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Spinner, toast } from '@lionstep/ui';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

// Components
import PageHeading from '../../../../components/refactoring/pageHeading/PageHeading';
import AddCollaborator from './components/addCollaborator';
import CollaboratorsTable from './components/collaboratorsTable';
import ShareJobModal from './components/shareJobModal';

// Queries
import {
  useUserQuery,
  useUserCollaborators,
} from '../../../../queries/userQueries';
import { useJobTitles } from '../../../../queries/jobQueries';

// Constants
import { USER_ROLES } from '../../../../constants/user.const';

// Styles
import styles from './collaborators.module.scss';

const Collaborators = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lastAddedUserId, setLastAddedUserId] = useState(null);
  const { data: user } = useUserQuery();

  useEffect(() => {
    if (user?.role === USER_ROLES.viewer) {
      history.push('/');
    }
  }, [user]);

  const { isLoading: isLoadingJobsList, data: jobs } = useJobTitles();

  // Get Collaborator
  const handleGetCollaboratorOnError = (error) => {
    toast.error('There was an error while fetching collaborators', {
      id: 'FETCH_COLLABORATORS',
      statusCode: error?.response?.status,
    });
  };

  const {
    isLoading: isLoadingCollaborators,
    isRefetching: isRefetchingCollaborators,
    isError: isErrorCollaborators,
    data: collaborators,
    refetch: refetchCollaborators,
  } = useUserCollaborators({
    onError: handleGetCollaboratorOnError,
  });

  // Modal
  const handleOnModalClose = () => {
    setLastAddedUserId(null);
    setIsModalOpen(false);
  };

  // Add Collaborator
  const handleAddCollaboratorOnSuccess = (userId) => {
    setLastAddedUserId(userId);
    setIsModalOpen(true);
    refetchCollaborators();
  };

  if (isLoadingCollaborators || isLoadingJobsList) {
    return <Spinner position="fixed" size={100} />;
  }

  return (
    <Grid.Container className={styles.collaborators}>
      <Helmet>
        <title>{t('collaborator_page.title')} | Lionstep</title>
      </Helmet>

      <Grid.Row gutter={30}>
        <Grid.Col
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          <PageHeading>{t('collaborator_page.title')}</PageHeading>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          {isRefetchingCollaborators && <Spinner position="fixed" size={100} />}
          <AddCollaborator onSuccess={handleAddCollaboratorOnSuccess} />
          <ShareJobModal
            jobs={jobs}
            showModal={isModalOpen}
            onClose={handleOnModalClose}
            lastAddedUserId={lastAddedUserId}
          />
        </Grid.Col>
        <Grid.Col
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          <CollaboratorsTable
            isError={isErrorCollaborators}
            collaborators={collaborators}
            userData={user}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

export default Collaborators;
