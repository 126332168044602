import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal, Spinner, Tabs } from '@lionstep/ui';

// Components
import InviteExistingCollaborator from './InviteExistingCollaborator/InviteExistingCollaborator';
import AddNewCollaborator from './AddNewCollaborator/AddNewCollaborator';
import CollaboratorsTable from './CollaboratorsTable/CollaboratorsTable';

// Selectors
import { collaboratorsSelector } from '../../../../../company/redux/selectors/company.selectors';
import { isLoadingAddRemoveCollaboratorSelector } from '../../../../redux/selectors/dashboard.selectors';

// Hooks
import useIsLionstepAdmin from '../../../../../../hooks/useIsLionstepAdmin';

// Queries
import { useUserQuery } from '../../../../../../queries/userQueries';

// Styles
import styles from './jobShareModal.module.scss';

const JobShareModal = ({ job, show, onClose }) => {
  const { t } = useTranslation();
  const isLionstepAdmin = useIsLionstepAdmin();

  const { data: user } = useUserQuery();
  const collaborators = useSelector(collaboratorsSelector);
  const isLoadingAddRemoveCollaborator = useSelector(
    isLoadingAddRemoveCollaboratorSelector,
  );

  const safeToShowCollaborators = collaborators.filter((collaborator) => {
    if (isLionstepAdmin && collaborator.local_id !== user.local_id) {
      return true;
    }

    if (
      collaborator.local_id !== user.local_id &&
      !user.email.includes('@lionstep.com')
    ) {
      return true;
    }

    return false;
  });

  const collaboratorsNotOnJob = safeToShowCollaborators.filter(
    (collaborator) => !job?.users.includes(collaborator.local_id),
  );
  const collaboratorsOnJob = safeToShowCollaborators.filter((collaborator) =>
    job?.users.includes(collaborator.local_id),
  );

  return (
    <Modal show={show} onClose={onClose} className={styles.modal}>
      {isLoadingAddRemoveCollaborator && (
        <Spinner position={'absolute'} size={100} />
      )}
      <Modal.Header>{t('share_your_job')}</Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Tabs tabScrollerId="share-your-job">
          <Tabs.Item
            label="Invite existing collaborator"
            itemKey="invite-existing-collaborator"
          >
            <InviteExistingCollaborator
              job={job}
              collaborators={collaborators}
              collaboratorsNotOnJob={collaboratorsNotOnJob}
            />
            <CollaboratorsTable
              job={job}
              collaboratorsOnJob={collaboratorsOnJob}
            />
          </Tabs.Item>
          <Tabs.Item label="Add new viewer" itemKey="add-new-viewer">
            <AddNewCollaborator job={job} />
            <CollaboratorsTable
              job={job}
              collaboratorsOnJob={collaboratorsOnJob}
            />
          </Tabs.Item>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

JobShareModal.defaultProps = {
  job: {},
};

JobShareModal.propTypes = {
  job: PropTypes.shape(),
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default JobShareModal;
