import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { upperFirst } from 'lodash';
import {
  Grid,
  FormInput,
  FormRadioButton,
  FormSelectV2,
  Spinner,
  Button,
  Card,
  Icon,
} from '@lionstep/ui';

// Actions

// Constants
import industries from '../../../../support/static/industries_display.json';

// Components
import PageHeading from '../../../../components/refactoring/pageHeading/PageHeading';

// Hooks
import useIsLionstepAdmin from '../../../../hooks/useIsLionstepAdmin';

// Queries
import { useUserQuery, useUserMutation } from '../../../../queries/userQueries';

// Styles
import styles from './userProfile.module.scss';

const UserProfile = () => {
  const { i18n, t } = useTranslation();
  const isLionstepAdmin = useIsLionstepAdmin();
  const { data: user } = useUserQuery();
  const { mutate: updateUser, isLoading } = useUserMutation();

  const schema = Yup.object().shape({
    fullName: Yup.string()
      .matches(
        /[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+\s+[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+/,
        t('Please provide your full name'),
      )
      .required(t('This fields is required')),
    email: Yup.string().required('This fields is required'),
    industry: Yup.object().shape().required('This fields is required'),
    language: Yup.object().shape().required('This fields is required'),
    isHr: Yup.string().required('This fields is required'),
  });
  const languageOptions = [
    { value: 'en', label: upperFirst(t('english')) },
    { value: 'de', label: upperFirst(t('german')) },
    { value: 'es', label: upperFirst(t('spanish')) },
    { value: 'fr', label: upperFirst(t('french')) },
  ];

  const handleFormDefaultValues = () => {
    const industry = industries.find((item) => item.mid === user?.industry);
    const language = languageOptions.find(
      (item) => item.value === user?.language,
    );

    return {
      fullName: user?.display_name || '',
      email: user?.email || '',
      industry: industry
        ? { label: industry.display[i18n.language], value: industry.mid }
        : { label: '', value: '' },
      language: language
        ? { label: language.label, value: language.value }
        : { label: upperFirst(t('english')), value: 'en' },
      isHr: user?.is_hr ? 'yes' : 'no',
    };
  };

  const formMethods = useForm({
    defaultValues: handleFormDefaultValues(),
    resolver: yupResolver(schema),
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    reset(handleFormDefaultValues());
  }, [user]);

  const handleOnSubmit = (data) => {
    const [firstName, ...restName] = data.fullName.split(' ');
    const lastName = restName.join(' ');

    const payload = {
      first_name: firstName,
      last_name: lastName,
      language: data.language.value === 'es' ? 'es-ES' : data.language.value,
      industry: data.industry.value,
      is_hr: data.isHr === 'yes',
    };

    updateUser({
      data: payload,
    });
  };

  if (isLoading && !user) {
    return <Spinner position="fixed" size={100} />;
  }

  return (
    <div className={styles.userProfile}>
      <Helmet>
        <title>{t('user_profile_page.title')} | Lionstep</title>
      </Helmet>
      {isLoading && <Spinner position="fixed" size={100} />}
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Grid.Container>
            <Grid.Row>
              <Grid.Col
                xs={24}
                xl={{ span: 22, offset: 1 }}
                xxl={{ span: 18, offset: 3 }}
              >
                <PageHeading>{t('user_profile_page.title')}</PageHeading>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col
                xs={24}
                xl={{ span: 22, offset: 1 }}
                xxl={{ span: 18, offset: 3 }}
              >
                <Card className={styles.card}>
                  <Grid.Row gutter={30}>
                    <Grid.Col xs={24}>
                      {isLionstepAdmin && (
                        <span className={styles.infoPlusLabel}>
                          <Icon
                            name="InfoPlus"
                            className={styles.infoPlusIcon}
                          />
                          Info+ Account
                        </span>
                      )}
                    </Grid.Col>
                    <Grid.Col xs={24} lg={12}>
                      <FormInput
                        label={t('user_profile_page.input_labels.full_name')}
                        name="fullName"
                        required
                        error={errors.fullName?.message}
                      />
                    </Grid.Col>

                    <Grid.Col xs={24} lg={12}>
                      <FormInput
                        label={t('user_profile_page.input_labels.work_email')}
                        name="email"
                        required
                        readOnly
                        error={errors.email?.message}
                      />
                    </Grid.Col>

                    <Grid.Col xs={24} lg={12}>
                      <FormSelectV2
                        label={t('user_profile_page.input_labels.area_of_hire')}
                        name="industry"
                        required
                        error={errors.industry?.message}
                        options={industries.map((industry) => ({
                          label: industry.display[i18n.language],
                          value: industry.mid,
                        }))}
                      />
                    </Grid.Col>

                    <Grid.Col xs={24} lg={12}>
                      <FormSelectV2
                        label={t('user_profile_page.input_labels.language')}
                        name="language"
                        required
                        error={errors.language?.message}
                        options={languageOptions}
                      />
                    </Grid.Col>

                    <Grid.Col xs={24} lg={12}>
                      <FormRadioButton
                        label={t(
                          'user_profile_page.input_labels.customisation',
                        )}
                        name="isHr"
                        formItemClassName={styles.formRadioButton}
                        inputAlign
                        options={[
                          {
                            label: t(
                              'user_profile_page.input_labels.i_work_in_hr',
                            ),
                            value: 'yes',
                          },
                          {
                            label: t(
                              'user_profile_page.input_labels.i_dont_work_in_hr',
                            ),
                            value: 'no',
                          },
                        ]}
                        error={errors.isHr?.message}
                        renderTemplate={(isHrElements) => (
                          <Grid.Row gutter={30}>
                            {isHrElements.map((element) => (
                              <Grid.Col key={element.props.value}>
                                {element}
                              </Grid.Col>
                            ))}
                          </Grid.Row>
                        )}
                      />
                    </Grid.Col>
                  </Grid.Row>
                </Card>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col
                xs={24}
                xl={{ span: 22, offset: 1 }}
                xxl={{ span: 18, offset: 3 }}
              >
                <Button typeHtml="submit">
                  {t('user_profile_page.submit_btn_text')}
                </Button>
              </Grid.Col>
            </Grid.Row>
          </Grid.Container>
        </form>
      </FormProvider>
    </div>
  );
};

export default UserProfile;
