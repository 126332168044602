import React from 'react';
import PropTypes from 'prop-types';

const ExtraMaternityPaternityLeave = ({
  companyColour,
  className,
  ...restProps
}) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 78 68"
    className={className}
  >
    <g id="Coloured">
      <path
        fill={companyColour}
        d="M5.8,32.5L32.3,24l0,0l0,0l0,0c0,0,0,0,0.1,0s0.1-0.1,0.2-0.1c0,0,0,0,0.1,0l0,0l0,0c0,0,0.1-0.1,0.1-0.2
          c0,0,0-0.1,0.1-0.1l0,0l0,0l0,0l0,0v-0.1c0-0.1,0-0.1,0-0.2l0,0l0,0l0,0l0,0V1c0-0.6-0.4-1-1-1c-7.4,0-13.3,1.3-17.8,3.8l-2.5-2.5
          c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l2.2,2.2C10,6.5,8.1,8.6,6.7,11.1l-2.4-1c-0.5-0.2-1.1,0-1.3,0.5s0,1.1,0.5,1.3l2.3,1
          c-1,2.4-1.8,5.1-2.2,8.2L1,21l0,0c-0.5,0-1,0.4-1,1s0.4,1,1,1l2.5,0.1c-0.1,1.1-0.1,2.2-0.1,3.3v0.3c0,1.2,0,2.7,0.2,4.4L0.7,32
          c-0.5,0.2-0.8,0.7-0.6,1.3C0.2,33.7,0.6,34,1,34c0.1,0,0.2,0,0.3,0l2.6-0.8c0.5,2.3,1.6,4.9,3.7,7.3c0.2,0.2,0.5,0.3,0.8,0.3
          c0.2,0,0.5-0.1,0.7-0.2c0.4-0.4,0.5-1,0.1-1.4C7.2,36.9,6.3,34.6,5.8,32.5z M31,2v18.6L15.7,5.3C19.6,3.2,24.7,2.1,31,2z M13.9,6.3
          L27.6,20l-19-8.1C10,9.7,11.8,7.8,13.9,6.3z M7.7,13.7l19,8.2l-21-0.8C6,18.3,6.7,15.8,7.7,13.7z M5.3,26.7v-0.3
          c0-1.1,0-2.2,0.1-3.2l20.8,0.7L5.5,30.5C5.3,29.1,5.3,27.8,5.3,26.7z"
      />
      <path
        fill={companyColour}
        d="M73,28.7c0-0.4,0-0.7,0-1c0-0.7,0-1.7-1-1.7H44c-0.6,0-1,0.4-1,1s0.4,1,1,1h0.1c0.5,2.3,2.5,4,4.9,4
          c2,0,3.7-1.2,4.5-2.9C54.3,30.8,56,32,58,32s3.7-1.2,4.5-2.9C63.3,30.8,65,32,67,32c1.6,0,3.1-0.8,4-2c0,2.7-0.3,5.7-2.8,8.3
          c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3C73.1,36.1,73,31.6,73,28.7z M49,30c-1.3,0-2.4-0.8-2.8-2h5.6
          C51.4,29.2,50.3,30,49,30z M58,30c-1.3,0-2.4-0.8-2.8-2h5.6C60.4,29.2,59.3,30,58,30z M67,30c-1.3,0-2.4-0.8-2.8-2h5.6
          C69.4,29.2,68.3,30,67,30z"
      />
      <path
        fill={companyColour}
        d="M45,51H32c-0.6,0-1,0.4-1,1s0.4,1,1,1h13c0.6,0,1-0.4,1-1S45.6,51,45,51z"
      />
    </g>
    <g id="Grey">
      <path
        fill="#899198"
        d="M17.1,49c-0.8,0-1.6,0.3-2.1,0.8c-0.6,0.6-0.9,1.3-0.9,2.1c0,1.7,1.3,3,3,3.1c1.6,0,3-1.3,3-2.9
          C20,50.4,18.7,49,17.1,49z M17,53c-0.6,0-1-0.5-1-1s0.5-1,1-1c0.3,0,0.5,0.1,0.7,0.3S18,51.7,18,52C18,52.6,17.5,53,17,53z"
      />
      <path
        fill="#899198"
        d="M17,36C8.2,36,1,43.2,1,52s7.2,16,16,16s16-7.2,16-16S25.8,36,17,36z M25,53h5.9c-0.2,3.1-1.4,5.9-3.3,8.2
          l-4.9-4.9c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l4.9,4.9c-2.2,1.9-5,3.2-8.2,3.4v-6c0-0.6-0.4-1-1-1s-1,0.4-1,1v5.9
          c-3.1-0.2-5.9-1.5-8.2-3.4l4.9-4.9c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-4.9,4.9c-1.9-2.2-3.2-5-3.4-8.2h6c0.6,0,1-0.4,1-1
          c0-0.6-0.4-1-1-1H3.1c0.2-3.1,1.5-5.9,3.4-8.2l4.9,4.9c0.2,0.2,0.5,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4
          l-4.9-4.9c2.2-1.9,5-3.2,8.1-3.4v6c0,0.6,0.4,1,1,1s1-0.5,1-1V38c3.1,0.2,5.9,1.5,8.2,3.4l-4.9,4.9c-0.4,0.4-0.4,1,0,1.4
          c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l4.9-4.9c1.9,2.2,3.2,5,3.4,8.2h-6c-0.6,0-1,0.4-1,1C24,52.6,24.4,53,25,53z"
      />
      <path
        fill="#899198"
        d="M60.1,49c-0.8,0-1.6,0.3-2.1,0.8c-0.6,0.6-0.9,1.3-0.9,2.1c0,1.7,1.3,3,3,3.1c1.6,0,3-1.3,3-2.9
          C63,50.4,61.7,49,60.1,49z M60,53c-0.6,0-1-0.5-1-1s0.5-1,1-1c0.3,0,0.5,0.1,0.7,0.3S61,51.7,61,52C61,52.6,60.5,53,60,53z"
      />
      <path
        fill="#899198"
        d="M60,36c-8.8,0-16,7.2-16,16s7.2,16,16,16s16-7.2,16-16S68.8,36,60,36z M68,53h5.9c-0.2,3.1-1.4,5.9-3.3,8.2
          l-4.9-4.9c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l4.9,4.9c-2.2,1.9-5,3.2-8.2,3.4v-6c0-0.6-0.4-1-1-1s-1,0.4-1,1v5.9
          c-3.1-0.2-5.9-1.5-8.2-3.4l4.9-4.9c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-4.9,4.9c-1.9-2.2-3.2-5-3.4-8.2h6c0.6,0,1-0.4,1-1
          c0-0.6-0.4-1-1-1h-5.9c0.2-3.1,1.5-5.9,3.4-8.2l4.9,4.9c0.2,0.2,0.5,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4
          l-4.9-4.9c2.2-1.9,5-3.2,8.1-3.4v6c0,0.6,0.4,1,1,1s1-0.5,1-1V38c3.1,0.2,5.9,1.5,8.2,3.4l-4.9,4.9c-0.4,0.4-0.4,1,0,1.4
          c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l4.9-4.9c1.9,2.2,3.2,5,3.4,8.2h-6c-0.6,0-1,0.4-1,1C67,52.6,67.4,53,68,53z"
      />
      <path
        fill="#899198"
        d="M37,20c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8s8-3.6,8-8C45,23.6,41.4,20,37,20z M37,34c-3.3,0-6-2.7-6-6s2.7-6,6-6
          s6,2.7,6,6S40.3,34,37,34z"
      />
      <path
        fill="#899198"
        d="M77,11c-5.6,0-9.7,6.2-10,15h2c0.3-7.5,3.6-13,8-13c0.6,0,1-0.4,1-1C78,11.4,77.6,11,77,11z"
      />
    </g>
  </svg>
);

ExtraMaternityPaternityLeave.defaultProps = {
  companyColour: '#ab1923',
};

ExtraMaternityPaternityLeave.propTypes = {
  companyColour: PropTypes.string,
};

export default ExtraMaternityPaternityLeave;
