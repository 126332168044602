export const PLAN_OPTIONS = [
  {
    label: 'purchases_page.plan.form.name.no_plan',
    value: null,
  },
  {
    label: 'purchases_page.plan.form.name.professional_solution',
    value: 'professional_solution',
  },
  {
    label: 'purchases_page.plan.form.name.enterprise_solution',
    value: 'enterprise_solution',
  },
  {
    label: 'purchases_page.plan.form.name.professional_subscription',
    value: 'professional_subscription',
  },
  {
    label: 'purchases_page.plan.form.name.enterprise_subscription',
    value: 'enterprise_subscription',
  },
];
export const SUBSCRIPTION_PLAN_VALUES = [
  'professional_subscription',
  'enterprise_subscription',
];
