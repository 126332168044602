import moment from 'moment';
import { CANDIDATE_DROPPED_OUT_REASONS } from '../../job.constants';
import {
  TEXT_COLOR,
  BAR_TOOLTIP,
  BAR_COLORS,
  DOUGHNUT_COLORS,
  EMPTY_DOUGHNUT,
  DOUGHNUT_TOOLTIP,
} from '../../components/Charts/charts.const';
import {
  emptyDoughnutPlugin,
  htmlBarChartLegendPlugin,
  htmlDoughnutChartLegendPlugin,
} from '../../components/Charts/charts.helper';

export const candidateFunnelFormatter = ({ candidateFunnel, styles, t }) => {
  const maxValue = Math.max(
    candidateFunnel.extension_checked_interest +
      candidateFunnel.description_view_from_non_extension,
    candidateFunnel.description_view_from_extension +
      candidateFunnel.description_view_from_non_extension,
  );

  const datasetInternal = [
    candidateFunnel.extension_checked_interest +
      candidateFunnel.description_view_from_non_extension,
    candidateFunnel.description_view_from_extension +
      candidateFunnel.description_view_from_non_extension,
    candidateFunnel.sent_salary_validation,
    candidateFunnel.validated_killer_question,
    candidateFunnel.call_done,
    candidateFunnel.total_validated_count,
    candidateFunnel.total_rejected_count,
    candidateFunnel.hired,
  ];

  const data = {
    labels: [
      t('insights_page.candidate_funnel.labels.sourcing'),
      t('insights_page.candidate_funnel.labels.job_page'),
      t('insights_page.candidate_funnel.labels.salary_check'),
      t('insights_page.candidate_funnel.labels.key_points'),
      t('insights_page.candidate_funnel.labels.call_completed'),
      t('insights_page.candidate_funnel.labels.validated'),
      t('insights_page.candidate_funnel.labels.rejected'),
      t('insights_page.candidate_funnel.labels.hired'),
    ],
    datasets: [
      {
        label: t('insights_page.candidate_funnel.legend.number_of_candidates'),
        backgroundColor: BAR_COLORS[0],
        data: datasetInternal,
        borderRadius: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: BAR_TOOLTIP,
      annotation: {
        annotations: datasetInternal.map((value, index) => ({
          type: 'label',
          id: `label${index}`,
          backgroundColor: '#F8F8F4',
          borderWidth: 1,
          borderColor: '#E9E6DC',
          borderShadowColor: '#00000014',
          shadowBlur: 3,
          borderRadius: 99,
          padding: {
            top: 2,
            bottom: 2,
            left: 6,
            right: 6,
          },
          xValue: index,
          yValue: () => value,
          yAdjust: -15,
          content: () => value,
          color: TEXT_COLOR,
          font: {
            size: 11,
            weight: 500,
          },
        })),
      },
    },
    scales: {
      x: {
        stacked: true,
        font: {
          size: 12,
        },
        ticks: {
          color: TEXT_COLOR,
        },
      },
      y: {
        stacked: true,
        font: {
          size: 12,
        },
        ticks: {
          color: TEXT_COLOR,
        },
        suggestedMax: maxValue + maxValue / 10,
      },
    },
  };

  const plugins = [
    htmlBarChartLegendPlugin(
      'candidateFunnelChart',
      'candidateFunnelLegend',
      styles,
    ),
  ];

  return {
    data,
    options,
    plugins,
  };
};

export const newSourcedCandidateFormatter = ({
  candidatesInternal,
  candidatesExternal,
  styles,
  t,
}) => {
  const dataset = [];
  const labels = [];

  // local copy to store all intervals
  const localAllRecords = [...candidatesInternal, ...candidatesExternal].sort(
    (first, second) => {
      if (second.date > first.date) return 1;
      if (second.date < first.date) return -1;
      return 0;
    },
  );

  if (localAllRecords.length) {
    const reducedAllByMonth = localAllRecords.reduce((acc, curr) => {
      const date = moment(curr.date).format('YYYY-MM');

      const foundRecordIndex = acc.findIndex((rec) => rec.date === date);

      if (foundRecordIndex !== -1) {
        acc[foundRecordIndex] = {
          ...acc[foundRecordIndex],
          count: curr.count + acc[foundRecordIndex].count,
        };
      } else {
        acc.push({
          date,
          count: curr.count || 0,
        });
      }

      return acc;
    }, []);

    // showing last 3 months at most
    reducedAllByMonth.slice(0, 3).forEach((record) => {
      const monthName = moment(record.date).format('MMMM');

      labels.unshift(monthName);
      dataset.unshift(record.count);
    });
  }

  const data = {
    labels,
    datasets: [
      {
        label: t('insights_page.validation_by_channel.legend.candidates'),
        backgroundColor: BAR_COLORS[0],
        data: dataset,
        borderRadius: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: BAR_TOOLTIP,
    },
    scales: {
      x: {
        stacked: true,
        font: {
          size: 12,
        },
        ticks: {
          color: TEXT_COLOR,
        },
      },
      y: {
        stacked: true,
        font: {
          size: 12,
        },
        ticks: {
          color: TEXT_COLOR,
        },
      },
    },
  };

  const plugins = [
    htmlBarChartLegendPlugin(
      'newSourcedCandidateChart',
      'newSourcedCandidateLegend',
      styles,
    ),
  ];

  return {
    data,
    options,
    plugins,
  };
};

// Rejection reason
const calculateDataValueToPercentage = ({ data, formattedData }) => {
  const rejectionValueSum = Object.values(data).reduce(
    (acc, curr) => acc + curr,
    0,
  );

  return formattedData.map((item) => ({
    ...item,
    value: ((item.value / rejectionValueSum || 0) * 100).toFixed(0),
  }));
};

const groupAndSortRejectionReasons = ({ rejectionReasons, t }) => {
  const sortedItems = Object.entries(rejectionReasons).sort(
    (firstFilter, secondFilter) => secondFilter[1] - firstFilter[1],
  );

  const result = sortedItems.reduce((acc, curr, index) => {
    const [filterValue, filterCount] = curr;
    const translation = CANDIDATE_DROPPED_OUT_REASONS.find(
      (item) => item.value === filterValue,
    );

    if (index < 5) {
      acc[index] = {
        label: t(translation.label),
        value: filterCount,
      };
    } else {
      acc[5] = {
        label: t('candidates_page.rejection_reason_filters.other'),
        value: (acc[5] ? acc[5].value : 0) + filterCount,
      };
    }

    return acc;
  }, []);

  return result;
};

export const rejectionReasonFormatter = ({ rejectionReasons, t, styles }) => {
  let reasons = groupAndSortRejectionReasons({ rejectionReasons, t });

  reasons = calculateDataValueToPercentage({
    data: rejectionReasons,
    formattedData: reasons,
  });

  const data = {
    labels: reasons.map((reason) => reason.label),
    datasets: [
      {
        data: reasons.map((reason) => reason.value),
        backgroundColor: [
          DOUGHNUT_COLORS[0],
          DOUGHNUT_COLORS[1],
          DOUGHNUT_COLORS[2],
          DOUGHNUT_COLORS[3],
          DOUGHNUT_COLORS[4],
          DOUGHNUT_COLORS[5],
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      emptyDoughnut: EMPTY_DOUGHNUT,
      legend: {
        display: false,
      },
      tooltip: DOUGHNUT_TOOLTIP,
    },
  };

  const plugins = [
    htmlDoughnutChartLegendPlugin(
      'rejectionReasonChart',
      'rejectionReasonLegend',
      styles,
    ),
    emptyDoughnutPlugin,
  ];

  return {
    id: 'rejectionReasonChart',
    data,
    options,
    plugins,
  };
};
