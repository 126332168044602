import { combineReducers } from 'redux';

import companyReducer from './reducers/company.reducer';
import companyInsightsReducer from '../pages/CompanyInsights/companyInsights.reducer';
import creditsReducer from './reducers/credits.reducer';
import settingsReducer from '../pages/Settings/settings.reducer';

export default combineReducers({
  company: companyReducer,
  companyInsights: companyInsightsReducer,
  credits: creditsReducer,
  settings: settingsReducer,
});
