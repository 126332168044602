/* eslint-disable no-control-regex */
import React, { useEffect, Suspense } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { Spinner } from '@lionstep/ui';

import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';

import Footer from '../components/footer/Footer';
import Login from '../components/refactoring/login/Login';
import BrowserOutdated from '../components/BrowserOutdated';
import Register from '../components/refactoring/register/Register';
import ForgotPassword from '../components/refactoring/passwordReset/ForgotPassword';
import ResetPassword from '../components/refactoring/passwordReset/ResetPassword';
import SetInvitePassword from '../components/refactoring/passwordReset/SetInvitePassword';

// Refactoring
// import Job from '../modules/job';
import JobLayout from '../modules/job/components/jobLayout';
import AdminActions from '../modules/job/pages/AdminActions';
import CreateJob from '../modules/job/pages/CreateJob';
import EditJob from '../modules/job/pages/EditJob';
import DuplicateJob from '../modules/job/pages/DuplicateJob';
import Analytics from '../modules/job/pages/Analytics';
import Insights from '../modules/job/pages/Insights';
import Candidates from '../modules/job/pages/Candidates';
import Candidate from '../modules/job/pages/Candidate';
import Dashboard from '../modules/dashboard/pages/Dashboard';
import Credits from '../modules/company/pages/Credits';
import Purchases from '../modules/company/pages/Purchases';
import Profile from '../modules/company/pages/UserProfile';
import Company from '../modules/company/pages/Company';
import CompanyInsights from '../modules/company/pages/CompanyInsights';
import Settings from '../modules/company/pages/Settings';
import Collaborators from '../modules/company/pages/Collaborators';

const analytics = require('react-segment');

const Main = (props) => {
  useEffect(() => {
    analytics.default.load('rjEZuh8yuacP6IZLNYW9Z08HJcRlAeG4');
  }, []);

  return (
    <div className="App">
      <Suspense fallback={<Spinner position="fixed" size={100} />}>
        <Switch>
          {/* POST LOGIN */}
          <PrivateRoute path="/" exact component={Dashboard} />
          <PrivateRoute path="/profile" exact component={Profile} />
          <PrivateRoute path="/company" exact component={Company} />
          <PrivateRoute
            path="/company-insights"
            exact
            component={CompanyInsights}
          />
          <PrivateRoute path="/credits" exact component={Credits} />

          <PrivateRoute path="/job-settings" exact component={Settings} />
          <PrivateRoute path="/purchases" exact component={Purchases} />

          {/* TODO: REMOVE THIS IN HAPPY DAYS  */}
          <PrivateRoute
            path="/create-job"
            exact
            component={CreateJob}
            layout={JobLayout}
          />
          <PrivateRoute
            path="/edit-job/:id"
            exact
            component={EditJob}
            layout={JobLayout}
          />
          <PrivateRoute
            path="/duplicate-job/:id"
            exact
            component={DuplicateJob}
            layout={JobLayout}
          />
          <PrivateRoute
            path="/edit-job/:id/admin"
            exact
            component={AdminActions}
            layout={JobLayout}
          />
          <PrivateRoute
            path="/edit-job/:id/analytics"
            exact
            component={Analytics}
            layout={JobLayout}
          />
          <PrivateRoute
            path="/edit-job/:id/insights"
            exact
            component={Insights}
            layout={JobLayout}
          />
          <PrivateRoute
            path="/edit-job/:id/candidates"
            exact
            component={Candidates}
            layout={JobLayout}
          />
          <PrivateRoute
            path="/edit-job/:id/candidates/:candidateId"
            exact
            component={Candidate}
            layout={JobLayout}
          />
          <PrivateRoute path="/collaborators" exact component={Collaborators} />
          {/* TODO: ENABLE IN HAPPY DAYS - Modules routing */}
          {/* <PrivateRoute path="/job" component={Job} /> */}

          {/* PRE LOGIN */}
          <PublicRoute path="/login" exact component={Login} />
          <PublicRoute
            path="/password-reset"
            exact
            component={ForgotPassword}
          />
          <PublicRoute path="/auth" exact component={ResetPassword} />
          <PublicRoute path="/invite" exact component={SetInvitePassword} />
          <PublicRoute path="/register" exact component={Register} />
          <PublicRoute path="/browser-outdated" component={BrowserOutdated} />
          <PublicRoute component={BrowserOutdated} />
        </Switch>
      </Suspense>

      <Footer history={props.history} />
    </div>
  );
};

export default withRouter(Main);
