import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Spinner, Card, Tabs } from '@lionstep/ui';
import { Helmet } from 'react-helmet';

// Actions
import { getCompanyInsights } from './companyInsights.actions';

// Selectors
import {
  insightsSelector,
  isLoadingInsightsSelector,
} from './companyInsights.selectors';

// Components
import PageHeading from '../../../../components/refactoring/pageHeading/PageHeading';
import {
  TimeToFirstValidation,
  NetPromoterScore,
} from '../../../job/pages/Insights/components/infoBox';
import Disclaimer from '../../../job/pages/Insights/components/disclaimer';

import CandidateFunnelChart from '../../../job/components/Charts/CandidateFunnelChart';
import NewSourcedCandidateChart from '../../../job/components/Charts/NewSourcedCandidateChart';
import RejectedReasonsChart from '../../../job/components/Charts/RejectedReasonsChart';

// Helpers
import {
  candidateFunnelFormatter,
  newSourcedCandidateFormatter,
  rejectionReasonFormatter,
} from '../../../job/pages/Insights/insights.helpers';

// Styles
import styles from './companyInsights.module.scss';

const TABS = {
  ALL_JOBS: 'allJobs', // shared=false
  MY_JOBS: 'myJobs', // shared=true
};

const CompanyInsights = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const insights = useSelector(insightsSelector);
  const isLoading = useSelector(isLoadingInsightsSelector);
  const [activeTab, setActiveTab] = useState(TABS.ALL_JOBS);

  useEffect(() => {
    dispatch(getCompanyInsights({ shared: activeTab === TABS.MY_JOBS }));
  }, [activeTab]);

  const handleOnTabClick = (key) => {
    setActiveTab(key);
  };

  const renderInsights = () => {
    if (!insights) {
      return null;
    }

    return (
      <Grid.Row gutter={[30, 30]}>
        <Grid.Col xs={24} sm={12} xl={8}>
          <Card>
            <TimeToFirstValidation insights={insights} />
          </Card>
        </Grid.Col>

        <Grid.Col xs={24} sm={12} xl={8}>
          <Card>
            <NetPromoterScore insights={insights} />
          </Card>
        </Grid.Col>

        <Grid.Col xs={24}>
          <Card>
            <CandidateFunnelChart
              formatterFunc={candidateFunnelFormatter}
              insights={insights}
            />
          </Card>
        </Grid.Col>

        <Grid.Col xs={24} xl={12}>
          <Card className={styles.cardFullHeight}>
            <NewSourcedCandidateChart
              formatterFunc={newSourcedCandidateFormatter}
              insights={insights}
            />
          </Card>
        </Grid.Col>

        <Grid.Col xs={24} xl={12}>
          <Card className={styles.cardFullHeight}>
            <RejectedReasonsChart
              formatterFunc={rejectionReasonFormatter}
              insights={insights}
            />
          </Card>
        </Grid.Col>
      </Grid.Row>
    );
  };

  return (
    <Grid.Container className={styles.companyInsights}>
      <Helmet>
        <title>{t('company_insights_page.title')} | Lionstep</title>
      </Helmet>

      {isLoading && <Spinner position="fixed" size={100} />}

      <Grid.Row>
        <Grid.Col
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          <PageHeading className={styles.pageHeading}>
            {t('company_insights_page.title')}
          </PageHeading>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          <Disclaimer />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          <Tabs
            activeKey={activeTab}
            onClick={handleOnTabClick}
            tabScrollerId="company-insights"
          >
            <Tabs.Item label="All Jobs" itemKey={TABS.ALL_JOBS}>
              {renderInsights()}
            </Tabs.Item>
            <Tabs.Item label="My Jobs" itemKey={TABS.MY_JOBS}>
              {renderInsights()}
            </Tabs.Item>
          </Tabs>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

export default CompanyInsights;
