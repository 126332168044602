import React from 'react';
import PropTypes from 'prop-types';

const CustomWorkEnvBonus = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 68"
    className={className}
  >
    <g id="Coloured">
      <path
        fill={companyColour}
        d="M65,39h-7c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h7c1.1,0,2-0.9,2-2v-3C67,39.9,66.1,39,65,39z M65,44l-7,0l0-3
          l7,0L65,44z"
      />
      <path
        fill={companyColour}
        d="M35,10h-4c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-2C37,10.9,36.1,10,35,10z M35,14l-4,0l0-2
          l4,0L35,14z"
      />
      <path
        fill={companyColour}
        d="M46,10h-4c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-2C48,10.9,47.1,10,46,10z M46,14l-4,0l0-2
          l4,0L46,14z"
      />
      <path
        fill={companyColour}
        d="M35,19h-4c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-2C37,19.9,36.1,19,35,19z M35,23l-4,0l0-2
          l4,0L35,23z"
      />
      <path
        fill={companyColour}
        d="M46,19h-4c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-2C48,19.9,47.1,19,46,19z M46,23l-4,0l0-2
          l4,0L46,23z"
      />
      <path
        fill={companyColour}
        d="M35,28h-4c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-2C37,28.9,36.1,28,35,28z M35,32l-4,0l0-2
          l4,0L35,32z"
      />
      <path
        fill={companyColour}
        d="M46,28h-4c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-2C48,28.9,47.1,28,46,28z M46,32l-4,0l0-2
          l4,0L46,32z"
      />
      <path
        fill={companyColour}
        d="M35,37h-4c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-2C37,37.9,36.1,37,35,37z M35,41l-4,0l0-2
          l4,0L35,41z"
      />
      <path
        fill={companyColour}
        d="M46,37h-4c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-2C48,37.9,47.1,37,46,37z M46,41l-4,0l0-2
          l4,0L46,41z"
      />
      <path
        fill={companyColour}
        d="M35,46h-4c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-2C37,46.9,36.1,46,35,46z M35,50l-4,0l0-2
          l4,0L35,50z"
      />
      <path
        fill={companyColour}
        d="M46,46h-4c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-2C48,46.9,47.1,46,46,46z M46,50l-4,0l0-2
          l4,0L46,50z"
      />
      <path
        fill={companyColour}
        d="M65,48h-7c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h7c1.1,0,2-0.9,2-2v-3C67,48.9,66.1,48,65,48z M65,53l-7,0l0-3
          l7,0L65,53z"
      />
    </g>
    <path
      id="Grey"
      fill="#899198"
      d="M68,29H53V4c0-2.2-1.8-4-4-4H28c-2.2,0-4,1.8-4,4v16H7c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h1v16H7
        c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h1v16H1c-0.6,0-1,0.4-1,1s0.4,1,1,1h25h5h5h15h2h5h5h7h1c0.6,0,1-0.4,1-1c0-0.2,0-0.3-0.1-0.4
        C72,66.4,72,66.2,72,66V33C72,30.8,70.2,29,68,29z M68,31c1.1,0,2,0.9,2,2v1h-8c-0.6,0-1,0.4-1,1s0.4,1,1,1h8l0,30l-5,0c0,0,0,0,0,0
        v-6c0-1.1-0.9-2-2-2h-5c-1.1,0-2,0.9-2,2v6c0,0,0,0,0,0l-3,0V36h4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4v-3H68z M58,66l0-6l5,0l0,6l-2.9,0
        L58,66z M28,2h21c1.1,0,2,0.9,2,2v1H35c-0.6,0-1,0.4-1,1s0.4,1,1,1h16l0,59l-13,0c0,0,0,0,0,0v-6c0-1.1-0.9-2-2-2h-5
        c-1.1,0-2,0.9-2,2v6c0,0,0,0,0,0l-3,0V49v-5V26v-5V7h4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4V4C26,2.9,26.9,2,28,2z M19,62.5l5-2.8v5.6
        L19,62.5z M24,33.3l-5-2.8l5-2.8V33.3z M21.2,34h-8.4l4.2-2.4L21.2,34z M21.2,36L17,38.4L12.8,36H21.2z M24,36.7v5.6l-5-2.8L24,36.7
        z M24,56.3l-5-2.8l5-2.8V56.3z M21.2,57h-8.4l4.2-2.4L21.2,57z M21.2,59L17,61.4L12.8,59H21.2z M17,29.4L12.8,27h8.4L17,29.4z
        M15,30.5l-5,2.8v-5.6L15,30.5z M15,39.5l-5,2.8v-5.6L15,39.5z M17,40.6l4.2,2.4h-8.4L17,40.6z M17,52.4L12.8,50h8.4L17,52.4z
        M15,53.5l-5,2.8v-5.6L15,53.5z M15,62.5l-5,2.8v-5.6L15,62.5z M17,63.6l4.2,2.4h-8.4L17,63.6z M31,66l0-6l5,0l0,6l-3.7,0L31,66z
        M8,22h16v3H8V22z M8,45h16v3H8V45z"
    />
  </svg>
);

CustomWorkEnvBonus.defaultProps = {
  companyColour: '#ab1923',
};

CustomWorkEnvBonus.propTypes = {
  companyColour: PropTypes.string,
};

export default CustomWorkEnvBonus;
