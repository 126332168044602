import React from 'react';
import PropTypes from 'prop-types';
import { ButtonLink, Icon } from '@lionstep/ui';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

// Components
import CustomBenefit from './CustomBenefit';

// Styles
import styles from './customBenefits.module.scss';

const CustomBenefits = ({ fields, languages, onAppend, onUpdate }) => {
  const { t } = useTranslation();

  const handleOnAddBenefit = () => {
    const benefit = {
      benefitId: uuidv4(),
      icon: '',
      description: languages.map((lang) => ({
        content: '',
        lang,
      })),
      deleted: false,
    };

    onAppend(benefit, true);
  };

  let labelIndex = 0;

  return (
    <div className={styles.customBenefits}>
      {fields.map((field, index) => {
        if (!field.deleted) {
          labelIndex += 1;

          return (
            <CustomBenefit
              field={field}
              key={field.id}
              index={index}
              labelIndex={labelIndex}
              languages={languages}
              onUpdate={onUpdate}
            />
          );
        }

        return null;
      })}
      <ButtonLink
        className={styles.customBenefitsAdd}
        onClick={handleOnAddBenefit}
      >
        <Icon name="Plus" />{' '}
        {t('company_page.displayed_section.benefits.custom_benefits.add_btn')}
      </ButtonLink>
    </div>
  );
};

CustomBenefits.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  onAppend: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default CustomBenefits;
