export const GET_JOBS_TITLES_START = 'SETTINGS::GET_JOBS_TITLES_START';
export const GET_JOBS_TITLES_SUCCESS = 'SETTINGS::GET_JOBS_TITLES_SUCCESS';
export const GET_JOBS_TITLES_FAIL = 'SETTINGS::GET_JOBS_TITLES_FAIL';

export const GET_COLLABORATORS_START = 'SETTINGS::GET_COLLABORATORS_START';
export const GET_COLLABORATORS_SUCCESS = 'SETTINGS::GET_COLLABORATORS_SUCCESS';
export const GET_COLLABORATORS_FAIL = 'SETTINGS::GET_COLLABORATORS_FAIL';

export const ADD_MAILING_PERMISSION_START =
  'SETTINGS::ADD_MAILING_PERMISSION_START';
export const ADD_MAILING_PERMISSION_SUCCESS =
  'SETTINGS::ADD_MAILING_PERMISSION_SUCCESS';
export const ADD_MAILING_PERMISSION_FAIL =
  'SETTINGS::ADD_MAILING_PERMISSION_FAIL';

export const REMOVE_MAILING_PERMISSION_START =
  'SETTINGS::REMOVE_MAILING_PERMISSION_START';
export const REMOVE_MAILING_PERMISSION_SUCCESS =
  'SETTINGS::REMOVE_MAILING_PERMISSION_SUCCESS';
export const REMOVE_MAILING_PERMISSION_FAIL =
  'SETTINGS::REMOVE_MAILING_PERMISSION_FAIL';

export const CHANGE_JOB_OWNER_START = 'SETTINGS::CHANGE_JOB_OWNER_START';
export const CHANGE_JOB_OWNER_SUCCESS = 'SETTINGS::CHANGE_JOB_OWNER_SUCCESS';
export const CHANGE_JOB_OWNER_FAIL = 'SETTINGS::CHANGE_JOB_OWNER_FAIL';

export const REMOVE_JOB_COLLABORATOR_START =
  'SETTINGS::REMOVE_JOB_COLLABORATOR_START';
export const REMOVE_JOB_COLLABORATOR_SUCCESS =
  'SETTINGS::REMOVE_JOB_COLLABORATOR_SUCCESS';
export const REMOVE_JOB_COLLABORATOR_FAIL =
  'SETTINGS::REMOVE_JOB_COLLABORATOR_FAIL';
