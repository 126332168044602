import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

// Components
import { Drawer, Icon } from '@lionstep/ui';

// Selectors
import { appLanguageSelector } from '../../../../modules/app/redux/selectors/language.selectors';

// Hooks
import useProfileDropdown from './useProfileDropdown';
import useLanguageDropdown from './useLanguageDropdown';

// Styles
import styles from './mobileDrawer.module.scss';

const cx = classnames.bind(styles);

const MobileDrawer = ({ show, onClose, user }) => {
  const { t } = useTranslation();
  const appLanguage = useSelector(appLanguageSelector);

  const { items: profileItems, handleLogout } = useProfileDropdown(user);
  const { items: languageItems, handleOnLanguageMenuItemClick } =
    useLanguageDropdown();

  const renderDropdownMenuItem = (item, onClick) => (
    <div
      className={cx(styles.languageMenuItem, {
        [styles.menuItemChecked]: appLanguage === item.languageCode,
      })}
      key={item.languageCode}
      onClick={() => {
        handleOnLanguageMenuItemClick(item.languageCode);
        onClick();
      }}
    >
      {/* Flag */}
      <span className={styles.menuItemFlag}>{item.flag}</span>

      {/* Label */}
      {item.label}
      {/*  Checked icon */}
      {appLanguage === item.languageCode && (
        <span className={styles.menuItemCheckedIcon}>
          <Icon name="Check" size={30} />
        </span>
      )}
    </div>
  );

  const renderMenuItem = (item, onClick) => (
    <Link
      className={styles.menuItem}
      key={item.path}
      to={item.path}
      onClick={onClick}
    >
      {item.label}
    </Link>
  );

  return (
    <Drawer
      show={show}
      onClose={onClose}
      className={styles.drawer}
      closableOnClick={false}
    >
      <div className={styles.drawerContent}>
        {/* TOP PART */}
        <div className={styles.drawerTopPart}>
          <img
            alt={user.display_name}
            src={
              user.photoURL ||
              'https://0.s3.envato.com/files/35951991/80x80.JPG'
            }
          />

          <div className={styles.drawerUser}>
            <span className={styles.userLabel}>{user.display_name}</span>
            <span className={styles.companyLabel}>{user.company_name}</span>
          </div>
        </div>

        {/* MIDDLE PART */}
        <div className={styles.drawerMiddlePart}>
          {profileItems.map((item) =>
            item.render ? renderMenuItem(item, onClose) : null,
          )}

          <a
            className={styles.menuItem}
            href="https://help.lionstep.com/"
            target="_blank"
            rel="noreferrer"
            onClick={onClose}
          >
            {t('help')}
          </a>
          <div
            className={styles.menuItem}
            onClick={() => {
              handleLogout();
              onClose();
            }}
          >
            {t('logout')}
          </div>
        </div>

        {/* BOTTOM PART */}
        <div className={styles.drawerBottomPart}>
          <span className={styles.languageLabel}>{t('language')}</span>

          {languageItems.map((item) => renderDropdownMenuItem(item, onClose))}
        </div>
      </div>
    </Drawer>
  );
};
MobileDrawer.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};

export default MobileDrawer;
