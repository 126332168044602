import React from 'react';
import PropTypes from 'prop-types';

const LearningOpportunities = ({ companyColour, className, ...restProps }) => (
  <svg
    {...restProps}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 74 58"
    className={className}
  >
    <g id="Grey">
      <path
        d="M70.5,4H69V2.2C69,1,68,0,66.8,0H44.3c-1,0-1.9,0.2-2.9,0.5C40.1,1,38.2,2,37,3.8C35.8,2,33.9,1,32.6,0.5
          C31.6,0.2,30.7,0,29.7,0H7.2C6.6,0,6,0.2,5.6,0.7C5.2,1.1,5,1.6,5,2.2V4H3.5C1.6,4,0,5.6,0,7.5v47C0,56.4,1.6,58,3.5,58h67
          c1.9,0,3.5-1.6,3.5-3.5v-47C74,5.6,72.4,4,70.5,4z M7,2.2C7,2.2,7,2.1,7,2.1C7.1,2,7.1,2,7.2,2h22.5c0.7,0,1.5,0.1,2.1,0.4
          C33.8,3.1,36,4.5,36,7.2V32c0,0.6,0.4,1,1,1s1-0.4,1-1V7.2c0-2.7,2.2-4.1,4.1-4.8C42.8,2.1,43.6,2,44.3,2h22.5
          C66.9,2,67,2.1,67,2.2v47.6c0,0.1-0.1,0.2-0.2,0.2H44.3c-1,0-1.9,0.2-2.9,0.5c-1.4,0.5-2.6,1.2-3.4,2V46c0-0.6-0.4-1-1-1
          s-1,0.4-1,1v6.5c-0.9-0.8-2-1.5-3.4-2c-0.9-0.3-1.9-0.5-2.9-0.5H7.2c-0.1,0-0.1,0-0.2-0.1c0,0-0.1-0.1-0.1-0.2V2.2z M2,54.5v-47
          C2,6.7,2.7,6,3.5,6H5v43.8c0,0.6,0.2,1.2,0.6,1.6C6,51.8,6.6,52,7.2,52h22.5c0.7,0,1.5,0.1,2.1,0.4c2.3,0.9,3.6,2.1,4,3.6H3.5
          C2.7,56,2,55.3,2,54.5z M72,54.5c0,0.8-0.7,1.5-1.5,1.5H38.1c0.4-1.6,1.7-2.7,4-3.6c0.7-0.3,1.4-0.4,2.2-0.4h22.5
          c1.2,0,2.2-1,2.2-2.2V6h1.5C71.3,6,72,6.7,72,7.5V54.5z"
        fill="#899198"
      />
      <path
        d="M37,42c0.6,0,1-0.4,1-1v-3.9c0-0.6-0.4-1-1-1s-1,0.4-1,1V41C36,41.6,36.4,42,37,42z"
        fill="#899198"
      />
      <path
        d="M25,36h2c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1S24.4,36,25,36z"
        fill="#899198"
      />
      <path
        d="M31,36h1c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1c-0.6,0-1,0.4-1,1S30.4,36,31,36z"
        fill="#899198"
      />
      <path
        d="M23,41h9c0.6,0,1-0.4,1-1s-0.4-1-1-1h-9c-0.6,0-1,0.4-1,1S22.4,41,23,41z"
        fill="#899198"
      />
      <path
        d="M16,46h1c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1c-0.6,0-1,0.4-1,1S15.4,46,16,46z"
        fill="#899198"
      />
      <path
        d="M21,46h6c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1S20.4,46,21,46z"
        fill="#899198"
      />
      <path
        d="M32,44h-1c-0.6,0-1,0.4-1,1s0.4,1,1,1h1c0.6,0,1-0.4,1-1S32.6,44,32,44z"
        fill="#899198"
      />
      <path
        d="M42,36h2c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1S41.4,36,42,36z"
        fill="#899198"
      />
      <path
        d="M49,36h10c0.6,0,1-0.4,1-1s-0.4-1-1-1H49c-0.6,0-1,0.4-1,1S48.4,36,49,36z"
        fill="#899198"
      />
      <path
        d="M59,44h-1c-0.6,0-1,0.4-1,1s0.4,1,1,1h1c0.6,0,1-0.4,1-1S59.6,44,59,44z"
        fill="#899198"
      />
      <path
        d="M42,46h11c0.6,0,1-0.4,1-1s-0.4-1-1-1H42c-0.6,0-1,0.4-1,1S41.4,46,42,46z"
        fill="#899198"
      />
      <path
        d="M42,41h12c0.6,0,1-0.4,1-1s-0.4-1-1-1H42c-0.6,0-1,0.4-1,1S41.4,41,42,41z"
        fill="#899198"
      />
      <path
        d="M58,40c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4C58.4,39,58,39.4,58,40z"
        fill="#899198"
      />
    </g>
    <g id="Coloured">
      <path
        d="M21,28c-0.1-0.6-0.4-1.1-0.9-1.5c-0.5-0.4-1.1-0.5-1.7-0.4L11.9,27c-1.1,0.2-1.9,1.1-1.9,2.3v7.5
          c0,0.7,0.3,1.4,0.9,1.8c0.4,0.3,0.9,0.5,1.4,0.5c0.2,0,0.4,0,0.5-0.1l7.5-1.9c1.1-0.3,1.9-1.4,1.7-2.5L21,28z M19.8,35.1L12.3,37
          c-0.1,0-0.2,0-0.2,0C12,36.9,12,36.8,12,36.7v-7.5c0-0.1,0.1-0.3,0.2-0.3l6.5-0.9c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0.1
          c0,0,0.1,0.1,0.1,0.2l0.9,6.6C20,34.9,19.9,35.1,19.8,35.1z"
        fill={companyColour}
      />
      <path
        d="M31.2,6.5c-0.5-0.4-1.1-0.6-1.7-0.5L12,8.2c-0.5,0.1-0.9,0.3-1.2,0.5c0,0,0,0-0.1,0.1c-0.4,0.4-0.7,1-0.7,1.6
          v11.3c0,0.6,0.3,1.3,0.7,1.7c0.4,0.4,0.9,0.6,1.5,0.6c0.1,0,0.2,0,0.3,0L31,21.8c1.2-0.1,2.1-1.2,2-2.4L32,8
          C31.9,7.4,31.6,6.9,31.2,6.5z M12,11l7.4,4L12,21V11z M14.2,10l13.3-1.7l-6.5,5.3L14.2,10z M21.3,16l7.5,4l-14.5,1.7L21.3,16z
          M23,14.6L30,8.8l0.9,10.1L23,14.6z"
        fill={companyColour}
      />
      <path
        d="M65,17.5c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1L61,6.5c-0.2-0.6-0.6-1-1.2-1.3
          c-0.5-0.3-1.2-0.3-1.7-0.1L53,7c-0.6-0.9-1.7-1.4-2.8-1.4c-1.9,0-3.5,1.6-3.5,3.5c0,0.1,0,0.1,0,0.2l-5.2,1.9
          c-0.6,0.2-1,0.6-1.3,1.2c-0.3,0.5-0.3,1.2-0.1,1.7l6.1,16.5c0.2,0.6,0.6,1,1.2,1.3c0.3,0.1,0.6,0.2,1,0.2c0.3,0,0.5,0,0.8-0.1
          L59.8,28c0,0,0,0,0,0c0.1,0,0.2-0.1,0.2-0.1c0,0,0,0,0.1-0.1c0.1-0.1,0.2-0.2,0.2-0.3l4.5-9.6c0,0,0,0,0-0.1
          C65,17.7,65,17.6,65,17.5z M50.2,7.6c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5S49.3,7.6,50.2,7.6z M48.4,30
          c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.1L42,13.3c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.2-0.1l5.2-1.9c0.6,0.9,1.7,1.4,2.8,1.4
          c1.9,0,3.5-1.6,3.5-3.5c0-0.1,0-0.1,0-0.2L58.8,7C58.9,7,59,7,59,7c0.1,0,0.1,0.1,0.1,0.1l3.5,9.7l-5,1.8c-0.6,0.2-1,0.6-1.3,1.2
          c-0.3,0.5-0.3,1.2-0.1,1.7l1.8,4.9L48.4,30z M59.6,24.5l-1.3-3.6c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.1l3.7-1.3L59.6,24.5z"
        fill={companyColour}
      />
    </g>
  </svg>
);

LearningOpportunities.defaultProps = {
  companyColour: '#ab1923',
};

LearningOpportunities.propTypes = {
  companyColour: PropTypes.string,
};

export default LearningOpportunities;
