import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, Modal, Spinner, Text, Table } from '@lionstep/ui';

// Actions
import {
  addMailingPermissionToCollaborator,
  removeMailingPermissionToCollaborator,
} from '../../../../../company/redux/actions';

// Selectors
import {
  collaboratorsSelector,
  isLoadingCollaboratorsSelector,
} from '../../../../../company/redux/selectors/company.selectors';

// Components
import InfoPlusIconWithTooltip from '../../../../../../components/refactoring/infoPlusIconWithTooltip';

// Styles
import styles from './jobMailingPermissionsModal.module.scss';

const JobMailingPermissionsModal = ({ show, job, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const collaborators = useSelector(collaboratorsSelector);
  const isLoading = useSelector(isLoadingCollaboratorsSelector);

  const handleOnAddMailingPermission = (userId) => {
    dispatch(
      addMailingPermissionToCollaborator({
        job_id: job.id,
        user_id: userId,
      }),
    );
  };

  const handleOnRemoveMailingPermission = (userId) => {
    dispatch(
      removeMailingPermissionToCollaborator({
        job_id: job.id,
        user_id: userId,
      }),
    );
  };

  return (
    <Modal className={styles.modal} show={show} onClose={onClose}>
      <Modal.Header className={styles.modalHeader}>
        {t('dashboard_page.modals.mailing_permissions_title')}
        <InfoPlusIconWithTooltip />
        <Text className={styles.modalSubHeader}>
          {job?.opening_title || ''}
        </Text>
      </Modal.Header>

      <Modal.Body className={styles.modalBody}>
        {isLoading && <Spinner position="absolute" size={100} />}

        <Table>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderCell colSpan={2}>{t('email')}</Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell textAlign="center">
                {t('dashboard_page.modals.mailing_permissions_title')}
              </Table.HeaderCell>
            </Table.HeaderRow>
          </Table.Header>

          <Table.Body>
            {collaborators.map((collaborator) => {
              const onMailingList = collaborator.job_mailings.includes(
                job?.id.toString(),
              );
              return (
                <Table.Row key={collaborator.local_id}>
                  <Table.Cell colSpan={2}>{collaborator.email}</Table.Cell>
                  <Table.Cell />
                  <Table.Cell textAlign="center">
                    <Checkbox
                      name={`mailing-${collaborator.local_id}`}
                      label={''}
                      checked={onMailingList}
                      onChange={(e) => {
                        if (e.target.checked) {
                          handleOnAddMailingPermission(collaborator.local_id);
                        } else {
                          handleOnRemoveMailingPermission(
                            collaborator.local_id,
                          );
                        }
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Modal.Body>
      <Modal.Footer
        confirmText={t('submit')}
        onConfirm={onClose}
        confirmButtonGhost
      />
    </Modal>
  );
};

JobMailingPermissionsModal.defaultProps = {
  job: {},
};

JobMailingPermissionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  job: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
};

export default JobMailingPermissionsModal;
