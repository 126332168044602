// eslint-disable-next-line import/prefer-default-export
export const SPENT_ON_OPTIONS = [
  {
    value: 'validation',
    label: 'credits_page.charge_credits.spent_on.options.validation',
  },
  {
    value: 'search',
    label: 'credits_page.charge_credits.spent_on.options.search',
  },
  {
    value: 'personality_test',
    label: 'credits_page.charge_credits.spent_on.options.personality_test',
  },
  {
    value: 'subscription',
    label: 'credits_page.charge_credits.spent_on.options.subscription',
  },
];
