import { groupBy } from 'lodash';

// sort all multi language field in order -> en, de, es, fr
const sortByLang = (lang1, lang2) => {
  if (lang1 === 'en') return -1;

  if (lang1 === 'de' && lang2 !== 'en') return -1;

  if (lang1 === 'es' && lang2 !== 'en' && lang2 !== 'de') return -1;

  if (lang1 === 'fr') return 1;

  return 0;
};

/**
 * @param {Array<{lang: string, selected: boolean}>} companyLanguages - Array of language objects
 * @param {string} language - Language whose "selected" status needs to be checked
 * @returns {object|undefined} Corresponding language if it is selected, otherwise undefined
 */
const isLanguageSelected = (companyLanguages, language) =>
  companyLanguages.find(
    (companyLanguage) =>
      companyLanguage.lang === language && companyLanguage.selected,
  );

const SKIPPED_FIELDS = [
  'acuity_links_de',
  'acuity_links_en',
  'acuity_links_es',
  'acuity_links_fr',
  'logo_filename',
];

export const companyInputMapper = (company) => ({
  info_languages: company?.info_languages
    ?.filter((language) => language.selected)
    .map((language) => language.lang) ?? ['en', 'de'],
  acuity_links_en: company?.acuity_links?.en || '',
  acuity_links_de: company?.acuity_links?.de || '',
  acuity_links_es: company?.acuity_links?.es || '',
  acuity_links_fr: company?.acuity_links?.fr || '',
  company_name: company?.company_name,
  company_url: company?.company_url ?? '',
  enable_kombo_integration: company?.enable_kombo_integration ? 'yes' : 'no',
  other_names: company?.other_names ?? '',
  can_be_disclosed: company?.can_be_disclosed ? 'yes' : 'no',
  replacement_name: company?.replacement_name ?? '',
  location: company?.locations?.length
    ? {
        value: company.locations[0].placeId,
        label: company.locations[0].name,
      }
    : null,
  competitors_list: company?.competitors_list ?? '',
  preferred_companies: company?.preferred_companies ?? '',
  hide_longlist: company?.hide_longlist,
  sensitive_client: company?.sensitive_client ? 'yes' : 'no',
  global_competitors_list: company?.global_competitors_list,
  logo_filename:
    company?.logo_url?.split('/')[company.logo_url.split('/').length - 1],
  logo_url: company?.logo_url,
  colour: company?.colour ?? '',
  text_colour: company?.text_colour ?? '#192543',
  description: company?.description
    ?.filter((description) =>
      isLanguageSelected(company.info_languages, description.lang),
    )
    .sort((desc1, desc2) => sortByLang(desc1.lang, desc2.lang)) ?? [
    {
      content: '',
      lang: 'en',
    },
    {
      content: '',
      lang: 'de',
    },
    {
      content: '',
      lang: 'es',
    },
    {
      content: '',
      lang: 'fr',
    },
  ],
  industry: company?.industry,
  industry_object: company?.industry_object
    ? { value: company.industry_object, label: company.industry_object.mid }
    : null,
  founded: company?.founded ?? '',
  company_video: company?.company_video ?? '',
  benefits_pool: company?.benefits_pool ?? [],
  benefits_pool_selected: company?.benefits_pool_selected ?? [],
  benefits_custom:
    company?.benefits_custom?.map((benefit) => ({
      ...benefit,
      benefitId: benefit.id,
      description: benefit.description.sort((desc1, desc2) =>
        sortByLang(desc1.lang, desc2.lang),
      ),
    })) ?? [],
  kununu_rating: company?.kununu_rating ?? '',
  glassdoor_rating: company?.glassdoor_rating ?? '',
  diversity_toggle: company?.diversity_toggle ? 'yes' : 'no',
  diversity_text: company?.diversity_text,
  vision: company?.vision ?? '',
  office_description: company?.office_description ?? '',
  corporate_culture: company?.corporate_culture ?? '',
});

const COMPANY_DETAILS_FIELDS = [
  'location',
  'corporate_culture',
  'vision',
  'office_description',
  'colour',
  'logo_url',
  'company_name',
  'competitors_list',
  'other_names',
];

export const companyOutputMapper = (company) => {
  const companyDto = {
    acuity_links: {
      de: company.acuity_links_de,
      en: company.acuity_links_en,
      es: company.acuity_links_es,
      fr: company.acuity_links_fr,
    },
    can_be_disclosed: company.can_be_disclosed === 'yes',
    sensitive_client: company.sensitive_client === 'yes',
    company_details: {},
  };

  const keys = Object.keys(company);

  for (let i = 0; i < keys.length; i++) {
    if (COMPANY_DETAILS_FIELDS.includes(keys[i])) {
      switch (keys[i]) {
        case 'logo_url':
          companyDto.company_details.logo_blob = company[keys[i]];
          break;

        case 'location':
          companyDto.company_details.locations = company.location
            ? [
                {
                  placeId: company.location.value,
                  name: company.location.label,
                },
              ]
            : [];
          break;

        default:
          companyDto.company_details[keys[i]] = company[keys[i]];
          break;
      }
    } else if (keys[i] === 'industry_object') {
      companyDto.industry_object = company.industry_object?.value;
      // Industry is not used anymore, kept for backward compatibility
      companyDto.industry = company.industry_object?.value.mid;
    } else if (!SKIPPED_FIELDS.includes(keys[i])) {
      switch (keys[i]) {
        case 'can_be_disclosed':
          companyDto.can_be_disclosed = company.can_be_disclosed === 'yes';
          break;

        case 'enable_kombo_integration':
          companyDto.enable_kombo_integration =
            company.enable_kombo_integration === 'yes';
          break;

        case 'diversity_toggle':
          companyDto.diversity_toggle = company.diversity_toggle === 'yes';
          break;

        case 'sensitive_client':
          companyDto.sensitive_client = company.sensitive_client === 'yes';
          break;

        case 'info_languages':
          companyDto.info_languages = [
            {
              lang: 'en',
              selected: !!company.info_languages.find((lang) => lang === 'en'),
            },
            {
              lang: 'de',
              selected: !!company.info_languages.find((lang) => lang === 'de'),
            },
            {
              lang: 'es',
              selected: !!company.info_languages.find((lang) => lang === 'es'),
            },
            {
              lang: 'fr',
              selected: !!company.info_languages.find((lang) => lang === 'fr'),
            },
          ];
          break;

        case 'benefits_custom':
          companyDto.benefits_custom = company.benefits_custom.map(
            ({ benefitId, ...restBenefit }) => ({
              ...restBenefit,
              id: benefitId,
            }),
          );
          break;

        default:
          companyDto[keys[i]] = company[keys[i]];
          break;
      }
    }
  }

  return companyDto;
};

export const benefitsFormatting = (benefits) => {
  if (!benefits) {
    return null;
  }

  const mappedKeys = {
    'Time off': 'time_off_group',
    'Food, Transportation & Other discounts': 'food_and_transport_group',
    'Health Programs': 'health_group',
    'Work environment': 'work_environment_group',
    Financial: 'financial_group',
  };
  const groupedBenefits = groupBy(benefits, 'benefit_group');

  return Object.keys(groupedBenefits).reduce((acc, curr) => {
    acc[mappedKeys[curr]] = groupedBenefits[curr].map((item) => item.id);

    return acc;
  }, {});
};
