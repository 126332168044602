import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Queries
import { useUserLogoutQuery } from '../../../../queries/userQueries';

// Constants
import { USER_ROLES } from '../../../../constants/user.const';

// Hooks
import useIsLionstepAdmin from '../../../../hooks/useIsLionstepAdmin';

const useProfileDropdown = (user) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isLionstepAdmin = useIsLionstepAdmin();
  const { refetch: userLogout } = useUserLogoutQuery({
    history,
    clearReduxStore: () => dispatch({ type: 'LOGOUT_USER' }),
  });

  const PROFILE_ITEMS = [
    {
      label: t('user_profile'),
      path: '/profile',
      render: true,
    },
    {
      label: t('company'),
      path: '/company',
      render: true,
    },
    {
      label: t('company_insights_page.title'),
      path: '/company-insights',
      render: true,
    },
    {
      label: t('credits_page.title'),
      path: '/credits',
      render: isLionstepAdmin || user.role === USER_ROLES.admin,
    },
    {
      label: t('collaborators'),
      path: '/collaborators',
      render: isLionstepAdmin || user.role === USER_ROLES.admin,
    },
    {
      label: t('job_settings'),
      path: '/job-settings',
      render: true,
    },
    {
      label: t('purchases_page.title'),
      path: '/purchases',
      render: isLionstepAdmin,
    },
  ];

  return {
    items: PROFILE_ITEMS,
    handleLogout: userLogout,
  };
};

export default useProfileDropdown;
