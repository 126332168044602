import React from 'react';
import PropTypes from 'prop-types';

// Components
import QuestionYesNoHide from './QuestionYesNoHide';
import QuestionTitleAndType from './QuestionTitleAndType';
import QuestionYesNo from './QuestionYesNo';
import QuestionMultipleChoice from './QuestionMultipleChoice';
import QuestionSkills from './QuestionSkills';

// Constants
import { QUESTIONS_TYPE } from '../../adminActions.constants';

const Question = ({
  onQuestionChoiceAdd,
  onQuestionChoiceChange,
  onQuestionChoiceDelete,
  onQuestionTypeChange,
  onQuestionValueChange,
  question,
  questionIndex,
  skills,
  supportedQuestionTypes,
  isLoadingSkills,
}) => (
  <>
    {/* QUESTION COMMON PART */}

    {/* Question Title */}
    <QuestionTitleAndType
      supportedQuestionTypes={supportedQuestionTypes}
      question={question}
      questionIndex={questionIndex}
      onQuestionValueChange={onQuestionValueChange}
      onQuestionTypeChange={onQuestionTypeChange}
    />
    {/* Show Hide Question */}
    {question.type === QUESTIONS_TYPE.yesNo && (
      <QuestionYesNoHide
        question={question}
        questionIndex={questionIndex}
        onQuestionValueChange={onQuestionValueChange}
      />
    )}
    {/* QUESTION SPECIFIC PART */}
    {question.type === QUESTIONS_TYPE.yesNo && (
      <QuestionYesNo question={question} questionIndex={questionIndex} />
    )}
    {question.type === QUESTIONS_TYPE.multipleChoice && (
      <QuestionMultipleChoice
        question={question}
        questionIndex={questionIndex}
        onQuestionChoiceChange={onQuestionChoiceChange}
        onQuestionChoiceAdd={onQuestionChoiceAdd}
        onQuestionChoiceDelete={onQuestionChoiceDelete}
      />
    )}
    {question.type === QUESTIONS_TYPE.skills && (
      <QuestionSkills
        question={question}
        questionIndex={questionIndex}
        onQuestionChoiceAdd={onQuestionChoiceAdd}
        onQuestionChoiceDelete={onQuestionChoiceDelete}
        skills={skills}
        isLoadingSkills={isLoadingSkills}
      />
    )}
  </>
);

Question.propTypes = {
  question: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    hide: PropTypes.bool.isRequired,
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        choice: PropTypes.string.isRequired,
        expected_str: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  questionIndex: PropTypes.number.isRequired,
  onQuestionChoiceChange: PropTypes.func.isRequired,
  onQuestionChoiceAdd: PropTypes.func.isRequired,
  onQuestionChoiceDelete: PropTypes.func.isRequired,
  onQuestionTypeChange: PropTypes.func.isRequired,
  onQuestionValueChange: PropTypes.func.isRequired,
  skills: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  supportedQuestionTypes: PropTypes.arrayOf(
    PropTypes.oneOf([
      QUESTIONS_TYPE.yesNo,
      QUESTIONS_TYPE.multipleChoice,
      QUESTIONS_TYPE.skills,
      QUESTIONS_TYPE.openAnswer,
    ]),
  ).isRequired,
  isLoadingSkills: PropTypes.bool.isRequired,
};

export default Question;
