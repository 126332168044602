import React from 'react';

const BrowserOutdated = () => (
  <div
    style={{
      backgroundColor: 'rgba(255, 255, 255, 1)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
      width: '100%',
    }}
  >
    <p style={{ width: '50%', fontSize: 16 }}>
      Please use Internet Explorer 11 ( or newer), Chrome, Firefox or Safari to
      access the Lionstep Service. Your browser is not supported anymore.
    </p>
  </div>
);

export default BrowserOutdated;
