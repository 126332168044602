import React, { useState, useRef } from 'react';
import classnames from 'classnames/bind';
import { Icon } from '@lionstep/ui';

// Hooks
import useClickOutside from '../../../../hooks/useClickOutside';

// Styles
import styles from './headerDropdown.module.scss';

const cx = classnames.bind(styles);

// Const
const HeaderDropdown = ({
  menuItems,
  menuSecondary = false,
  renderDropdownTrigger,
  renderDropdownMenuItem,
  renderDropdownMenu,
}) => {
  const dropdownRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  const handleOnMenuOpen = () => setIsOpen((currVal) => !currVal);

  return (
    <div className={styles.headerDropdown} ref={dropdownRef}>
      <div className={styles.dropdownTrigger} onClick={handleOnMenuOpen}>
        {renderDropdownTrigger()}
        <Icon
          className={cx(styles.dropdownTriggerIcon, {
            [styles.dropdownTriggerIconSecondary]: menuSecondary,
          })}
          name={isOpen ? 'ArrowUp' : 'ArrowDown'}
          size={24}
        />
      </div>
      <div
        className={cx(styles.menu, {
          [styles.open]: isOpen,
          [styles.menuSecondary]: menuSecondary,
        })}
      >
        {menuItems &&
          menuItems.map((item) =>
            renderDropdownMenuItem(item, handleOnMenuOpen),
          )}
        {renderDropdownMenu && renderDropdownMenu(handleOnMenuOpen)}
      </div>
    </div>
  );
};

export default HeaderDropdown;
