import { capitalize } from 'lodash';

// Consts
import { CANDIDATES_STATUSES } from '../../../../job.constants';

// Career Path
export const workingExperienceFormatter = ({
  workingExperience,
  t,
  hideActivePositionCompany = false,
}) =>
  workingExperience
    ? workingExperience?.map((item) => {
        const isActivePosition = item.end === new Date().getFullYear();
        const hideCompanyName =
          (item.company_name &&
            isActivePosition &&
            hideActivePositionCompany) ||
          !item.company_name;

        return {
          label: `${item.start || '/'} - ${
            isActivePosition ? t('present') : item.end || '/'
          }`,
          value: {
            title: `${item.title_display || item.title_mid || ''}${
              hideCompanyName ? '' : ` @ ${item.company_name}`
            }`,
            subtitle:
              item.company_city || item.company_country
                ? `${item.company_city ? `${item.company_city}, ` : ``}${
                    item.company_country || ''
                  }`
                : null,
            description: item.description?.replaceAll('\n', ''),
          },
        };
      })
    : [];

export const educationFormatter = (education) =>
  education
    ? education?.map((item) => ({
        label: `${item.start || '/'} - ${item.end || '/'}`,
        value: `${item.degrees?.[0] || ''}${
          item.degrees?.[0] && item.name ? ' @ ' : ''
        }${item.name || ''}`,
      }))
    : [];

// Capabilities
export const capabilitiesSkillsFormatter = (skills) => [
  {
    label: '',
    value: skills
      ? skills.map((skill) => capitalize(skill.display || skill.mid))
      : [],
  },
];

export const capabilitiesLanguagesFormatter = (languages) => [
  {
    label: '',
    value: languages
      ? languages.map((language) =>
          capitalize(language.display || language.mid),
        )
      : [],
  },
];

// Key info
export const formatNoticePeriod = (value, t) => {
  if (value > 6) {
    return t('candidate_page.body.more_than_months', { number: 6 });
  }

  return `${value} ${t('candidate_page.body.months')}`;
};

const formatCurrency = (value) =>
  String(value)
    .replace("'", '')
    .replace(/\d(?=(?:\d{3})+$)/g, '$&.');

export const formatSalaryExpectation = ({ candidate, extraInfo }) => {
  let salaryExpectation = '';

  if (candidate.min_salary) {
    // Format currency
    salaryExpectation = formatCurrency(candidate.min_salary);

    // Add currency symbol
    salaryExpectation += ` ${extraInfo.currency ?? '¤'}`;
  }

  return salaryExpectation;
};

// PDF Export
const ADDITIONAL_QUESTION_TRANSLATIONS = {
  deutsch: {
    leave_current_position_reason:
      'Warum willst Du Deine derzeitige Position wechseln?',
    in_interview_process:
      'Befindst Du dich derzeit in einem Bewerbungsprozess mit anderen Unternehmen?',
    previous_applied_to_job_yes:
      'Der Bewerber hat sich in der Vergangenheit bei dem Unternehmen beworben',
    previous_applied_to_job_no:
      'Der Kandidat bewirbt sich zum ersten Mal im Unternehmen.',
    yes: 'Ja',
    no: 'Nein',
  },
  english: {
    leave_current_position_reason:
      'Why do you want to leave your current position?',
    in_interview_process:
      'Are you currently in an interview process with other companies?',
    previous_applied_to_job_yes:
      'The applicant has applied to the company in the past',
    previous_applied_to_job_no:
      'The candidate applies for the first time at the company.',
    yes: 'Yes',
    no: 'No',
  },
  français: {
    leave_current_position_reason:
      'Pourquoi voulez-vous quitter votre poste actuel?',
    in_interview_process:
      "Êtes-vous en train de passer des entretiens avec d'autres entreprises?",
    previous_applied_to_job_yes:
      "Le candidat a déjà postulé pour un emploi dans l'entreprise",
    previous_applied_to_job_no:
      "Le candidat postule pour la première fois dans l'entreprise.",
    yes: 'Oui',
    no: 'Non',
  },
  español: {
    leave_current_position_reason: '¿Por qué quiere dejar su puesto actual?',
    in_interview_process:
      '¿Está actualmente en un proceso de entrevistas con otras empresas?',
    previous_applied_to_job_yes:
      'El solicitante ha solicitado un trabajo en la empresa en el pasado.',
    previous_applied_to_job_no:
      'El candidato se postula por primera vez en la empresa.',
    yes: 'Sí',
    no: 'No',
  },
};

export const formatSummary = ({
  additionalInfo,
  candidate,
  extraInfo,
  jobLanguage,
  t,
  withKeyInfo = false,
}) => {
  // In some cases we get strangely formatted HTML in additionalInfo so we clean up newlines and empty paragraphs
  const cleanAdditionalInfo = additionalInfo
    .replaceAll('>\n<', '><')
    .replaceAll('<p></p>', '');

  const leavePositionReason =
    additionalInfo && candidate.leave_current_position_reason
      ? `<b>${ADDITIONAL_QUESTION_TRANSLATIONS[jobLanguage].leave_current_position_reason}</b><p>${candidate.leave_current_position_reason}</p>`
      : '';

  const interviewProcessInfo = additionalInfo
    ? `<b>${
        ADDITIONAL_QUESTION_TRANSLATIONS[jobLanguage].in_interview_process
      }</b><p>${
        candidate.in_interview_process
          ? `${ADDITIONAL_QUESTION_TRANSLATIONS[jobLanguage].yes}. ${candidate.in_interview_process_description}`
          : ADDITIONAL_QUESTION_TRANSLATIONS[jobLanguage].no
      }</p>`
    : '';

  const previouslyApplied = candidate?.previous_applied_to_job
    ? `<b>${ADDITIONAL_QUESTION_TRANSLATIONS[jobLanguage].previous_applied_to_job_yes}</b><p>${candidate.previous_applied_to_job_comment}</p>`
    : `<b>${ADDITIONAL_QUESTION_TRANSLATIONS[jobLanguage].previous_applied_to_job_no}</b>`;

  let keyInfo = '';

  if (candidate.status !== CANDIDATES_STATUSES.contacted.value) {
    const salaryExpectationCurrency = formatSalaryExpectation({
      candidate,
      extraInfo,
    });

    // Salary expectation
    const salaryExpectation =
      `${t('candidate_page.body.salary_expectation')}: ` +
      `${salaryExpectationCurrency || t('no_info_to_show')}` +
      `${
        candidate.salary_on_budget
          ? ` / ${t('candidate_page.body.on_budget')}`
          : ''
      }` +
      `${
        candidate.negotiable_salary
          ? ` / ${t('candidate_page.body.negotiable_salary')}`
          : ''
      }` +
      `${candidate.salary_comment ? ` / ${candidate.salary_comment}` : ''}`;

    // Place of residence
    const candidateLocation =
      candidate.talent_location && candidate.talent_location !== 'Unknown'
        ? candidate.talent_location
        : '';
    const hasCandidateLocationBadges =
      candidate.location_valid && candidate.willing_to_move;
    const candidateLocationBadges = candidate.location_valid
      ? t('candidate_page.body.lives_in_area')
      : t('candidate_page.body.willing_to_move');

    const placeOfResidence =
      `${t('candidate_page.body.place_of_residence')}: ` +
      `${candidateLocation || t('no_info_to_show')}` +
      `${hasCandidateLocationBadges ? ` / ${candidateLocationBadges}` : ''}`;

    // Notice period
    const noticePeriod =
      `${t('candidate_page.body.notice_period')}: ` +
      `${
        candidate.notice_period
          ? formatNoticePeriod(candidate.notice_period, t)
          : t('no_info_to_show')
      }` +
      `${
        candidate.notice_period_comment
          ? ` / ${candidate.notice_period_comment}`
          : ''
      }`;

    // Documents
    const candidateUploadedDocuments = candidate.documents_uploaded
      ? t('candidate_page.body.documents_available')
      : '';

    const candidateDocumentsComment =
      candidate.documents_uploaded_comment || t('no_info_to_show');

    const documents = `${t('documents')}: ${
      candidateUploadedDocuments || candidateDocumentsComment
    }`;

    keyInfo = `
      <p>
        ${salaryExpectation}
        ${placeOfResidence}
        ${noticePeriod}
        ${documents}
      </p>
    `;
  }

  const enrichedAdditionalInfo = cleanAdditionalInfo.concat(
    leavePositionReason,
    interviewProcessInfo,
    previouslyApplied,
    withKeyInfo ? keyInfo : '',
  );

  return enrichedAdditionalInfo;
};
