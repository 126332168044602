import { camelCase } from 'lodash';

export const getActiveItem = (pathname) => {
  const pathSections = pathname.split('/').filter((item) => item);
  let activeItem = 'createJob';

  if (pathSections.length === 2 && pathSections[0] === 'edit-job') {
    activeItem = 'editJob';
  } else if (pathSections.length === 2 && pathSections[0] === 'duplicate-job') {
    activeItem = 'duplicateJob';
  } else if (pathSections.includes('key-points')) {
    activeItem = 'keyPoints';
  } else if (pathSections.includes('admin')) {
    activeItem = 'adminActions';
  } else if (pathSections.includes('insights')) {
    activeItem = 'insights';
  } else if (pathSections.includes('analytics')) {
    activeItem = 'analytics';
  } else if (pathSections.includes('talents')) {
    activeItem = 'potentialCandidates';
  } else if (pathSections.includes('favorites')) {
    activeItem = 'favorites';
  } else if (pathSections.includes('candidates')) {
    activeItem = 'candidates';
  }

  return activeItem;
};

export const getActiveSubItem = (pathname, keyPoints) => {
  const pathSections = pathname.split('/').filter((item) => item);
  let activeItem = '';

  if (pathSections.length === 2) {
    activeItem = 'general';
  } else if (pathSections.includes('key-points')) {
    activeItem = keyPoints ? camelCase(keyPoints) : 'salaryCheck';
  }

  return activeItem;
};
