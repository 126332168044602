/* eslint-disable import/prefer-default-export */
const getCandidateInfoItem = (container, spans) => {
  const containerWidth = container.offsetWidth;
  let rowWidth = 0;
  const rows = [[]];

  spans.forEach((span) => {
    const spanWidth =
      span.offsetWidth + parseInt(getComputedStyle(span).marginRight, 10);

    if (rowWidth + spanWidth > containerWidth) {
      rows.push([]);
      rowWidth = 0;
    }

    rowWidth += spanWidth;
    rows[rows.length - 1].push(span);
  });

  return rows;
};

export const updateCandidateInfoClasses = (
  containerClass,
  childrenClass,
  childrenLastElementClass,
) => {
  const container = document.querySelector(`.${containerClass}`);
  const spans = Array.from(container.querySelectorAll(`.${childrenClass}`));
  spans.forEach((span) => {
    span.classList.remove(childrenLastElementClass);
  });

  const rows = getCandidateInfoItem(container, spans);
  rows.forEach((row) => {
    const lastChild = row[row.length - 1];
    lastChild.classList.add(childrenLastElementClass);
  });
};
