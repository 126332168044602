/* eslint-disable import/prefer-default-export */
import { toast } from '@lionstep/ui';

import * as types from './companyInsights.types';

import { getCompanyInsightsApi } from '../../../../api/company.api';

export const getCompanyInsights =
  ({ shared = false }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.GET_COMPANY_INSIGHTS_START });

      const { data } = await getCompanyInsightsApi({ params: { shared } });

      dispatch({ type: types.GET_COMPANY_INSIGHTS_SUCCESS, payload: data });
    } catch (error) {
      toast.error('Error getting company insights data', {
        id: types.GET_COMPANY_INSIGHTS_FAILS,
        statusCode: error?.response?.status,
      });
      dispatch({ type: types.GET_COMPANY_INSIGHTS_FAILS });
    }
  };
