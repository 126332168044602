import types from './types';

const logoutUser = () => ({
  type: types.LOGOUT_USER,
});

const loginUser = (data) => ({
  type: types.LOGIN_USER,
  async: true,
  payload: {
    path: '/api/v1/user/login',
    method: 'POST',
    body: data,
  },
});

const signupUser = (data) => ({
  type: types.SIGNUP_USER,
  async: true,
  payload: {
    path: '/api/v1/user/signup',
    method: 'POST',
    body: data,
  },
});

const resetPassword = (data) => ({
  type: types.RESET_PASSWORD,
  async: true,
  payload: {
    path: '/api/v1/user/reset-password',
    method: 'POST',
    body: data,
  },
});

const refreshUserToken = (data) => ({
  type: types.REFRESH_USER_TOKEN,
  async: true,
  payload: {
    path: '/api/v1/user/refresh',
    method: 'POST',
    body: data,
  },
});

const verifySignupCollaborator = (data) => ({
  type: types.VERIFY_SIGNUP_COLLABORATOR,
  async: true,
  payload: {
    path: '/api/v1/user/verify-signup-collaborator',
    method: 'POST',
    body: data,
  },
});

const signupCollaborator = (data) => ({
  type: types.SIGNUP_COLLABORATOR,
  async: true,
  payload: {
    path: '/api/v1/users/signup-collaborator',
    method: 'POST',
    body: data,
  },
});

const verifyOobCode = (data) => ({
  type: types.VERIFY_OOB_CODE,
  async: true,
  payload: {
    path: '/api/v1/user/verify-oobcode',
    method: 'POST',
    body: data,
  },
});

const changePassword = (data) => ({
  type: types.CHANGE_PASSWORD,
  async: true,
  payload: {
    path: '/api/v1/user/change-password',
    method: 'POST',
    body: data,
  },
});

export default {
  loginUser,
  signupUser,
  logoutUser,
  refreshUserToken,
  verifyOobCode,
  changePassword,
  resetPassword,
  verifySignupCollaborator,
  signupCollaborator,
};
