import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useSearchParams = () => {
  const history = useHistory();

  const getQueryParams = () => {
    const newParams = {};
    const queryParams = new URLSearchParams(history.location.search);

    queryParams.forEach((value, key) => {
      newParams[key] = value;
    });

    return newParams;
  };

  const [params, setParams] = useState(getQueryParams());

  useEffect(() => {
    setParams(getQueryParams());
  }, [history.location.search]);

  const setQueryParams = (newParams, pathname = '') => {
    const queryParams = new URLSearchParams(newParams);
    history.push({
      pathname: pathname || history.location.pathname,
      search: `?${queryParams.toString()}`,
    });
  };

  return {
    params,
    setQueryParams,
  };
};

export default useSearchParams;
