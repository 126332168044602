import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { debounce, difference } from 'lodash';
import moment from 'moment';
import {
  Button,
  Filepicker,
  FormCheckbox,
  FormInput,
  FormRadioButton,
  FormSelectV2,
  FormTextarea,
  Grid,
  Label,
  Heading,
  Icon,
  Spinner,
  toast,
} from '@lionstep/ui';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { showKomboConnect } from '@kombo-api/connect';
import classnames from 'classnames';

// Selectors
import {
  companySelector,
  isLoadingCompanySelector,
  isUpdatingCompanySelector,
  locationsSelector,
  isLoadingLocationsSelector,
  isLoadingCompanyPicturesSelector,
  companyPicturesSelector,
} from '../../redux/selectors/company.selectors';

// Actions
import {
  getCompany,
  getCompanyPictures,
  getLocations,
  updateCompany,
} from '../../redux/actions/company.actions';

// Hooks
import useIsLionstepAdmin from '../../../../hooks/useIsLionstepAdmin';

// Helpers
import {
  companyInputMapper,
  companyOutputMapper,
  benefitsFormatting,
} from './company.helpers';
import {
  languageShorthandToFull,
  toBase64,
  copyTextToClipboard,
} from '../../../../support';

// Support
import industriesDisplay from '../../../../support/static/industries_display.json';

// Contants
import { DATE_FORMAT } from '../../../../constants/date.const';

// Queries
import { useUserQuery } from '../../../../queries/userQueries';
import { useCompanyKomboIntegrationQuery } from '../../../../queries/companyQueries';
import {
  useKomboCreateLinkMutation,
  useKomboActivateIntegrationMutation,
} from '../../../../queries/komboQueries';

// Components
import ContentCard from '../../../../components/refactoring/contentCard';
import Layout from './components/layout';
import ColorChooser from './components/ColorChooser';
import CompanyPhotos from './components/companyPhotos';
import CustomBenefits from './components/customBenefits';
import PageHeading from '../../../../components/refactoring/pageHeading/PageHeading';
import InfoPlusIconWithTooltip from '../../../../components/refactoring/infoPlusIconWithTooltip';
import ShowHideTranslation from './components/showHideTranslation';

// Styles
import styles from './company.module.scss';

const cx = classnames.bind(styles);

const COMPANY_INDUSTRY_OPTIONS = [
  // TODO: Do we really want empty industry
  {
    label: '',
    value: {
      mid: null,
      display: {
        en: '',
        de: '',
        es: '',
        fr: '',
      },
    },
  },
  ...industriesDisplay.map((industry) => ({
    label: industry.mid,
    value: industry,
  })),
];

// Used to show langauges for empty company. This should be removed once it's done on BE side
const DEFAULT_INFO_LANGUAGES_OPTIONS = [
  { lang: 'en', selected: true },
  { lang: 'de', selected: true },
  { lang: 'es', selected: false },
  { lang: 'fr', selected: false },
];

const Company = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isLionstepAdmin = useIsLionstepAdmin();

  const company = useSelector(companySelector);
  const isLoadingCompany = useSelector(isLoadingCompanySelector);
  const isUpdatingCompany = useSelector(isUpdatingCompanySelector);
  const locations = useSelector(locationsSelector);
  const isLoadingLocations = useSelector(isLoadingLocationsSelector);
  const isLoadingCompanyPictures = useSelector(
    isLoadingCompanyPicturesSelector,
  );
  const companyPictures = useSelector(companyPicturesSelector);

  // Switch this to user from python
  const { data: user } = useUserQuery();
  const {
    data: companyKomboIntegration,
    isFetching: isLoadingCompanyKomboIntegration,
  } = useCompanyKomboIntegrationQuery(
    {
      id: user.company_id,
    },
    {
      enabled: company?.enable_kombo_integration,
    },
  );
  const {
    mutate: createKomboLink,
    data: komboLink,
    isFetching: isLoadingKomboLink,
  } = useKomboCreateLinkMutation();
  const { mutate: activateKomboIntegration } =
    useKomboActivateIntegrationMutation();

  useEffect(() => {
    if (user && !company) {
      dispatch(getCompanyPictures());
    } else if (user && !companyPictures.length) {
      /**
       * This check is for the case when the company was already loaded
       * (for example by routing to the create-job route) but we still
       * need to load the company pictures.
       *
       * @see {@link https://lionstep.atlassian.net/browse/DEV-415}
       */
      dispatch(getCompanyPictures());
    }
  }, [user]);

  const schema = Yup.object().shape({
    info_languages: Yup.array().nullable(),
    acuity_links_de: Yup.string().url('Must be a valid url').nullable(),
    acuity_links_en: Yup.string().url('Must be a valid url').nullable(),
    acuity_links_es: Yup.string().url('Must be a valid url').nullable(),
    acuity_links_fr: Yup.string().url('Must be a valid url').nullable(),
    company_name: Yup.string().required('Required'),
    company_url: Yup.string().url('Must be a valid url'),
    other_names: Yup.string(),
    enable_kombo_integration: Yup.string(),
    can_be_disclosed: Yup.string(),
    replacement_name: Yup.string(),
    location: Yup.object().nullable(),
    competitors_list: Yup.string(),
    preferred_companies: Yup.string(),
    hide_longlist: Yup.boolean(),
    sensitive_client: Yup.string(),
    global_competitors_list: Yup.string().nullable(),
    logo: Yup.object().nullable(),
    logo_filename: Yup.string().nullable(),
    logo_url: Yup.string().nullable(),
    colour: Yup.string()
      .required('Required')
      .matches(
        /^#([0-9a-f]{3}|[0-9a-f]{6})$/i,
        'Must be a valid HEX colour value',
      )
      .nullable(),
    text_colour: Yup.string().required('Required'),
    description: Yup.array().nullable(),
    industry: Yup.string().nullable(),
    industry_object: Yup.object().nullable(),
    founded: Yup.string(),
    company_video: Yup.string().url('Must be a valid url'),
    benefits_pool: Yup.array().nullable(),
    benefits_pool_selected: Yup.array().nullable(),
    benefits_custom: Yup.array().nullable(),
    kununu_rating: Yup.string(),
    glassdoor_rating: Yup.string(),
    diversity_toggle: Yup.string().nullable(),
    diversity_text: Yup.string().when('diversity_toggle', {
      is: true,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
    vision: Yup.string(),
    office_description: Yup.string(),
    corporate_culture: Yup.string(),
  });

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: companyInputMapper(company),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = formMethods;

  const {
    fields: benefitsCustomFields,
    append: appendBenefitsCustom,
    update: updateBenefitsCustom,
  } = useFieldArray({
    control,
    name: 'benefits_custom',
  });
  const { fields: descriptionFields, append: appendDescription } =
    useFieldArray({
      control,
      name: 'description',
    });

  const watchInfoLanguage = watch('info_languages');
  const watchCaBeDisclosed = watch('can_be_disclosed');
  const watchLogoUrl = watch('logo_url');
  const watchColour = watch('colour');
  const watchSensitiveClient = watch('sensitive_client');
  const watchDiversityToggle = watch('diversity_toggle');

  useEffect(() => {
    if (company) {
      reset(companyInputMapper(company));
    }
  }, [company]);

  useEffect(() => {
    // Add New Description
    if (watchInfoLanguage && descriptionFields) {
      const langDiff = difference(
        watchInfoLanguage,
        descriptionFields.map((item) => item.lang),
      );

      if (langDiff.length) {
        langDiff.forEach((lang) =>
          appendDescription(
            company?.description?.find(
              (template) => template.lang === lang,
            ) || {
              lang,
              content: '',
            },
          ),
        );
      }
    }

    // Add New Custom Benefits
    if (watchInfoLanguage && benefitsCustomFields) {
      benefitsCustomFields.forEach((customField, index) => {
        const currBenefitLanguages = customField.description.map(
          (ben) => ben.lang,
        );
        const langDiff = difference(watchInfoLanguage, currBenefitLanguages);

        if (langDiff.length) {
          updateBenefitsCustom(index, {
            ...customField,
            description: [
              ...customField.description,
              ...langDiff.map((langDiffItem) => ({
                content: '',
                lang: langDiffItem,
              })),
            ],
          });
        }
      });
    }
  }, [watchInfoLanguage]);

  const handleOnLocationsSearch = debounce((value) => {
    if (value && value.length > 2) {
      dispatch(getLocations(value, i18n.language));
    }
  }, 500);

  // Think about this
  const handleOnCompanyIndustryChange = (value) => {
    setValue('industry', value.label);
  };

  const handleOnCompanyLogoChange = async ([file]) => {
    const base64String = await toBase64(file);
    setValue('logo_url', base64String);
    setValue('logo_filename', file.name);
  };

  const handleOnCompnayLogoRemove = () => {
    setValue('logo_url', null);
    setValue('logo_filename', null);
  };

  const handleOnCompnayColorRemove = () => {
    setValue('colour', '');
  };

  const handleOnCopyToClipboardClick = () => {
    copyTextToClipboard(company.company_id);
  };

  const handleOnSubmitCompany = async (data) => {
    await dispatch(
      updateCompany({
        id: user.company_id,
        company: companyOutputMapper(data),
      }),
    );

    // TODO: hackish solution until API returns full payload from updateCompany
    dispatch(getCompany(user.company_id));
  };

  // Kombo Integration
  const handleOnKomboIntegration = async () => {
    try {
      const languages = company.info_languages.filter((lang) => lang.selected);
      const language = languages.length ? languages[0].lang : 'en';

      createKomboLink({
        end_user_email: user.email,
        end_user_organization_name: company.company_name,
        end_user_origin_id: company.company_id.toString(),
        language,
      });
    } catch {
      toast.error('Error during Kombo integration, please contact support.', {
        toastId: 'KOMBO_INTEGRATION_ERROR',
      });
    }
  };

  const handleOnKomboActivation = async () => {
    try {
      const activationToken = await showKomboConnect(komboLink);

      await activateKomboIntegration(activationToken);

      toast.success('Kombo integrated successfully!', {
        toastId: 'KOMBO_INTEGRATION_SUCCESS',
      });
    } catch (error) {
      toast.error('Error during Kombo integration, please contact support.', {
        toastId: 'KOMBO_INTEGRATION_ERROR',
      });
    }
  };

  useEffect(() => {
    if (komboLink) {
      handleOnKomboActivation();
    }
  }, [komboLink]);

  // RENDERS
  const renderAcuityLinkInput = (lang) => (
    <FormInput
      formItemClassName={styles.formInputAcuityLinks}
      prefix={
        <img
          src={`/flags/${languageShorthandToFull(lang)}.svg`}
          alt={lang}
          style={{ padding: '10px', borderRadius: '50%', width: '100%' }}
        />
      }
      name={`acuity_links_${lang}`}
      error={errors[`acuity_links_${lang}`]?.message}
    />
  );

  if (
    !company ||
    isLoadingCompany ||
    isLoadingCompanyPictures ||
    isLoadingCompanyKomboIntegration
  ) {
    return <Spinner position="fixed" size={100} />;
  }

  const benefitsFormatted = benefitsFormatting(company.benefits_pool);

  return (
    <Layout company={company}>
      <Helmet>
        <title>{t('company_page.title')} | Lionstep</title>
      </Helmet>

      {isUpdatingCompany && <Spinner position="fixed" size={100} />}

      <Grid.Container className={styles.company}>
        <Grid.Row>
          <Grid.Col xs={24}>
            <PageHeading>{t('company_page.title')}</PageHeading>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col xs={24}>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(handleOnSubmitCompany)}>
                <ContentCard>
                  {/* General  section */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <Heading
                        level={3}
                        id="general"
                        style={{ marginBottom: '25px' }}
                      >
                        {t('company_page.general_section.title')}
                      </Heading>
                    </Grid.Col>
                  </Grid.Row>
                  {/* Company Language */}
                  <Grid.Row>
                    <Grid.Col xs={24} xl={16}>
                      <FormCheckbox
                        inputAlign
                        label={t(
                          'company_page.general_section.company_languages.label',
                        )}
                        labelBold
                        name="info_languages"
                        options={
                          company.info_languages?.map((language) => ({
                            label: t(`languages_short.${language.lang}`),
                            value: language.lang,
                          })) ??
                          DEFAULT_INFO_LANGUAGES_OPTIONS.map((language) => ({
                            label: t(`languages_short.${language.lang}`),
                            value: language.lang,
                          }))
                        }
                        renderTemplate={(elements) => (
                          <Grid.Row gutter={30}>
                            {elements.map((element) => (
                              <Grid.Col xs={12} key={element.props.value}>
                                {element}
                              </Grid.Col>
                            ))}
                          </Grid.Row>
                        )}
                      />
                    </Grid.Col>
                  </Grid.Row>
                  {/* Company Name */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <FormInput
                        label={t(
                          'company_page.general_section.company_name.label',
                        )}
                        labelBold
                        name="company_name"
                        required
                        error={errors.company_name?.message}
                      />
                    </Grid.Col>
                  </Grid.Row>
                  {/* Company Id */}
                  {isLionstepAdmin && (
                    <Grid.Row>
                      <Grid.Col xs={24} md={16} lg={12}>
                        <FormInput
                          label={t(
                            'company_page.general_section.company_id.label',
                          )}
                          labelBold
                          name="company_id"
                          readOnly
                          value={company.company_id}
                          actionAddon={
                            <div className={styles.copyToClipboardWrapper}>
                              <span
                                className={styles.copyToClipboard}
                                onClick={handleOnCopyToClipboardClick}
                              >
                                <Icon name="Copy" />{' '}
                                {t(
                                  'company_page.general_section.company_id.copy_to_clipboard',
                                )}
                              </span>
                            </div>
                          }
                        />
                      </Grid.Col>
                    </Grid.Row>
                  )}
                  {/* Company URL */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <FormInput
                        label={t(
                          'company_page.general_section.company_url.label',
                        )}
                        labelBold
                        name="company_url"
                        placeholder={t(
                          'company_page.general_section.company_url.placeholder',
                        )}
                        error={errors.company_url?.message}
                      />
                    </Grid.Col>
                  </Grid.Row>
                  {/* Company Aliases */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <FormTextarea
                        name="other_names"
                        hint={t(
                          'company_page.general_section.company_aliases.helptext',
                        )}
                        label={t(
                          'company_page.general_section.company_aliases.label',
                        )}
                        labelBold
                        placeholder={t(
                          'company_page.general_section.company_aliases.placeholder',
                        )}
                      />
                    </Grid.Col>
                  </Grid.Row>
                  {/* Can be disclosed */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <FormRadioButton
                        name={'can_be_disclosed'}
                        label={t(
                          'company_page.general_section.disclose_company.label',
                        )}
                        labelBold
                        inputAlign
                        hint={t(
                          'company_page.general_section.disclose_company.helptext',
                        )}
                        options={[
                          {
                            label: t('extra_questions.yes_no_values.yes'),
                            value: 'yes',
                          },
                          {
                            label: t('extra_questions.yes_no_values.no'),
                            value: 'no',
                          },
                        ]}
                        renderTemplate={(elements) => (
                          <Grid.Row gutter={30}>
                            {elements.map((element) => (
                              <Grid.Col key={element.props.value}>
                                {element}
                              </Grid.Col>
                            ))}
                          </Grid.Row>
                        )}
                      />
                    </Grid.Col>
                  </Grid.Row>
                  {/* Replacement name */}
                  {watchCaBeDisclosed === 'no' && (
                    <Grid.Row>
                      <Grid.Col xs={24}>
                        <FormInput
                          label={t(
                            'company_page.general_section.replacement_name.label',
                          )}
                          labelBold
                          name="replacement_name"
                          placeholder={t(
                            'company_page.general_section.replacement_name.placeholder',
                          )}
                          error={errors.replacement_name?.message}
                        />
                      </Grid.Col>
                    </Grid.Row>
                  )}
                  {/* Location */}
                  <FormSelectV2
                    searchable
                    isLoading={isLoadingLocations}
                    name="location"
                    label={t(
                      'company_page.general_section.company_location.label',
                    )}
                    labelBold
                    placeholder={t(
                      'company_page.general_section.company_location.placeholder',
                    )}
                    error={errors.job_location?.message}
                    onSearch={handleOnLocationsSearch}
                    options={locations.map((location) => ({
                      label: location.description,
                      value: location.place_id,
                    }))}
                  />

                  {/* Competitors list */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <FormTextarea
                        name="competitors_list"
                        label={t(
                          'company_page.general_section.competitors_list.label',
                        )}
                        placeholder={t(
                          'company_page.general_section.competitors_list.placeholder',
                        )}
                        hint={t(
                          'company_page.general_section.competitors_list.helptext',
                        )}
                      />
                    </Grid.Col>
                  </Grid.Row>

                  {/* Preferred companies */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <FormTextarea
                        name="preferred_companies"
                        label={t(
                          'company_page.general_section.preferred_companies.label',
                        )}
                        placeholder={t(
                          'company_page.general_section.preferred_companies.placeholder',
                        )}
                        hint={t(
                          'company_page.general_section.preferred_companies.helptext',
                        )}
                      />
                    </Grid.Col>
                  </Grid.Row>
                  {/* Acuity Links */}
                  {isLionstepAdmin && (
                    <Grid.Row>
                      <Grid.Col xs={24}>
                        <Label bold htmlFor="acuity_links">
                          {t('company_page.general_section.acuity_links.label')}
                        </Label>
                      </Grid.Col>
                      <Grid.Col xs={24}>
                        {watchInfoLanguage?.includes('en') &&
                          renderAcuityLinkInput('en')}
                        {watchInfoLanguage?.includes('de') &&
                          renderAcuityLinkInput('de')}
                        {watchInfoLanguage?.includes('fr') &&
                          renderAcuityLinkInput('fr')}
                        {watchInfoLanguage?.includes('es') &&
                          renderAcuityLinkInput('es')}
                      </Grid.Col>
                    </Grid.Row>
                  )}

                  {/* Sensitive Client */}
                  {isLionstepAdmin && (
                    <>
                      <Grid.Row>
                        <Grid.Col xs={24}>
                          <FormRadioButton
                            name={'sensitive_client'}
                            label={
                              <>
                                {t(
                                  'company_page.general_section.sensitive_client.label',
                                )}
                                <InfoPlusIconWithTooltip />
                              </>
                            }
                            labelBold
                            inputAlign
                            options={[
                              {
                                label: t('extra_questions.yes_no_values.yes'),
                                value: 'yes',
                              },
                              {
                                label: t('extra_questions.yes_no_values.no'),
                                value: 'no',
                              },
                            ]}
                            renderTemplate={(elements) => (
                              <Grid.Row gutter={30}>
                                {elements.map((element) => (
                                  <Grid.Col key={element.props.value}>
                                    {element}
                                  </Grid.Col>
                                ))}
                              </Grid.Row>
                            )}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      {/* Enable Company integration */}
                      {isLionstepAdmin && (
                        <Grid.Row>
                          <Grid.Col xs={24}>
                            <FormRadioButton
                              name={'enable_kombo_integration'}
                              label={
                                <>
                                  {t(
                                    'company_page.general_section.enable_kombo_integration.label',
                                  )}
                                  <InfoPlusIconWithTooltip />
                                </>
                              }
                              labelBold
                              inputAlign
                              options={[
                                {
                                  label: t('extra_questions.yes_no_values.yes'),
                                  value: 'yes',
                                },
                                {
                                  label: t('extra_questions.yes_no_values.no'),
                                  value: 'no',
                                },
                              ]}
                              renderTemplate={(elements) => (
                                <Grid.Row gutter={30}>
                                  {elements.map((element) => (
                                    <Grid.Col key={element.props.value}>
                                      {element}
                                    </Grid.Col>
                                  ))}
                                </Grid.Row>
                              )}
                            />
                          </Grid.Col>
                        </Grid.Row>
                      )}

                      {/* Company Blacklist */}
                      {watchSensitiveClient === 'yes' && (
                        <Grid.Row>
                          <Grid.Col xs={24}>
                            <FormTextarea
                              name="global_competitors_list"
                              label={t(
                                'company_page.general_section.global_blacklist.label',
                              )}
                              labelBold
                              placeholder={t(
                                'company_page.general_section.global_blacklist.placeholder',
                              )}
                            />
                          </Grid.Col>
                        </Grid.Row>
                      )}
                    </>
                  )}
                </ContentCard>
                <ContentCard>
                  {/* Displayed section */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <Heading
                        level={3}
                        id="displayed"
                        style={{ marginBottom: '25px' }}
                      >
                        {t('company_page.displayed_section.title')}
                      </Heading>
                    </Grid.Col>
                  </Grid.Row>
                  {/* Company Logo */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <Label htmlFor="logo_filename" bold>
                        {t('company_page.displayed_section.logo.label')}
                      </Label>
                      <div className={styles.rowWithPreview}>
                        <div className={styles.previewSquare}>
                          {watchLogoUrl && (
                            <img src={watchLogoUrl} alt="company logo" />
                          )}
                        </div>
                        <FormInput
                          formItemClassName={styles.previewInputSection}
                          name="logo_filename"
                          readOnly
                          error={errors.logo_filename?.message}
                          suffix={
                            watchLogoUrl ? (
                              <Icon
                                className={styles.removeIcon}
                                name="CloseCircle"
                                size={24}
                                onClick={handleOnCompnayLogoRemove}
                              />
                            ) : null
                          }
                          actionAddon={
                            <Filepicker
                              accept="image/*"
                              onDropAccepted={handleOnCompanyLogoChange}
                              tabindex="-1"
                            >
                              <Button typeHtml="button">
                                {t(
                                  'company_page.displayed_section.logo.upload_logo',
                                )}
                              </Button>
                            </Filepicker>
                          }
                        />
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                  {/* Company Color */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <Label
                        htmlFor="colour"
                        bold
                        hint={t(
                          'company_page.displayed_section.color.helptext',
                        )}
                      >
                        {t('company_page.displayed_section.color.label')}
                      </Label>
                      <div className={styles.rowWithPreview}>
                        <div className={styles.previewSquare}>
                          <span style={{ backgroundColor: watchColour }} />
                        </div>
                        <FormInput
                          formItemClassName={styles.previewInputSection}
                          name="colour"
                          error={errors.colour?.message}
                          suffix={
                            watchColour ? (
                              <Icon
                                className={styles.removeIcon}
                                name="CloseCircle"
                                size={24}
                                onClick={handleOnCompnayColorRemove}
                              />
                            ) : null
                          }
                          actionAddon={
                            <ColorChooser
                              color={watchColour}
                              onColorChange={(color) => {
                                setValue('colour', color);
                              }}
                            />
                          }
                        />
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                  {/* Job Description color */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <FormRadioButton
                        name={'text_colour'}
                        label={t(
                          'company_page.displayed_section.text_color.label',
                        )}
                        labelBold
                        hint={t(
                          'company_page.displayed_section.text_color.helptext',
                        )}
                        inputAlign
                        options={[
                          {
                            value: '#192543',
                            label: t(
                              'company_page.displayed_section.text_color.options.dark',
                            ),
                          },
                          {
                            value: '#fff',
                            label: t(
                              'company_page.displayed_section.text_color.options.light',
                            ),
                          },
                        ]}
                        renderTemplate={(elements) => (
                          <Grid.Row gutter={30}>
                            {elements.map((element) => (
                              <Grid.Col key={element.props.value}>
                                {element}
                              </Grid.Col>
                            ))}
                          </Grid.Row>
                        )}
                      />
                    </Grid.Col>
                  </Grid.Row>
                  {/* Company Description */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <ShowHideTranslation className={styles.messageTemplates}>
                        <ShowHideTranslation.Label
                          htmlFor="description"
                          bold
                          hint={t(
                            'company_page.displayed_section.descritpion.helptext',
                          )}
                        >
                          {t(
                            'company_page.displayed_section.descritpion.label',
                          )}
                        </ShowHideTranslation.Label>
                        {descriptionFields.map((temp, index) =>
                          watchInfoLanguage?.includes(temp.lang) ? (
                            <ShowHideTranslation.Item
                              key={temp.id}
                              index={index}
                              isEditor
                              language={temp.lang}
                              name={`description.${index}.content`}
                              translationPlaceholder={temp.content}
                            />
                          ) : null,
                        )}
                      </ShowHideTranslation>
                    </Grid.Col>
                  </Grid.Row>
                  {/* Company Details */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <Heading level={5} style={{ marginBottom: '25px' }}>
                        {t(
                          'company_page.displayed_section.company_details.label',
                        )}
                      </Heading>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row gutter={30}>
                    {/* Industry */}
                    <Grid.Col xs={24} md={12} lg={8}>
                      <FormSelectV2
                        name="industry_object"
                        label={t(
                          'company_page.displayed_section.company_details.industry',
                        )}
                        placeholder="Select"
                        onChange={handleOnCompanyIndustryChange}
                        options={COMPANY_INDUSTRY_OPTIONS}
                      />
                    </Grid.Col>
                    {/* Founded */}
                    <Grid.Col xs={24} md={12} lg={8}>
                      <FormInput
                        label={t(
                          'company_page.displayed_section.company_details.founded',
                        )}
                        placeholder="Add Year"
                        name="founded"
                      />
                    </Grid.Col>
                    <Grid.Col xs={24} lg={8}>
                      {' '}
                    </Grid.Col>
                    {/* GlassDoor */}
                    <Grid.Col xs={24} md={12} lg={8}>
                      <FormInput
                        label={t(
                          'company_page.displayed_section.glassdoor_rating.label',
                        )}
                        placeholder="E.g. 4.7"
                        name="glassdoor_rating"
                      />
                    </Grid.Col>
                    {/* Kununu */}
                    <Grid.Col xs={24} md={12} lg={8}>
                      <FormInput
                        label={t(
                          'company_page.displayed_section.kununu_rating.label',
                        )}
                        placeholder="E.g. 4.7"
                        name="kununu_rating"
                      />
                    </Grid.Col>
                  </Grid.Row>
                  {/* Diversity */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <FormRadioButton
                        name={'diversity_toggle'}
                        label={t(
                          'company_page.displayed_section.diversity_toggle.label',
                        )}
                        labelBold
                        inputAlign
                        hint={t(
                          'company_page.displayed_section.diversity_toggle.helptext',
                        )}
                        options={[
                          {
                            label: t('extra_questions.yes_no_values.yes'),
                            value: 'yes',
                          },
                          {
                            label: t('extra_questions.yes_no_values.no'),
                            value: 'no',
                          },
                        ]}
                        renderTemplate={(elements) => (
                          <Grid.Row gutter={30}>
                            {elements.map((element) => (
                              <Grid.Col key={element.props.value}>
                                {element}
                              </Grid.Col>
                            ))}
                          </Grid.Row>
                        )}
                      />
                    </Grid.Col>
                  </Grid.Row>

                  {/* Diversity text */}
                  {watchDiversityToggle === 'yes' && (
                    <Grid.Row>
                      <Grid.Col xs={24}>
                        <FormTextarea
                          name="diversity_text"
                          hint={t(
                            'company_page.displayed_section.diversity_text.helptext',
                          )}
                          label={t(
                            'company_page.displayed_section.diversity_text.label',
                          )}
                        />
                      </Grid.Col>
                    </Grid.Row>
                  )}
                  {/* Company Video */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <FormInput
                        label={t(
                          'company_page.displayed_section.company_video.label',
                        )}
                        placeholder={t(
                          'company_page.displayed_section.company_video.placeholder',
                        )}
                        hint={t(
                          'company_page.displayed_section.company_video.helptext',
                        )}
                        labelBold
                        error={errors.company_video?.message}
                        name="company_video"
                      />
                    </Grid.Col>
                  </Grid.Row>
                  {/* Company Benefits */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <Heading level={5} style={{ marginBottom: '25px' }}>
                        {t('company_page.displayed_section.benefits.label')}
                      </Heading>
                    </Grid.Col>
                  </Grid.Row>
                  {benefitsFormatted &&
                    Object.keys(benefitsFormatted).map((benefit) => (
                      <Grid.Row key={benefit}>
                        <Grid.Col xs={24} xl={16}>
                          <FormCheckbox
                            name={'benefits_pool_selected'}
                            label={t(
                              `company_page.displayed_section.benefits.${benefit}.label`,
                            )}
                            labelBold
                            inputAlign
                            options={benefitsFormatted[benefit].map((item) => ({
                              label: t(
                                `company_page.displayed_section.benefits.${benefit}.${item}`,
                              ),
                              value: item,
                            }))}
                            renderTemplate={(elements) => (
                              <Grid.Row gutter={30}>
                                {elements.map((element) => (
                                  <Grid.Col
                                    key={`${benefit}-${element.props.value}`}
                                    xs={12}
                                  >
                                    {React.cloneElement(element, {
                                      ...element.props,
                                      id: element.props.value,
                                    })}
                                  </Grid.Col>
                                ))}
                              </Grid.Row>
                            )}
                          />
                        </Grid.Col>
                      </Grid.Row>
                    ))}

                  {/* Custom Benefits */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <Label
                        bold
                        hint={t(
                          'company_page.displayed_section.benefits.custom_benefits.helptext',
                        )}
                        htmlFor="custom_benefits"
                      >
                        {t(
                          'company_page.displayed_section.benefits.custom_benefits.label',
                        )}
                      </Label>
                    </Grid.Col>
                    <Grid.Col xs={24}>
                      <CustomBenefits
                        languages={watchInfoLanguage}
                        fields={benefitsCustomFields}
                        onAppend={appendBenefitsCustom}
                        onUpdate={updateBenefitsCustom}
                      />
                    </Grid.Col>
                  </Grid.Row>

                  {/* Company Photos */}
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <CompanyPhotos />
                    </Grid.Col>
                  </Grid.Row>
                </ContentCard>

                {/* Kombo integration */}
                {company?.enable_kombo_integration && (
                  <ContentCard>
                    {/* General  section */}
                    <Grid.Row>
                      <Grid.Col xs={24}>
                        <Heading
                          level={3}
                          id="kombo"
                          style={{ marginBottom: '25px' }}
                        >
                          {t('company_page.kombo_section.title')}
                        </Heading>
                      </Grid.Col>
                    </Grid.Row>

                    {companyKomboIntegration ? (
                      <Grid.Row gutter={[30, 30]}>
                        <Grid.Col xs={24} md={8}>
                          <div className={styles.integrationLabel}>
                            {company.company_name}
                          </div>
                          <div className={styles.integrationText}>
                            {moment(
                              companyKomboIntegration.state_updated_at,
                            ).format(DATE_FORMAT)}
                          </div>
                        </Grid.Col>
                        <Grid.Col xs={24} md={8}>
                          <div
                            className={cx(
                              styles.integrationLabel,
                              styles.integrationLabelCapitalized,
                            )}
                          >
                            {companyKomboIntegration.tool}
                          </div>
                          <div className={styles.integrationText}>ATS</div>
                        </Grid.Col>
                        <Grid.Col xs={24} md={8}>
                          <div
                            className={cx(
                              styles.integrationLabel,
                              styles.integrationLabelCapitalized,
                            )}
                          >
                            {companyKomboIntegration.state}
                          </div>
                          <div className={styles.integrationText}>
                            {companyKomboIntegration.last_sync_ended_at ? (
                              <>
                                Synced{' '}
                                {moment(
                                  companyKomboIntegration.last_sync_ended_at,
                                ).fromNow()}
                              </>
                            ) : (
                              'Not Synced Yet'
                            )}
                          </div>
                        </Grid.Col>
                      </Grid.Row>
                    ) : (
                      <Grid.Row>
                        <Grid.Col xs={24}>
                          <Button
                            ghost
                            disabled={isLoadingKomboLink}
                            onClick={handleOnKomboIntegration}
                          >
                            Connect To ATS
                          </Button>
                        </Grid.Col>
                      </Grid.Row>
                    )}
                  </ContentCard>
                )}

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <Button typeHtml="submit">
                      {t('company_page.save_btn')}
                    </Button>
                  </Grid.Col>
                </Grid.Row>
              </form>
            </FormProvider>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Layout>
  );
};

export default Company;
