import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Badge, Icon } from '@lionstep/ui';

// Constants
import { USER_ROLES } from '../../../../../../constants/user.const';

// Queries
import { useUserQuery } from '../../../../../../queries/userQueries';

// Helpers
import {
  formatSalaryExpectation,
  formatNoticePeriod,
} from './candidateBody.helpers';

// Styles
import styles from './contentKeyInfo.module.scss';

// Render
const ContentKeyInfo = ({ candidate, extraInfo }) => {
  const { data: user } = useUserQuery();
  const { t } = useTranslation();

  const renderSalaryBadges = () => {
    const badges = [];

    if (candidate.salary_on_budget) {
      badges.push(
        <Badge type="success">{t('candidate_page.body.on_budget')}</Badge>,
      );
    }

    if (candidate.negotiable_salary) {
      badges.push(
        <Badge type="success" ghost>
          {t('candidate_page.body.negotiable_salary')}
        </Badge>,
      );
    }

    return badges;
  };

  const renderLocationBadge = () => {
    if (!candidate.location_valid && !candidate.willing_to_move) {
      return null;
    }

    return (
      <Badge type="success" ghost={!candidate.location_valid}>
        {candidate.location_valid
          ? t('candidate_page.body.lives_in_area')
          : t('candidate_page.body.willing_to_move')}
      </Badge>
    );
  };

  const salaryExpectation = formatSalaryExpectation({ candidate, extraInfo });

  return (
    <div className={styles.contentCard}>
      <div className={styles.content}>
        {/* Salary */}
        {user.role !== USER_ROLES.viewer && (
          <>
            <span className={styles.subtitle}>
              {t('candidate_page.body.salary_expectation')}
            </span>
            <div>
              <div className={styles.valueAndBadge}>
                <span
                  className={classnames({
                    [styles.noInfoToShow]: !salaryExpectation,
                  })}
                >
                  {salaryExpectation || t('no_info_to_show')}
                </span>
                {renderSalaryBadges()}
              </div>
              {candidate.salary_comment && (
                <p className={styles.note}>{candidate.salary_comment}</p>
              )}
            </div>
          </>
        )}
        {/* Location */}
        <span className={styles.subtitle}>
          {t('candidate_page.body.place_of_residence')}
        </span>
        <div>
          <div className={styles.valueAndBadge}>
            {candidate.talent_location &&
            candidate.talent_location !== 'Unknown' ? (
              <span className={styles.location}>
                {candidate.talent_location}
              </span>
            ) : (
              <span className={styles.noInfoToShow}>
                {t('no_info_to_show')}
              </span>
            )}
            {renderLocationBadge()}
          </div>
          {candidate.location_comment && (
            <p className={styles.note}>{candidate.location_comment}</p>
          )}
        </div>
        {/* Notice period */}
        <span className={styles.subtitle}>
          {t('candidate_page.body.notice_period')}
        </span>
        <div>
          <div className={styles.valueAndBadge}>
            {candidate.notice_period ? (
              <span>{formatNoticePeriod(candidate.notice_period, t)}</span>
            ) : (
              <span className={styles.noInfoToShow}>
                {t('no_info_to_show')}
              </span>
            )}
          </div>
          {candidate.notice_period_comment && (
            <p className={styles.note}>{candidate.notice_period_comment}</p>
          )}
        </div>
        {/* Documents */}
        <span className={styles.subtitle}>{t('documents')}</span>
        <div>
          <div className={styles.valueAndBadge}>
            {candidate.documents_uploaded ? (
              <span className={styles.documentsAvailable}>
                <Icon name="CheckCircle" />{' '}
                {t('candidate_page.body.documents_available')}
              </span>
            ) : (
              <span
                className={classnames({
                  [styles.noInfoToShow]: !candidate.documents_uploaded_comment,
                })}
              >
                {candidate.documents_uploaded_comment || t('no_info_to_show')}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ContentKeyInfo.propTypes = {
  candidate: PropTypes.shape().isRequired,
  extraInfo: PropTypes.shape().isRequired,
};

export default ContentKeyInfo;
