// import './wdyr'; // IMPORTANT TO BE USED ONLY IN DEV MODE !

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

import * as Sentry from '@sentry/react';

import store from './redux/store';

import './styles/app.scss';
import '@lionstep/ui/dist/index.css';

import App from './container/App';
import i18n from './i18n';
import { unregister } from './registerServiceWorker';
import { ReactComponent as Logo } from './assets/icons/lionstep-logo.svg';

import { ENV, SENTRY_DNS } from './config';

function checkCookie() {
  let { cookieEnabled } = navigator;
  if (!cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
  }
  return cookieEnabled;
}

unregister();

if (ENV === 'production' || ENV === 'qa') {
  Sentry.init({
    dsn: SENTRY_DNS,
  });
}

if (checkCookie()) {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>,
  );
} else {
  ReactDOM.render(
    <div
      style={{ height: '100vh' }}
      className="d-flex align-items-center justify-content-center"
    >
      <div>
        <h4>
          <Logo
            className="d-flex"
            style={{ height: 30, width: 'auto', margin: '0 auto' }}
          />
        </h4>
        <h4 className="p-5 text-center">
          Please enable browser cookies to view the page content.
        </h4>
      </div>
    </div>,
    document.getElementById('root'),
  );
}
