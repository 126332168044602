import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/react';
import { Grid, Spinner, Heading, Text, toast } from '@lionstep/ui';

// Helpers
import { getRecaptcha } from '../../../support';

// Queries
import { useUserRegisterMutation } from '../../../queries/userQueries';

// Components
import RegisterForm from './RegisterForm';
import { ReactComponent as Logo } from '../../../assets/icons/lionstep-logo.svg';

// Constants
import { USER_ROLES } from '../../../constants/user.const';

// Styles
import styles from './register.module.scss';

const Register = () => {
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const { mutate: userRegister, isLoading } = useUserRegisterMutation();

  const handleOnSubmit = async (data) => {
    const token = await getRecaptcha();

    userRegister(
      {
        data: {
          ...data,
          role: USER_ROLES.admin,
          recaptcha_token: token,
          lang: i18n.language,
        },
      },
      {
        onSuccess: () => history.push('/'),
        onError: (error) => {
          toast.error('Register fail, please contact support.', {
            id: 'REGISTER_FAIL',
          });

          Sentry.captureException(error, {
            extra: {
              ...data,
              recaptcha_token: token,
              lang: i18n.language,
            },
          });
        },
      },
    );
  };

  return (
    <Grid.Container>
      <Grid.Row gutter={{ xs: 32, md: 64 }} className={styles.register}>
        <Grid.Col className={styles.info} xs={12} lg={{ span: 10, offset: 2 }}>
          <Logo className={styles.logo} />
          <Heading className={styles.title} level={1} type="secondary">
            {`${t('start_free_trial')}.`}
          </Heading>
          <Text>{t('see_matching_candidates')}</Text>
          <div className={styles.infoList} data-testid="register-info-list">
            <Text className={styles.infoListItem}>
              {t('get_interested_candidates')}
            </Text>
            <Text className={styles.infoListItem}>{t('keep_track')}</Text>
            <Text className={styles.infoListItem}>{t('benefit_from')}</Text>
          </div>
        </Grid.Col>
        <Grid.Col xs={24} md={12} lg={10} xl={10}>
          <Helmet>
            <title>{`${t('register')} | Lionstep`}</title>
          </Helmet>

          {isLoading && <Spinner position="fixed" size={100} />}

          <RegisterForm onSubmit={handleOnSubmit} />
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

export default Register;
