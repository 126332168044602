import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table, Icon, Heading } from '@lionstep/ui';

// Actions
import { removeJobCollaborator } from '../../../../../redux/actions';

// Styles
import styles from './collaboratorsTable.module.scss';

const CollaboratorsTable = ({ collaboratorsOnJob, job }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleOnRemoveCollaborator = (userId) => {
    dispatch(removeJobCollaborator({ jobId: job.id, userId }));
  };

  return (
    <>
      <Heading className={styles.collaboratorTitle} level={4}>
        {t('collaborators_on_job')}
      </Heading>
      <Table>
        <Table.Header>
          <Table.HeaderRow>
            <Table.HeaderCell>{t('name')}</Table.HeaderCell>
            <Table.HeaderCell>{t('email')}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">{t('role')}</Table.HeaderCell>
          </Table.HeaderRow>
        </Table.Header>
        <Table.Body>
          {collaboratorsOnJob.map((collaborator) => (
            <Table.Row key={collaborator.local_id}>
              <Table.Cell>{collaborator.display_name}</Table.Cell>
              <Table.Cell>{collaborator.email}</Table.Cell>
              <Table.Cell
                textAlign="center"
                className={styles.collaboratorRoleCell}
              >
                {collaborator.role}
                <span
                  className={styles.collaboratorRemoveIcon}
                  onClick={() =>
                    handleOnRemoveCollaborator(collaborator.local_id)
                  }
                >
                  <Icon name="CloseCircle" />
                </span>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

CollaboratorsTable.defaultProps = {
  job: {},
};

CollaboratorsTable.propTypes = {
  collaboratorsOnJob: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  job: PropTypes.shape(),
};

export default CollaboratorsTable;
